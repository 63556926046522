import { Component, Input, OnInit } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiDocumentSentFilter, IApiDocumentSentFilterType, IApiDocumentSentOrderBy, IApiSortOrder } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DownloadHelper } from 'src/app/shared/services';
import { ReportSentMetricService } from 'src/app/shared/services/reports';
import { ReportSentMetricDataSource } from 'src/app/shared/services/reports/report-sent-metric/report-sent-metric.datasource';

@Component({
  selector: 'app-report-sent-metric-by-editor',
  templateUrl: './report-sent-metric-by-editor.component.html',
  styleUrls: ['./report-sent-metric-by-editor.component.scss']
})
export class ReportSentMetricByEditorComponent implements OnInit {
  private _dataSource: ReportSentMetricDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public data = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiDocumentSentOrderBy.SendDate,
      dir: "desc",
    },
  ];
  
  constructor(
    private loaderService: LoaderService,
    private reportSentMetricService: ReportSentMetricService,
    private notificationService: NotificationsService,
    private downloadHelper: DownloadHelper,
  ) { 
    this.dataSource = new ReportSentMetricDataSource(this.reportSentMetricService);
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.applyFilter(IApiDocumentSentFilterType.ReportBy, 'EDITOR');
    this.dataSource.listPage.orderBy = IApiDocumentSentOrderBy.SendDate;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
  }

  ngOnInit(): void {
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiDocumentSentOrderBy.SendDate;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  private load(): void {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = (res || [])?.map(item => {
          return {
            ...item,
            ReportEditor: JSON.parse(item.ReportEditor)
          };
        });
      });
    });
  }

  public applyFilter(filters: IApiDocumentSentFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.applyFilter(IApiDocumentSentFilterType.ReportBy, 'EDITOR');
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  public exportCsv() {
    const params = {
      filters: this.dataSource?.lastFilters,
      orderBy: this.dataSource?.listPage?.orderBy,
      last: -1,
      sortOrder: this.dataSource?.listPage?.sortOrder === 'ASC' ? IApiSortOrder.Asc : IApiSortOrder.Desc,
    };
    this.loaderService.show$(this.reportSentMetricService.getCsvReport(params).pipe(
      this.notificationService.snackbarErrorPipe("Error downloading report; please try again"),
    )).subscribe((res) => {
      if (res) {
        this.downloadHelper.download(res, 'report_sent_metric_by_editor.csv');
      }
    });
  }

}
