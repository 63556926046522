<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <ng-container [ngSwitch]="data.dialogType">
        <span *ngSwitchCase="dialogTypes.SENDBACK">Send Back Report</span>
        <span *ngSwitchCase="dialogTypes.INDICATE_CAUSE">Indicate Cause</span>
        <span *ngSwitchCase="dialogTypes.APPROVE">Approve Report</span>
        <span *ngSwitchCase="dialogTypes.ASSIGN">Assign Investigation Report</span>
        <span *ngSwitchCase="dialogTypes.APPROVE_REPORT">Approve Investigation Report</span>
        <span *ngSwitchCase="dialogTypes.UNASSIGN">Unassign Document</span>
        <span *ngSwitchCase="dialogTypes.APPROVE_FINAL">Approve Final Report</span>
      </ng-container>
      <p class="modal-subtitle mb-0">Investigation #{{data?.nefcoNumber}}</p>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <ng-container [ngSwitch]="data.dialogType">
      <p class="warning-message" *ngSwitchCase="'assign'">Please confirm the assignment of this report.</p>
      <p class="warning-message" *ngSwitchCase="'approve report'">Please confirm the assignment of this report.</p>
      <p class="warning-message" *ngSwitchCase="dialogTypes.UNASSIGN">Please provide a comment for unassigning this
        document.</p>
    </ng-container>
    <!-- Comment Date and Name -->
    <kendo-gridlayout *ngIf="data.dialogType === 'investigation comment'; else formInputs" [rows]="['auto']"
      [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <p class="kendo-caption">Entered by:</p>
        <p class="kendo-caption-body"> {{ data.assignedUser | fullName }} </p>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item>
        <p class="kendo-caption">Date:</p>
        <p class="kendo-caption-body" [innerHTML]="data.updatedAt | customDate: 'MM/dd/yyyy'"></p>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <!-- Form input -->
    <ng-template #formInputs>
      <kendo-gridlayout [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngIf="investigationDetail; else causeLoader">
          <div class="kendo-formfield">
            <kendo-label [for]="causeOfFire" text="Cause of Fire"></kendo-label>
            <kendo-dropdownlist #causeOfFire [data]="(causes | keyvalue)" textField="key" valueField="value"
              [value]="investigationDetail?.cause" [valuePrimitive]="false" fillMode="outline" [filterable]="false"
              [defaultItem]="{value: null, key: 'Select cause of fire'}" (valueChange)="cause = $event.value"
              [popupSettings]="{appendTo: 'component', animate: false, width: 250 }">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.value === null" class="placeholder"> {{ dataItem?.key }} </span>
                <span *ngIf="dataItem?.value !== null" class="not-placeholder"> {{ dataItem?.value }} </span>
              </ng-template>
              <ng-template kendoDropDownListItemTemplate="" let-dataItem>
                <span *ngIf="dataItem?.value === null" class="placeholder"> {{ dataItem?.key }} </span>
                <span *ngIf="dataItem?.value !== null" class="not-placeholder"> {{ dataItem?.value }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <ng-template #causeLoader>
        <kendo-loader type="infinite-spinner" themeColor="primary" size="small">
        </kendo-loader>
      </ng-template>
      <p class="warning-message mb-0" *ngIf="data.dialogType === dialogTypes.SENDBACK">Please provide an explanation for
        sending this report back</p>
    </ng-template>
    <!-- Comment information -->
    <kendo-gridlayout [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <kendo-formfield>
          <kendo-label class="asterisk" text="Comments"></kendo-label>
          <kendo-textarea name="comments" #notes="ngModel" [(ngModel)]="comment" placeholder="comments"
            fillMode="outline" resizable="both" appNoWhiteSpace></kendo-textarea>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
    <div class="actions-btn">
      <ng-container [ngSwitch]="data.dialogType">
        <button kendoButton [size]="'medium'" themeColor="light" class="kendo-btn" (click)="close(false)">
          Cancel
        </button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          (click)="advanceReport(true)" *ngSwitchCase="dialogTypes.SENDBACK">
          Send Back
        </button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.INDICATE_CAUSE" (click)="indicateCause()">Save</button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.APPROVE" (click)="advanceReport()">Approve Report</button>
        <button kendoButton class="kendo-btn" [disabled]="!comment" [size]="'medium'" themeColor="primary"
          *ngSwitchCase="dialogTypes.ASSIGN" (click)="advanceReport()">
          Assign Report
        </button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.APPROVE_REPORT" (click)="advanceReport()">Approve
          Report</button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.APPROVE_FINAL" (click)="advanceReport()">Approve
          Report</button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.REQUEST_UPDATE" (click)="updateRequest()">Update Request</button>
        <button kendoButton class="kendo-btn" [size]="'medium'" themeColor="primary" [disabled]="!comment"
          *ngSwitchCase="dialogTypes.UNASSIGN" (click)="advanceReport(true)">Unassign</button>
      </ng-container>
    </div>
  </div>
</div>
