import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { investigationScheduledDateUpdateAll, investigationScheduledDateUpdateOwn } from 'src/app/shared/helpers/auth-config/investigations.config';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { IApiInvestigation, IApiInvestigationOnScene, IApiInvestigationOnSceneFilterType, IApiInvestigationRoleNames, IApiInvestigationStaff, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, InsuredPartyService, InvestigationOnSceneService } from 'src/app/shared/services';

@Component({
  selector: 'app-mobile-schedule-on-scene-exam',
  templateUrl: './mobile-schedule-on-scene-exam.component.html',
  styleUrls: ['./mobile-schedule-on-scene-exam.component.scss']
})
export class MobileScheduleOnSceneExamComponent implements AfterViewInit, OnDestroy {

  @ViewChild('drawerScheduleDate') drawerScheduleDate: DrawerComponent;
  @ViewChild("insuredFrm", { static: false }) insuredFrm: NgForm;

  public investigation: IApiInvestigation;
  public currentDate = new Date(dayjs().startOf('day').toDate());
  public currentDateTime = new Date(dayjs().toDate());

  public staffList = [];
  public authConfig = {
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn
  }
  public currentUser: IApiUser;
  public scheduledFormEntry = [];
  private removeScheduledEntryIds = [];
  private investigationOnScenes: IApiInvestigationOnScene[] = null;
  public hasAllPermission: boolean = false;

  public steps = [
    { label: "Enter Contact Insured Information" },
    { label: "Schedule On Scene Exam Date"},
  ];

  public model = {
    staff: null,
    contactedDate: null
  }
  public currentStep = 0;
  public receivedDate: Date = null;

  constructor(
    private router: Router,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private auth: AuthService,
    private investigationOnSceneService: InvestigationOnSceneService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private insuredPartyService: InsuredPartyService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const data = this.router.getCurrentNavigation()?.extras?.state?.data
        if (data?.id) {
          this.investigation = data;
        }
      }
    });
  }

  convert(scheduledDate, timezone) {
    const chicagoTime = dayjs(scheduledDate).tz(timezone);
    const hours = chicagoTime.hour();
    const minutes = chicagoTime.minute();
    const date = new Date(scheduledDate);
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setDate(chicagoTime.date());
    return date;
  }
  setForm() {
    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
      this.auth.hasCategoryPermission(this.authConfig.investigationScheduledDateUpdateAll.category, this.authConfig.investigationScheduledDateUpdateAll.appliedPermissions).subscribe((result) => {
        if (result === false) {
          this.investigationOnScenes = this.investigationOnScenes?.filter((onScene) => onScene?.User?.id === u.id);
        } else {
          this.hasAllPermission = true;
        }

        this.model.staff = this.hasAllPermission === false ? this.currentUser.id : this.model.staff;
        const pendingArray = [];
        const datesArray = this.investigationOnScenes?.filter((onScene) => onScene?.status === InvestigationOnSceneStatus.SCHEDULED).sort((a: any, b: any) => new Date(b.scheduledDate).valueOf() - new Date(a.scheduledDate).valueOf());
        for (const entry of datesArray) {
          const scheduled = !this.investigationOnScenes?.some(onScene => onScene?.status !== InvestigationOnSceneStatus.SCHEDULED && entry?.User?.id === onScene?.User?.id && entry?.scheduledDate === onScene?.scheduledDate);
          if (scheduled) {
            pendingArray.push(entry);
          }
        }
        this.investigationOnScenes = pendingArray;

        if (this.investigationOnScenes?.length) {
          this.investigationOnScenes?.forEach((onScene) => {
            const date = this.convert(onScene.scheduledDate, this.investigation?.timezone);
            this.addScheduledEntry({
              id: onScene?.id,
              scheduledDate: date,
              scheduledTime: date,
              UserId: onScene?.User?.id,
            });
          })
        } else {
          this.addScheduledEntry();
        }
      })
    }
    );


  }

  ngAfterViewInit() {
    this.drawerScheduleDate.toggle();
    this.addBodyClass(true);
    this.cdr.detectChanges();
    if (this.investigation?.id) {
      if(this.investigation.InsuredContacted){
        this.currentStep = 1;
      }
      this.receivedDate = new Date(this.investigation.receivedDate);
      this.staffList = this.staffSort(this.investigation.InvestigationStaff);
      this.loader.show$(
        forkJoin([
          this.investigationOnSceneService.get([{ type: IApiInvestigationOnSceneFilterType.InvestigationId, value: this.investigation.id }], { limit: -1 }).pipe(
            unwrapConnection()
          ),
        ])
      ).subscribe(([investigationOnScenes]) => {
        this.investigationOnScenes = investigationOnScenes;
        this.setForm();
      });
    }
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  close() {
    this.drawerScheduleDate.toggle();
    this.addBodyClass(false);
    this.location.back();
  }

  public trackByIndex(index: number) {
    return index + "_data";
  }

  public addScheduledEntry(data?) {
    this.scheduledFormEntry.push({
      id: data?.id || '',
      scheduledDate: data?.scheduledDate || null,
      scheduledTime: data?.scheduledTime || '',
      InvestigationId: this.investigation.id || '',
      UserId: this.hasAllPermission === false ? this.currentUser.id : (data?.UserId || ''),
      timezone: data?.timezone || '',
      notifyClient: this.scheduledFormEntry?.length && !data?.id ? true : false
    })
  }

  public removeScheduledEntry(index: number) {
    if (this.scheduledFormEntry[index]?.id) {
      this.notifications
        .kendoMobileConfirm(
          "Are you sure you want to remove this scheduled on scene exam date? You will not be able to undo this action.",
          "Remove On Scene Exam Date",
          "No, Don’t Remove",
          "Yes, Remove",
          "100%",
          true,
          "",
          "p-16",
          true
        )
        .subscribe((result) => {
          if (result === true) {
            this.removeScheduledEntryIds.push(this.scheduledFormEntry[index]?.id);
            this.scheduledFormEntry.splice(index, 1);
            if (this.scheduledFormEntry[0] && !this.scheduledFormEntry[0].id) {
              this.scheduledFormEntry[0].notifyClient = false;
            }
          }
        });

    } else {
      this.scheduledFormEntry.splice(index, 1);
      if (this.scheduledFormEntry[0] && !this.scheduledFormEntry[0].id) {
        this.scheduledFormEntry[0].notifyClient = false;
      }
    }
  }

  submit(): void {
    const entries = this.scheduledFormEntry?.map(onScene => {
      const scheduledDate = dayjs(dayjs(onScene?.scheduledDate).format('YYYY-MM-DD') + 'T' + dayjs(onScene?.scheduledTime).format('HH:mm:ss')).tz(this.investigation.timezone, true).toISOString();
      return {
        id: onScene.id,
        InvestigationId: onScene?.InvestigationId,
        UserId: onScene?.UserId,
        scheduledDate: scheduledDate,
        notifyClient: onScene.notifyClient
      }
    })
    this.loader.show$(
      this.investigationOnSceneService.updateInvestigationOnSceneScheduledDate(
        {
          removeIds: this.removeScheduledEntryIds,
          data: entries
        }
      )
    ).subscribe(data => this.close())
  }

  public insuredSubmit() {
    if (!this.insuredFrm.valid) {
      return;
    }
    const date = dayjs(this.model.contactedDate).tz(this.investigation.timezone, true).toISOString();
    const input = {
      InvestigationId: this.investigation.id,
      UserId: this.hasAllPermission === false ? this.currentUser.id : this.model.staff,
      date: date
    };
    this.loader.show$(
      this.insuredPartyService.addInsuredContact(input),
    ).subscribe((result) => {
      this.currentStep = 1;
      this.investigation.InsuredContacted = result;
    })
  }

  deleteSingle(): void {
    this.notifications.kendoConfirm(
      "Are you sure you want to delete this on scene investigation? All data associated with this on scene investigation will be removed from Extranet 3.",
      "Delete On Scene Investigation?",
      "No, Don’t Delete",
      "Yes, Delete",
      600,
      "fa-solid fa-circle-exclamation fa-xl",
      this.investigation?.nefcoNumber ? `Investigation ID: ${this.investigation?.nefcoNumber}` : ""
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          this.loader.show$(
            this.investigationOnSceneService.updateInvestigationOnSceneScheduledDate(
              {
                removeIds: [this.scheduledFormEntry[0]?.id],
                data: []
              }
            )
          ))
      )
      .subscribe(() => {
        this.close();
      });
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_ = list?.filter((user) => user?.Role?.title === IApiInvestigationRoleNames.Investigator);
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

  noAction(e){
    e.preventDefault();
  }

  public ngOnDestroy(): void {
    this.addBodyClass(false);
  }

}
