<mat-form-field *ngIf="isMat">
    <mat-label> Risk Type </mat-label>
    <mat-select name="riskTypeSelect" [(ngModel)]="riskTypeId" (selectionChange)="handleSelectionChange($event)">
        <mat-option *ngFor="let riskType of riskTypes" [value]="riskType.id" [innerHtml]="riskType.name"></mat-option>
    </mat-select>
</mat-form-field>
<kendo-formfield *ngIf="!isMat">
  <kendo-label [for]="riskType" text="Risk Type"></kendo-label>
  <kendo-dropdownlist [data]="riskTypes" textField="name" valueField="id" fillMode="outline" [defaultItem]="{id: null, name: 'Select risk type'}" (valueChange)="handleSelectionChange($event)"
    [valuePrimitive]="true"  [name]="'riskTypes'" #riskType="ngModel" [(ngModel)]="riskTypeId" [required]="required">
    <ng-template kendoDropDownListValueTemplate let-dataItem>
      <span *ngIf="!dataItem?.id" class="placeholder"> {{ dataItem?.name}} </span>
      <span *ngIf="dataItem?.id" class="not-placeholder"> {{ dataItem?.name}} </span>
    </ng-template>
    <ng-template kendoDropDownListNoDataTemplate>
    </ng-template>
  </kendo-dropdownlist>
  <kendo-formerror *ngIf="!riskType?.valid && (riskType?.dirty || riskType?.touched)">
    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
    <span *ngIf="riskType?.errors?.required">Risk type is required</span>
  </kendo-formerror>
</kendo-formfield>
