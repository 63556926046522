<div [dialogRef]="dialog" (appClickOutside)="close()">
<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationReportsSnapshotsDownload"
  #investigationReportsSnapshotsDownload="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-dialog-titlebar (close)="close()">
  <div class="modal-title">
    <span>{{ report.title }}</span>
  </div>
</kendo-dialog-titlebar>
<kendo-card width="100%" class="mb-24 mt-24">
  <kendo-panelbar [expandMode]="2">
    <!-- Workflow step and add comment / send to next step -->
    <kendo-panelbar-item [expanded]="currentSnapshot?.status !== 'FINAL'">
      <ng-template kendoPanelBarItemTitle>
        <span class="d-inline-block mt-1">Workflow Step:&nbsp;{{ InvestigationReportStatus[currentSnapshot?.status]
          }}</span>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
          class="mb-16 kendo-gridlayout-common">
          <kendo-gridlayout-item class="mb-0">
            <div class="kendo-formfield">
              <kendo-label text="Please provide some details about this update."></kendo-label>
              <kendo-textarea #commentReview="ngModel" name="reviewComment" [(ngModel)]="reviewComment"
                placeholder="Enter details" fillMode="outline" resizable="both">
              </kendo-textarea>
            </div>
          </kendo-gridlayout-item>
          <div class="mt-16 right-buttons">
            <button kendoButton themeColor="light" fillMode="solid" (click)="sendBack()"
              [disabled]="!reviewComment?.trim()?.length" *ngIf="currentSnapshot?.status !== 'DRAFT'">
              {{ currentSnapshot?.status === IApiInvestigationReportStatus.ClientReview ? "Send Back to Editor" : "Send Back" }}
            </button>
            <button kendoButton
              [themeColor]="currentSnapshot?.status === IApiInvestigationReportStatus.EditorReview ? 'light' : 'primary'"
              class="ml-16" fillMode="solid" (click)="approve()" [disabled]="!reviewComment?.trim()?.length"
              *ngIf="currentSnapshot?.status !== 'FINAL'">
              Send to {{ reports.nextStatus({status: currentSnapshot.status, isIncendiary: (investigation.isIncendiary
              ||
              investigation?.Detail?.cause === causes.Incendiary)}) }}
            </button>
            <button kendoButton themeColor="primary" class="ml-16" fillMode="solid" (click)="moveToFinal()"
              [disabled]="!reviewComment?.trim()?.length"
              *ngIf="currentSnapshot?.status === IApiInvestigationReportStatus.EditorReview">
              Send to {{ InvestigationReportStatus.FINAL }}
            </button>
          </div>
        </kendo-gridlayout>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<!-- Document History table -->
<kendo-card width="100%" class="mb-24 mt-24">
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <span class="d-inline-block mt-1">History ({{ history.length }})</span>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <kendo-grid [data]="history" [sortable]="false" [navigatable]="false">

          <kendo-grid-column title="#" [width]="10" [sortable]="false" [headerClass]="'nocursor'"
            class="id-column font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <a [href]="dataItem.s3Uri | s3" target="_blank" *ngIf="investigationReportsSnapshotsDownload?.enabled">
                <i class="fa-solid fa-download"></i>
              </a>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Created" [width]="20" [sortable]="false" [headerClass]="'nocursor'"
            class="font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem?.createdAt | customDate: 'MM/dd/yyyy'}}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Status" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <app-report-status-icon-kendo [snapshot]="dataItem"></app-report-status-icon-kendo>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Comment" [width]="35" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p [innerHTML]="dataItem.comment"></p>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="User" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.CreatedBy | fullName }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
<kendo-card width="100%" class="mb-24 mt-24">
  <kendo-panelbar [expandMode]="2">
    <kendo-panelbar-item [expanded]="true">
      <ng-template kendoPanelBarItemTitle>
        <span class="d-inline-block mt-1"> Comment Log ({{ report.Comments.length }})</span>
      </ng-template>
      <ng-template kendoPanelBarContent>
        <kendo-grid [data]="report?.Comments" [sortable]="false" [navigatable]="false">
          <kendo-grid-column title="Created" [width]="20" [sortable]="false" [headerClass]="'nocursor'"
            class="font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{dataItem?.createdAt | customDate: 'MM/dd/yyyy'}}</span>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="Comment" [width]="60" [sortable]="false" [headerClass]="'nocursor'"
            class="font-weight600">
            <ng-template kendoGridCellTemplate let-dataItem>
              <p [innerHTML]="dataItem?.text || '-'"></p>
            </ng-template>
          </kendo-grid-column>
          <kendo-grid-column title="User" [width]="20" [sortable]="false" [headerClass]="'nocursor'">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span>{{ dataItem?.CreatedBy | fullName }}</span>
            </ng-template>
          </kendo-grid-column>
        </kendo-grid>
        <!-- Add comment section -->
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
          class="mb-16 kendo-gridlayout-common mt-1">
          <kendo-gridlayout-item>
            <div class="kendo-formfield">
              <kendo-label text="Leave a comment ..."></kendo-label>
              <kendo-textarea #comment name="reviewComment" placeholder="Enter comment" fillMode="outline"
                resizable="both">
              </kendo-textarea>
            </div>
          </kendo-gridlayout-item>
          <div class="right-buttons">
            <button kendoButton themeColor="primary" fillMode="solid" [disabled]="!comment?.value?.trim()"
              (click)="addComment(comment)">Add
              Comment</button>
          </div>
        </kendo-gridlayout>
      </ng-template>
    </kendo-panelbar-item>
  </kendo-panelbar>
</kendo-card>
</div>