import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { TimeEntryTypeService } from 'src/app/shared/services/time-entry/time-entry-type/time-entry-type.service';
import { TimeEntryTypeAppliesToNames } from 'src/app/shared/modules/graphql/enums/time-entry.enums';
import { IApiTimeEntryType, IApiUpdateTimeEntryTypeInput, IApiAddTimeEntryTypeInput } from "src/app/shared/modules/graphql/types/types";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';

@Component({
  templateUrl: './time-entry-type-modal.component.html',
  styleUrls: ['./time-entry-type-modal.component.scss']
})
export class TimeEntryTypeModalComponent extends DialogContentBase implements OnInit  {
  @ViewChild('form', { static: false }) form: NgForm;
  @Input() data: IApiTimeEntryType

  public timeEntryTypeAppliesTo = [{
    key: 'Investigation',
    value: TimeEntryTypeAppliesToNames.INVESTIGATION
  },{
    key: 'Unlinked',
    value: TimeEntryTypeAppliesToNames.UNLINKED
  },{
    key: 'Both',
    value: TimeEntryTypeAppliesToNames.BOTH
  }];

  public model: IApiUpdateTimeEntryTypeInput = { 
    id: "",
    name: '', 
    appliesTo: '', 
    nonStandard: false, 
    additionalDetails: '', 
    activated: true, 
    qbAccount: "" 
  };

  constructor(
    private timeEntryTypeService: TimeEntryTypeService,
    private notificaitonService: NotificationsService,
    public dialog: DialogRef,
  ) {
    super(dialog);
  }

  ngOnInit(): void {
    const {
      id = "",
      name = "",
      appliesTo = "",
      nonStandard = false,
      activated = true,
      additionalDetails = "",
      qbAccount = ""
    } = this.data || {};

    this.model = {
      id,
      name,
      appliesTo,
      nonStandard,
      additionalDetails,
      activated,
      qbAccount
    };
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  close() {
    this.dialog.close();
  }

  private save() {
    this.timeEntryTypeService.update({...this.model,name: this.model?.name?.trim(), additionalDetails: this.model?.additionalDetails?.trim(), id: this.data.id, qbAccount: this.model?.qbAccount?.trim()}).pipe(
      this.notificaitonService.snackbarErrorPipe("Error adding time entry type."),
      this.notificaitonService.snackbarPipe("Time entry type updated.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  private add() {
    const createData = { ...this.model };
    delete createData.id;
    this.timeEntryTypeService.add(createData as IApiAddTimeEntryTypeInput).pipe(
      this.notificaitonService.snackbarErrorPipe("Error adding time entry type."),
      this.notificaitonService.snackbarPipe("Time entry type created.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }
}
