import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SortDescriptor } from '@progress/kendo-data-query';
import { filter } from 'rxjs/operators';
import moment from 'moment';
import { InvestigationStaffDataSource } from 'src/app/shared/services/investigation/investigation-staff/investigation-staff.datasource';
import { InvestigationOnSceneService, InvestigationStaffService, StateService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiChangeOnSceneStatusInput, IApiInvestigation, IApiInvestigationOnScene, IApiInvestigationOnSceneFilter, IApiInvestigationOnSceneFilterType, IApiInvestigationOnSceneOrderBy, IApiInvestigationRoleNames, IApiInvestigationStaff, IApiInvestigationStaffFilter, IApiInvestigationStaffFilterType, IApiInvestigationStaffOrderBy, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IState } from 'src/app/shared/interfaces/state.interfaces';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationOnSceneActivityModalComponent } from 'src/app/shared/components/investigation-on-scene-activity-modal/investigation-on-scene-activity-modal.component'; import {
  dashboardInvestigatorsOnScene,
  dashboardInvestigatorsOnSceneView,
  dashboardInvestigatorsOnSceneUpdate
} from "../../../../shared/helpers/auth-config/dashboard.config";
import { InvestigationOnSceneDataSource } from 'src/app/shared/services/investigation/investigation-on-scene/investigation-staff.datasource';
@Component({
  selector: 'app-dashboard-investigators-table',
  templateUrl: './dashboard-investigators-table.component.html',
  styleUrls: ['./dashboard-investigators-table.component.scss'],
})
export class DashboardInvestigatorsTableComponent implements OnInit {

  public authConfig = {
    dashboardInvestigatorsOnScene,
    dashboardInvestigatorsOnSceneView,
    dashboardInvestigatorsOnSceneUpdate
  };

  @ViewChild('filterForm', { static: false }) filterForm: NgForm;
  @Output() total = new EventEmitter<number>();

  // TODO the filter needs to be added/implemented to only get scheduled investigations

  private _filters: IApiInvestigationOnSceneFilter[] = [];

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  private _dataSource: InvestigationOnSceneDataSource;

  public get dataSource() {
    return this._dataSource;
  }

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public roleName = IApiInvestigationRoleNames;
  public filterTypes = IApiInvestigationOnSceneFilterType;
  public investigationOnSceneStatus = InvestigationOnSceneStatus;
  public investigationOnSceneStatusAll: { text: InvestigationOnSceneStatus, value: InvestigationOnSceneStatus | null }[] = [
    { text: InvestigationOnSceneStatus.EN_ROUTE, value: InvestigationOnSceneStatus.EN_ROUTE },
    { text: InvestigationOnSceneStatus.ON_SCENE, value: InvestigationOnSceneStatus.ON_SCENE },
    { text: InvestigationOnSceneStatus.OFF_SCENE, value: InvestigationOnSceneStatus.OFF_SCENE },
    { text: InvestigationOnSceneStatus.SCHEDULED, value: InvestigationOnSceneStatus.SCHEDULED }
  ];
  public stateList: Array<IState>;
  public onSceneList: IApiInvestigationOnScene[] = [];
  public orderBy = IApiInvestigationOnSceneOrderBy;
  public sort: SortDescriptor[] = [{
    field: IApiInvestigationOnSceneOrderBy.ScheduledDate,
    dir: 'asc'
  }];
  public lastUpdated = moment();
  public selectAll: boolean = false;

  public showDialogStatusChange: boolean = false;
  public selectedStatus: string = '';
  public isSingleStatusChange: boolean = false;
  public singleSelected: IApiInvestigationOnScene | null = null;
  public selected: string[] = [];
  public selectAllInterminate: boolean = false;

  constructor(
    private investigationStaffService: InvestigationStaffService,
    private loader: LoaderService,
    private stateService: StateService,
    private notifications: NotificationsService,
    private investigationOnSceneService: InvestigationOnSceneService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this.stateList = this.stateService.allStates;
    this._dataSource = new InvestigationOnSceneDataSource(this.investigationOnSceneService);
    this._dataSource.listPage.sortOrder = SortOrder.ASC;
    this._dataSource.listPage.orderBy = IApiInvestigationOnSceneOrderBy.ScheduledDate;
    this._dataSource.listPage.limit = 100;
    this.loader.attachObservable(this._dataSource.loading$);
    this.load();
  }

  public getInvestigator(investigation: IApiInvestigation): IApiInvestigationStaff {
    return investigation.InvestigationStaff.find((e) => e.Role.title === IApiInvestigationRoleNames.Investigator);
  }

  public async load(defaultFilter = true) {
    this.singleSelected = null;
    this.selected = [];
    this.selectAll = false;
    if (defaultFilter) {
      this.dataSource.applyFilter(IApiInvestigationOnSceneFilterType.ScheduledDate, JSON.stringify({ startDate: moment().startOf('day'), endDate: moment().endOf('day') }));
    }

    await this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.onSceneList = res;
      });
    });
    this.total.emit(this.pageOptions.totalCount);
  }

  public actionSelected(event, dataItem: IApiInvestigationOnScene): void {
    switch (event?.text) {
      case 'Send Message':
        this.sendMessage(dataItem);
        break;
      case 'View Activity Log':
        this.viewActivityLogs(dataItem)
        break;
      default:
        break;
    }
  }

  public sendMessage(dataItem: IApiInvestigationOnScene) {
    this.investigationStaffService.sendSMSToInvestigator({ StaffId: dataItem?.id }).subscribe((res) => {
      if (res?.success) {
        this.notifications.notify("SMS sent successfully");
      } else {
        this.notifications.alert(res?.message, "Uh oh, something went wrong!", "Ok")
      }
    });
  }

  public viewActivityLogs(dataItem: IApiInvestigationOnScene): void {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationOnSceneActivityModalComponent,
      width: 720,
      maxWidth: 720,
      cssClass: 'right-position',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationOnSceneActivityModalComponent;
    dialogInstance.dataItem = dataItem;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) { };
    })
  }

  public changeStatus(dataitem?: IApiInvestigationOnScene | null, isSingleStatusChange = true): void {
    this.isSingleStatusChange = isSingleStatusChange;
    if (this.isSingleStatusChange) {
      this.singleSelected = dataitem;
    } else {
      this.singleSelected = null;
    }
    this.showDialogStatusChangeOpen(true);
  }

  public showDialogStatusChangeOpen(open: boolean): void {
    this.showDialogStatusChange = open;
    if (this.showDialogStatusChange) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      /* reset because of modal closed */
      this.selectedStatus = '';
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  get saveStatusData(): IApiChangeOnSceneStatusInput {
    let data = {
      data: this.singleSelected
        ? [
          {
            InvestigationId: this.singleSelected.Investigation.id,
            UserId: this.singleSelected.User.id,
            scheduledDate: this.singleSelected.scheduledDate
          },
        ]
        : this.onSceneList
          .filter((item) => this.selected.includes(item.id))
          .map((item) => {
            return {
              InvestigationId: item.Investigation.id,
              UserId: item.User.id,
              scheduledDate: item?.scheduledDate
            };
          }),
      status: this.selectedStatus,
    };
    return data;
  };

  public saveStatus(): void {
    this.filterForm.form.markAllAsTouched();
    if (this.filterForm?.form?.invalid) {
      return;
    }
    this.investigationOnSceneService.changeOnSceneStatus(this.saveStatusData).pipe(
      this.notifications.snackbarPipe("Status updated"),
      this.notifications.snackbarErrorPipe("Error updating status")
    ).subscribe(() => {
      this.showDialogStatusChangeOpen(false);
      this.clear();
    })
  }

  public selectAllCheckboxChange(e) {
    if (e.target.checked) {
      this.selected = this.onSceneList.map(item => item.id);
    } else {
      this.selected = [];
    }
  }

  singleCheckboxChecked(checked: boolean, dataItem: IApiInvestigationOnScene) {
    if (checked) {
      if (!this.selected.includes(dataItem?.id)) {
        this.selected.push(dataItem?.id);
      }
    } else {
      if (this.selected.includes(dataItem?.id)) {
        this.selected = this.selected.filter(id => id != dataItem?.id);
      }
    }
    this.selectAll = this.selected.length === this.onSceneList.length ? true : false
    this.selectAllInterminate = !this.selected.length ? false : this.selected.length !== this.onSceneList.length ? true : false
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'USER_NAME';
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load(false);
  }


  public setVal(event: any, type: IApiInvestigationOnSceneFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }

  public setFilters(value: string | undefined, type: IApiInvestigationOnSceneFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
    this.filters.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    });
  }

  public filterValue(filter: IApiInvestigationOnSceneFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public appliedFilter(): void {
    this.load(false);
  }

  public clear(): void {
    this.filters.map(item => {
      this.dataSource.removeFilter(item.type);
    });
    this.filters = [];
    this.load();
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
}
