import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { forkJoin, Observable } from 'rxjs';
import { switchMap, tap, filter } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { IApiCertification, IApiCertificationAgency, IApiCertificationFilterType, IApiCertificationStatusType, IApiCertificationType, IApiCertificationTypeOrderBy, IApiCommentableType, IApiDocument, IApiDocumentType, IApiDocumentTypeOrderBy, IApiUploadTypes, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AuthService, CertificationService, CertificationTypeService, DocumentService, DocumentTypeService, StateService, UserService } from 'src/app/shared/services';
import { staffCertificationsOwnDelete, staffCertificationsOwnUpdate, staffCertificationsDelete, staffCertificationsUpdate } from 'src/app/shared/helpers/auth-config/staff.config';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import { imageMimeType, pdfMimeType } from 'src/app/shared/helpers/helper';
import { FileInterface } from 'src/app/shared/services/s3/s3-service';

interface IEditCertfication extends IApiCertification {
  edit: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-staff-certifications',
  templateUrl: './staff-certifications.component.html',
  styleUrls: ['./staff-certifications.component.scss']
})
export class StaffCertificationsComponent implements OnInit {

  public authConfig = {
    staffCertificationsUpdate,
    staffCertificationsDelete,
    staffCertificationsOwnDelete,
    staffCertificationsOwnUpdate
  };

  public certifications: IApiCertification[] = [];
  public trackEdit: IEditCertfication[] = [];
  private _user: IApiUser;

  public documentId = null;
  
  public documentTypes: IApiDocumentType[] = [];
  public certificationTypes: IApiCertificationType[] = [];

  // Select Lists Options - TODO fetch data for lists
  public statuses = IApiCertificationStatusType;
  public agencies: IApiCertificationAgency[] = [];
  public authenticatedUserId: string = null;
  public comment: string = null;
  constructor(
    private certificationService: CertificationService,
    private activatedRoute: ActivatedRoute,
    public stateService: StateService,
    private notification: NotificationsService,
    private documentService: DocumentService,
    private loader: LoaderService,
    private documentTypeService: DocumentTypeService,
    private commentService: GqlCommentService,
    private certificationType: CertificationTypeService,
    private userService: UserService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) { }

  public removeDocument(cert: IApiCertification) {
    this.documentService.remove(cert.Document.id).pipe(
      this.notification.snackbarErrorPipe("Unable to delete document, please try again."),
      switchMap(() => this.hydrateCertifications())
    ).subscribe(() => {
      this.notification.notify("Document removed successfully!");
    });
  }

  public addNewComment(cert: IApiCertification, comment: string) {
    this.commentService.addComment({ commentableId: cert.id, commentableType: IApiCommentableType.Certification, text: comment }).pipe(
      switchMap(() => this.hydrateCertifications())
    ).subscribe(() => {
      this.comment = null;
      this.notification.notify("Comment added!");
    });
  }

  private hydrateCertifications(): Observable<IApiCertification[]> {
    return this.certificationService.get([{ type: IApiCertificationFilterType.User, value: this.activatedRoute.snapshot.params.id }], { sortOrder: SortOrder.ASC, limit: -1 })
      .pipe(
        unwrapConnection(),
        tap((certs) => {
          this.certifications = certs;
          this.certifications = this.certifications.map((elem) => ({
            ...elem,
            issuedAt: new Date(elem.issuedAt),
            expiresAt: new Date(elem.expiresAt),
          }));
          this.trackEdit = this.certifications.map((elem) => ({
            ...elem,
            edit: false,
            issuedAt: new Date(elem.issuedAt),
            expiresAt: new Date(elem.expiresAt),
          }));
        })
      );
  }

  public updateCertification(cert: IApiCertification) {
    if (!cert?.issuedAt || !cert.expiresAt || !cert.status) {
      return;
    }
    const updateCert = {
      ...cert,
      TypeId: cert.Type.id,
      User: this._user.id,
      DocumentId: cert.Document?.id || null,
    };
    delete updateCert.__typename;
    delete updateCert.Type;
    delete updateCert.Document;
    delete updateCert.Comments;
    this.certificationService.update(updateCert as any).pipe(
      this.notification.snackbarErrorPipe("Unable to update this certification, please try again."),
      switchMap(() => this.hydrateCertifications())
    ).subscribe(() => this.notification.notify("Certification updated successfully!"));
  }

  public async onUploadSuccess(files : FileInterface[], cert: IApiCertification) {
    try {
      const type = this.documentTypes.find(({ name }) => name === "Certification");
      const doc = await this.loader.show$(
        forkJoin(
          files.map(({ key }) => {
            return this.documentService.add({
              uri: '',
              s3Uri: key,
              title: `${cert.Type.title}-${this._user.firstName}-${this._user.lastName}`,
              caption: ``,
              InvestigationId: null,
              TypeId: type.id
            });
          })
        )
      ).toPromise();
      if (!cert.Document) {
        cert.Document = {
          id: null,
          title: null,
          uri: null,
          s3Uri: null,
          caption: null,
        };
      }
      cert.Document.id = (doc[0] as IApiDocument)?.id;
      this.notification.notify("Upload successful!");
      this.updateCertification(cert);
    }
    catch (exc) {
      this.notification.snackbarError("Error linking uploaded document. Please try again.");
    }
  }

  public fileSelector(cert: IApiCertification) {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: null,
      FolderId: null,
      UserId: this._user?.id,
      type: IApiUploadTypes.Certificates,
      restrictions: [...imageMimeType, ...pdfMimeType],
      multiple: false
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.onUploadSuccess(res, cert);
      }
    });
  }

  public loadData() {
    this.activatedRoute.params.pipe(
      untilDestroyed(this),
      switchMap(({ id }) => this.loader.show$(
        forkJoin([
          this.userService.getByIdOnlyNefco(id),
          this.documentTypeService.get([], { orderBy: IApiDocumentTypeOrderBy.Name, sortOrder: SortOrder.ASC }).pipe(unwrapConnection()),
          this.certificationType.get([], { orderBy: IApiCertificationTypeOrderBy.Title, sortOrder: SortOrder.ASC }).pipe(unwrapConnection()),
          this.hydrateCertifications()
        ]))
      ),
    ).subscribe(([user, docTypes, certTypes]) => {
      this._user = user;
      this.documentTypes = docTypes;
      this.certificationTypes = certTypes;
    });
  }

  public certTypeCompare(option, value) {
    return option.id === value.id;
  }

  public deleteStaffCertification(id: string) {
    this.notification
    .kendoConfirm(
      "Are you sure you want to remove this certification?",
      "Confirm remove?"
    )
    .pipe(
      filter((v) => !!v),
      this.loader.showPipe(
        this.certificationService.remove(id)
      ),
      this.notification.snackbarPipe("Certification removed successfully!"),
      this.notification.snackbarErrorPipe("Error removing Certification; please try again"))
    .subscribe(() => {
      this.loadData();
    }, () => {
      this.loadData();
    });
  }

  public ngOnInit() {
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this.loadData();
  }

  public get isOwnerUser(): boolean {
    return (this.activatedRoute.snapshot.params.id) === this.authenticatedUserId;
  }
}
