import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IApiEmail, IApiPhone } from '../../modules/graphql/types/types';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-email-phone-view',
  templateUrl: './email-phone-view.component.html',
  styleUrls: ['./email-phone-view.component.scss']
})
export class EmailPhoneViewComponent {
  @Input() email: IApiEmail;
  @Input() phone: IApiPhone;
  @Input() copyEmail: boolean = false;
  @Output() clickFn = new EventEmitter<boolean>();

  constructor(
    private clipboard: Clipboard
  ) { }

  onClickPhone() {
    this.clickFn.emit(true);
  }

  copyText(text){
    if (this.copyEmail === true) {
      this.clipboard.copy(text);
    }
  }

}
