import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { IApiInvestigation, IApiPhoto, IApiPhotoFilterType, IApiPhotoFolder, IApiPhotoFolderFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { PhotoFolderService, PhotoService } from 'src/app/shared/services';

export interface IInvestigationEvidencePhotosModalData {
  investigation: IApiInvestigation;
}

export interface IEvidencePhotosModalFolder extends IApiPhotoFolder {
  EvidencePhotos: IApiPhoto[];
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-evidence-photos-modal',
  templateUrl: './investigation-evidence-photos-modal.component.html',
  styleUrls: ['./investigation-evidence-photos-modal.component.scss']
})
export class InvestigationEvidencePhotosModalComponent extends DialogContentBase implements OnInit {

  public folders: IEvidencePhotosModalFolder[] = [];

  public loading = new BehaviorSubject<boolean>(true);

  public get nefcoNumber() {
    return this.data?.investigation?.nefcoNumber;
  }

  @Input() public data: IInvestigationEvidencePhotosModalData;

  constructor(
    // private photoFolderService: PhotoFolderService,
    private photoService: PhotoService,
    private dialogRef: DialogRef,
    private loaderService: LoaderService
  ) {
    super(dialogRef);
    // this.loading.pipe(untilDestroyed(this)).subscribe((v) => this.loading = v);
  }

  ngOnInit(): void {
    const filters = [
      { type: IApiPhotoFilterType.Investigation, value: this.data.investigation.id },
      { type: IApiPhotoFilterType.Evidence, value: "true" }
    ];
    this.loaderService.show$(this.photoService.get(filters).pipe(
      unwrapConnection(),
      map((photos: IApiPhoto[]) => {
        // consolidate all individual photos into folders
        return photos.reduce((p, c) => {
          const folder = c.Folder as IEvidencePhotosModalFolder;
          const found = p.find((v) => v.id === folder.id);
          if (found) {
            found.EvidencePhotos.push(c);
            return p;
          } else {
            folder.EvidencePhotos = [c];
            return [...p, folder];
          }
        }, []);
      })
    )).subscribe((f) => {
      this.folders = f;
    });
  }

  public close(result = false) {
    this.dialogRef.close(result);
  }

  public openPrint(photo) {
    photo.isLoaded = true;
    if (this.folders.every(folder => folder.EvidencePhotos.every((p: any) => p.isLoaded))) {
      this.loading.next(false);
    }
  }

}
