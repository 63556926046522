<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>Download Documents</span>
      <div class="modal-subtitle">Investigation #{{ data.investigation.nefcoNumber }}</div>
    </div>
  </kendo-dialog-titlebar>
  <section>

    <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }"
      [cols]="['repeat(1,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <div class="kendo-formfield">
          <div class="k-checkbox-wrap">
            <input type="checkbox" kendoCheckBox #downloadAll id="downloadAll"
              (change)="checkAll($event.target); error = null;" [checked]="allSelected()" />
            <kendo-label [for]="downloadAll" class="k-checkbox-label font-weight-600 checkbox-margin mb-0"
              text="Download all documents"></kendo-label>
          </div>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>

    <hr class="mt-0" />

    <p class="mt-8" style="font-style: italic;">
      Download selected documents:
    </p>

    <ng-container *ngIf="uploads?.length">
      <p class="mt-16">
        Uploads
      </p>

      <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngFor="let upload of uploads; let i = index">
          <div class="kendo-formfield">
            <input type="checkbox" kendoCheckBox [name]="upload.title" [id]="upload.title + i" [checked]="upload.download"
              (change)="upload.download = !upload.download; error = null" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin mb-0" [text]="upload.title"
              [for]="upload.title + i"></kendo-label>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <ng-container *ngIf="reports?.length">
      <p class="mt-16">
        Reports
      </p>

      <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 16 }" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngFor="let report of reports; let i = index">
          <div class="kendo-formfield">
            <input type="checkbox" kendoCheckBox [name]="report.title" [id]="report.title + i" [checked]="report.download"
              (change)="report.download = !report.download; error = null" />
            <kendo-label class="k-checkbox-label font-weight-600 checkbox-margin mb-0" [text]="report.title"
              [for]="report.title + i"></kendo-label>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>

    <hr class="mb-0" *ngIf="error" />

    <p>{{ error }}</p>
  </section>

  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-16" (click)="dialog.close(false)">Cancel</button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="download()">DOWNLOAD</button>
  </kendo-dialog-actions>
</div>