import { Component, OnInit } from "@angular/core";
import {
  Columns,
  IInvestigationTableConfig,
  ReportTableType,
} from "../report-table/report-table.component";
import { getRiskTypeIcon } from "src/app/shared/helpers/reactive-form.class";
import { InvestigationStatusNames } from "src/app/shared/helpers/helper";
import { SortDescriptor } from "@progress/kendo-data-query";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { DownloadHelper } from "src/app/shared/services";
import { EvidenceReportDataSource } from "src/app/shared/services/evidence/evidence-report/evidence-report.datasource";
import { EvidenceReportService } from "src/app/shared/services/evidence/evidence-report/evidence-report.service";
import { SortOrder } from "src/app/shared/modules/graphql/enums/generic.enums";
import { IApiInvestigationMetrics, IApiSortOrder } from "src/app/shared/modules/graphql/types/types";

@Component({
  selector: "app-evidence-report",
  templateUrl: "./evidence-report.component.html",
  styleUrls: ["./evidence-report.component.scss"],
})
export class EvidenceReportComponent implements OnInit {
  public columnsNameList: Array<string> = [];
  public filterColumns = [`repeat(${5},minmax(0,1fr))`];
  public type = ReportTableType;
  public tableData: IApiInvestigationMetrics[] = [];

  private _dataSource: EvidenceReportDataSource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }
  public sort: SortDescriptor[] = [{
    field: 'CREATED_AT',
    dir: 'desc'
  }];
  public investigationStatusNames = InvestigationStatusNames;

  constructor(
    private loaderService: LoaderService,
    private notifications: NotificationsService,
    private downloadHelper: DownloadHelper,
    private evidenceReportService: EvidenceReportService,
  ) {
    this.loaderService.isMatLoader = false;
    this.dataSource = new EvidenceReportDataSource(this.evidenceReportService);
    // Table Initialization / Setup
    this.loaderService.attachObservable(this.dataSource.loading$);

    // Default to sort by newest at top
    // this.dataSource.listPage.sortOrder = SortOrder.DESC;
    // this.dataSource.applyFilter(IApiInvestigationMetricsFilterType.IsInvestigatorPrimary, "1");
    // this.dataSource.applyFilter(IApiInvestigationMetricsFilterType.ExcludeCancelled, "1");
    // this.dataSource.listPage.orderBy = IApiInvestigationOrderBy.CreatedAt;

    this.dataSource?.contents$.subscribe((res) => {
      this.tableData = res;
    });
  }

  public tableConfig: IInvestigationTableConfig = {
    type: ReportTableType.evidence,
    displayedColumns: [
      Columns.nefcoNumber,
      Columns.status,
      Columns.riskDate,
      Columns.insured,
      Columns.dateReceived,
      Columns.riskType,
      Columns.address,
      Columns.city,
      Columns.state,
      Columns.postal,
      Columns.claimNumber,
      Columns.policyNumber,
      Columns.billTo,
      Columns.evidenceStatusDate,
      Columns.evidenceStatus,
      Columns.evidenceNumber,
      Columns.description,
      Columns.evidenceLocation,
    ],
    viewType: null,
    startWithCount: false,
  };

  ngOnInit(): void {
    this.load();
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = "CREATED_AT";
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public async load(): Promise<any> {
    this.dataSource.pagingReset();
    await this.dataSource.load();
  }

  public applyFilter(filterList) {
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
  }

  public clearFilter(filterList) {
    filterList.forEach(({ type }) => this.dataSource.removeFilter(type));
    this.load();
  }

  public filterReport(filterList) {
    filterList.forEach(({ type, value }) => this.dataSource.applyFilter(type, value));
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public async exportCsv(): Promise<any> {
    let tabName = 'InvestigationsReport';
    const params = {
      filters: this.dataSource?.lastFilters,
      orderBy: this.dataSource?.listPage?.orderBy,
      sortOrder: this.dataSource?.listPage?.sortOrder === 'ASC' ? IApiSortOrder.Asc : IApiSortOrder.Desc,
    };
    // download investigation report
    this.loaderService.show$(this.evidenceReportService.csvReport(params).pipe(
      this.notifications.snackbarErrorPipe("Error downloading report; please try again"),
    )).subscribe(() => {
      this.notifications.kendoConfirm("Your request to export Company Investigation and Evidence Report has been successfully submitted. You will receive a confirmation email when they are ready.", "Company Investigation and Evidence Report Download", "", "Ok").subscribe();
    });
  }

}
