import { Component, OnInit } from '@angular/core';
import { filter, switchMap, take } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DownloadHelper, InvestigationTemplatDownloadService, TimeEntryService } from 'src/app/shared/services';
import { IApiUserFilterType } from '../../../../shared/modules/graphql/types/types';
import * as dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IPayRollDay } from '../time-kendo-card/time-kendo-card.component';
import { SelectionModel } from '@angular/cdk/collections';

@UntilDestroy()
@Component({
  selector: 'app-bookkeeping-payroll-management',
  templateUrl: './bookkeeping-payroll-management.component.html',
  styleUrls: ['./bookkeeping-payroll-management.component.scss']
})
export class BookkeepingPayrollManagementComponent implements OnInit {

  public selectedUser: string;
  public selectedTabIndex = 0;
  public maxDate: Date = null;

  /** Date information */
  private _referenceDate = new Date();
  private get referenceDate() {
    return this._referenceDate;
  }
  private set referenceDate(val) {
    this._referenceDate = val;
    this.currentPayPeriod(this.referenceDate);
  }

  public selectedTimeEntries = new SelectionModel<IPayRollDay>(true, []);
  public reloadChild: Date = new Date();

  private _selectAllEntries: boolean = false;

  set selectAllEntries(val) {
    this._selectAllEntries = val;
  }

  get selectAllEntries() {
    return this._selectAllEntries;
  }

  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  // Base payPeriod
  public payPeriod: any = {
    start: null,
    end: null,
    payDate: null
  };

  constructor(
    private timeEntryService: TimeEntryService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private templateService: InvestigationTemplatDownloadService,
    private downloadHelper: DownloadHelper,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.currentPayPeriod(new Date(), true);
  }

  // NOTE: This is used in the backend as well
  public currentPayPeriod(givenDate = new Date(), initLoad = false): any {

    const tempPayPeriod = NefcoDateHelper.currentPayPeriod(givenDate);
    let queryParamDate = null;

    if (initLoad) {

      this.maxDate = tempPayPeriod.payDate;

      // See if query params on init load
      // If date, update variable
      this.route.queryParams.pipe(
        untilDestroyed(this),
        take(1),
      ).subscribe((params) => {
        const {
          date = null
        } = params;
        queryParamDate = date;
      });

    }

    // If a date query parameter, default to that on init load
    if (initLoad && queryParamDate) {
      this.payPeriod = NefcoDateHelper.payPeriodbyPaidDate(queryParamDate);
      this.referenceDate = this.payPeriod.start;
    }

    // If the previous "payDate" hasn't passed yet, default to that on init load
    else if (initLoad && (dayjs().isBefore(dayjs(tempPayPeriod.payDate).subtract(2, 'week').endOf('day')))) {
      this.payPeriod =  {
        start: dayjs(tempPayPeriod.start).subtract(2, 'week').toDate(),
        end: dayjs(tempPayPeriod.end).subtract(2, 'week').toDate(),
        payDate: dayjs(tempPayPeriod.payDate).subtract(2, 'week').toDate()
      };
      this.referenceDate = dayjs(this.referenceDate).subtract(2, 'week').toDate();
      this.maxDate = this.payPeriod.payDate;
    }
    // Otherwise, default to the current "payDate"
    else {
      this.payPeriod =  tempPayPeriod;
    }
  }

  public onDateRangeChanged(direction: string) {
    if (direction === "forward") {
      this.referenceDate = dayjs(this.referenceDate).add(2, 'week').toDate();
    } else if (direction === "reverse") {
      this.referenceDate = dayjs(this.referenceDate).subtract(2, 'week').toDate();
    }

    // Reset Select
    this.selectReset();
  }

  public toggleSelected(e: IPayRollDay) {
    e.selected ? this.selectedTimeEntries.select(e) : this.selectedTimeEntries.deselect(e);
  }

  public selectReset() {
    this.selectAllEntries = false;
    this.selectedTimeEntries.clear();
  }

  public markAsPaid() {
    const updateEntryIds = this.selectedTimeEntries.selected.map((obj) => obj.unpaidEntries).flat(1).map((obj) => obj.id);
    if (updateEntryIds?.length === 0) {
      this.notifications.snackbarError("Please select at least one time log entry.");
      return;
    }
    this.notifications.kendoConfirm('Mark all selected hours as "paid"?').pipe(
      filter(v => !!v),
      switchMap(() => this.loader.show$(forkJoin([
        ...updateEntryIds.map((id) => this.timeEntryService.update({ id: id, paidDate: this.payPeriod.payDate }))
      ])))
    ).pipe(
      this.notifications.snackbarErrorPipe("Error marking entires as paid"),
      this.notifications.snackbarPipe("Selected entries have been marked as paid")
    ).subscribe(() => {
      this.reloadChild = new Date();
      this.selectReset();
    });
  }

  public markAllAsPaid() {
    this.notifications
      .kendoConfirm(
        "Are you sure you want to mark all hours as paid?",
        "Mark All as Paid?",
        "No, Don’t Mark All as Paid",
        "Yes, Mark All as Paid"
      )
      .pipe(
        filter((v) => !!v),
        switchMap(() =>
          this.loader.show$(
            this.timeEntryService.markAllPaid({
              paidDate: this.payPeriod.payDate,
            })
          )
        )
      )
      .pipe(
        this.notifications.snackbarErrorPipe("Error marking all entires as paid"),
        this.notifications.snackbarPipe(
          "All entries successfully marked as paid"
        )
      )
      .subscribe(() => {
        this.reloadChild = new Date();
        this.selectReset();
      });
  }

  public downloadCsv(preview = false) {
    // "includeUnpaid" param is legacy wording, this is really just controlling if we return individual entries vs a summed view
    this.templateService.generatePayrollListCSV({ paidDate: this.payPeriod.payDate, includeUnpaid: this.selectedTabIndex === 1, preview }).subscribe((val) => {
      const filename = `PayrollList_${dayjs(this.payPeriod.payDate).format("MM/DD/YYYY")}${preview ? '_PREVIEW-UNPAID' : '_APPROVED-TO-PAY'}${this.selectedTabIndex === 1 ? '_ENTRY-LIST' : '_REVIEW'}.csv`;
      this.downloadHelper.download(val, filename);
    });
  }

  public userSelectionChange($event): void {
    this.selectedUser = $event?.id || null;
    this.selectReset();
  }

  public _selectAllEntriesChanged($event): void {
    this.selectAllEntries = $event;
  }

}
