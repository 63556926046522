import gql from 'graphql-tag';
import { buildConnectionQuery } from "../../helpers.class";
import { IApiInvestigationFilterType } from '../../types/types';

const userFragment = `
  id
  email
  firstName
  lastName
`;

const phoneFragment = `
  id
  isPrimary
  number
  extension
  TypeId
  type
  Type {
    id
    name
  }
`;

const emailFragment = `
  id
  isPrimary
  address
  TypeId
  Type {
    id
    name
  }
`;

const addressFragment = `
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
  latitude
  longitude
  TypeId
  Type {
    id
    name
  }
`;

const addressFragmentForReadyToBill = `
  id
  isPrimary
  address1
  address2
  address3
  city
  state
  postal
  country
`;

const evidenceFragment = `
  EvidenceBilling {
    id
    palletCount
    override
    billable
    rate
    startDate
    lastExpensed
    currentExpensed
    notes
    BillingFrequency {
      id
      name
      months
    }
  }
  Evidence {
    id
    evidenceNumber
    ueNumber
    description
    takenFrom
    createdAt
    InvoiceItems {
      id
    }
    CreatedBy {
      id
      firstName
      lastName
    }
    Comments {
      id
      text
      CreatedBy {
        id
        firstName
        lastName
      }
    }
    SubService {
      id
    }
    EvidenceStorageLocation {
      id
      name
      Phone { ${phoneFragment} }
      Address { ${addressFragment} }
    }
    Transfers {
      id
      trackingNumber
      comment
      createdAt
      FromLocation {
        id
        name
      }
      ToLocation {
        id
        name
      }
      ShippingCarrier {
        id
        name
      }
      ShippingDocument {
        id
      }
    }
    Photos {
      id
    }
    Status
    StatusHistory {
      id
      name
      createdAt
    }
    CreatedBy { ${userFragment} }
  }
`;

const evidenceForReadyToBillFragment = `
  EvidenceBilling {
    id
    rate
    billable
     BillingFrequency {
      name
    }
  }
`;

const partiesFragment = `
  Parties {
    id
    isActive
    notes
    Contact {
      id
      firstName
      middleName
      lastName
      jobTitle
      suffix
      policyNumber
      claimNumber
      insuredName
      nickName
      Phones { ${phoneFragment} }
      Addresses { ${addressFragment} }
      Emails { ${emailFragment} }
      Branches {
        Company {
          id
          name
        }
      }
    }
    Role {
      id
      name
    }
  }
`;

const billingFragment = `
  BillTo {
    id
    firstName
    middleName
    lastName
    suffix
    Role {
      id
      name
    }
    Phones { ${phoneFragment} }
    BillingAddress { ${addressFragment} }
    Emails { ${emailFragment} }
  }
  BillToBranch {
    id
    name
    BillingAddress { ${addressFragment} }
    Company {
      id
      name
      billingInstructions
      qbListId
    }
  }
`;

const billingFragmentForReadyToBill = `
  BillTo {
    id
    firstName
    middleName
    lastName
    BillingAddress { ${addressFragmentForReadyToBill} }
  }
  BillToBranch {
    id
    name
    Company {
      id
      name
      billingInstructions
      qbListId
    }
    BillingAddress { ${addressFragmentForReadyToBill} }
  }
`;

const sceneContactFragment = `
  SceneContact {
    id
    firstName
    middleName
    lastName
    suffix
    Phones { ${phoneFragment} }
    Addresses { ${addressFragment} }
    Emails { ${emailFragment} }
  }
`;

const statusHistoryFragment = `
  StatusHistory {
    id
    requiresFollowUp
    comment
    name
    createdAt
  }
`;

const vehiclesFragment = `
  Vehicles {
    id
    make
    model
    year
    vinNumber
    stockNumber
  }
`;

const companyFragment = `
  Company {
    id
    name
    FlatRates {
      id
      value
      Risk {
        id
        name
      }
    }
    BillingRules {
      id
      value
      notes
      TimeEntryType {
        id
        name
      }
      ExpenseItem {
        id
        name
      }
    }
    Branches {
      id
      name
      isPrimary
      Addresses { ${addressFragment} }
    }
  }
`;

const companyReadyToBillFragment = `
  Company {
    id
    name
    FlatRates {
      id
      value
      Risk {
        id
        name
      }
    }
    BillingRules {
      id
      value
      notes
      TimeEntryType {
        id
        name
      }
      ExpenseItem {
        id
        name
      }
    }
  }
`;


const notificationsFragment = `
  Notifications {
    id
  }
`;

const linkedInvestigationsFragment = `
  LinkedInvestigations {
    id
    nefcoNumber
  }
`;

const servicesFragment = `
  Services {
    id
    AssignedUsers { ${userFragment} }
    SubServices {
      id
      isComplete
      isVisible
      AssignedUser { ${userFragment} }
      Type {
        id
        name
      }
    }
    Type {
      id
      name
    }
  }
`;

// TODO trim this down
const documentsFragment = `
  Documents {
    id
    uri
    caption
    title
    createdAt
    History {
      id
      comment
      uri
      status
      ApprovalHistory {
        id
        isApproved
        comment
        DocumentSnapshot {
          id
          status
        }
        CreatedBy { ${userFragment} }
      }
      CreatedBy { ${userFragment} }
    }
    Comments {
      id
      createdAt
      updatedAt
      text
      CreatedBy { ${userFragment} }
    }
    UpdatedBy { ${userFragment} }
    CreatedBy { ${userFragment} }
    Type {
      id
      name
      Category {
        id
        name
      }
    }
    Shares {
      id
      createdAt
    }
  }
`;

const timeAndExpenseFragment = `
  TimeEntries {
    id
    Type {
      id
      name
      appliesTo
      nonStandard
      additionalDetails
      qbAccount
      createdAt
      rate
      isFlatRate
    }
    InvoiceItems {
      id
    }
    paidDate
    workday
    hours
    description
    User { ${userFragment} }
    createdAt
    updatedAt
  }
  Expenses {
    id
    expenseDate
    billableQuantity
    nonBillableQuantity
    nefcoVehicle
    outOfPocket
    authorizedBy
    authorizedDate
    description
    paidDate
    Investigation {
      id
    }
    InvoiceItems {
      id
    }
    Document {
      id
      title
      uri
    }
    User { ${userFragment} }
    ExpenseItem {
      id
      name
      description
      value
      reimbursementRate
      isFlatRate
      qbAccount
      ExpenseType {
        id
        name
      }
      ExpenseCategory {
        id
      }
    }
    ExpensePurpose {
      id
      name
      details
    }
    createdAt
    updatedAt
  }
  Mileage {
    id
    expenseDate
    billableQuantity
    nonBillableQuantity
    nefcoVehicle
    outOfPocket
    authorizedBy
    authorizedDate
    description
    paidDate
    InvoiceItems {
      id
    }
    User { ${userFragment} }
    ExpenseItem {
      id
      name
      description
      value
      reimbursementRate
      isFlatRate
      qbAccount
      ExpenseType {
        id
        name
      }
      ExpenseCategory {
        id
      }
    }
    ExpensePurpose {
      id
      name
      details
    }
    createdAt
    updatedAt
  }
`;

const investigationStaffFragment = `
  InvestigationStaff {
    id
    isPrimary
    isDefault
    clientRequested
    createdAt
    scheduledSceneExamDate
    User {
      id
      firstName
      lastName
      timezone
      {{ INVESTIGATION_STAFF_DETAILS }}
    }
    Role {
      id
      title
    }
  }
`;

const investigationStaffDetailsFragment = `
  Phones { ${phoneFragment} }
  Emails { ${emailFragment} }
  OnSceneHistory {
    id
    status
  }
`;

const clientDetailsFragment = `
  middleName
  suffix
  additionalInformation
  Branches {
    id
    name
    Phones { ${phoneFragment} }
    Emails { ${emailFragment} }
    Addresses { ${addressFragment} }
    Company {
      id
      name
    }
  }
  Phones { ${phoneFragment} }
  Addresses { ${addressFragment} }
  Emails { ${emailFragment} }
  Role {
    id
    name
  }
`;

const appClientDetailsFragment = `
  Phones { ${phoneFragment} }
  Emails { ${emailFragment} }
`;

const clientBranchDetailsFragment = `
  Addresses { ${addressFragment} }
`;

const insuredDetailsFragment = `
  id
  insuredName
  contactName
  suffix
  title
  Phones { ${phoneFragment} }
  Addresses { ${addressFragment} }
  Emails { ${emailFragment} }
`;

const investigationDetailsFragment = `
  cause
  notes
  partyNotes
  vehicleNotes
  linkedInvestigationNotes
  billingInstructionNotes
  staffNotes
  riskDescription
  evidenceGatheringComplete
  specialInstructions
  budgetAmount
`;

const investigationDetailsReadyToBillViewFragment = `
  Detail {
    budgetAmount
  }
`;

const investigationFragment = `
  billingInstructions
  receivedDate
  onHold
  isIncendiary
  isRejected
  isRetainerReceived
  isOngoing
  LossAddress { ${addressFragment} }
`;

const investigationReadyToBillViewFragment = `
  billingInstructions
`;

const extrasFragment = `
  Requests {
    id
    notes
    authorizedBy
    Status
    createdAt
    updatedAt
    CreatedBy { ${userFragment} }
    RequestType {
      id
      name
    }
    RecentHistory {
      id
      name
      comment
      createdAt
      CreatedBy { ${userFragment} }
    }
    StatusHistory {
      id
      name
      comment
      CreatedBy { ${userFragment} }
    }
  }
  History {
    id
    CreatedBy { ${userFragment} }
    comment
    updateType
    updateCategory
    createdAt
  }
  CreatedBy { ${userFragment} }
`;

const ViewOnlyNefconumberTemplateFragment = `
  id
  createdAt
  updatedAt
  nefcoNumber
`;

const onSceneHistoryFragment = `
OnSceneHistory {
  id
  status
  scheduledDate
  User{
    id
    firstName
    lastName
    timezone
  }
}`;

const investigationMarkCloseFragment = `
  InvestigationMarkClose{
    markClosed
  }
`;

const templateFragment = `
  id
  createdAt
  updatedAt
  deletedAt
  nefcoNumber
  lossDate
  status
  firstViewDate
  conflictCheckRun
  docusketchRequested
  isXactimate
  isSymbility
  UnBilledEntries
  timezone
  InvestigationSource {
    id
    name
  }
  Comments {
    id
    text
    createdAt
    CreatedBy { ${userFragment} }
  }
  Detail {
    id
    policyNumber
    claimNumber
    {{ INVESTIGATION_DETAILS }}
  }
  RiskType {
    id
    name
  }
  Priority {
    id
    name
  }
  InvestigationAddress { ${addressFragment} }
  Client {
    id
    firstName
    lastName
    isActive
    {{ CLIENT_DETAILS }}
  }
  ClientBranch {
    id
    name
    {{ CLIENT_BRANCH_DETAILS }}
    Company {
      id
      name
      billingInstructions
      qbListId
    }
  }
  Insured {
    id
    insuredName
    contactName
    suffix
    title
    Phones { ${phoneFragment} }
    Addresses { ${addressFragment} }
    Emails { ${emailFragment} }
    {{ INSURED_DETAILS }}
  }
  InvestigationSource {
    id
    name
  }
  {{ INVESTIGATION }}
  {{ INVESTIGATION_STAFF }}
  {{ STATUSHISTORY }}
  {{ PARTIES }}
  {{ SCENECONTACT }}
  {{ EVIDENCE }}
  {{ VEHICLES }}
  {{ BILLING }}
  {{ COMPANY }}
  {{ NOTIFICATIONS }}
  {{ LINKED_INVESTIGATIONS }}
  {{ SERVICES }}
  {{ DOCUMENTS }}
  {{ TIME_AND_EXPENSE }}
  {{ EXTRAS }}
  {{ ON_SCENE_HISTORY }}
  {{ INVESTIGATION_MARK_CLOSED }}
  {{ INSURED_CONTACTED }}
  InvestigationAssignmentSmsHistory {
    id
    message
    source
    action
    description
    deletedAt
    createdAt
    updatedAt
    UserId
    AssignedUserId
  }
  {{ PROGRESS }}
`;

const mobileInvestigationViewTemplate = `
  id
  createdAt
  updatedAt
  deletedAt
  nefcoNumber
  lossDate
  status
  timezone
  Detail {
    id
    policyNumber
    claimNumber
    {{ INVESTIGATION_DETAILS }}
  }
  Comments {
    id
    text
    createdAt
    CreatedBy { ${userFragment} }
  }
  RiskType {
    id
    name
  }
  InvestigationAddress { ${addressFragment} }
  Client {
    id
    firstName
    lastName
    isActive
    {{ CLIENT_DETAILS }}
  }
  ClientBranch {
    id
    name
    {{ CLIENT_BRANCH_DETAILS }}
    Company {
      id
      name
      billingInstructions
      qbListId
    }
  }
  Insured {
    id
    insuredName
    contactName
    suffix
    title
    Phones { ${phoneFragment} }
    Addresses { ${addressFragment} }
    Emails { ${emailFragment} }
    {{ INSURED_DETAILS }}
  }
  {{ INVESTIGATION }}
  {{ INVESTIGATION_STAFF }}
  {{ STATUSHISTORY }}
  {{ PARTIES }}
  {{ SCENECONTACT }}
  {{ EVIDENCE }}
  {{ VEHICLES }}
  {{ BILLING }}
  {{ COMPANY }}
  {{ NOTIFICATIONS }}
  {{ LINKED_INVESTIGATIONS }}
  {{ SERVICES }}
  {{ DOCUMENTS }}
  {{ TIME_AND_EXPENSE }}
  {{ EXTRAS }}
  {{ ON_SCENE_HISTORY }}
  {{ INVESTIGATION_MARK_CLOSED }}
  {{ INSURED_CONTACTED }}
  {{ PROGRESS }}
  {{ NEAR_ONSCENE }}
`;

const appListTemplateFragment = `
  id
  createdAt
  nefcoNumber
  receivedDate
  lossDate
  Detail {
    id
    policyNumber
    claimNumber
    {{ INVESTIGATION_DETAILS }}
  }
  RiskType {
    id
    name
  }
  InvestigationAddress { ${addressFragment} }
  Client {
    id
    firstName
    lastName
    {{ CLIENT_DETAILS }}
  }
  Insured {
    id
    insuredName
    contactName
    Phones { ${phoneFragment} }
  }
  timezone
  {{ INVESTIGATION }}
  {{ INVESTIGATION_STAFF }}
  {{ STATUSHISTORY }}
  {{ PARTIES }}
  {{ SCENECONTACT }}
  {{ EVIDENCE }}
  {{ VEHICLES }}
  {{ BILLING }}
  {{ COMPANY }}
  {{ NOTIFICATIONS }}
  {{ LINKED_INVESTIGATIONS }}
  {{ SERVICES }}
  {{ DOCUMENTS }}
  {{ TIME_AND_EXPENSE }}
  {{ EXTRAS }}
  {{ ON_SCENE_HISTORY }}
  {{ INVESTIGATION_MARK_CLOSED }}
  {{ INSURED_CONTACTED }}
  {{ PROGRESS }}
  {{ NEAR_ONSCENE }}
`;

const inProgressViewFragmentNew = `
  id
  createdAt
  updatedAt
  deletedAt
  nefcoNumber
  lossDate
  status
  firstViewDate
  conflictCheckRun
  docusketchRequested
  isXactimate
  isSymbility
  UnBilledEntries
  Comments {
    id
    text
    createdAt
    CreatedBy { ${userFragment} }
  }
  Detail {
    id
    policyNumber
    claimNumber
    {{ INVESTIGATION_DETAILS }}
  }
  RiskType {
    id
    name
  }
  Priority {
    id
    name
  }
  InvestigationAddress { ${addressFragment} }
  Client {
    id
    firstName
    lastName
    isActive
    {{ CLIENT_DETAILS }}
  }
  ClientBranch {
    id
    name
    {{ CLIENT_BRANCH_DETAILS }}
    Company {
      id
      name
      billingInstructions
      qbListId
    }
  }
  Insured {
    id
    insuredName
    contactName
    suffix
    title
  }
  {{ INVESTIGATION }}
  {{ INVESTIGATION_STAFF }}
  {{ STATUSHISTORY }}
  {{ PARTIES }}
  {{ SCENECONTACT }}
  {{ EVIDENCE }}
  {{ VEHICLES }}
  {{ BILLING }}
  {{ COMPANY }}
  {{ NOTIFICATIONS }}
  {{ LINKED_INVESTIGATIONS }}
  {{ SERVICES }}
  {{ DOCUMENTS }}
  {{ TIME_AND_EXPENSE }}
  {{ EXTRAS }}
  {{ ON_SCENE_HISTORY }}
  {{ INVESTIGATION_MARK_CLOSED }}
  {{ PROGRESS }}
`

const billViewFragment = `
id
createdAt
nefcoNumber
lossDate
status
ActiveAndDraft
RiskType {
  id
  name
}
InvestigationAddress { ${addressFragmentForReadyToBill} }
Client {
  id
  firstName
  lastName
  {{ CLIENT_DETAILS }}
}
ClientBranch {
  id
  Company {
    name
  }
}
Insured {
  id
  insuredName
  contactName
  Phones { ${phoneFragment} }
}
{{ INVESTIGATION }}
{{ INVESTIGATION_DETAILS }}
{{ INVESTIGATION_STAFF }}
{{ BILLING }}
{{ LINKED_INVESTIGATIONS }}
{{ DOCUMENTS }}
{{ TIME_AND_EXPENSE }}
{{ EVIDENCE }}
{{ COMPANY }}
`;

const ViewProximityCheckFragment = `
id
nefcoNumber
lossDate
RiskType {
  id
  name
}
Insured {
  id
  insuredName
  contactName
}
LossAddress { ${addressFragment} }
`;

const insuredContactedFragment = `
InsuredContacted{
  id
  date
  User {
    id
    firstName
    lastName
  }
}`;

const progressFragment = `Progress`;

const nearOnSceneFragment = `
  NearOnScene {
    id
    status
    scheduledDate
    UserId
  }
`;

const evidenceRegex = /\{\{\s?EVIDENCE\s?\}\}/g;
const extrasRegex = /\{\{\s?EXTRAS\s?\}\}/g;
const partiesRegex = /\{\{\s?PARTIES\s?\}\}/g;
const billingRegex = /\{\{\s?BILLING\s?\}\}/g;
const sceneContactRegex = /\{\{\s?SCENECONTACT\s?\}\}/g;
const statusHistoryRegex = /\{\{\s?STATUSHISTORY\s?\}\}/g;
const vehiclesRegex = /\{\{\s?VEHICLES\s?\}\}/g;
const companyRegex = /\{\{\s?COMPANY\s?\}\}/g;
const notificationsRegex = /\{\{\s?NOTIFICATIONS\s?\}\}/g;
const linkedInvestigationsRegex = /\{\{\s?LINKED_INVESTIGATIONS\s?\}\}/g;
const servicesRegex = /\{\{\s?SERVICES\s?\}\}/g;
const documentsRegex = /\{\{\s?DOCUMENTS\s?\}\}/g;
const timeAndExpenseRegex = /\{\{\s?TIME_AND_EXPENSE\s?\}\}/g;
const investigationStaffRegex = /\{\{\s?INVESTIGATION_STAFF\s?\}\}/g;
const investigationStaffDetailsRegex = /\{\{\s?INVESTIGATION_STAFF_DETAILS\s?\}\}/g;
const clientDetailsRegex = /\{\{\s?CLIENT_DETAILS\s?\}\}/g;
const clientBranchDetailsRegex = /\{\{\s?CLIENT_BRANCH_DETAILS\s?\}\}/g;
const insuredDetailsRegex = /\{\{\s?INSURED_DETAILS\s?\}\}/g;
const investigationRegex = /\{\{\s?INVESTIGATION\s?\}\}/g;
const investigationDetailsRegex = /\{\{\s?INVESTIGATION_DETAILS\s?\}\}/g;
const investigationOnSceneHistoryRegex = /\{\{\s?ON_SCENE_HISTORY\s?\}\}/g;
const investigationMarkCloseRegex = /\{\{\s?INVESTIGATION_MARK_CLOSED\s?\}\}/g;
const progressRegex = /\{\{\s?PROGRESS\s?\}\}/g;
const insuredContactedRegex = /\{\{\s?INSURED_CONTACTED\s?\}\}/g;
const nearOnSceneRegex = /\{\{\s?NEAR_ONSCENE\s?\}\}/g;


const nodeFragment = templateFragment
  .replace(evidenceRegex, evidenceFragment)
  .replace(extrasRegex, extrasFragment)
  .replace(partiesRegex, partiesFragment)
  .replace(billingRegex, billingFragment)
  .replace(sceneContactRegex, sceneContactFragment)
  .replace(statusHistoryRegex, statusHistoryFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(companyRegex, companyFragment)
  .replace(notificationsRegex, notificationsFragment)
  .replace(linkedInvestigationsRegex, linkedInvestigationsFragment)
  .replace(servicesRegex, servicesFragment)
  .replace(documentsRegex, documentsFragment)
  .replace(timeAndExpenseRegex, timeAndExpenseFragment)
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, investigationStaffDetailsFragment))
  .replace(clientDetailsRegex, clientDetailsFragment)
  .replace(clientBranchDetailsRegex, clientBranchDetailsFragment)
  .replace(insuredDetailsRegex, insuredDetailsFragment)
  .replace(investigationRegex, investigationFragment)
  .replace(investigationDetailsRegex, investigationDetailsFragment)
  .replace(investigationOnSceneHistoryRegex, onSceneHistoryFragment)
  .replace(investigationMarkCloseRegex, investigationMarkCloseFragment)
  .replace(progressRegex, progressFragment)
  .replace(insuredContactedRegex, insuredContactedFragment);

  const mobileInvestigationViewTemplateFragment = mobileInvestigationViewTemplate
  .replace(evidenceRegex, "")
  .replace(extrasRegex, extrasFragment)
  .replace(partiesRegex, partiesFragment)
  .replace(billingRegex, billingFragment)
  .replace(sceneContactRegex, sceneContactFragment)
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, companyFragment)
  .replace(notificationsRegex, "")
  .replace(linkedInvestigationsRegex, linkedInvestigationsFragment)
  .replace(servicesRegex, "")
  .replace(documentsRegex, "")
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, investigationStaffDetailsFragment))
  .replace(clientDetailsRegex, clientDetailsFragment)
  .replace(clientBranchDetailsRegex, clientBranchDetailsFragment)
  .replace(insuredDetailsRegex, insuredDetailsFragment)
  .replace(investigationRegex, investigationFragment)
  .replace(investigationDetailsRegex, investigationDetailsFragment)
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationMarkCloseRegex, "")
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, insuredContactedFragment)
  .replace(nearOnSceneRegex, nearOnSceneFragment);

const listFragment = templateFragment
  .replace(evidenceRegex, "")
  .replace(extrasRegex, "")
  .replace(partiesRegex, partiesFragment)
  .replace(billingRegex, billingFragment)
  .replace(sceneContactRegex, sceneContactFragment)
  .replace(statusHistoryRegex, statusHistoryFragment)
  .replace(vehiclesRegex, vehiclesFragment)
  .replace(companyRegex, companyFragment)
  .replace(notificationsRegex, notificationsFragment)
  .replace(linkedInvestigationsRegex, linkedInvestigationsFragment)
  .replace(servicesRegex, "")
  .replace(documentsRegex, documentsFragment)
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, investigationStaffDetailsFragment))
  .replace(clientDetailsRegex, "")
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, investigationFragment)
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationMarkCloseRegex, "")
  .replace(investigationDetailsRegex, "")
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, '');

const listViewFragment = templateFragment
  .replace(evidenceRegex, "")
  .replace(extrasRegex, "")
  .replace(partiesRegex, "")
  .replace(billingRegex, "")
  .replace(sceneContactRegex, "")
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, "")
  .replace(notificationsRegex, "")
  .replace(linkedInvestigationsRegex, "")
  .replace(servicesRegex, "")
  .replace(documentsRegex, "")
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, ""))
  .replace(clientDetailsRegex, "")
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, investigationFragment)
  .replace(investigationMarkCloseRegex, "")
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationDetailsRegex, "")
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, insuredContactedFragment);

const appListFragment = appListTemplateFragment
  .replace(evidenceRegex, "")
  .replace(extrasRegex, "")
  .replace(partiesRegex, "")
  .replace(billingRegex, "")
  .replace(sceneContactRegex, "")
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, "")
  .replace(notificationsRegex, "")
  .replace(linkedInvestigationsRegex, "")
  .replace(servicesRegex, "")
  .replace(documentsRegex, "")
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, ""))
  .replace(clientDetailsRegex, appClientDetailsFragment)
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, "")
  .replace(investigationMarkCloseRegex, "")
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationDetailsRegex, "")
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, insuredContactedFragment)
  .replace(nearOnSceneRegex, nearOnSceneFragment);

const readyToBillViewFragment = billViewFragment
  .replace(evidenceRegex, evidenceForReadyToBillFragment)
  .replace(extrasRegex, "")
  .replace(partiesRegex, "")
  .replace(billingRegex, billingFragmentForReadyToBill)
  .replace(sceneContactRegex, "")
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, companyReadyToBillFragment)
  .replace(notificationsRegex, "")
  .replace(linkedInvestigationsRegex, "")
  .replace(servicesRegex, "")
  .replace(documentsRegex, "")
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, ""))
  .replace(clientDetailsRegex, "")
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, investigationReadyToBillViewFragment)
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationMarkCloseRegex, "")
  .replace(investigationDetailsRegex, investigationDetailsReadyToBillViewFragment)
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, '');

const inProgressViewFragment = inProgressViewFragmentNew
  .replace(evidenceRegex, "")
  .replace(extrasRegex, "")
  .replace(partiesRegex, "")
  .replace(billingRegex, "")
  .replace(sceneContactRegex, "")
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, "")
  .replace(notificationsRegex, notificationsFragment)
  .replace(linkedInvestigationsRegex, "")
  .replace(servicesRegex, "")
  .replace(documentsRegex, "")
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, investigationStaffFragment.replace(investigationStaffDetailsRegex, ""))
  .replace(clientDetailsRegex, "")
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, "")
  .replace(investigationMarkCloseRegex, investigationMarkCloseFragment)
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationDetailsRegex, "")
  .replace(progressRegex, progressFragment)
  .replace(insuredContactedRegex, '');

const pendingViewFragment = templateFragment
  .replace(evidenceRegex, "")
  .replace(extrasRegex, "")
  .replace(partiesRegex, "")
  .replace(billingRegex, "")
  .replace(sceneContactRegex, "")
  .replace(statusHistoryRegex, "")
  .replace(vehiclesRegex, "")
  .replace(companyRegex, "")
  .replace(notificationsRegex, notificationsFragment)
  .replace(linkedInvestigationsRegex, linkedInvestigationsFragment)
  .replace(servicesRegex, "")
  .replace(documentsRegex, documentsFragment)
  .replace(timeAndExpenseRegex, "")
  .replace(investigationStaffRegex, "")
  .replace(clientDetailsRegex, "")
  .replace(clientBranchDetailsRegex, "")
  .replace(insuredDetailsRegex, "")
  .replace(investigationRegex, "")
  .replace(investigationOnSceneHistoryRegex, "")
  .replace(investigationMarkCloseRegex, "")
  .replace(investigationDetailsRegex, "")
  .replace(progressRegex, "")
  .replace(insuredContactedRegex, '');

const investigationSideBarViewFragment = `
  id
  status
  lossDate
  Detail {
    id
    policyNumber
    claimNumber
    cause
  }
  Priority {
    id
    name
  }
  RiskType {
    id
    name
  }
  Comments {
    id
    createdAt
    updatedAt
    text
    CreatedBy { ${userFragment} }
  }
  LossAddress { ${addressFragment} }
  Insured {
    id
    insuredName
    contactName
    suffix
    title
    Phones { ${phoneFragment} }
    Addresses { ${addressFragment} }
    Emails { ${emailFragment} }
  }
  ${investigationStaffFragment.replace(investigationStaffDetailsRegex, investigationStaffDetailsFragment)}
  Client {
    id
    firstName
    lastName
    isActive
    ${clientDetailsFragment}
  }
  ClientBranch {
    id
    name
    ${clientBranchDetailsFragment}
    Company {
      id
      name
      billingInstructions
      qbListId
    }
  }
`


export const investigationConnectionQuery = (isNode = false, view = null) => {
  if (view && view?.type) {
    switch (view.type) {
      case IApiInvestigationFilterType.ViewOnlyNefconumber:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", ViewOnlyNefconumberTemplateFragment)}`;
      case IApiInvestigationFilterType.AppList:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", appListFragment)}`;
      case IApiInvestigationFilterType.ViewListView:
      case IApiInvestigationFilterType.ViewOngoingView:
      case IApiInvestigationFilterType.ViewAssignedView:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", listViewFragment)}`;

      case IApiInvestigationFilterType.ViewPendingView:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", pendingViewFragment)}`;

      case IApiInvestigationFilterType.ViewInprogressView:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", inProgressViewFragment)}`;

      case IApiInvestigationFilterType.ViewReadytobillView:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", readyToBillViewFragment)}`;
      case IApiInvestigationFilterType.ViewProximityCheck:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", ViewProximityCheckFragment)}`;

      default:
        return gql`${buildConnectionQuery("Investigation", "investigationsConnection", isNode ? nodeFragment : listFragment)}`;
    }
  } else {
    return gql`${buildConnectionQuery("Investigation", "investigationsConnection", isNode ? nodeFragment : listFragment)}`;
  }
};

export const addInvestigationMutation = gql`mutation AddInvestigation($input: AddInvestigationInput!) {
  addInvestigation(input: $input) { ${nodeFragment} }
}`;

export const addInvestigationV2Mutation = gql`mutation AddInvestigationV2($input: AddInvestigationV2Input!) {
  addInvestigationV2(input: $input) { ${nodeFragment} }
}`;

export const updateInvestigationMutation = gql`mutation UpdateInvestigation($input: UpdateInvestigationInput!) {
  updateInvestigation(input: $input) { ${nodeFragment} }
}`;

export const removeInvestigationMutation = gql`mutation RemoveInvestigation($id: ID!) {
  removeInvestigation(id: $id)
}`;

export const rejectInvestigationMutation = gql`mutation RejectInvestigation($id: ID!) {
  rejectInvestigation(id: $id)
}`;

export const restoreInvestigationMutation = gql`mutation RestoreInvestigation($id: ID!) {
  restoreInvestigation(id: $id)
}`;

export const getInvestigationById = gql`query GetInvestigationById($id: ID!) {
  getInvestigationById(id: $id) { ${nodeFragment} }
}`;

export const getInvestigationByIdForMobile = gql`query GetInvestigationById($id: ID!, $isMobile: Boolean) {
  getInvestigationById(id: $id, isMobile: $isMobile) { ${mobileInvestigationViewTemplateFragment} }
}`;

export const getInvestigationByIdForSidebar = gql`query GetInvestigationById($id: ID!) {
  getInvestigationById(id: $id) { ${investigationSideBarViewFragment} }
}`;

export const markInvestigationViewed = gql`mutation MarkInvestigationViewed($id: ID!) {
  markInvestigationViewed(id: $id)
}`;

export const investigationAssignmentSMSMutation = gql`mutation InvestigationAssignmentSMS($input: InvestigationAssignmentSMSInput!) {
  investigationAssignmentSMS(input: $input) {
    id
    messageSid
    message
    from
    to
    messageStatus
    direction
    source
    action
    description
    createdAt
    updatedAt
  }
}`;

export const updateInvestigationStaffScheduledDate = gql`mutation UpdateInvestigationStaffScheduledDate($input: UpdateScheduledDateInput!) {\n    updateInvestigationStaffScheduledDate(input: $input) }`

export const getClosestInvestigatorByInvestigationId = gql`query getClosestInvestigatorByInvestigationId($id: ID!) {\n  getClosestInvestigatorByInvestigationId(id: $id) { user {\n    id, firstName, lastName } distance type \n}\n}`

export const refreshPendingInvestigationSubscrption = gql`subscription onRefreshPendingInvestigation {
  refreshPendingInvestigation
}`;
