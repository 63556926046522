import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { isArray } from 'lodash';
import { map } from 'rxjs/operators';
import { staff } from 'src/app/shared/helpers/auth-config/staff.config';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiDocumentSentFilter, IApiDocumentSentFilterType, IApiInvestigationRoleNames, IApiUser, IApiUserFilterType, IApiUserOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { UserService } from 'src/app/shared/services';

@Component({
  selector: 'app-report-sent-metric-filters',
  templateUrl: './report-sent-metric-filters.component.html',
  styleUrls: ['./report-sent-metric-filters.component.scss']
})
export class ReportSentMetricFiltersComponent implements OnInit {
  public authConfig = {
    staff
  }
  @ViewChild('startDateRange', { static: false }) startDateRange: any;
  @ViewChild('endDateRange', { static: false }) endDateRange: any;
  @Output() clearFilter = new EventEmitter<IApiDocumentSentFilter[]>();
  @Output() applyFilter = new EventEmitter<IApiDocumentSentFilter[]>();
  @Output() exportCsv = new EventEmitter<boolean>(false);
  
  
  private _filters: IApiDocumentSentFilter[] = [];
  public filterTypes = IApiDocumentSentFilterType;

  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public range = { startDate: null, endDate: null };
  public userRoleName = IApiInvestigationRoleNames;
  public loading = true;
  public users: IApiUser[] = [];
  public selectedId: string[] = [];
  constructor(
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    const filters = [{
        type: this.userViewFilter,
        value: 'true'
    }, {
      type: IApiUserFilterType.Position,
      value: JSON.stringify(this.userRoleName.Editor.valueOf())
    }];
    
    this.userService.get(filters, { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiUserOrderBy.Firstname}).pipe(
      unwrapConnection(),
      map((item) => {
        const newContact = item.map((i) => {
          return {
            ...i,
            itemFullName: `${i.firstName} ${i.lastName}`
          }
        });
        return newContact;
      })
    ).subscribe((results) => {
       this.users = results;
       this.loading = false;
     }, () => {
      this.loading = false;
     });
  }

  public setFilters(value: string | undefined, type: IApiDocumentSentFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filter: IApiDocumentSentFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public filterByIdValue(filter: IApiDocumentSentFilterType) {
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }

  public updateDateFilters() {
    if (this.range.startDate && this.range.endDate) {
      const dates = {
        startDate: NefcoDateHelper.toUtcStartOfDay(this.range.startDate, true),
        endDate: NefcoDateHelper.toUtcStartOfDay(this.range.endDate, false),
      };
      this.setFilters(
        JSON.stringify({
          startDate: dates.startDate,
          endDate: dates.endDate,
        }),
        IApiDocumentSentFilterType.SendDateRange
      );
    } else {
      this.setFilters(null, IApiDocumentSentFilterType.SendDateRange);
    }
  }

  public focusDateInput(start = true) {
    if (this.startDateRange && start) {
      setTimeout(() => {
        this.startDateRange?.focus();
      }, 1);
    } else {
      setTimeout(() => {
        this.endDateRange?.focus();
      }, 1);
    }
  }

  public appliedFilters() {
    this.applyFilter.emit(this.filters);
  }

  public clearAll() {
    this.filters = [];
    this.selectedId = [];
    this.range = { startDate: null, endDate: null };
    this.clearFilter.emit(this.filters);
  }

  public exportCSV() {
    this.exportCsv.emit(true);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
}
