<section>
    <div class="page-header">
      <h1 class="mb-0">Report Sent Metrics</h1>
    </div>
</section>

<kendo-tabstrip
  #tabstrip
  [scrollable]="false"
  (tabSelect)="onTabChanged($event)"
>
  <kendo-tabstrip-tab [title]="'by-editor'" [selected]="selectedTab === 'by-editor'">
    <ng-template kendoTabTitle><span>By Editor</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-report-sent-metric-by-editor></app-report-sent-metric-by-editor>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>

  <kendo-tabstrip-tab [title]="'by-report-type'" [selected]="selectedTab === 'by-report-type'">
    <ng-template kendoTabTitle><span>By Report Type</span></ng-template>
    <ng-template kendoTabContent>
      <section class="tab-content">
        <app-report-sent-metric-by-report-type></app-report-sent-metric-by-report-type>
      </section>
    </ng-template>
  </kendo-tabstrip-tab>
</kendo-tabstrip>