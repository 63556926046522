import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SelectEvent, TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'app-report-sent-metric-tabs',
  templateUrl: './report-sent-metric-tabs.component.html',
  styleUrls: ['./report-sent-metric-tabs.component.scss']
})
export class ReportSentMetricTabsComponent implements AfterViewInit {

  @ViewChild("tabstrip") public tabstrip: TabStripComponent;
  public selectedTab: string = 'by-editor';
  constructor() { }

  onTabChanged(event: SelectEvent) {
    this.selectedTab = event?.title;
  }

  public ngAfterViewInit() {
    Promise.resolve(null).then(() => this.tabstrip.selectTab(0));
  }

}
