import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, take } from 'rxjs/operators';
import { AuthService, ServiceService, ServiceTypeService } from 'src/app/shared/services';
import { IApiServiceType, IApiAddServiceInput, IApiUser, IApiInvestigationStaff } from 'src/app/shared/modules/graphql/types/types';
import {
  investigationServicesCreate,
  investigationServicesCreateOwn,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  templateUrl: './investigation-add-service-modal.component.html',
  styleUrls: ['./investigation-add-service-modal.component.scss']
})
export class InvestigationAddServiceModalComponent extends DialogContentBase implements OnInit {

  public authConfig = {
    investigationServicesCreate,
    investigationServicesCreateOwn
  };

  @ViewChild("investigationServicesCreate") investigationServicesCreatePerm;
  @ViewChild("investigationServicesCreateOwn") investigationServicesCreateOwnPerm;
  @Input() public data: any
  /**
   * ID of the service to update
   */
  private _id: "";
  public get serviceId() { return this._id; }
  public set serviceId(val) { this._id = val; }

  /**
   * service data
   */
  private _service: IApiAddServiceInput;
  public get service() {
    return this._service;
  }
  public set service(val) { this._service = val; }

  public serviceTypes: IApiServiceType[] = [];
  private user: IApiUser;
  constructor(
    private serviceTypesService: ServiceTypeService,
    private serviceService: ServiceService,
    private loader: LoaderService,
    private notifications: NotificationsService,
    public dialog: DialogRef,
    private auth: AuthService,
  ) {
    // Find logged in user
    super(dialog);
  }

  ngOnInit(): void {
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // this._service = data || {};
    this._service = {
      TypeId: null,
      InvestigationId: this.data.Investigation.id,
      AssignedUserIds: null,
      authorizedBy: null,
      notes: null
    };

    // load service types
    this.loader.show$(this.serviceTypesService.get()).pipe(
      unwrapConnection(),
      filter(r => !!r),
      this.notifications.snackbarErrorPipe("Error loading ServiceTypes")
    ).subscribe((res) => {
      this.serviceTypes = res.sort((a, b) => a.name > b.name ? 1 : -1);
    });
  }

  public onSubmit() {
    if (this._id) {
      this.save();
    } else {
      this.add();
    }
  }

  public onCancelClick(result = false): void {
    this.dialog.close(result);
  }

  /**
   * Updates the current record
   */
  private save() {
    this.loader.show$(this.serviceService.update({ id: this._id, ...this._service })).pipe(
      this.notifications.snackbarPipe("Service saved successfully"),
      this.notifications.snackbarErrorPipe("Error updating service; please try again")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  // Sending back to parent component to save to capture investigation history
  private add() {
    this.dialog.close({ ...this._service, AssignedUserIds: [this._service.AssignedUserIds] }); // AssignedUserIds needs to be an array
  }

  get getStaff() {
    const own = this.investigationServicesCreateOwnPerm?.enabled;
    const all = this.investigationServicesCreatePerm?.enabled;
    if (own && !all) {
      return this.staffSort(this.data.Investigation.InvestigationStaff.filter(item => item.User.id === this.user.id));
    } else {
      return this.staffSort(this.data.Investigation.InvestigationStaff);
    }
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

}
