import { Component, Input, OnInit } from "@angular/core";
import { DialogContentBase, DialogRef } from "@progress/kendo-angular-dialog";
import { IApiUser } from "./../../../../shared/modules/graphql/types/types";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService } from "./../../../../shared/services/authorization/auth.service";
import { IApiDocument } from "src/app/shared/modules/graphql/types/types";

@UntilDestroy()
@Component({
  selector: "app-investigation-report-o365-launch",
  templateUrl: "./investigation-report-o365-launch.component.html",
  styleUrls: ["./investigation-report-o365-launch.component.scss"],
})
export class InvestigationReportO365LaunchComponent extends DialogContentBase implements OnInit {
  @Input() public report: IApiDocument;
  public user: IApiUser;
  public cookieUrl = "";

  constructor(private auth: AuthService, public dialogRef: DialogRef) {
    super(dialogRef);
    this.auth.authenticatedUser
      .pipe(untilDestroyed(this))
      .subscribe((user) => (this.user = user));
  }

  ngOnInit(): void {
    // iFrame URL to pass info into Office o365 addin for cookie
    this.cookieUrl = `https://d2urwrrqk4nn6k.cloudfront.net/cookiepass.html?docId=${this.report?.id}&investId=${this.report?.Investigation?.id}&nefcoNum=${this.report?.Investigation?.nefcoNumber}`;
  }

  public launchReport() {
    window.open(this.report.uri);
    this.dialogRef.close(true);
  }
}
