import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { AuthService, TimeEntryService } from "src/app/shared/services";
import moment from "moment";
import { NotificationsService } from "../../../../shared/modules/notifications/notifications.service";
import {
  IApiAddTimeEntryInput,
  IApiInvestigation,
  IApiTimeEntry,
  IApiTimeEntryFilter,
  IApiTimeEntryFilterType,
  IApiTimeEntryOrderBy,
  IApiUpdateTimeEntryInput,
  IApiTimeEntryStatusNames,
  IApiTimeEntryTotal,
} from "src/app/shared/modules/graphql/types/types";
import { TimeEntryDataSource } from "src/app/shared/services/time-entry";
import { filter, switchMap } from "rxjs/operators";
import {
  IInvestigationTimeExpenseModalData
} from "../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component";
import { InvestigationTimeAndExpModalKendoComponent } from "../../investigations";
import { DialogCloseResult, DialogRef, DialogService } from "@progress/kendo-angular-dialog";
import { SortDescriptor } from "@progress/kendo-data-query";
import { SortOrder } from "src/app/shared/modules/graphql/enums/generic.enums";
import { timeEntryUnlinkedHoursDeleteOwn, timeEntryUnlinkedHoursDelete, timeEntryInvestigationHoursDeleteOwn, timeEntryInvestigationHoursDelete, timeEntryInvestigationHoursUpdateOwn, timeEntryUnlinkedHoursUpdateOwn, timeEntryUnlinkedHoursUpdate, timeEntryInvestigationHoursUpdate, timeEntryInvestigationHoursList, timeEntryUnlinkedHoursList, timeEntryUnlinkedHoursCreate, timeEntryInvestigationHoursCreate } from "src/app/shared/helpers/auth-config/time-expenses.config";

@Component({
  selector: "app-time-exp-hours-kendo",
  templateUrl: "./time-exp-hours-kendo.component.html",
  styleUrls: ["./time-exp-hours-kendo.component.scss"],
})
export class TimeExpHoursKendoComponent implements OnInit {
  public authConfig = {
    timeEntryUnlinkedHoursCreate,
    timeEntryInvestigationHoursCreate,
    timeEntryInvestigationHoursList,
    timeEntryUnlinkedHoursList,
    timeEntryUnlinkedHoursUpdate,
    timeEntryInvestigationHoursUpdate,
    timeEntryUnlinkedHoursUpdateOwn,
    timeEntryInvestigationHoursUpdateOwn,
    timeEntryInvestigationHoursDelete,
    timeEntryInvestigationHoursDeleteOwn,
    timeEntryUnlinkedHoursDelete,
    timeEntryUnlinkedHoursDeleteOwn
  }

  @ViewChild('timeEntryInvestigationHoursUpdate') timeEntryInvestigationHoursUpdatePermission;
  @ViewChild('timeEntryUnlinkedHoursUpdate') timeEntryUnlinkedHoursUpdatePermission;
  @ViewChild('timeEntryUnlinkedHoursUpdateOwn') timeEntryUnlinkedHoursUpdateOwnPermission;
  @ViewChild('timeEntryInvestigationHoursUpdateOwn') timeEntryInvestigationHoursUpdateOwnPermission;
  @ViewChild('timeEntryInvestigationHoursDelete') timeEntryInvestigationHoursDeletePermission;
  @ViewChild('timeEntryInvestigationHoursDeleteOwn') timeEntryInvestigationHoursDeleteOwnPermission;
  @ViewChild('timeEntryUnlinkedHoursDelete') timeEntryUnlinkedHoursDeletePermission;
  @ViewChild('timeEntryUnlinkedHoursDeleteOwn') timeEntryUnlinkedHoursDeleteOwnPermission;
  @Input() investigations: IApiInvestigation[];
  @Input() userId: string;

  private _dataSource: TimeEntryDataSource;
  public get dataSource(): TimeEntryDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Variables for filters
  public dateFilterType = "work";
  private dateFilterWeek: string = (moment().isoWeek() - 1).toString();
  private dateFilterYear: string = (moment().year()).toString();

  private dateRangeFilter: string;
  private visibleTimeEntries: IApiTimeEntry[];

  public timeEntries: IApiTimeEntry[];
  public timeEntriesCount: IApiTimeEntryTotal;

  public displayedColumns = [
    "date",
    "invId",
    "type",
    "hours",
    "paid",
    "actions",
  ];
  public footerCols = ["date", "invId", "type", "hours", "paid", "actions"];
  public selectedInvestigation: IApiInvestigation = null;
  public totalHours: number = null;

  private _filters: IApiTimeEntryFilter[] = [];
  private _timeEntryForm = {
    id: null,
    TypeId: null,
    workday: new Date(),
    hours: null,
    UserId: null,
    InvestigationId: null,
    description: "",
  };

  // Setters/getters for above
  public get timeEntryForm() {
    return this._timeEntryForm;
  }

  public sort: SortDescriptor[] = [{
    field: 'WORKDAY',
    dir: 'desc'
  }];
  public authenticatedUserId = null;

  constructor(
    public loader: LoaderService,
    private timeEntryService: TimeEntryService,
    private notifications: NotificationsService,
    private dialogService: DialogService,
    public auth: AuthService,
  ) { }

  public applyFilters() {
    this.dataSource.removeFilter(IApiTimeEntryFilterType.PayPeriod);
    if (this.dateRangeFilter) {
      this.dataSource.removeFilter(IApiTimeEntryFilterType.Week);
      this.dataSource.removeFilter(IApiTimeEntryFilterType.Year);
      this.dataSource.applyFilter(IApiTimeEntryFilterType.DateRange, this.dateRangeFilter);
    } else {
      this.dataSource.removeFilter(IApiTimeEntryFilterType.DateRange);
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Year, this.dateFilterYear)
      this.dataSource.applyFilter(IApiTimeEntryFilterType.Week, this.dateFilterWeek);
    }
    this.load();
  }


  public dateRangeChange(event) {
    this.dateRangeFilter = event;
    this.applyFilters();
  }

  public dateChange(event) {
    this.dateRangeFilter = null;
    this.dateFilterWeek = (event.isoWeek() - 1).toString();
    this.dateFilterYear = (event.year()).toString();
    this.applyFilters();
  }

  private getTimeEntryTotal() {
    this.timeEntryService.getTimeEntryTotal(this.dataSource.lastFilters, this.pageOptions.getPageOptions())
      .subscribe(response => this.timeEntriesCount = response);

  }

  public investigationChanged(event) {
    this.dataSource.applyFilter(IApiTimeEntryFilterType.Investigation, event);
    this.load();
  }

  public formSubmitted(timeEntry) {
    if (timeEntry.id) this.updateTimeEntry(timeEntry);
    else {
      const { TypeId, workday, hours, UserId, InvestigationId, description, nonBillableHours } =
        timeEntry;
      const timeEntryInput: IApiAddTimeEntryInput = {
        TypeId: TypeId,
        UserId: UserId,
        workday: workday,
        hours: hours || 0,
        InvestigationId: InvestigationId,
        description: description,
        nonBillableHours: nonBillableHours,
      };

      this.addTimeEntry(timeEntryInput);
    }
  }

  public get timeEntryTotal() {
    if (this.visibleTimeEntries) {
      return this.visibleTimeEntries.reduce(
        (p, c) => p + c.hours + c.nonBillableHours,
        0
      );
    } else {
      return 0;
    }
  }

  public showPaid(name: string) {
    return name === IApiTimeEntryStatusNames.Approved;
  }

  private updateTimeEntry(timeEntry: IApiUpdateTimeEntryInput) {
    this.loader
      .show$(this.timeEntryService.update(timeEntry))
      .pipe(
        this.notifications.snackbarPipe("Time entry updated."),
        this.notifications.snackbarErrorPipe("Error updating Time entry.")
      )
      .subscribe(() => this.load());
  }

  public editTime(item?: IApiTimeEntry) {
    const timeItem = (item as IApiTimeEntry)?.workday
      ? { ...item }
      : {
        id: null,
        Type: null,
        workday: new Date(),
        hours: null,
        nonBillableHours: null,
        User: null,
      };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: item?.Investigation?.id,
      nefcoNumber: item?.Investigation?.nefcoNumber,
      expense: null,
      time: timeItem as IApiTimeEntry,
      staff: null,
      adminView: false,
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = [];
    dialogInstance.investigations = this.investigations;
    dialogInstance.expenseItems = [];
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.load();
    })
  }

  public deleteTime(entry) {

    this.notifications.kendoConfirm(
      "Are you sure you want to delete this hours entry?",
      "Delete Hours Entry?",
      "No, Don’t Delete",
      "Yes, Delete"
    )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.timeEntryService.remove(entry.id))
      )
      .subscribe(() => {
        this.load();
      });
  }

  private addTimeEntry(timeEntry: IApiAddTimeEntryInput) {
    this.loader
      .show$(this.timeEntryService.add(timeEntry))
      .pipe(
        this.notifications.snackbarPipe("Time entry added."),
        this.notifications.snackbarErrorPipe("Error adding Time entry.")
      )
      .subscribe(() => this.load());
  }

  ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this._dataSource = new TimeEntryDataSource(this.timeEntryService);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource.applyFilter(
      IApiTimeEntryFilterType.Week,
      this.dateFilterWeek
    );
    this.dataSource.applyFilter(IApiTimeEntryFilterType.Year, this.dateFilterYear)
    this.dataSource.applyFilter(IApiTimeEntryFilterType.Employee, "");

    this._timeEntryForm.UserId = this.userId;
    this.pageOptions.orderBy = IApiTimeEntryOrderBy.Workday;

    this.load();
  }

  public load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
    this.getTimeEntryTotal();
    this.dataSource.contents$.subscribe((data) => {
      this.visibleTimeEntries = data;
      this.timeEntries = data;
      setTimeout(() => {
        try {
          document.getElementsByTagName('body')?.[0].click();
        } catch (error) { }
      }, 100);
    });
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'WORKDAY';
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  }

  // check permission for edit
  public checkEditPermission = (row) => {
    if (row?.Investigation) {
      if (this.timeEntryInvestigationHoursUpdatePermission?.enabled || (this.timeEntryInvestigationHoursUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    } else {
      if (this.timeEntryUnlinkedHoursUpdatePermission?.enabled || (this.timeEntryUnlinkedHoursUpdateOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    }
  }

  // check permission for delete
  public checkDeletePermission = (row) => {
    if (row?.Investigation) {
      if (this.timeEntryInvestigationHoursDeletePermission?.enabled || (this.timeEntryInvestigationHoursDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    } else {
      if (this.timeEntryUnlinkedHoursDeletePermission?.enabled || (this.timeEntryUnlinkedHoursDeleteOwnPermission?.enabled && row?.User?.id === this.authenticatedUserId)) return true;
      else return false;
    }
  }
}
