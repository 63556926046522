import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { InvestigationTemplatDownloadService } from '../../../../shared/services/investigation/investigation-template-download/investigation-template-download.service';
import { ElementRef, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { InvestigationDocumentsSidebarComponent } from '../investigation-documents-sidebar/investigation-documents-sidebar.component';
import { Subscription, forkJoin, of } from "rxjs";
import { filter, map, switchMap, take } from "rxjs/operators";
import { InvestigationReportService, DocumentService, DocumentTypeService, AuthService, DocumentShareService, SharedReportService, InvestigationService, DownloadHelper } from "src/app/shared/services";
import { IApiDocument, IApiDocumentFilterType, IApiDocumentOrderBy, IApiDocumentSent, IApiDocumentSentFilterType, IApiDocumentSentOrderBy, IApiDocumentShare, IApiDocumentType, IApiDocumentTypeOrderBy, IApiInvestigation, IApiInvestigationRoleNames, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUploadTypes, IApiUser, IApiUserFilterType } from "src/app/shared/modules/graphql/types/types";
import { unwrapConnection } from "src/app/shared/rxjs.pipes";
import { DocumentCategories } from 'src/app/shared/modules/graphql/constants/document.constants';
import { InvestigationReportO365LaunchComponent } from "../investigation-report-o365-launch/investigation-report-o365-launch.component";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import {
  investigationDocumentShareListView,
  investigationDocumentsListView,
  investigationDocumentShareCreate,
  investigationDocumentsCreate,
  investigationDocumentsDelete,
  investigationDocumentsUpdate,
  investigationDocumentsDownload,
  investigationReportsList,
  investigationReportsView,
  investigationReportsCreate,
  investigationReportsUpdate,
  investigationReportsAssignEditorUpdate,
  investigationReportsInTechReviewUpdate,
  investigationReportsInEditorReviewUpdate,
  investigationReportsDelete,
  investigationReportsDeleteOwn
} from "src/app/shared/helpers/auth-config/investigations.config";
import { IApiInvestigationReportStatus } from 'src/app/shared/modules/graphql/types/types';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MarkasSentComponent } from './markas-sent/markas-sent.component';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SharedReportDataSource } from 'src/app/shared/services/document/shared-report/shared-report.datasource';
import { NotesModalComponent } from 'src/app/shared/modules/notes/notes-modal/notes-modal.component';
import { DropDownListComponent } from '@progress/kendo-angular-dropdowns';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { DocumentDataSource } from 'src/app/shared/services/document/document.datasource';
import { DocumentShareDataSource } from 'src/app/shared/services/document/document-share/document-type.datasource';
import { environment } from 'src/environments/environment';
import { FileSelectModalComponent } from 'src/app/shared/components/file-select-modal/file-select-modal.component';
import {
  dcmMimeType,
  htmlMimeType,
  imageMimeType,
  messageMimeType,
  pdfMimeType,
  pptMimeType,
  psMimeType,
  textMimeType,
  vCardMimeType,
  videoMimeType,
  wordMimeTypes,
  zipMimeType,
} from "src/app/shared/helpers/helper";
import { FileInterface } from 'src/app/shared/services/s3/s3-service';

interface ICheckableDocumentType extends IApiDocumentType {
  checked: boolean;
}

interface ICheckableDocument extends IApiDocument {
  checked: boolean;
}

export interface IApiDocumentSentRecords extends IApiDocumentSent {
  name?: string;
  companyName?: string;
}

@Component({
  selector: 'app-investigation-documents',
  templateUrl: './investigation-documents.component.html',
  styleUrls: ['./investigation-documents.component.scss']
})

export class InvestigationDocumentsComponent implements OnInit, OnDestroy {

  public authConfig = {
    investigationDocumentShareListView,
    investigationDocumentsListView,
    investigationDocumentShareCreate,
    investigationDocumentsCreate,
    investigationDocumentsDelete,
    investigationDocumentsUpdate,
    investigationDocumentsDownload,
    investigationReportsList,
    investigationReportsView,
    investigationReportsCreate,
    investigationReportsUpdate,
    investigationReportsAssignEditorUpdate,
    investigationReportsInTechReviewUpdate,
    investigationReportsInEditorReviewUpdate,
    investigationReportsDelete,
    investigationReportsDeleteOwn
  };
  @ViewChild("docTitle") private docTitle: any;
  public sharedDocs: IApiDocumentShare[] = [];
  public sharedReportList: IApiDocumentSentRecords[] = [];

  // @Input() public investigation: IApiInvestigation;
  private _investigation: IApiInvestigation;
  @Input() public set investigation(val: IApiInvestigation) {
    this._investigation = val;
    this.getDocuments();
  }

  public get investigation() {
    return this._investigation;
  }
  @Output() changed = new EventEmitter<IApiDocument[]>();
  @Output() reload = new EventEmitter<boolean>();

  public searchTerm: string | null = '';

  public get IInvestigationReportStatus(): typeof IApiInvestigationReportStatus {
    return IApiInvestigationReportStatus;
  }

  public get checkedDownloads() {
    return this.downloadTypes.filter(d => d.checked);
  }

  public get checkedUploads() {
    return this.uploads.filter(d => d.checked);
  }

  public get checkedReports() {
    return this.reports.filter(d => d.checked);
  }

  public get finalReports() {
    return this.reports.filter(d => d?.Status.status === IApiInvestigationReportStatus.Final);
  }

  public uploads: ICheckableDocument[] = [];

  public reports: ICheckableDocument[] = [];

  public selectedDocumentType: IApiDocumentType = null;
  private _documentTypes: IApiDocumentType[] = [];

  public uploadTypes: IApiDocumentType[] = [];

  public downloadTypes: ICheckableDocumentType[] = [];

  public reportTypes: IApiDocumentType[] = [];

  // Table column names
  public sharedDocsColumns = ["docName", "shared", "parties"];
  public uploadDocsColumns = ["docType", "docName", "uploadBy", "date", "icons"];
  public downloadDocsColumns = ["docName", "download"];
  public reportsColumns = ["docName", "createdBy", "status", "date", "updatedBy", "actions"];

  public selectAll = false;

  public selectedDocs: [] = null;

  // public docTitle: string = null;

  // Setting document upload title here so it can be autofilled on selection change
  public selectedUploadType: IApiDocumentType;
  public docTitleValue: string = null;

  public user: IApiUser;

  public selectedReportKeys = [];

  public editors = [];
  public sort: SortDescriptor[] = [{
    field: IApiDocumentSentOrderBy.SendDate,
    dir: 'desc'
  }];


  public dataSource: SharedReportDataSource;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public userRoleName = IApiInvestigationRoleNames.Editor;

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public sortUpload: SortDescriptor[] = [{
    field: IApiDocumentOrderBy.CreatedAt,
    dir: 'desc'
  }];

  public get pageOptionsUpload() {
    if (!this.dataSourceUpload) return null;
    return this.dataSourceUpload.listPage;
  }

  public dataSourceUpload: DocumentDataSource;

  public sortShared: SortDescriptor[] = [{
    field: IApiDocumentOrderBy.CreatedAt,
    dir: 'desc'
  }];

  public get pageOptionsShared() {
    if (!this.dataSourceShared) return null;
    return this.dataSourceShared.listPage;
  }

  public dataSourceShared: DocumentShareDataSource;
  public selectedUploadTypeId: string;
  public selectedReportTypeId: string;
  public refreshPendingSubscription: Subscription;

  private combineSortOrder = [
    "Permission to Enter Property",
    "Fire Department Report",
    "Fire Marshal Report",
    "Police Department Report",
    "Scene Diagram",
    "ETL",
    "Lab Results",
    "Lightning Strike Report",
    "Weather Report",
    "Sign In Sheet"
  ];

  constructor(
    private dialog: MatDialog,
    private notifications: NotificationsService,
    private documentService: DocumentService,
    private documentShareService: DocumentShareService,
    private documentTypeService: DocumentTypeService,
    private reportService: InvestigationReportService,
    private templateService: InvestigationTemplatDownloadService,
    private loader: LoaderService,
    public auth: AuthService,
    private dialogService: DialogService,
    private sharedReportService: SharedReportService,
    private investigationService: InvestigationService,
    private downloadHelper: DownloadHelper,
  ) {
    this.loader.isMatLoader = false;
    this.dataSourceShared = new DocumentShareDataSource(this.documentShareService);
    this.loader.attachObservable(this.dataSourceShared.loading$);
    this.dataSource = new SharedReportDataSource(this.sharedReportService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSourceUpload = new DocumentDataSource(this.documentService);
    this.loader.attachObservable(this.dataSourceUpload.loading$);
    this.documentTypeService.get([], {
      // Need SortOrder or call will error
      // Need to specify a limit or it only returns the first 25
      sortOrder: SortOrder.ASC,
      orderBy: IApiDocumentTypeOrderBy.Name,
      limit: 1000
    }).pipe(
      unwrapConnection(),
      map<IApiDocumentType[], IApiDocumentType[]>(types => types.sort((a, b) => a.name < b.name ? -1 : 1))
    ).subscribe((result) => {
      this._documentTypes = result
      this.reportTypes = this._documentTypes.filter(t => t.Category.name === DocumentCategories.REPORT.valueOf());
      this.uploadTypes = this._documentTypes.filter(t => t.Category.name === DocumentCategories.UPLOAD.valueOf());
      this.downloadTypes = this._documentTypes.filter(t => (t.Category.name === DocumentCategories.DOWNLOAD.valueOf() && t.name.includes("Blank"))) as ICheckableDocumentType[];
    });

    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);
  }

  public ngOnInit() {
    this.editors = this.investigation?.InvestigationStaff?.filter(staff => staff.Role.title === IApiInvestigationRoleNames.Editor)
    this.editors = this.editors?.map(staff => {
      return {
        ...staff?.User,
        name: `${staff?.User?.firstName} ${staff?.User?.lastName}`
      }
    }
    );

    /* START Shared datasource load */
    this.dataSourceShared.applyFilter(IApiDocumentFilterType.Investigation, this.investigation.id);
    this.dataSourceShared.listPage.sortOrder = SortOrder.DESC;
    this.dataSourceShared.listPage.orderBy = IApiDocumentOrderBy.CreatedAt;
    this.dataSourceShared.listPage.limit = 10;
    this.sharedLoad().then(() => {
      this.dataSourceShared.contents$.subscribe(res => {
        this.sharedDocs = res;
      });
    });
    /* END Shared datasource load */

    /* START Shared Report datasource load */
    this.dataSource.applyFilter(IApiDocumentSentFilterType.Investigation, this.investigation.id);
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.listPage.orderBy = IApiDocumentSentOrderBy.SendDate;
    this.dataSource.listPage.limit = 10;
    this.sharedReportLoad().then(() => {
      this.dataSource.contents$.subscribe((content) => {
        if (content?.length) {
          content.map(obj => {
            if (obj?.BillTo) {
              obj.name = `${obj?.BillTo?.firstName || ''} ${obj?.BillTo?.lastName || ''}`;
              if (obj?.BillTo?.id === this.investigation?.BillTo?.id) {
                obj.companyName = `${this?.investigation?.BillToBranch?.Company?.name}`;
              };
            } else if (obj?.Client) {
              obj.name = `${obj?.Client?.firstName || ''} ${obj?.Client?.lastName || ''}`;
              if (obj?.Client?.id === this.investigation?.Client?.id) {
                obj.companyName = `${this?.investigation?.ClientBranch?.Company?.name}`;
              }
            } else if (obj?.Insured) {
              obj.name = (obj?.Insured?.contactName === '' ? obj?.Insured?.insuredName : obj?.Insured?.contactName) || '';
              obj.companyName = (obj?.Insured?.contactName === '' ? '' : obj?.Insured?.insuredName) || '';
            } else if (obj?.Contact) {
              const latestCompany = (obj?.Contact?.BranchAssignments || []).filter(item => {
                return !item.endDate
              })?.sort((a: any, b: any) => new Date(b.startDate).valueOf() - new Date(a.startDate).valueOf());
              obj.name = `${obj?.Contact?.firstName || ''} ${obj?.Contact?.lastName || ''}`;
              if (this.investigation?.Parties?.find(p => p.Contact.id === obj.Contact?.id && latestCompany?.length)) {
                obj.companyName = `${latestCompany[0].Branch?.Company?.name || ''}`;
              }
            }
          });
        }
        this.sharedReportList = content;
      });
    });
    /* END Shared Report datasource load */

    /* START Upload datasource load */
    this.dataSourceUpload.applyFilter(IApiDocumentFilterType.Investigation, this.investigation.id);
    this.dataSourceUpload.applyFilter(IApiDocumentFilterType.Category, DocumentCategories.UPLOAD.valueOf());
    this.dataSourceUpload.listPage.sortOrder = SortOrder.DESC;
    this.dataSourceUpload.listPage.orderBy = IApiDocumentOrderBy.CreatedAt;
    this.dataSourceUpload.listPage.limit = 10;
    this.uploadLoad().then(() => {
      this.dataSourceUpload.contents$.subscribe(res => {
        this.uploads = res?.map(item => { item['checked'] = false; return item; }) || [] as any;
      });
    });
    /* END Upload datasource load */
    this.getDocuments();

    this.refreshPendingSubscription = this.documentShareService.refreshInvestigationDocumentShare().subscribe((resp) => {
      if (this.investigation.id === resp) {
        this.notifications.notify("Your files are ready for sharing! The page has been refreshed.");
        this.sharedLoad();
      }
    });
  }

  public markAsSent() {
    const dialog: DialogRef = this.dialogService.open({
      content: MarkasSentComponent,
      width: 600,
      maxWidth: 600,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    let documentIds = [];
    let documentTypeIds = [];
    if (this.checkedDownloads?.length) {
      documentTypeIds = this.checkedDownloads.map(obj => obj.id);
    }
    if (this.checkedUploads?.length) {
      this.checkedUploads.map(obj => {
        documentIds.push(obj.id);
      });
    }
    if (this.checkedReports?.length) {
      this.checkedReports.map(obj => {
        documentIds.push(obj.id);
      });
    }

    const data = {
      investigation: this.investigation,
      documentIds,
      documentTypeIds
    };
    const dialogInstance = dialog.content.instance as MarkasSentComponent;
    dialogInstance.data = data;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === 'sent') {
        this.downloadTypes.map(a => a.checked = false);
        this.uploads.map(a => a.checked = false);
        this.reports.map(a => a.checked = false);
        this.dataSource.load();
      }
    });
  }

  public async sharedReportLoad() {
    await this.dataSource.load();
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = "CREATED_AT";
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.dataSource.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public getDocuments() {
    // Hydrate documents
    this.loader.show$(
      forkJoin({
        reports: this.documentService.get([{
          type: IApiDocumentFilterType.Investigation,
          value: this.investigation.id
        }, {
          type: IApiDocumentFilterType.Category,
          value: DocumentCategories.REPORT.valueOf()
        }], { orderBy: IApiDocumentOrderBy.Title, sortOrder: SortOrder.ASC, limit: -1 }).pipe(
          unwrapConnection()
        )
      })
    ).subscribe(({ reports }) => {
      this.reports = reports;
      // this.sharedDocs = shares;
    });
  }

  public sortChangeUpload = (e: SortDescriptor[]) => {
    this.sortUpload = e;
    if (e && e?.[0]?.dir) {
      this.dataSourceUpload.listPage.orderBy = e?.[0]?.field;
      this.dataSourceUpload.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSourceUpload.listPage.orderBy = "CREATED_AT";
      this.dataSourceUpload.listPage.sortOrder = SortOrder.ASC;
    }
    this.dataSourceUpload.load();
  }

  public async sharedLoad() {
    await this.dataSourceShared.load();
  }

  public pageChangeShared(event) {
    this.pageOptionsUpload?.paginate(event);
  }

  public sortChangeShared = (e: SortDescriptor[]) => {
    this.sortShared = e;
    if (e && e?.[0]?.dir) {
      this.dataSourceShared.listPage.orderBy = e?.[0]?.field;
      this.dataSourceShared.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSourceShared.listPage.orderBy = "CREATED_AT";
      this.dataSourceShared.listPage.sortOrder = SortOrder.ASC;
    }
    this.dataSourceShared.load();
  }

  public async uploadLoad() {
    await this.dataSourceUpload.load();
  }

  public pageChangeUpload(event) {
    this.pageOptionsUpload?.paginate(event);
  }

  public checkAll(event, affectedArray: ICheckableDocumentType[] | ICheckableDocument[]): void {
    affectedArray.forEach(d => d.checked = event.checked);
  }

  public openReport(report) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDocumentsSidebarComponent,
      width: "50%",
      height: "100vh",
      cssClass: 'right-position history-log',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDocumentsSidebarComponent;
    dialogInstance.data = {
      report,
      investigation: this.investigation
    };
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) this.getDocuments();
    })
  }

  public showLaunchReport(report: IApiDocument) {
    if (!this.user.msGraphToken) {
      this.notifications.error("You do not currently have an o365 ID associated with your account. Please enter this in the space provided in your user account profile an then try again.");
      return;
    }

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationReportO365LaunchComponent,
      width: "45%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationReportO365LaunchComponent;
    dialogInstance.report =  report;
    dialog.result.subscribe();
  }

  public deleteUpload(id) {
    this.notifications.kendoConfirm("Are you sure you want to delete this upload?").pipe(
      filter(result => !!result),
      switchMap(() => this.documentService.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting upload"),
      this.notifications.snackbarPipe("Upload deleted successfully.")
    ).subscribe(() => {
      this.uploadLoad();
      this.emitChanged();
    });
  }

  public pdfCombine() {
    // TODO Send to backend to be combined?
  }

  private setUploadUriOrder() {
    const orderMap = this.combineSortOrder.reduce((acc, item, index) => {
      acc[item] = index;
      return acc;
    }, {});
    const upload = this.checkedUploads.sort((a, b) => {
      // Get the order index for both a and b
      const indexA = orderMap[a?.Type?.name] !== undefined ? orderMap[a?.Type?.name] : Number.MAX_SAFE_INTEGER;
      const indexB = orderMap[b?.Type?.name] !== undefined ? orderMap[b?.Type?.name] : Number.MAX_SAFE_INTEGER;

      // Compare the indices
      return indexA - indexB;
    });
    const reports = this.checkedReports.sort((a, b) => {
      if (a?.Type?.name === "Photo Sheet") {
        return 1;
      } else if (b?.Type?.name === "Photo Sheet") {
        return -1;
      } else {
        return 0;
      }
    });
    return [...reports?.map(({ History }) => {
      const finalStatus = History.find((obj) => obj.status === IApiInvestigationReportStatus.Final);
      return finalStatus.s3Uri;
    }), ...upload?.map(({ s3Uri }) => s3Uri)];
  }
  public templateDownload(documentTypeId?) {
    const uploadUris = this.setUploadUriOrder();
    this.loader.show$(
      this.templateService.generateTemplateDownload({
        InvestigationId: this.investigation.id,
        // Return download document type ids
        DocumentTypeIds: documentTypeId ? [documentTypeId] : this.checkedDownloads.map(({ id }) => id),
        // Return Upload URIs for Uploads and Reports
        UploadUris: uploadUris,
      })
    ).subscribe(downloadUrl => {
      if (downloadUrl) {
        this.downloadHelper.openURL(downloadUrl);
      }
    });
  }

  public async createReport() {
    try {
      await this.loader.show$(
        this.reportService.create({
          investigationId: this.investigation.id,
          documentTypeId: this.selectedReportTypeId
        })
      ).toPromise();

      this.getDocuments();
      this.reload.emit(true);
      this.selectedReportTypeId = null;
    }
    catch (exc) {
      console.error(exc);
      this.selectedReportTypeId = null;
      this.notifications.error("Error creating report. Please try again.");
    }
  }

  async uploadSuccess(files: FileInterface[]) {
    try {
      await this.loader.show$(
        forkJoin(
          files.map(({ key }, index) => {
            const docNum = this.uploads.filter((doc) => doc?.Type.id === this.selectedUploadTypeId).length + (index + 1);
            return this.documentService.add({
              uri: '',
              s3Uri: key,
              title: (this.selectedUploadType?.name === this.docTitleValue?.trim()) ? `${this.docTitleValue} ${docNum}` : this.docTitleValue,
              caption: (this.selectedUploadType?.name === this.docTitleValue?.trim()) ? `${this.docTitleValue} ${docNum}` : this.docTitleValue,
              InvestigationId: this.investigation.id,
              TypeId: this.selectedUploadTypeId
            });
          })
        )
      ).toPromise();

      this.uploadLoad();

      this.notifications.notify("Upload successful!");
      this.emitChanged();
      this.reload.emit(true);

      this.docTitleValue = null;
      this.selectedUploadTypeId = null;
    }
    catch (exc) {
      console.error(exc);
      this.docTitleValue = null;
      this.selectedUploadTypeId = null;
      this.notifications.error("Error linking uploaded document. Please try again.");
    }
  }

  public fileSelector() {
    const dialog: DialogRef = this.dialogService.open({
      content: FileSelectModalComponent,
      width: 600,
      maxWidth: 600,
      maxHeight: 670,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as FileSelectModalComponent;
    dialogInstance.data = {
      InvestigationId: this.investigation.id,
      FolderId: null,
      UserId: null,
      type: IApiUploadTypes.Documents,
      restrictions: [
        ...wordMimeTypes,
        ...imageMimeType,
        ...pdfMimeType,
        ...zipMimeType,
        ...videoMimeType,
        ...dcmMimeType,
        ...textMimeType,
        ...htmlMimeType,
        ...messageMimeType,
        ...vCardMimeType,
        ...pptMimeType,
        ...psMimeType,
      ],
      multiple: true,
      limit: 10,
      restrictionsSize: [{ zip: 167772160, video: 1610612736 }],
    };
    dialog.result.subscribe((res: any) => {
      if (res?.length && res) {
        this.uploadSuccess(res);
      }
    });
  }

  private emitChanged() {
    this.changed.emit(this.uploads);
  }

  // Document Upload - Auto Populate Document Title with Document Type
  public autofillDocTitle() {
    this.selectedUploadType = this.uploadTypes.find(upload => upload.id === this.selectedUploadTypeId);
    if (this.selectedUploadType?.name === DocumentCategories.OTHER.valueOf() || this.selectedUploadType?.name === 'Correspondence') {
      this.docTitleValue = null;
      this.docTitle?.input?.nativeElement?.focus();
    } else {
      this.docTitleValue = this.selectedUploadType?.name || null;
    }
  }

  updateEditorInReport(event, dataItem) {
    if (!event?.id) {
      return;
    }
    this.loader.show$(
      this.documentService.update({
        id: dataItem?.id,
        EditorId: event?.id,
        advanceReport: true
      }).pipe(
        this.notifications.snackbarErrorPipe("Error assigning editor; please try again"),
      )
    ).subscribe(response => {
      this.reload.emit(true);
    });
  }

  private updateInvestigationHistoryWithComment() {
    // Trigger comment dialog and, then update investigation with History attribute
    const { id } = this.investigation;
    const data = {
      notes: "",
      title: "Please add a comment on what you are deleting.",
      original: ""
    };

    const dialog: DialogRef = this.dialogService.open({
      content: NotesModalComponent,
      width: 660,
      maxWidth: 660,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const userInfo = dialog.content.instance as NotesModalComponent;
    userInfo.data = data;

    return dialog.result.pipe(
      switchMap((comment: any) => {
        // If no comment came back, return false so we can revert the checkbox to its previous state
        if (typeof comment === 'string') return this.loader.show$(this.investigationService.update({ id, History: { InvestigationId: id, updateType: IApiInvestigationUpdateTypes.Delete, updateCategory: IApiInvestigationUpdateCategories.Documents, comment } })).pipe(
          this.notifications.snackbarErrorPipe()
        );
        else return of(false);
      }),
    );
  }

  public deleteDraft(report: IApiDocument) {
    this.notifications.kendoConfirm(
      `Are you sure you want to delete this draft report? All information for this report will be removed.`,
      `Delete ${report?.title}?`,
      "No, Don’t Delete",
      "Yes, Delete",
      600
    ).subscribe((res) => {
      if (res === true) {
        this.updateInvestigationHistoryWithComment().subscribe(res => {
          if (res) {
            this.loader.show$(this.documentService.remove(report?.id).pipe(
              this.notifications.snackbarErrorPipe("Error deleting report"),
              this.notifications.snackbarPipe("Report deleted successfully.")
            )).subscribe(() => {
              this.getDocuments()
              this.reload.emit(true);
            });
          }
        })
      }
    });
  }

  public openInNewTab(url: string) {
    if (url) {
      this.downloadHelper.openURL(url);
    }
  }

  ngOnDestroy(): void {
    if (this.refreshPendingSubscription) {
      this.refreshPendingSubscription.unsubscribe();
    }
  }

}
