import { of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take, switchMap, tap, mergeMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationPartyService, ContactRoleService, InvestigationService } from 'src/app/shared/services/';
import { InvestigationSelectContactCompanyModalComponent } from '..';
import { IApiInvestigationPartyFilterType, IApiContactRole, IApiInvestigationParty, IApiContact, IApiAddAddressInput, IApiAddPhoneInput, IApiAddEmailInput, IApiAddInvestigationPartyInput, IApiInvestigation, IApiUpdateInvestigationInput, IApiInvestigationUpdateTypes, IApiInvestigationUpdateCategories, IApiContactRoleFilterType, IApiInsuredParty } from 'src/app/shared/modules/graphql/types/types';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { CreateUpdateContactComponent } from '../../contacts/create-update-contact/create-update-contact.component';
import { forkJoin } from 'rxjs';
import _, { isEqual } from "lodash";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { InvestigationInsuredPartyModalComponent } from '../investigation-insured-party-modal/investigation-insured-party-modal.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  contactsCreateConfig,
  contactsProfileContactInformationUpdate,
  contactsProfileCompanyInformationUpdate,
  contactsDelete,
} from "src/app/shared/helpers/auth-config/contacts.config";

import {
  investigationPartiesCreate,
  investigationPartiesUpdate,
  investigationPartiesDelete,
  investigationInformationUpdate,
} from "src/app/shared/helpers/auth-config/investigations.config";
@UntilDestroy()
@Component({
  selector: 'app-investigation-party',
  templateUrl: './investigation-party.component.html',
  styleUrls: ['./investigation-party.component.scss']
})
export class InvestigationPartyComponent implements OnInit {
  public authConfig = {
    contactsCreateConfig,
    contactsProfileContactInformationUpdate,
    contactsProfileCompanyInformationUpdate,
    contactsDelete,
    investigationPartiesCreate,
    investigationPartiesUpdate,
    investigationPartiesDelete,
    investigationInformationUpdate,
  };
  public roles: IApiContactRole[] = [];
  public parties: IApiInvestigationParty[] = [];
  public contacts: IApiContact[];
  public showAddParty = true;

  // don't love this, but it's seeded data. It'll do.
  public insuredRole = "Insured";
  public insured;

  private _investigation: IApiInvestigation;
  @Input() public set investigation(val: IApiInvestigation) {
    // Only fire this if values are different otherwise we make a LOT of requests
    if (!isEqual(val, this._investigation)) {
      this._investigation = val;
      if (val && !this.parties.length) this.loadParties().subscribe(() => {
        this.checkParties();
      });
    }
  }

  public get investigation() {
    return this._investigation;
  }

  public get investigationId(): string {
    return this._investigation ? this._investigation.id : null;
  }

  private _sceneContact: IApiContact;

  public get sceneContactId(): string {
    return this._investigation && this._investigation.SceneContact ? this._investigation.SceneContact.id : null;
  }
  public set sceneContactId(val: string) { }

  public get sceneContact(): IApiContact {
    return this._sceneContact;
  }
  public set sceneContact(val: IApiContact) { }

  public isInsured(role: IApiContactRole): boolean {
    return role?.name === this.insuredRole;
  }

  public contactRole: IApiContactRole | null = null;

  constructor(
    private investigationService: InvestigationService,
    private investigationPartyService: InvestigationPartyService,
    private contactRoleService: ContactRoleService,
    private dialog: MatDialog,
    private notifications: NotificationsService,
    private dialogService: DialogService,
  ) { }

  // Checks to see if the role is "other", "insured", or "tenant"
  private checkForRole(role: IApiContactRole) {
    if (!role) return false;

    const { ContactType: { name } } = role;
    return name === "External Party" || name === "Insured";
  }

  // Open the correct modal depending on role selected
  public openModal(role: IApiContactRole) {
    if (role.isPrivate) this.addNewContact(role);
    else this.selectContact(role);
  }

  public selectContact(role: IApiContactRole) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationSelectContactCompanyModalComponent,
      width: '80%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationSelectContactCompanyModalComponent;
    dialogInstance.data = {
      role: role,
      partyAdd: true,
    };
    dialog.result
      .pipe(
        filter((v) => !_.isEmpty(v)),
        switchMap(({ contact, newContact }: any) => forkJoin([
          this.addContact(this.addItem(role, contact), newContact),
          this.investigationService.update({
            id: this.investigation.id,
            History: {
              InvestigationId: this.investigation.id,
              updateCategory: IApiInvestigationUpdateCategories.Parties,
              updateType: IApiInvestigationUpdateTypes.Create,
              comment: `${contact.firstName} ${contact.lastName} with role "${role.name}" added to investigation`
            }
          })
        ])),
        this.notifications.snackbarErrorPipe("There was an error setting the Client contact. Please try again.")
      ).subscribe(() => {
        this.contactRole = null;
      }, () => {
        this.contactRole = null;
      });
  }

  // add contact modal
  public addNewContact(role: IApiContactRole) {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = {
      message: `Please enter information for the ${role.name}.`,
      role: role.name,
      disableRoleSelect: true,
      partyAdd: true,
    };
    dialog.result
      .pipe(
        filter((v) => !_.isEmpty(v)),
        switchMap((result: any) => forkJoin([
          this.addContact(this.addItem(role, result), true),
          this.investigationService.update({
            id: this.investigation.id,
            History: {
              InvestigationId: this.investigation.id,
              updateCategory: IApiInvestigationUpdateCategories.Parties,
              updateType: IApiInvestigationUpdateTypes.Create,
              comment: `${result.firstName} ${result.lastName} with role "${role.name}" added to investigation`
            }
          })
        ])),
        this.notifications.snackbarErrorPipe("There was an error setting the contact. Please try again.")
      )
      .subscribe(() => {
        this.contactRole = null;
      }, () => {
        this.contactRole = null;
      });
  }

  public editInsured(party) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationInsuredPartyModalComponent,
      width: 600,
      maxWidth: 600,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const userInfo = dialog.content.instance as InvestigationInsuredPartyModalComponent;
    userInfo.party = party;

    dialog.result.subscribe((result: any) => {
      if (result?.id) {
        this.insured = result;
      }
      this.rehydrate();
    })
  }

  public editContact(contactId: string) {
    const dialog: DialogRef = this.dialogService.open({
      content: CreateUpdateContactComponent,
      width: '80%',
      maxWidth: '90%',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as CreateUpdateContactComponent;
    dialogInstance.data = { contactId };
    dialog.result
      .pipe(
        switchMap(() => this.rehydrate())
      )
      .subscribe(() => { });
  }

  public addItem(role: IApiContactRole, contact?: IApiContact) {
    const party = {
      id: null,
      isActive: true,
      Role: role,
      Contact: contact || {
        id: null,
        firstName: "",
        lastName: "",
        Phones: [{
          id: null,
          number: "",
          TypeId: null,
          Type: null,
          isPrimary: true
        }],
        Emails: [{
          id: null,
          address: "",
          isPrimary: true,
          Type: null,
          TypeId: null,
        }],
        Addresses: [{
          id: null,
          isPrimary: true,
          Type: null,
          address1: "",
          city: "",
          state: "",
          postal: "",
          country: "US"
        }]
      }
    };

    this.parties.push(party);

    return party;
  }

  private rehydrate() {
    return forkJoin([
      this.loadParties(),
      this.investigationService.getById(this.investigation.id).pipe(tap((i) => { this._investigation = i }))
    ]);
  }

  private loadParties() {
    return this.investigationPartyService.get([{ type: IApiInvestigationPartyFilterType.Investigation, value: this.investigationId }]).pipe(
      take(1),
      this.notifications.snackbarErrorPipe('An error occured while retrieving Parties'),
      unwrapConnection(),
      tap(parties => {
        this.parties = parties;
        this.contacts = this.parties.map(({ Role, Contact }) => {
          Contact.Role = Role;
          return Contact;
        }); // .filter((obj) => this.checkForRole(obj.Role));

        if (this.investigation.Client) this.contacts.unshift({
          ...this.investigation.Client,
          Role: null
        });
      })
    );
  }

  private checkParties() {
    if (this.roles.length && this.parties && !this.parties.length) {
      // need the insured first always
      const found = this.roles.find(r => r.name === this.insuredRole);
      if (found) this.addItem(found);
    }
  }

  public ngOnInit() {
    this.insured = this.investigation.Insured;
    // Set scene contact
    this._sceneContact = this.investigation && this.investigation.SceneContact ? this.investigation.SceneContact : null;

    // get roles
    this.contactRoleService.get([{ type: IApiContactRoleFilterType.ViewListView, value: 'true' }], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe((roles) => {
      this.roles = roles; // .filter(r => this.checkForRole(r));
      this.checkParties();
    });
  }

  public addContact(party: IApiInvestigationParty, newContact: boolean) {
    const {
      isActive,
      Role: { id: RoleId },
      Contact: {
        id: contactId,
        firstName,
        lastName,
        claimNumber,
        policyNumber,
        Addresses,
        Phones,
        Emails
      }
    } = party;

    const parse = (r) => {
      const a = { ...r };

      if (a.Type) a.TypeId = a.Type.id;

      delete a.Type;
      delete a.id;

      return a;
    };

    const assnObj: IApiAddInvestigationPartyInput = {
      InvestigationId: this.investigationId,
      RoleId,
      isActive,
      newContact
    };

    if (contactId) {
      assnObj.ContactId = contactId;
    }
    else {
      assnObj.Contact = {
        firstName,
        lastName,
        claimNumber,
        policyNumber,
        RoleId,
        Addresses: Addresses.map(parse).map(a => a as IApiAddAddressInput),
        Phones: Phones.map(parse),
        Emails: Emails.map(parse)
      };
    }

    return this.investigationPartyService.add(assnObj).pipe(
      // empty so the error can be shown to the user - i.e. if claim number exists
      this.notifications.snackbarErrorPipe(),
      this.notifications.snackbarPipe(contactId ? "New contact added!" : "Contact linked!"),
      switchMap(() => this.rehydrate())
    );
  }

  public removeParty(party: IApiInvestigationParty) {
    this.notifications.kendoConfirm("Really remove this contact assignment?").pipe(
      filter(r => !!r),
      switchMap((result) => forkJoin([
        party.id ? this.investigationPartyService.remove(party.id) : of(null),
        this.investigationService.update({
          id: this.investigation.id,
          History: {
            InvestigationId: this.investigation.id,
            updateCategory: IApiInvestigationUpdateCategories.Parties,
            updateType: IApiInvestigationUpdateTypes.Delete,
            comment: `${party.Contact.firstName} ${party.Contact.lastName} with role "${party.Role.name}" removed from investigation`
          }
        })
      ])),
      this.notifications.snackbarPipe("Assignment removed!"),
      switchMap(() => this.rehydrate())
    ).subscribe();
  }

  public updateSceneContact(contactId: string, confirm = true) {
    if (!contactId) {
      return;
    }
    (confirm ? this.notifications.kendoConfirm("Really change Scene Contact?") : of(true)).pipe(
      filter(r => !!r),
      switchMap(() => this.investigationService.update({
        id: this.investigationId,
        SceneContactId: contactId,
        History: {
          InvestigationId: this.investigation.id,
          updateCategory: IApiInvestigationUpdateCategories.Parties,
          updateType: IApiInvestigationUpdateTypes.Update,
          comment: `Scene contact updated on investigation`
        }
      })),
      this.notifications.snackbarPipe("Scene Contact updated!"),
      switchMap(() => this.rehydrate())
    ).subscribe(() => {
      const foundParty = this.parties.find(({ Contact: { id } }) => id === contactId);
      // If not in party, contact is client
      this._sceneContact = !!foundParty ? foundParty.Contact : this.investigation?.Client;
    });
  }
}
