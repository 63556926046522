import { Component, OnInit } from '@angular/core';
import { IApiSettingsFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { SettingsService } from 'src/app/shared/services';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-mobile-app',
  templateUrl: './download-mobile-app.component.html',
  styleUrls: ['./download-mobile-app.component.scss']
})
export class DownloadMobileAppComponent implements OnInit {

  public appSetting;
  public envS3 = environment.cloudFrontS3;
  constructor(
    private settingsService: SettingsService,
    private loaderService: LoaderService
  ) { }

  ngOnInit() {
    this.loaderService.show$(
      this.settingsService.get([
        {
          type: IApiSettingsFilterType.Keys,
          value: JSON.stringify(["APP_RELEASED_VERSION", "APP_RELEASED_BUILD", "APP_RELEASED_DATE"]),
        },
      ]).pipe(unwrapConnection())
    ).subscribe((settings) => {

        this.appSetting = settings?.reduce((acc, item) => {
          acc[item.key] = item.value;
          return acc;
        }, {});
        console.log(this.appSetting);
      });
  }

}
