import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { IInvestigationDetailSidebarData, InvestigationDetailsSidebarComponent } from '../investigation-details-sidebar/investigation-details-sidebar.component';
import { InvestigationDownloadPhotosModalComponent } from '../investigation-download-photos-modal/investigation-download-photos-modal.component';
import { InvestigationDownloadDocumentsModalComponent } from '../investigation-download-documents-modal/investigation-download-documents-modal.component';
import { InvestigationDownloadShareFilesModalComponent } from '../investigation-download-share-files-modal/investigation-download-share-files-modal.component';
import { InvestigationRequestModalComponent } from '../investigation-request-modal/investigation-request-modal.component';
import { MatDialog } from "@angular/material/dialog";
import { IApiInvestigation, IApiDocument, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUpdateInvestigationInput, IApiAddInvestigationHistoryInput, IApiAddRequestInput } from 'src/app/shared/modules/graphql/types/types';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { AuthService, InvestigationService, SubServiceService, RequestService } from "src/app/shared/services";
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { tap, filter, mergeMap, switchMap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import {
  investigationPhotos,
  investigationPhotosDownload,
  investigationPhotoShareCreate,
  investigationDocumentsListView,
  investigationDocumentsDownload,
  investigationDocumentShareCreate,
  investigationRequestsCreate,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { SelectEvent } from '@progress/kendo-angular-layout';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import _ from 'lodash';

export enum InvestigationManagementTabs {
  Details = "details",
  Services = "services",
  Evidence = "evidence",
  Photos = "photos",
  Documents = "documents",
  Expenses = "expenses"
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-management',
  templateUrl: './investigation-management.component.html',
  styleUrls: ['./investigation-management.component.scss']
})

export class InvestigationManagementComponent implements OnInit {

  public authConfig = {
    investigationPhotosDownload,
    investigationPhotos,
    investigationPhotoShareCreate,
    investigationDocumentsDownload,
    investigationDocumentsListView,
    investigationDocumentShareCreate,
    investigationRequestsCreate,
  };

  private _investigation: IApiInvestigation;

  public get investigation(): IApiInvestigation {
    return this._investigation;
  }

  public investigationManagementTabs = InvestigationManagementTabs;

  public userId = "";

  // Use to programatically change the current tab, initialize at the first tab
  public selectedTab = 0;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private auth: AuthService,
    private investigationService: InvestigationService,
    private loader: LoaderService,
    private router: Router,
    private subServiceService: SubServiceService,
    private notification: NotificationsService,
    private requestService: RequestService,
    private dialogService: DialogService,

  ) {
    this._investigation = this.route.snapshot.data.investigation;
    if (this._investigation?.firstViewDate === null && this._investigation?.InvestigationSource?.name === 'Online Intake Form') {
      this.markInvestigationViewed();
    }
    this.auth.authenticatedUser.pipe(
      untilDestroyed(this)
    ).subscribe(({ id }) => {
      this.userId = id;
    });
  }


  // Investigation Details Sidebar
  openReport() {
    const data: IInvestigationDetailSidebarData = {
      investigation: this.investigation,
      from: "INVESTIGATION_DETAILS"
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDetailsSidebarComponent,
      width: "50%",
      height: "100vh",
      cssClass: 'right-position history-log',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDetailsSidebarComponent;
    dialogInstance.data = data;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.reload();;
    })
  }

  // Investigation Request Modal
  openRequestModal() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationRequestModalComponent,
      width: "60%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationRequestModalComponent;
    dialogInstance.data = {  Investigation: { ...this.investigation } } as any;
    dialog.result.pipe(
        filter((v: IApiAddRequestInput) => {
          return _.isEmpty(v) ? false : !!v;
        }),mergeMap((newRequest) => {
          return this.addInvestigationHistory(IApiInvestigationUpdateCategories.Requests, IApiInvestigationUpdateTypes.Create).pipe(
            switchMap(() => this.requestService.add(newRequest))
          );
        }),
        this.notification.snackbarPipe("Request has been added"),
        this.notification.snackbarErrorPipe("Error adding request; please try again"),
      ).subscribe((res) => {
        if (res) {
          this.reload();
        }
      });
  }

  // Component to add investigation history entry
  private addInvestigationHistory(selectedCategory: IApiInvestigationUpdateCategories, selectedType: IApiInvestigationUpdateTypes) {

    const investigationUpdate: IApiUpdateInvestigationInput = {
      id: this._investigation.id
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationHistoryKendoModalComponent,
      width: "40%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this._investigation },
      selectedCategory,
      selectedType,
      noteOnly: true
    };
    return dialog.result.pipe(
      filter((v: DialogCloseResult) => {
        return _.isEmpty(v) ? false : !!v
      }),
      tap((v: IApiAddInvestigationHistoryInput) => (investigationUpdate as IApiUpdateInvestigationInput).History = v),
      switchMap(() => this.investigationService.update(investigationUpdate as IApiUpdateInvestigationInput))
    );
  }

  // Investigation Photo Downloads Modal
  openPhotoModal() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDownloadPhotosModalComponent,
      width: "600px",
      autoFocusedElement: "#downloadAll",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDownloadPhotosModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this.investigation }
    };
    dialog.result.subscribe();
  }

  // Investigation Document Downloads Modal
  openDocumentsModal() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDownloadDocumentsModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDownloadDocumentsModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this.investigation }
    };
    dialog.result.subscribe();

  }

  // Investigation Download Share Files Modal
  openShareFilesModal() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationDownloadShareFilesModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationDownloadShareFilesModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this.investigation }
    };
    document.body.classList.add("kendo-dialog-open");
    dialog.result.subscribe(result => {
      document.body.classList.remove("kendo-dialog-open");
    });
  }
  ngOnInit() {
    this.route.queryParams.pipe(
      untilDestroyed(this)
    ).subscribe(({ tab }) => {
      switch (tab) {
        case InvestigationManagementTabs.Services:
          this.selectedTab = 1;
          break;
        case InvestigationManagementTabs.Evidence:
          this.selectedTab = 2;
          break;
        case InvestigationManagementTabs.Photos:
          this.selectedTab = 3;
          break;
        case InvestigationManagementTabs.Documents:
          this.selectedTab = 4;
          break;
        case InvestigationManagementTabs.Expenses:
          this.selectedTab = 5;
          break;
        default:
          this.selectedTab = 0;
      }
    });
  }

  public reload(silent = false) {
    if (!silent) this.loader.show();

    this.investigationService.getById(this._investigation.id).pipe(
      this.loader.finalizeHidePipe()
    ).subscribe(data => this._investigation = data);
  }

  handleUploadsChanged(documents: IApiDocument[]) {
    // Needed?
  }

  public changeTab(e: SelectEvent) {
    // update the route with the current tab
    let tab = this.investigationManagementTabs.Details
    if (e?.index === 0) {
      tab = this.investigationManagementTabs.Details;
    } else if (e?.index === 1) {
      tab = this.investigationManagementTabs.Services
    } else if (e?.index === 2) {
      tab = this.investigationManagementTabs.Evidence
    } else if (e?.index === 3) {
      tab = this.investigationManagementTabs.Photos
    } else if (e?.index === 4) {
      tab = this.investigationManagementTabs.Documents
    } else if (e?.index === 5) {
      tab = this.investigationManagementTabs.Expenses
    } else {
      tab = this.investigationManagementTabs.Details
    }
    this.router.navigate([], { queryParams: { tab }, relativeTo: this.route });
  }

  markInvestigationViewed() {
    this.investigationService.markViewed(this._investigation.id).subscribe(() => {
      this.reload(true)
    });
  }
}
