import { IApiUserFilterType, IApiUserOrderBy } from './../../../../shared/modules/graphql/types/types';
import { UserDataSource } from './../../../../shared/services/user/user.datasource';
import { StateService, UserService } from 'src/app/shared/services';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { switchMap, filter, tap, take } from 'rxjs/operators';
import { IApiPermissionRole, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { Component, Input, OnInit } from '@angular/core';
import { PermissionRoleService } from 'src/app/shared/services';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { of, forkJoin } from "rxjs";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';

@Component({
  templateUrl: './permission-role-assign-modal.component.html',
  styleUrls: ['./permission-role-assign-modal.component.scss']
})
export class PermissionRoleAssignModalComponent extends DialogContentBase implements OnInit {
  @Input() public data: any;

  public isSubmitted = false;

  public selected: any = {};
  public get selectedUsers() {
    return Object.keys(this.selected).map(id => this.selected[id]).sort((a, b) => a.lastName > b.lastName ? 1 : 0);
  }

  private _role: IApiPermissionRole;
  public get role() {
    return this._role;
  }

  private _dataSource: UserDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public searchValue = "";

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public orderByOptions = IApiUserOrderBy;
  public sort: SortDescriptor[] = [
    {
      field: IApiUserOrderBy.Lastname,
      dir: "desc",
    },
  ];

  constructor(
    private permissionRoles: PermissionRoleService,
    public dialog: DialogRef,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private users: UserService,
    private stateService: StateService,
  ) {
    super(dialog);
    this._dataSource = new UserDataSource(this.users);
    this.loader.attachObservable(this._dataSource.loading$);
    this._dataSource.listPage.limit = 10;
  }

  ngOnInit(): void {
    this._role = this.data;

    // populate the data
    this.load();
  }

  public cancel(): void {
    of(this.selectedUsers).pipe(
      switchMap(users => users.length
        ? this.notifications.kendoConfirm("You have selected users; discard and close?", "Hang on a second!")
        : of(true)
      ),
      filter(result => result === true)
    ).subscribe(() => this.dialog.close(false));
  }

  public load(search?: string, positionId?: string) {
    // start by excluding all the users assigned
    const userIds = this.role.AssignedUsers.map(user => user.id);
    const filters: Array<any> = [
      {
        type: IApiUserFilterType.NotUserId,
        value: JSON.stringify(userIds)
      }
    ];

    if (search) filters.push({
      type: IApiUserFilterType.Search,
      value: search
    });
    else filters.filter((e) => e.type !== IApiUserFilterType.Search);

    this.dataSource.pagingReset();
    filters.push({
      type: IApiUserFilterType.ViewAssignmentStaffRole,
      value: 'true'
    });
    if (filters && filters?.length > 0) {
      filters.map((obj) => {
        this.dataSource.applyFilter(obj.type, obj.value);
      });
    }
    this.dataSource.load(filters);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiUserOrderBy.Lastname;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode);
  }

  public toggleUser(user: IApiUser) {
    if (this.selected[user.id]) {
      this.removeUser(user);
      return;
    }

    this.selected[user.id] = user;
  }

  public removeUser({ id }: IApiUser) {
    delete this.selected[id];
  }

  public assignUsers() {
    this.loader.show$(
      forkJoin(
        this.selectedUsers.map(user => {
          const { id: userId } = user;
          return this.permissionRoles.assignUser({
            userId,
            permissionRoleId: this.role.id
          }).pipe(
            // remove successfully added users as they succeed leaving only potential failures
            tap(() => this.removeUser(user)),
            take(1)
          );
        }),
      )
    ).pipe(
      this.notifications.snackbarPipe("Successfully added Users to Permission Role!"),
      this.notifications.snackbarErrorPipe("Error adding User(s); please try again.")
    ).subscribe(() => {
      this.dialog.close(true)
    });
  }
}
