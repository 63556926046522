import { Injectable } from "@angular/core";
import { Apollo } from "apollo-angular";
import { Observable } from "rxjs";
import { safeResolve } from 'src/app/shared/modules/graphql/rxjs';
import { IApiDocumentSentFilter, IApiQueryReportSentExportConnectionArgs, IApiReportSentConnection } from "../../../types/types";
import { IGqlBaseOptions } from "../../../interfaces";
import { connectionQuery } from "../../../helpers.class";
import { reportSentMetricReportConnectionQuery, reportSentMetricReportExportQuery } from "./report-sent-metric.operations";

@Injectable()
export class GqlReportSentMetricService {
  public constructor(
    private apollo: Apollo
  ) { }

  public get(filters: IApiDocumentSentFilter[], options: IGqlBaseOptions): Observable<IApiReportSentConnection> {
    return this.apollo.query(connectionQuery(reportSentMetricReportConnectionQuery, filters, options)).pipe(
      safeResolve(true)
    );
  }

  public getCsvReport(input?: IApiQueryReportSentExportConnectionArgs): Observable<string> {
    return this.apollo.query({
      query: reportSentMetricReportExportQuery,
      variables: { ...input }
    }).pipe(
      safeResolve(true)
    );
  }

}
