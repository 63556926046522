import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

@Directive({
  selector: "[appNoWhiteSpace]",
  providers: [{ provide: NG_VALIDATORS, useExisting: WhitespaceValidator, multi: true }]
})
export class WhitespaceValidator implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (control?.value && (control?.value as string)?.trim()?.length === 0) {
        return { isSpace: true, required: true };
    }
  }
}
