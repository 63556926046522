import { filter, switchMap } from 'rxjs/operators';
import { IApiShippingCarrier } from './../../../../shared/modules/graphql/types/types';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { ShippingCarrierService } from './../../../../shared/services/shipping-carrier/shipping-carrier.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { CarriersManageModalComponent } from '../carriers-manage-modal/carriers-manage-modal.component';
import { ShippingCarrierDataSource } from "src/app/shared/services/shipping-carrier/shipping-carrier.datasource";
import { manageCarriersCreate, manageCarriersUpdate, manageCarriersDelete } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';


@Component({
  selector: 'app-carriers-list',
  templateUrl: './carriers-list.component.html',
  styleUrls: ['./carriers-list.component.scss']
})
export class CarriersListComponent implements OnInit {

  public authConfig = {
    manageCarriersCreate,
    manageCarriersUpdate,
    manageCarriersDelete
  }

  public carrierList: IApiShippingCarrier[];

  private _dataSource: ShippingCarrierDataSource;
  public get dataSource(): ShippingCarrierDataSource {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }
  public columnsToDisplay = ['NAME', 'action'];
  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private dialog: MatDialog,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private carriers: ShippingCarrierService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
    this._dataSource = new ShippingCarrierDataSource(this.carriers);
    this.loader.attachObservable(this._dataSource.loading$);
    this.loader.isMatLoader = false;
    this.dataSource?.contents$.subscribe((res) => {
      this.carrierList = res;
    });
    this.load();
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public delete(id: string) {
    this.notifications.kendoConfirm("Really delete this Shipping Carrier?").pipe(
      filter(v => !!v),
      switchMap(() => this.carriers.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting Shipping Carrier")
    ).subscribe(() => this.load());
  }

  public edit(data?: IApiShippingCarrier) {
    const dialog: DialogRef = this.dialogService.open({
      content: CarriersManageModalComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as CarriersManageModalComponent;
    dialogInstance.data = data;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    })
  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Edit':
        this.edit(dataItem);
        break;
      case 'Delete':
        this.delete(dataItem?.id)
        break;
      default:
        break;
    }
  }
}
