<p class="kendo-caption-body text-overflow mb-0" *ngIf="email; else phoneTemplate">
  <a class="linkmd" href="mailto:{{email?.address}}" (click)="copyText(email?.address)"> {{email?.address}}</a>&nbsp;
  <span *ngIf="email?.Type">({{ email?.Type?.name }})</span>
</p>
<ng-template #phoneTemplate>
  <p class="kendo-caption-body text-overflow mb-0" *ngIf="phone?.number else noValue">
    <a (click)="onClickPhone()" class="linkmd" href="tel:{{phone?.number}}">{{ phone?.number | phoneFormat : "US" }}</a> {{ phone?.extension }}
    <span *ngIf="phone?.Type">({{ phone?.Type?.name }})</span>&nbsp;<span *ngIf="phone?.isPrimary">(Primary)</span>
    <br />
    <span *ngIf="phone?.type" class="type-description">{{phone?.type}}</span>
  </p>
</ng-template>
<ng-template #noValue>
  <p>-</p>
</ng-template>
