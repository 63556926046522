import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { distinctAgainst } from "src/app/shared/rxjs.pipes";
import { AuthService } from "src/app/shared/services";
import {
  manageExpenseReportCategoriesList,
  manageExpenseItemsList,
  manageExpensePurposesList,
  manageTimeEntryTypesList,
  manage,
  manageDocumentTypes,
  manageCarriersList,
  manageCertificationsList,
  manageEvidenceLocationsList,
  manageExpenseItems,
  manageServices,
  manageSubServices,
  manageUserRoles,
  manageUserRolesList,
  manageDocumentTemplatingView
} from "../../../../shared/helpers/auth-config/manage.config";

import {
  contacts
} from "../../../../shared/helpers/auth-config/contacts.config";

import {
  staff
} from "../../../../shared/helpers/auth-config/staff.config";

import {
  expenseInvestigationExpensesAll,
  expenseInvestigationHours,
  expenseInvestigationMileage,
  timeEntryInvestigationHours
} from "../../../../shared/helpers/auth-config/time-expenses.config";
import {
  companies
} from "../../../../shared/helpers/auth-config/companies.config";
import { evidence } from 'src/app/shared/helpers/auth-config/evidence.config';
import { PermissionActions, } from 'src/app/shared/modules/graphql/constants/permission.constants';
import {
  companyInvestigationAndEvidenceReport,
  companyInvestigationReport,
  investigationTimelogPacing,
  investigationReportTimelog,
  quarterlyUtilizationReport,
  quarterlyHourReport,
  reportSentMetric,
  investigationMetricBasicCharts,
  investigationMetricInvestigator,
  investigationMetricState,
  investigationMetricBillToCompany,
  investigationMetricClientCompany,
  investigationMetricTechReviewer,
  investigationMetricContact,
  investigationMetricRisktype,
  investigationMetricDetermination
} from "src/app/shared/helpers/auth-config/reports.config";
import {
  bookKeepingEvidenceBilling,
  bookKeepingExpenseReports,
  bookKeepingInvoiceList,
  bookKeepingPayroll,
  bookKeepingTimeExpensesEntryListExpensesMileage,
  bookKeepingTimeExpensesEntryListHours,
  bookKeepingTimeExpensesExpensesMileageAccountSummary,
  bookKeepingTimeExpensesHoursAccountSummary,
} from "src/app/shared/helpers/auth-config/bookkeeping.config";

import { investigation } from "src/app/shared/helpers/auth-config/investigations.config";

@UntilDestroy()
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  public authConfig = {
    investigation,
    manageExpenseReportCategoriesList,
    manageExpenseItemsList,
    manageExpensePurposesList,
    manageTimeEntryTypesList,
    manage,
    manageDocumentTypes,
    manageCarriersList,
    manageCertificationsList,
    manageEvidenceLocationsList,
    manageExpenseItems,
    manageServices,
    manageSubServices,
    manageUserRoles,
    manageUserRolesList,
    manageDocumentTemplatingView,
    contacts,
    staff,
    expenseInvestigationExpensesAll,
    expenseInvestigationHours,
    expenseInvestigationMileage,
    timeEntryInvestigationHours,
    companies,
    evidence,
    companyInvestigationAndEvidenceReport,
    companyInvestigationReport,
    investigationTimelogPacing,
    investigationReportTimelog,
    quarterlyUtilizationReport,
    quarterlyHourReport,
    reportSentMetric,
    investigationMetricBasicCharts,
    investigationMetricInvestigator,
    investigationMetricState,
    investigationMetricBillToCompany,
    investigationMetricClientCompany,
    investigationMetricTechReviewer,
    investigationMetricContact,
    investigationMetricRisktype,
    investigationMetricDetermination,
    bookKeepingPayroll,
    bookKeepingInvoiceList,
    bookKeepingExpenseReports,
    bookKeepingTimeExpensesEntryListHours,
    bookKeepingTimeExpensesEntryListExpensesMileage,
    bookKeepingTimeExpensesHoursAccountSummary,
    bookKeepingTimeExpensesExpensesMileageAccountSummary,
    bookKeepingEvidenceBilling
  };

  public portal = false;

  private _user = null;
  public get user() {
    return this._user;
  }

  constructor(
    public auth: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.auth.authenticatedUser.pipe(
      untilDestroyed(this),
      distinctAgainst(() => this.user),
    ).subscribe((user) => this._user = user);
    
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.portal = event.url.toLowerCase().includes("portal");
      }
    });

  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;

    // Close menus if the click is outside the navigation component
    if (!targetElement.closest('.menu')) {
      this.isMenuOpen = {};
    }
    
    // Close menus if the click is inside the navigation component
    if (targetElement.closest('.menu-content')) {
      this.isMenuOpen = {};
    }
  }

  isMenuOpen: { [key: string]: boolean } = {};
  toggleMenu(menu: string): void {
    this.isMenuOpen = {};
    this.isMenuOpen[menu] = !this.isMenuOpen[menu];
  }
}
