import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { DrawerComponent } from '@progress/kendo-angular-layout';
import _ from 'lodash';
import { companiesViewAll } from 'src/app/shared/helpers/auth-config/companies.config';
import { contactsProfileContactInformationViewAssigned, contactsProfileCompanyInformationView, contactsInvestigations } from 'src/app/shared/helpers/auth-config/contacts.config';
import { dashboardInvestigatorsOnSceneUpdate } from 'src/app/shared/helpers/auth-config/dashboard.config';
import { investigationAssignStaffCreate, investigationAssignStaffListView, investigationAssignStaffUpdate, investigationBillingInformationUpdate, investigationBillingInformationView, investigationInformationListView, investigationInformationUpdate, investigationLinkedInvestigationUpdate, investigationLinkedInvestigationView, investigationPartiesListView, investigationPartiesUpdate, investigationReceivedDateUpdate, investigationReject, investigationRequestsCreate, investigationScheduledDateUpdateAll, investigationScheduledDateUpdateOwn, investigationScheduledDateView, investigationVehiclesCreate, investigationVehiclesDelete, investigationVehiclesListView, investigationVehiclesUpdate } from 'src/app/shared/helpers/auth-config/investigations.config';
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';
import { IApiAddress, IApiInvestigation, IApiInvestigationRoleNames, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, InsuredPartyService, InvestigationService } from 'src/app/shared/services';
import { MobileChangeOnSceneStatusComponent } from '../mobile-change-on-scene-status/mobile-change-on-scene-status.component';
import { filter } from 'rxjs/operators';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import dayjs from 'dayjs';

@Component({
  selector: 'app-mobile-investigation-detail',
  templateUrl: './mobile-investigation-detail.component.html',
  styleUrls: ['./mobile-investigation-detail.component.scss']
})
export class MobileInvestigationDetailComponent implements AfterViewInit, OnDestroy {

  @ViewChild('drawer') drawer: DrawerComponent;
  @ViewChild('investigationScheduledDateView') investigationScheduledDateViewPerm;
  @ViewChild('investigationRequestsCreate') investigationRequestsCreatePerm;
  @ViewChild('dashboardInvestigatorsOnSceneUpdate') dashboardInvestigatorsOnSceneUpdatePerm;


  public authConfig = {
    investigationInformationListView,
    investigationInformationUpdate,
    investigationReject,
    investigationReceivedDateUpdate,
    investigationScheduledDateUpdateAll,
    investigationScheduledDateUpdateOwn,
    investigationScheduledDateView,
    investigationPartiesListView,
    investigationPartiesUpdate,
    investigationBillingInformationView,
    investigationBillingInformationUpdate,
    investigationLinkedInvestigationView,
    investigationLinkedInvestigationUpdate,
    investigationVehiclesListView,
    investigationVehiclesCreate,
    investigationVehiclesUpdate,
    investigationVehiclesDelete,
    investigationAssignStaffListView,
    investigationAssignStaffCreate,
    investigationAssignStaffUpdate,
    staffViewAll,
    contactsProfileContactInformationViewAssigned,
    contactsProfileCompanyInformationView,
    contactsInvestigations,
    companiesViewAll,
    investigationRequestsCreate,
    dashboardInvestigatorsOnSceneUpdate,
  };
  private _investigation: IApiInvestigation;
  public investigationOnSceneStatus = InvestigationOnSceneStatus;

  public get investigation(): IApiInvestigation {
    return this._investigation;
  }

  public actionItems = [];
  public currentUser: IApiUser;

  constructor(
    private investigationService: InvestigationService,
    private route: ActivatedRoute,
    private loader: LoaderService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private router: Router,
    private auth: AuthService,
    private insuredPartyService: InsuredPartyService,
    private dialogService: DialogService
  ) {
    this._investigation = this.route.snapshot.data.investigation;
    if (this._investigation?.firstViewDate === null && this._investigation?.InvestigationSource?.name === 'Online Intake Form') {
      this.markInvestigationViewed();
    }
  }

  ngAfterViewInit() {
    this.auth.authenticatedUser.subscribe((u) => {
      this.currentUser = u;
    });

    this.drawer.toggle(true);
    this.addBodyClass(true);
    this.cdr.detectChanges();
    this.buildActionItems();
  }

  buildActionItems() {
    this.actionItems = [];
    this.actionItems.push({ text: "Enter Time and Expense" });
    this.actionItems.push({ text: "View Investigation Log" });
    if (this.investigationRequestsCreatePerm?.enabled && _.findIndex(this.actionItems, { text: "Data Request" }) === -1) {
      this.actionItems.push({ text: "Data Request" });
    }

    if (this.investigation?.InvestigationStaff?.find(staff => staff?.Role.title === IApiInvestigationRoleNames.Investigator) &&
      this.investigationScheduledDateViewPerm?.enabled &&
      _.findIndex(this.actionItems, { text: "Schedule On Scene Exam Date" }) === -1) {
      this.actionItems.push({ text: "Schedule On Scene Exam Date" })
    }

    if (this.dashboardInvestigatorsOnSceneUpdatePerm?.enabled && _.findIndex(this.actionItems, { text: "Update Scene Status" }) === -1) {
      this.actionItems.push({ text: "Update Scene Status" })
    }
  }

  public close(): void {
    this.drawer.toggle(false);
    this.addBodyClass(false);
    this.location.back();

  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }


  public reload(silent = false) {
    if (!silent) this.loader.show();

    this.investigationService.getInvestigationByIdMobile(this._investigation.id, true).pipe(
      this.loader.finalizeHidePipe()
    ).subscribe(data => {
      this._investigation = data;
      this.buildActionItems();
    });
  }

  markInvestigationViewed() {
    this.investigationService.markViewed(this._investigation.id).subscribe(() => {
      this.reload(true)
    });
  }

  public openMap(address: IApiAddress) {
    const { address1, city, state, postal } = address;
    const googleMapUrl = encodeURI(`https://www.google.com/maps/search/?api=1&query=${address1} ${city} ${state} ${postal}`);
    window.open(googleMapUrl, "_blank");
  }

  public actionSelected(action) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: {
          ...this.investigation
        }
      }
    };
    switch (action) {
      case "Data Request":
        this.router.navigate(['data-request'], navigationExtras)
        break;
      case "Enter Time and Expense":
        if (this.investigation?.InvestigationStaff?.find(staff => staff?.User?.id === this.currentUser.id)) {
          this.router.navigate(['time-and-expense'], navigationExtras)
        } else {
          this.router.navigate(['admin/feature-unavailable'], navigationExtras);
        }
        break;
      case "View Investigation Log":
        this.router.navigate(['investigations-log', this.investigation.id], navigationExtras)
        break;
      case "Schedule On Scene Exam Date":
        if (this.investigation?.InvestigationStaff?.find(staff => staff?.User?.id === this.currentUser.id && staff?.Role?.title === IApiInvestigationRoleNames.Investigator)) {
          this.router.navigate(['schedule'], navigationExtras);
        } else {
          this.router.navigate(['admin/feature-unavailable'], navigationExtras);
        }
        break;
      case "Update Scene Status":
        const dialog: DialogRef = this.dialogService.open({
          content: MobileChangeOnSceneStatusComponent,
          width: "100%",
          cssClass: "change-status-dialog",
          maxWidth: "100%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as MobileChangeOnSceneStatusComponent;
        dialogInstance.onScene = this.investigation?.NearOnScene;
        dialogInstance.investigation = this.investigation;
        dialog.result.pipe(
          filter((v) => !!v)
        ).subscribe((result: DialogCloseResult) => {
          if (result === true) {
            this.reload(false);
          }
        });
        break;
      default:
        break;
    }
  }

  public saveInsuredContactInfo() {
    if (this.investigation?.id && this.currentUser?.id) {
      const date = dayjs().tz(this.investigation.timezone, true).toISOString();
      const input = {
        InvestigationId: this.investigation.id,
        UserId: this.currentUser.id,
        date: date,
      };
      this.insuredPartyService.addInsuredContact(input).subscribe();
    }
  }

  public clientCallHistorySave(investigation: IApiInvestigation) {
    this.investigationService.update({
      id: investigation.id,
      History: {
        updateType: IApiInvestigationUpdateTypes.Update,
        updateCategory: IApiInvestigationUpdateCategories.Detail,
        comment: `Contacted client ${investigation.Client?.firstName} ${investigation.Client?.lastName}`,
        InvestigationId: investigation.id
      },
    }).subscribe();
  }

  public ngOnDestroy(): void {
    this.addBodyClass(false);
  }

}
