<div [appEnforcePermission]="authConfig.investigationServicesCreate"
  #investigationServicesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesCreateOwn"
  #investigationServicesCreateOwn="appEnforcePermission"></div>
<div [dialogRef]="dialog" (appClickOutside)="onCancelClick()">
  <!-- Modal Header -->
  <kendo-dialog-titlebar (close)="onCancelClick()">
    <div class="modal-title">
      <span>Service</span>
      <div class="modal-subtitle">
        Investigation #{{ data.Investigation?.nefcoNumber }}
      </div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <form #addServiceForm="ngForm">

      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Type" class="asterisk"></kendo-label>
            <kendo-dropdownlist [data]="serviceTypes" name="type" #type="ngModel" [(ngModel)]="service.TypeId"
              [valuePrimitive]="true" fillMode="outline" textField="name" valueField="id"
              [defaultItem]="{id: null, name: 'Select service type'}"
              [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.id ? 'not-placeholder': 'placeholder'"> {{ dataItem?.name }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!type?.valid && (type?.dirty || type?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="type?.errors?.required">
                Service type can be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Assigned to" class="asterisk"></kendo-label>
            <kendo-dropdownlist [data]="getStaff" name="assignedTo" #assignedTo="ngModel"
              [(ngModel)]="service.AssignedUserIds" [valuePrimitive]="true" fillMode="outline" textField="userName"
              valueField="userId" [defaultItem]="{userId: null, userName: 'Select assigned to'}"
              [popupSettings]="{appendTo: 'component', animate: false}" required>
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.userId ? 'not-placeholder': 'placeholder'"> {{ dataItem?.userName }} </span>
              </ng-template>
            </kendo-dropdownlist>
            <kendo-formerror *ngIf="!assignedTo?.valid && (assignedTo?.dirty || assignedTo?.touched)">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="assignedTo?.errors?.required">
                Assigned to can be left blank
              </span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Authorized by"></kendo-label>
            <kendo-textbox name="name" #name="ngModel" [(ngModel)]="service.authorizedBy" placeholder="Authorized by"
              fillMode="outline" appNoWhiteSpace>
            </kendo-textbox>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item [colSpan]="3">
          <kendo-formfield>
            <kendo-label class="asterisk" text="Notes / Instructions"></kendo-label>
            <kendo-textarea name="notes" #notes="ngModel" [(ngModel)]="service.notes" placeholder="Enter Comment"
              fillMode="outline" class="min-height-96" resizable="both" [required]="true"
              appNoWhiteSpace></kendo-textarea>
            <kendo-formerror *ngIf="notes?.errors">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span *ngIf="notes?.errors.required">Nots cannot be left blank</span>
            </kendo-formerror>
          </kendo-formfield>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </form>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="onCancelClick()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16" (click)="onSubmit()"
    [disabled]="!addServiceForm.valid">Add
    Service
    </button>
  </kendo-dialog-actions>
</div>
