<section>
  <div class="page-header">
    <h4 class="mb-0 page-title">Download Mobile App</h4>
  </div>
</section>
<kendo-card width="100%">
  <kendo-card-body>
    <kendo-gridlayout class="box-layout" [rows]="['auto']" [gap]="{rows: 5, cols: 24}"
      [cols]="['repeat(2,minmax(0,1fr))']">
      <kendo-gridlayout-item class="box-layout-item">
        <div class="header">
          <i class="fa-brands fa-android"></i>
          <h5>Android Devices</h5>
          <img height="148" width="148" [src]="envS3 + '/ANDROID/android-qr.png'" />
        </div>
        <div class="main-content">
          <h6>QR code installation guide</h6>
          <ol>
            <li>
              Scan the QR Code: <span>Use your phone's camera app or a QR code scanner app to scan the provided QR
                code. A link to download the APK should appear; tap on it to start the download.</span>
            </li>
            <li>
              Enable Unknown Sources (First Time Only): <span>Go to Settings > Security (or Settings > Apps on some
                devices). Find Install unknown apps or Unknown sources and enable the browser or QR scanner you
                used.</span>
            </li>
            <li>
              Download and Install the APK: <span>After enabling unknown sources, tap on the APK link generated from the
                QR
                code. Once downloaded, tap Open in the notification, or find the file in Downloads and tap it to begin
                installation. Review permissions (if prompted) and tap Install.</span>
            </li>
            <li>
              Launch the App:<span> Tap Open to start using the app, or find it in your app drawer.</span>
            </li>
          </ol>
        </div>
        <div class="footer" *ngIf="appSetting">
          <p>Latest Version <b> {{appSetting?.APP_RELEASED_VERSION + '.' + appSetting?.APP_RELEASED_BUILD}} </b></p>
          <p class="mb-0">Latest Release Date <b> {{appSetting?.APP_RELEASED_DATE}} </b></p>
        </div>
      </kendo-gridlayout-item>
      <kendo-gridlayout-item class="box-layout-item flex-div">
        <section>
          <div class="header">
            <i class="fa-brands fa-apple"></i>
            <h5>Apple Devices</h5>
            <img height="148" width="148" [src]="envS3 + '/IOS/ios-qr.png'" />
          </div>
          <div class="main-content">
            <h6>QR code installation guide</h6>
            <p>
              Scan the QR Code: <span>Use your phone's camera app or a QR code scanner app to scan the provided QR
                code.</span>
            </p>
          </div>
        </section>
        <div class="footer" *ngIf="appSetting">
          <p>Latest Version <b> {{appSetting?.APP_RELEASED_VERSION + '.' + appSetting?.APP_RELEASED_BUILD}} </b></p>
          <p class="mb-0">Latest Release Date <b> {{appSetting?.APP_RELEASED_DATE}} </b></p>
        </div>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </kendo-card-body>
</kendo-card>
