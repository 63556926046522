import { forkJoin, Subject } from 'rxjs';
import { IApiAddEvidenceBillingInput, IApiEvidenceBillingFrequency, IApiEvidenceFilterType, IApiEvidenceStatusType, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiDocumentTypeFilterType, IApiAddInvestigationHistoryInput } from './../../../../shared/modules/graphql/types/types';
import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { InvestigationEvidenceSidebarComponent } from "../investigation-evidence-sidebar/investigation-evidence-sidebar.component";
import { EvidenceBillingFrequencyService, EvidenceBillingService, EvidenceDataSource, EvidenceService, EvidenceStorageLocationService } from 'src/app/shared/services/evidence';
import {
  IApiInvestigation,
  IApiEvidenceStorageLocationFilter,
  IApiEvidenceStorageLocationFilterType,
  IApiAddEvidenceTransferInput,
  IApiEvidenceStorageLocation
} from 'src/app/shared/modules/graphql/types/types';
import { EvidenceTransferService } from 'src/app/shared/services/evidence/evidence-transfer/evidence-transfer.service';
import { IEvidenceTransferModalData, InvestigationEvidenceTransferModalComponent } from '../investigation-evidence-transfer-modal/investigation-evidence-transfer-modal.component';
import { IApiEvidence } from '../../../../shared/modules/graphql/types/types';
import { debounceTime, delay, filter, map, switchMap, tap, timeout, switchAll, toArray } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _, { cloneDeep } from 'lodash';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { AuthService, DocumentTypeService, InvestigationService, InvestigationTemplatDownloadService } from 'src/app/shared/services';
import { DownloadHelper } from 'src/app/shared/services/downloader-helper.service';
import * as moment from "moment";
import { InvestigationEvidencePhotosModalComponent } from '../investigation-evidence-photos-modal/investigation-evidence-photos-modal.component';

import {
  evidence,
  evidenceDownload,
  evidenceView,
  evidenceCreate,
  evidenceUpdate,
  evidenceUpdateOwn,
  evidenceDelete,
  evidenceTransferCreate,
  evidenceTransferUpdate,
  evidenceBilling,
  evidenceBillingUpdate,
} from "src/app/shared/helpers/auth-config/evidence.config";
import { NgForm } from '@angular/forms';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import { environment } from 'src/environments/environment';

dayjs.extend(utc);

export interface IUiEvidenceItem extends IApiEvidence {
  isSelected?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-evidence',
  templateUrl: './investigation-evidence.component.html',
  styleUrls: ['./investigation-evidence.component.scss']
})
export class InvestigationEvidenceComponent implements OnInit, AfterViewInit {

  public get evidenceStatusType(): typeof IApiEvidenceStatusType {
    return IApiEvidenceStatusType;
  }

  public palletChange = new Subject<any>();
  public get ueNumber() {
    return this.investigation?.Evidence?.[0]?.ueNumber;
  }

  @ViewChild("evidenceBillingForm") evidenceBillingForm: NgForm;
  @ViewChild('evidenceDownload') evidenceDownloadPerm;
  @ViewChild('evidenceUpdate') evidenceUpdatePerm;
  @ViewChild('evidenceUpdateOwn') evidenceUpdateOwnPerm;
  @ViewChild('evidenceTransferCreate') evidenceTransferCreatePerm;
  @ViewChild('evidenceDelete') evidenceDeletePerm;

  @Output() reload = new EventEmitter<boolean>();

  public authConfig = {
    evidence,
    evidenceDownload,
    evidenceView,
    evidenceCreate,
    evidenceUpdate,
    evidenceUpdateOwn,
    evidenceDelete,
    evidenceTransferCreate,
    evidenceTransferUpdate,
    evidenceBilling,
    evidenceBillingUpdate,
  };

  @Input() userId: string;
  private _investigation: IApiInvestigation = null;
  public get investigation() {
    return this._investigation;
  }
  @Input() public set investigation(val: IApiInvestigation) {
    if (!val.EvidenceBilling) {
      val.EvidenceBilling = {
        id: null,
        palletCount: "0",
        override: null,
        rate: null,
        billable: null,
        BillingFrequency: {
          id: null,
          name: null,
        }
      };
    }
    this._investigation = val;
  }

  public statusTypes = Object.values(IApiEvidenceStatusType);

  private transmittalFormDocId: string = null;
  private evidenceDisposalFormId: string = null;


  /** EvidenceBilling */
  public billingOptions: IApiEvidenceBillingFrequency[];

  /** EvidenceStorageLocations */
  public evidenceStorageLocations: IApiEvidenceStorageLocation[];

  public evidenceItems: IUiEvidenceItem[] = [];
  public evidenceComplete = false;

  public get selectedEvidenceItems() {
    return this.evidenceItems.filter(({ isSelected }) => isSelected);
  }

  public checkedIds = [];
  public hasNewItem = false;

  public evidenceBillingEditMode = false;

  private _evidenceStorageLocationsFilter: IApiEvidenceStorageLocationFilter[] = [];

  private dataSource: EvidenceDataSource;

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public downloadActions = [
    "Download Item List",
    "Download Evidence History",
    "Download Hi-res Photos",
    "Download Low-res Photos",
    "Print Photo Sheet"
  ]

  public disableCheckMark = false;
  public authenticatedUserId = null;
  public evidenceActions = [];
  public isOpenMoreActions = false;
  constructor(
    private notifications: NotificationsService,
    private evidenceService: EvidenceService,
    private billingFrequencyService: EvidenceBillingFrequencyService,
    private evidenceStorageLocationService: EvidenceStorageLocationService,
    private evidenceTransferService: EvidenceTransferService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private evidenceBillingService: EvidenceBillingService,
    private investigationTemplateDownload: InvestigationTemplatDownloadService,
    private downloadHelper: DownloadHelper,
    private investigationService: InvestigationService,
    private documentTypeService: DocumentTypeService,
    private templateService: InvestigationTemplatDownloadService,
    private authService: AuthService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef
  ) {
    this.dataSource = new EvidenceDataSource(this.evidenceService);
    this.loader.attachObservable(this.dataSource.loading$);
  }

  ngOnInit() {
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this.billingFrequencyService.get([], { sortOrder: SortOrder.ASC }).pipe(
      unwrapConnection()
    ).subscribe((opts) => this.billingOptions = opts);

    this.evidenceStorageLocationService.get([
      {
        type: IApiEvidenceStorageLocationFilterType.ViewListView,
        value: "true"
      }
    ]).pipe(
      unwrapConnection()
    ).subscribe((opts) => this.evidenceStorageLocations = opts);

    this.pageOptions.limit = -1;
    // this.evidenceBilling = { ...this.investigation.EvidenceBilling };
    this.dataSource.applyFilter(IApiEvidenceFilterType.Investigation, this.investigation.id);

    this._evidenceStorageLocationsFilter.push({ type: IApiEvidenceStorageLocationFilterType.IsActive, value: 'true' });
    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((content) => {
      // const newItems = this.evidenceItems.filter(({ id }) => !id);
      // For some reason without clonedeep data-binding for evidencestoragelocation object will sync all of them - change one dropdown and all reflect the same value
      this.evidenceItems = [
        ...content.map((v) => ({ ...v, EvidenceStorageLocation: cloneDeep(v.EvidenceStorageLocation) })),
        // don't blow away new items when reloading after a save!
        // ...newItems - only need to do this if we are going to save multiple items simultaneously - otherwise we end up with duplicates on the page (new item and the item that was just created)
      ];
    });

    // SWB 5/22 - performance; just get the 2 types needed instead of all
    forkJoin([
      this.documentTypeService.get([
        {
          type: IApiDocumentTypeFilterType.Name,
          value: "Evidence Transmittal Form"
        }
      ]),
      this.documentTypeService.get([
        {
          type: IApiDocumentTypeFilterType.Name,
          value: "Evidence Disposition Form"
        }
      ])
    ]).pipe(
      switchAll(),
      unwrapConnection(),
      map(([result]) => result),
      toArray()
    ).subscribe(([transmit, disposition]) => {
      this.transmittalFormDocId = transmit?.id ? transmit.id : null;
      this.evidenceDisposalFormId = disposition?.id ? disposition.id : null;
    });


    this.loadEvidenceItems();

    // Debounce pallet change, appDebouncedInputChange too fast
    this.palletChange.pipe(
      untilDestroyed(this),
      debounceTime(500),
      tap(() => this.saveEvidenceBilling(true))
    ).subscribe();
  }
  ngAfterViewInit(): void {
    const actions = [
      { text: "Transfer", condition: this.evidenceTransferCreatePerm?.enabled },
      { text: "Download Disposal Letter", condition: this.evidenceDownloadPerm?.enabled },
      { text: "Delete", condition: this.evidenceDeletePerm?.enabled },
      { text: "Move to Disposal Requested", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Move to Awaiting Disposal", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Move to Disposed", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
    ];
    actions.forEach(action => {
      if (action.condition && !this.evidenceActions.some(e => e.text === action.text)) {
        this.evidenceActions.push({ text: action.text });
      }
    });
    if (this._investigation?.EvidenceBilling?.startDate) {
      this._investigation.EvidenceBilling.startDate = new Date(this._investigation?.EvidenceBilling?.startDate);
    }

    if (this._investigation?.EvidenceBilling?.currentExpensed) {
      this._investigation.EvidenceBilling.currentExpensed = new Date(this._investigation?.EvidenceBilling?.currentExpensed);
    }
    this.cdr.detectChanges();
  }

  public openSidebar(evidenceItem: IApiEvidence) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceSidebarComponent,
      width: "55%",
      height: "100vh",
      cssClass: 'right-position',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceSidebarComponent;
    dialogInstance.evidence = evidenceItem;
    dialog.result.subscribe((result: DialogCloseResult) => { });
  }

  public openTransferModal() {
    if (!this.selectedEvidenceItems.every(({ EvidenceStorageLocation }) => EvidenceStorageLocation.id === this.selectedEvidenceItems[0].EvidenceStorageLocation.id)) {
      return this.notifications.alert("All selected evidence items must be at the same location, please try again");
    }
    const data: IEvidenceTransferModalData = {
      evidence: this.selectedEvidenceItems,

    };
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceTransferModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceTransferModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result) {
        this.loadEvidenceItems();
        this.reload.emit(true);
      }
    });
  }

  public pleaseSelect() {
    this.notifications.alert('No evidence is selected. Please select at least one evidence item.');
  }

  public transferEvidence(transferData: IApiAddEvidenceTransferInput) {
    this.evidenceTransferService.add(transferData);
  }

  public downloadDisposalLetter() {
    this.loader.show$(
      this.templateService.generateTemplateDownload({
        InvestigationId: this.investigation.id,
        DocumentTypeIds: [this.evidenceDisposalFormId],
        UploadUris: [],
      })
    ).subscribe(downloadUrl => {
      if (downloadUrl) {
        this.downloadHelper.openURL(downloadUrl);
      }
    });
  }

  public viewPhotos() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidencePhotosModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidencePhotosModalComponent;
    dialogInstance.data = {
      investigation: this.investigation
    };
    return dialog.result.subscribe();
  }

  public downloadPhotos(lowRes = false) {
    this.loader.show$(
      this.investigationTemplateDownload.generatePhotoDownload({ lowRes, InvestigationId: this.investigation?.id, UserId: this.authenticatedUserId, uniqueId: `${Date.now()}_${this.authenticatedUserId}`, env: environment.env })
        .pipe(this.notifications.snackbarErrorPipe())
    ).subscribe(() => {
      this.notifications.kendoConfirm("Your photo download request has been successfully queued. You will receive a confirmation email when it is ready.", "Photo Download Request", "", "Ok").subscribe(() => {
      });
    });
  }

  public deleteItems(selectedItems) {
    const message = selectedItems.length > 1 ? 's' : '';
    this.notifications.kendoConfirm(`Are you sure you want to delete the selected item${message}?`, "Hang on a second!").pipe(
      filter(r => !!r),
      this.loader.finalizeHidePipe(),
      switchMap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationHistoryKendoModalComponent,
          width: "40%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
        dialogInstance.data = {
          // shallow copy so updates don't mutate locally
          investigation: { ...this.investigation },
          selectedCategory: IApiInvestigationUpdateCategories.Evidence,
          selectedType: IApiInvestigationUpdateTypes.Delete,
          noteOnly: true
        };
        return dialog.result.pipe(
          filter((v: DialogCloseResult) => {
            return _.isEmpty(v) ? false : !!v
          })
        );
      }),
      switchMap((v: IApiAddInvestigationHistoryInput) => {
        return this.loader.show$(forkJoin([
          this.investigationService.update({ id: this.investigation.id, History: v }),
          ...selectedItems.map((item) => {
            return this.evidenceService.removeEvidence({ id: item.id });
          })
        ]));
      }),
      this.notifications.snackbarErrorPipe(`Error removing item${message}.`),
      this.notifications.snackbarPipe(`Item${message} deleted`)
    ).subscribe((res) => {
      // Load evidence items to remove deleted items from UI
      this.reload.emit(true);
      this.loadEvidenceItems();
    });
  }

  public addItem() {
    this.evidenceItems.push({
      id: "",
      evidenceNumber: 0,
      takenFrom: "",
      description: "",
      Status: null,
      section: "",
      row: "",
      EvidenceStorageLocation: {
        id: "",
        name: "",
        type: "",
        isActive: true,
        additionalDetails: "",
        companyName: "",
      },
      Investigation: this.investigation,
      isSelected: false
    });

    this.hasNewItem = true;
  }

  public receiveItem(item: IApiEvidence) {
    const data: IEvidenceTransferModalData = {
      evidence: [item],
      receiveTransfer: true
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceTransferModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceTransferModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result) {
        this.loadEvidenceItems();
        this.reload.emit(true);
      }
    });
  }

  public cancelItem(item: IApiEvidence) {
    this.evidenceItems = this.evidenceItems.filter((obj) => obj !== item);
    this.hasNewItem = false;
  }

  public setSelectedStatus(status: string) {
    const Status = status as IApiEvidenceStatusType;
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationHistoryKendoModalComponent,
      width: "40%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this.investigation },
      selectedCategory: IApiInvestigationUpdateCategories.Evidence,
      selectedType: IApiInvestigationUpdateTypes.Update,
      noteOnly: true
    }

    dialog.result.pipe(
      filter((v) => !!v),
      switchMap((v: IApiAddInvestigationHistoryInput) => this.loader.show$(
        forkJoin([
          this.investigationService.update({ id: this.investigation.id, History: v }),
          ...this.selectedEvidenceItems.filter((obj) => {
            // set them all, but only update the db if they've saved new items
            obj.Status = Status;
            return !!obj.id;
          }).map(({ id }) => this.evidenceService.updateEvidence({ Status, id }).pipe(
            this.notifications.snackbarErrorPipe("Error updating Evidence status.")
          ))
        ]).pipe(
          this.notifications.snackbarPipe("Evidence Statuses Updated.")
        ))
      )
    ).subscribe(() => {
    });

  }

  public updateValidation(index, value, type) {
    this.evidenceItems[index][type] = !value;
  }

  public saveItem(index, { id, description, takenFrom, Status, EvidenceStorageLocation, section, row }: IApiEvidence) {

    if (
      description?.trim()?.length === 0 || takenFrom?.trim()?.length === 0 || !Status || !EvidenceStorageLocation?.id
    ) {
      this.evidenceItems[index]['descriptionError'] = description?.trim()?.length === 0;
      this.evidenceItems[index]['takenFromError'] = takenFrom?.trim()?.length === 0;
      // this.evidenceItems[index]['sectionError'] = section?.trim()?.length === 0;
      // this.evidenceItems[index]['rownError'] = row?.trim()?.length === 0;
      this.evidenceItems[index]['statusError'] = !Status;
      this.evidenceItems[index]['evidenceStorageLocationError'] = !EvidenceStorageLocation?.id;
      return;
    }

    this.disableCheckMark = true;
    if (!this.disableCheckMark) {
      return;
    }
    const data = {
      InvestigationId: this.investigation.id,
      description,
      takenFrom,
      Status: Status as IApiEvidenceStatusType,
      EvidenceStorageLocationId: EvidenceStorageLocation.id,
      section,
      row
    };

    if (id) {
      const dialog: DialogRef = this.dialogService.open({
        content: InvestigationHistoryKendoModalComponent,
        width: "40%",
        preventAction: (ev) => {
          return ev !== 'closed' as any;
        },
      });

      const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
      dialogInstance.data = {
        // shallow copy so updates don't mutate locally
        investigation: { ...this.investigation },
        selectedCategory: IApiInvestigationUpdateCategories.Evidence,
        selectedType: IApiInvestigationUpdateTypes.Update,
        noteOnly: true
      };
      // only update investigation history if a change is made to current evidence items
      dialog.result.pipe(
        filter((v) => {
          if (!v) {
            this.disableCheckMark = false;
          }
          return !!v;
        }),
        switchMap((v: IApiAddInvestigationHistoryInput) => this.loader.show$(
          forkJoin([
            this.investigationService.update({ id: this.investigation.id, History: v }),
            this.evidenceService.updateEvidence({ ...data, id })
          ])
        ).pipe(
          this.notifications.snackbarErrorPipe("Error updating Evidence Items"),
          this.notifications.snackbarPipe("Evidence items updated.")
        ))
      ).subscribe(() => {
        this.loadEvidenceItems();
        this.reload.emit(true);
        this.disableCheckMark = false;
      }, () => {
        this.disableCheckMark = false;
      });
    } else {
      this.evidenceService.addEvidence(data).pipe(
        this.notifications.snackbarErrorPipe("Error updating Evidence Items"),
        this.notifications.snackbarPipe("Evidence items updated.")
      ).subscribe(() => {
        this.loadEvidenceItems();
        this.reload.emit(true);
        this.disableCheckMark = false;
      }, () => {
        this.disableCheckMark = false;
      });
    }

    this.hasNewItem = false;
  }

  private loadEvidenceItems() {
    this.dataSource.load();
  }

  onPalletChange(palletCount) {
    if (palletCount.valid) {
      this.palletChange.next();
    }
  }

  public saveEvidenceBilling(palletOnly = false) {
    const frequencyId = this.investigation.EvidenceBilling.BillingFrequency.id;
    const data = {
      ...this.investigation.EvidenceBilling,
      palletCount: this.investigation.EvidenceBilling.palletCount ? this.investigation.EvidenceBilling.palletCount?.toString() : "0",
      BillingFrequencyId: frequencyId,
      override: true
    };
    delete data.BillingFrequency;
    delete data.lastExpensed;
    // delete data.currentExpensed;
    delete data.__typename;
    if (!data.id) delete data.id;

    this.loader.show$(
      forkJoin([
        this.investigationService.update({
          id: this.investigation.id, History: {
            InvestigationId: this.investigation.id,
            updateCategory: IApiInvestigationUpdateCategories.Evidence,
            updateType: IApiInvestigationUpdateTypes.Update,
            comment: palletOnly ? `Evidence pallet count updated` : `Evidence billing override information updated`
          }
        }),
        (data.id ? this.evidenceBillingService.update({ ...data }) : this.evidenceBillingService.add({ ...data, InvestigationId: this.investigation.id } as IApiAddEvidenceBillingInput)).pipe(
          this.notifications.snackbarErrorPipe("Unable to update evidence billing, please try again."),
          this.notifications.snackbarPipe("Evidence Billing Updated")
        )
      ])
    ).subscribe(() => {
      this.evidenceBillingEditMode = false;
      this.evidenceBillingForm.form.markAsPristine();
      this.loadEvidenceItems();
      this.reload.emit(true);
    });
  }

  public evidenceHistoryAction() {
    this.loader.show$(
      this.investigationTemplateDownload.generateEvidenceListCSV(this.investigation.id)
    ).subscribe((val) => {
      const filename = `Investigation_${this.investigation.nefcoNumber}_Evidence_${moment().format("MM/DD/YYYY")}.csv`;
      this.downloadHelper.download(val, filename);
    });
  }

  public printPhotos() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidencePhotosModalComponent,
      width: "100%",
      height: "100%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidencePhotosModalComponent;
    dialogInstance.data = {
      investigation: this.investigation
    };

    dialogInstance.loading.pipe(
      untilDestroyed(this),
      // filter out if true we only want to continue if loading is complete (false)
      filter((v) => !v),
      // push print into event queue so DOM updates before this triggers, which prevents other js from running
      delay(20)
    ).subscribe(() => {
      window.onafterprint = () => {
        dialogInstance.close();
        window.onafterprint = null;
      };
      window.print();
    });
  }

  public downloadEvidenceForm() {
    this.loader.show$(
      this.templateService.generateTemplateDownload({
        InvestigationId: this.investigation.id,
        DocumentTypeIds: [this.transmittalFormDocId],
        UploadUris: [],
      })
    ).subscribe((downloadUrl) => {
      this.downloadHelper.openURL(downloadUrl);
    });
  }

  public editEvidenceBilling() {
    // Set this to true
    if (!this.investigation.EvidenceBilling.override) this.investigation.EvidenceBilling.override = true;
    this.evidenceBillingEditMode = true;

  }

  public isOwn(createdById) {
    return this.authenticatedUserId === createdById;
  }

  public getNextBill() {
    let finalDate = "";
    if (this.investigation?.EvidenceBilling?.currentExpensed && this.investigation?.EvidenceBilling?.startDate) {
      const date = new Date(this.investigation?.EvidenceBilling?.currentExpensed).getTime() > new Date(this.investigation?.EvidenceBilling?.startDate).getTime() ? this.investigation?.EvidenceBilling?.currentExpensed : this.investigation?.EvidenceBilling?.startDate;
      finalDate = dayjs(date).add(+this.investigation?.EvidenceBilling.BillingFrequency.months, 'months').format('MM/DD/YYYY');
    } else if (this.investigation?.EvidenceBilling?.startDate) {
      finalDate = dayjs(this.investigation?.EvidenceBilling?.startDate).add(+this.investigation?.EvidenceBilling.BillingFrequency.months, 'months').format('MM/DD/YYYY');
    } else {
      finalDate = "";
    }

    if (finalDate) {
      return new Date(finalDate)?.getTime() > new Date(dayjs().format('MM/DD/YYYY')).getTime() ? finalDate : dayjs().format('MM/DD/YYYY');
    }
    return finalDate;
  }

  public itemSelected(event) {
    switch (event?.text) {
      case 'Download Disposal Letter':
        this.downloadDisposalLetter();
        break;
      case 'Move to Disposal Requested':
        this.setSelectedStatus(this.evidenceStatusType.DisposalRequested);
        break;
      case 'Move to Awaiting Disposal':
        this.setSelectedStatus(this.evidenceStatusType.AwaitingDisposal);
        break;
      case 'Disposed':
        this.setSelectedStatus(this.evidenceStatusType.Disposed);
        break;
      case 'Move to Disposed':
        this.setSelectedStatus(this.evidenceStatusType.Disposed);
        break;
      case 'Undispose':
        this.setSelectedStatus(this.evidenceStatusType.AwaitingDisposal);
        break;
      case 'Move to Destroyed':
        this.setSelectedStatus(this.evidenceStatusType.Destroyed);
        break;
      case 'Transfer':
        this.openTransferModal();
        break;
      case 'Delete':
        this.deleteItems(this.selectedEvidenceItems);
        break;
      default:
        break;
    }
  }

  public downloadItemSelected(item) {
    switch (item) {
      case "Download Item List":
        this.downloadEvidenceForm();
        break;
      case "Download Evidence History":
        this.evidenceHistoryAction();
        break;
      case "Download Hi-res Photos":
        this.downloadPhotos(false);
        break;
      case "Download Low-res Photos":
        this.downloadPhotos(true);
        break;
      case "Print Photo Sheet":
        this.printPhotos();
        break;
      default:
        break;
    }
  }

}
