import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { IApiAddInvestigationHistoryInput, IApiDocumentTypeFilterType, IApiEvidence, IApiEvidenceFilter, IApiEvidenceFilterType, IApiEvidenceOrderBy, IApiEvidenceStatusType, IApiEvidenceStorageLocation, IApiEvidenceStorageLocationFilterType, IApiInvestigationRoleNames, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { AuthService, DocumentTypeService, InvestigationService, InvestigationTemplatDownloadService } from 'src/app/shared/services';
import { IEvidenceTransferModalData, InvestigationEvidenceTransferModalComponent } from '../../investigations/investigation-evidence-transfer-modal/investigation-evidence-transfer-modal.component';
import { MatPaginator } from '@angular/material/paginator';
import { EvidenceDataSource, EvidenceService, EvidenceStorageLocationService } from 'src/app/shared/services/evidence';
import { filter, switchMap, tap, switchAll, toArray } from 'rxjs/operators';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import _, { isArray } from 'lodash';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { forkJoin } from 'rxjs';
import {
  evidenceDownload,
  evidence,
  evidenceView,
  evidenceUpdate,
  evidenceUpdateOwn,
  evidenceDelete,
  evidenceTransferCreate,
  evidenceTransferUpdate,
} from "src/app/shared/helpers/auth-config/evidence.config";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { InvestigationEvidenceSidebarComponent } from '../../investigations';
import { SelectionModel } from '@angular/cdk/collections';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadHelper } from 'src/app/shared/services/downloader-helper.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestigationHistoryKendoModalComponent } from '../../investigations/investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SortDescriptor } from '@progress/kendo-data-query';

interface ICheckedEvidence extends IApiEvidence {
  selected?: boolean;
}

@UntilDestroy()

@Component({
  selector: 'app-evidence-list',
  templateUrl: './evidence-list.component.html',
  styleUrls: ['./evidence-list.component.scss']
})
export class EvidenceListComponent implements OnInit, AfterViewInit {

  @ViewChild('evidenceDownload') evidenceDownloadPerm;
  @ViewChild('evidenceUpdate') evidenceUpdatePerm;
  @ViewChild('evidenceUpdateOwn') evidenceUpdateOwnPerm;
  @ViewChild('evidenceTransferCreate') evidenceTransferCreatePerm;
  @ViewChild('evidenceDelete') evidenceDeletePerm;

  public get evidenceStatusType(): typeof IApiEvidenceStatusType {
    return IApiEvidenceStatusType;
  }

  public authConfig = {
    evidenceDownload,
    evidence,
    evidenceView,
    evidenceUpdate,
    evidenceUpdateOwn,
    evidenceDelete,
    evidenceTransferCreate,
    evidenceTransferUpdate,
  };

  private _dataSource: EvidenceDataSource;
  public set dataSource(val) {
    this._dataSource = val;
  }
  public get dataSource() {
    return this._dataSource;
  }

  // Paging Options
  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }
  // Search Value
  public searchValue: string = "";

  public locationFilter: string;
  public statusFilter: string;
  public startDateFilter: Date;
  public endDateFilter: Date;

  // Selected evidence items
  public selectedEvidence = new SelectionModel<IApiEvidence>(true, []);
  public evidenceVisible: IApiEvidence[] = [];
  public evidenceVisibleOwn: IApiEvidence[] = [];

  public get selectedInvestigationIds() {
    return [... new Set(this.selectedEvidence.selected.map(({ Investigation }) => {
      return Investigation.id;
    }))];
  }

  // Evidence Storage Locations
  public evidenceStorageLocations: IApiEvidenceStorageLocation[];

  // Evidence Status
  public evidenceStatus = [{
    text: 'Awaiting Disposal', value: IApiEvidenceStatusType.AwaitingDisposal
  },
  {
    text: 'Disposal Requested', value: IApiEvidenceStatusType.DisposalRequested
  },
  {
    text: 'Shipped', value: IApiEvidenceStatusType.Shipped
  },
  {
    text: 'Stored', value: IApiEvidenceStatusType.Stored
  }];

  // Form IDs
  private evidenceDisposalFormId: string = null;

  public orderByOptions = IApiEvidenceOrderBy;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public userRoleName = IApiInvestigationRoleNames;
  private _filters: IApiEvidenceFilter[] = [];
  public filterTypes = IApiEvidenceFilterType;
  public isDisposedChecked = false;
  public isDestroyedChecked = false;
  public isIsBillableChecked = false;
  public isIsNonBillableChecked = false;

  public get filters() {
    return this._filters;
  }

  public set filters(val) {
    this._filters = val;
  }
  public authenticatedUserId = null;
  public evidenceActions = [];
  public isOpenMoreActions = false;
  public sort: SortDescriptor[] = [
    {
      field: IApiEvidenceOrderBy.Investigation,
      dir: "desc",
    },
  ];
  constructor(
    private notifications: NotificationsService,
    private investigationService: InvestigationService,
    private evidenceService: EvidenceService,
    private evidenceStorageLocationService: EvidenceStorageLocationService,
    private loader: LoaderService,
    private documentTypeService: DocumentTypeService,
    private templateService: InvestigationTemplatDownloadService,
    private downloadHelper: DownloadHelper,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialogService: DialogService,
    private cdr: ChangeDetectorRef,
  ) {
    this.dataSource = new EvidenceDataSource(this.evidenceService);
    this.dataSource.listPage.orderBy = IApiEvidenceOrderBy.Investigation;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
    this.dataSource.applyFilter(IApiEvidenceFilterType.Disposed, JSON.stringify(this.isDisposedChecked));
    this.setFilters(String(this.isDisposedChecked), IApiEvidenceFilterType.Disposed);
    this.loader.attachObservable(this.dataSource.loading$);
  }

  ngOnInit(): void {
    this.authService.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this.route.queryParams.pipe(untilDestroyed(this)).pipe(tap()).subscribe((params) => {
      if (params?.locationId) {
        this.dataSource.applyFilter(IApiEvidenceFilterType.Locations, JSON.stringify([params?.locationId]))
        this.setVal([params?.locationId], IApiEvidenceFilterType.Locations);
      }
      this.load();
    })

    // This is used to toggle the visible evidence items
    this.dataSource.contents$.pipe(untilDestroyed(this)).subscribe((content) => {
      this.evidenceVisible = content
      this.evidenceVisibleOwn = content.filter(i => i.CreatedBy.id === this.authenticatedUserId);
    });

    // SWB 5/22 - performance; just get the 2 types needed instead of all
    forkJoin([
      this.documentTypeService.get([
        {
          type: IApiDocumentTypeFilterType.Name,
          value: "Evidence Transmittal Form"
        }
      ]),
      this.documentTypeService.get([
        {
          type: IApiDocumentTypeFilterType.Name,
          value: "Evidence Disposition Form"
        }
      ]),
      this.evidenceStorageLocationService.get([
        {
          type: IApiEvidenceStorageLocationFilterType.ViewListView,
          value: "true"
        }
      ])
    ]).pipe(
      switchAll(),
      unwrapConnection(),
      toArray()
    ).subscribe(([transmit, disposition, evidenceLocations]) => {
      this.evidenceDisposalFormId = disposition.length ? disposition[0].id : null;
      this.evidenceStorageLocations = evidenceLocations;
    });
  }
  
  ngAfterViewInit(): void {
    const actions = [
      { text: "Download Disposal Letter", condition: this.evidenceDownloadPerm?.enabled },
      { text: "Move to Disposal Requested", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Move to Disposed", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Undispose", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Move to Destroyed", condition: this.evidenceUpdatePerm?.enabled || this.evidenceUpdateOwnPerm?.enabled },
      { text: "Transfer", condition: this.evidenceTransferCreatePerm?.enabled },
      { text: "Delete", condition: this.evidenceDeletePerm?.enabled }
    ];
    actions.forEach(action => {
      if (action.condition && !this.evidenceActions.some(e => e.text === action.text)) {
        this.evidenceActions.push({ text: action.text });
      }
    });
    this.cdr.detectChanges();
  }

  
  get getEvidenceActions() {
    return this.evidenceActions.filter(item =>
      this.isDisposedChecked ? item.text !== 'Move to Disposed' : item.text !== 'Undispose'
    );
  }

  public selectEvidence(e: any, item: IApiEvidence, all = false) {
    if (all) e.checked ? (!this.evidenceUpdatePerm?.enabled && this.evidenceUpdateOwnPerm?.enabled) ? this.selectedEvidence.select(...this.evidenceVisibleOwn) : this.selectedEvidence.select(...this.evidenceVisible) : this.selectedEvidence.clear();
    else e.checked ? this.selectedEvidence.select(item) : this.selectedEvidence.deselect(item);
  }

  public resetSelect() {
    this.selectedEvidence.clear();
  }

  // Action Menu Functions
  public pleaseSelect() {
    this.notifications.alert('No evidence is selected. Please select at least one evidence item.');
  }

  // Transfer Evidence
  public openTransferModal() {
    if (!this.selectedEvidence.selected.every(({ EvidenceStorageLocation }) => EvidenceStorageLocation.id === this.selectedEvidence.selected[0].EvidenceStorageLocation.id)) {
      return this.notifications.alert("All selected evidence items must be at the same location, please try again.");
    }
    const data: IEvidenceTransferModalData = {
      evidence: this.selectedEvidence.selected,
    };
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceTransferModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceTransferModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result) this.load();
    });
  }

  public openSidebar(evidenceItem: IApiEvidence) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceSidebarComponent,
      width: "55%",
      height: "100vh",
      cssClass: 'right-position',
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceSidebarComponent;
    dialogInstance.evidence = evidenceItem;
    dialog.result.subscribe((result: DialogCloseResult) => {});
  }

  public receiveItem(item: IApiEvidence) {
    const data: IEvidenceTransferModalData = {
      evidence: [item],
      receiveTransfer: true
    };
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationEvidenceTransferModalComponent,
      width: "80%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const dialogInstance = dialog.content.instance as InvestigationEvidenceTransferModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: any) => {
      if (result?.id) {
        this.load();
      }
    });
  }

  public downloadDisposalLetter() {
    if (this.selectedEvidence.selected.length > 1) {
      return this.notifications.alert("Only one evidence item can be selected to download a disposal letter, please try again.");
    }
    this.loader.show$(
      this.templateService.generateTemplateDownload({
        InvestigationId: this.selectedInvestigationIds[0],
        DocumentTypeIds: [this.evidenceDisposalFormId],
        UploadUris: [],
      })
    ).subscribe((downloadUrl) => {
      if (downloadUrl) {
        this.downloadHelper.openURL(downloadUrl);
      }
    });
  }

  public downloadEvidenceItems() {
    return this.loader.show$(
      this.evidenceService.generateEvidenceListCSV([...this.dataSource.lastFilters], { sortOrder: SortOrder.ASC, limit: -1 })
    ).subscribe((data) => {
      this.notifications.kendoConfirm("Your request to export Evidence list has been successfully submitted. You will receive a confirmation email when they are ready.", "Evidence List Export", "", "Ok").subscribe();
    });
  }

  public deleteItems() {
    const message = this.selectedEvidence.selected.length > 1 ? 's' : '';

    this.notifications.kendoConfirm(`Are you sure you want to delete the selected item${message}?`, "Hang on a second!").pipe(
      filter(r => !!r),
      this.loader.finalizeHidePipe(),
      switchMap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationHistoryKendoModalComponent,
          width: "40%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
        dialogInstance.data = {
          // shallow copy so updates don't mutate locally
          investigation: { id: this.selectedInvestigationIds[0] },
          selectedCategory: IApiInvestigationUpdateCategories.Evidence,
          selectedType: IApiInvestigationUpdateTypes.Delete,
          noteOnly: true
        };
        return dialog.result.pipe(
          filter((v: DialogCloseResult) => {
            return _.isEmpty(v) ? false : !!v
          }),);
      }),
      switchMap((v: IApiAddInvestigationHistoryInput) => {
        // Apply message to all investigations that are updated
        return this.loader.show$(forkJoin([
          ...this.selectedInvestigationIds.map((item) => {
            return this.investigationService.update({ id: item, History: { ...v, InvestigationId: item } });
          }),
          ...this.selectedEvidence.selected.map((item) => {
            return this.evidenceService.removeEvidence({ id: item.id });
          })
        ]));
      }),
      this.notifications.snackbarErrorPipe(`Error removing item${message}.`),
      this.notifications.snackbarPipe(`Item${message} deleted`)
    ).subscribe(() => {
      // Load evidence items to remove deleted items from UI
      this.load();
    });
  }

  public setSelectedStatus(status: string) {
    const Status = status as IApiEvidenceStatusType;
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationHistoryKendoModalComponent,
      width: "40%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
    dialogInstance.data = {
      // Need an id, only passing in the first. Will iterate over all of them below.
      investigation: { id: this.selectedInvestigationIds[0] },
      selectedCategory: IApiInvestigationUpdateCategories.Evidence,
      selectedType: IApiInvestigationUpdateTypes.Update,
      noteOnly: true
    };

    dialog.result.pipe(
      filter((v) => !!v),
      switchMap((v: IApiAddInvestigationHistoryInput) => this.loader.show$(
        forkJoin([
          ...this.selectedInvestigationIds.map((id) => this.investigationService.update({ id: id, History: { ...v, InvestigationId: id } })),
          ...this.selectedEvidence.selected.map(({ id }) => this.evidenceService.updateEvidence({ Status, id }).pipe(
            this.notifications.snackbarErrorPipe("Error updating Evidence status.")
          ))
        ]).pipe(
          this.notifications.snackbarPipe("Evidence Statuses Updated.")
        ))
      )
    ).subscribe((result: DialogCloseResult) => {
      this.load();
    })
  }

  public setDateFilter() {
    this.setFilters(NefcoDateHelper.dateFilterString(this.startDateFilter, this.endDateFilter), IApiEvidenceFilterType.Date)
    if (!this.startDateFilter && !this.endDateFilter) this.setFilters(null, IApiEvidenceFilterType.Date);
  }

  public onSearchChange() {
    this.dataSource.applyFilter(IApiEvidenceFilterType.Search, this.searchValue);
    this.setFilters(this.searchValue, IApiEvidenceFilterType.Search);
    this.load();
  }

  public onCheckboxChange(checked: boolean, filterType: IApiEvidenceFilterType) {
    this.dataSource.applyFilter(filterType, checked ? 'true' : null);
    this.setFilters(checked ? 'true' : null, filterType);
    this.load();
  }

  public setFilters(value: string | undefined, type: IApiEvidenceFilterType): void {
    const hasValue = (val: any) => (val !== undefined) || (val !== null); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this.filters.filter(f => f.type !== type);
    this.filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: isArray(value) && value?.length ? JSON.stringify(value) : !value?.length ? null : value
    }] : filtersCopy;
  }

  public filterValue(filterType: IApiEvidenceFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filterType)?.value;
    return value === "true" || value === "false" || isArray(value)
      ? JSON.parse(value)
      : typeof value === "string" && value
        ? value
        : null;
  }

  public filterByIdValue(filter: IApiEvidenceFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this.filters.find(({ type }) => type === filter)?.value;
    return value ? JSON.parse(value) : null;
  }


  public setVal(event: any, type: IApiEvidenceFilterType) {
    let val = null;
    if (event?.length > 0) {
      val = JSON.stringify(event);
    }
    this.setFilters(val, type);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

  applyFilters() {
    this.router.navigate([], { relativeTo: this.route, queryParams: {} });
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  clearAll() {
    this.router.navigate([], { relativeTo: this.route, queryParams: {} });
    this.filters = [];
    this.startDateFilter = null;
    this.endDateFilter = null;
    this.isDisposedChecked = false;
    this.isDestroyedChecked = false;
    this.isIsBillableChecked = false;
    this.isIsNonBillableChecked = false;
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.applyFilter(IApiEvidenceFilterType.Disposed, JSON.stringify(this.isDisposedChecked));
    this.setFilters(String(this.isDisposedChecked), IApiEvidenceFilterType.Disposed);
    this.dataSource.pagingReset();
    this.load();
  }

  // Load Evidence Items
  public load() {
    this.resetSelect();
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public isOwn(createdById) {
    return this.authenticatedUserId === createdById;
  }
  
  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiEvidenceOrderBy.Investigation;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public itemSelected(event) {
    switch (event?.text) {
      case 'Download Disposal Letter':
        this.downloadDisposalLetter();
        break;
      case 'Move to Disposal Requested':
        this.setSelectedStatus(this.evidenceStatusType.DisposalRequested);
        break;
      case 'Move to Awaiting Disposal':
        this.setSelectedStatus(this.evidenceStatusType.AwaitingDisposal);
        break;
      case 'Disposed':
        this.setSelectedStatus(this.evidenceStatusType.Disposed);
        break;
      case 'Move to Disposed':
        this.setSelectedStatus(this.evidenceStatusType.Disposed);
        break;
      case 'Undispose':
        this.setSelectedStatus(this.evidenceStatusType.AwaitingDisposal);
        break;
      case 'Move to Destroyed':
        this.setSelectedStatus(this.evidenceStatusType.Destroyed);
        break;
      case 'Transfer':
        this.openTransferModal();
        break;
      case 'Delete':
        this.deleteItems();
        break;
      default:
        break;
    }
  }
}
