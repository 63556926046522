<form [formGroup]="form" autocomplete="off">
  <section class="intake-page content-part">
    <div class="intake-container">
      <kendo-card id="form-invalid" width="100%" [ngClass]="{'mb-24': submitted || submissionError || claimAlreadyExists}">
        <div class="notification-error" *ngIf="submitted">
          <div class="icon">
            <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true" ></i>
          </div>
          <div class="message">
            <span>Please correct any error(s).</span>
          </div>
        </div>
        <div class="notification-error" *ngIf="submissionError">
          <div class="icon">
            <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true" ></i>
          </div>
          <div class="message">
            <span>There was an issue submitting your form. Please contact us at <a href="mailto:services@nefco.net" class="kendo-anchor-tag">services&#64;nefco.net</a> for assistance.</span>
          </div>
        </div>
        <div class="notification-error" *ngIf="claimAlreadyExists">
          <div class="icon">
            <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true" ></i>
          </div>
          <div class="message">
            <span>An existing case already exists with inputted claim number. Please contact us at <a href="mailto:services@nefco.net" class="kendo-anchor-tag">services&#64;nefco.net</a> for assistance.</span>
          </div>
        </div>
      </kendo-card>
      <kendo-card class="intake-card" width="100%">
        <h1>Submit a New Assignment</h1>
        <p>Use this form to create a new assignment for NEFCO and it’s investigators. Once your assignment has been reviewed, a member of our team will be in touch to discuss the next steps.</p>
          <kendo-gridlayout [rows]="['auto']" [cols]="['100%']" style="margin-bottom: 0;">
              <kendo-gridlayout-item>
                <kendo-formfield>
                  <kendo-label class="asterisk" [for]="email" text="Your Email Address"></kendo-label>
                  <kendo-textbox
                    #email
                    formControlName="email"
                    fillMode="outline"
                    [clearButton]="false"
                    [disabled]="true"
                  ></kendo-textbox>
                  <kendo-formhint>Your email address must use a corporate email address that is for your sole use.</kendo-formhint>
                  <kendo-formerror>
                    <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    <span *ngIf="form?.controls?.email?.errors?.required">Email cannot be left blank</span>
                    <span *ngIf="form?.controls?.email?.errors?.email">Invalid email address</span>
                  </kendo-formerror>
                </kendo-formfield>
              </kendo-gridlayout-item>
          </kendo-gridlayout>
          <kendo-gridlayout [rows]="['auto']" [cols]="['92%', '8%']" style="margin-bottom: 0;">
            <ng-container *ngFor="let control of  additionalEmailFieldAsFormArray.controls ;let i = index;" formArrayName='Emails' >
            <kendo-gridlayout-item class="email-list">
              <div>
                  <div [formGroupName]="i" >
                    <kendo-formfield>
                      <kendo-label class="asterisk" [for]="address" text="Your Notification Email"></kendo-label>
                      <kendo-textbox
                        #address
                        formControlName="address"
                        fillMode="outline"
                        [clearButton]="true"
                        appSkipClearButtonTabindex
                      ></kendo-textbox>
                      <kendo-formhint>Status notifications will be sent to this email address.</kendo-formhint>
                      <kendo-formerror>
                        <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        <span *ngIf="control?.controls?.address?.errors?.required">Email cannot be left blank</span>
                        <span *ngIf="!control?.controls?.address?.errors?.required && control?.controls?.address?.errors?.email">Invalid email address</span>
                      </kendo-formerror>
                    </kendo-formfield>
                  </div>
              </div>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="email-remove">
              <span class="k-icon k-i-minus-outline" (click)="removeAdditionalEmail(i)"></span>
            </kendo-gridlayout-item>
          </ng-container>
          <kendo-gridlayout-item>
            <button kendoButton class="mr-24" icon="plus-circle" themeColor="primary" fillMode="link"
              (click)="addAdditionalEmail()">Add Additional Email for Notifications</button>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
          <app-your-company-contact-information [companyTypes]="companyTypes" [isCurrentContact]="isCurrentContact" phoneFormGroupName="Phone" formGroupName="ClientInvestigationNote"></app-your-company-contact-information>
      </kendo-card>
      <app-loss-information formGroupName="lossInformation"></app-loss-information>
      <app-insured-information formGroupName="insuredInformation" [phoneTypes]="phoneTypes" ></app-insured-information>
      <app-loss-details-special-instructions formGroupName="lossDetailsSpecialInstructions"></app-loss-details-special-instructions>
      <app-create-password *ngIf="!existingClientDetails?.email" formGroupName="createPassword"></app-create-password>
      <app-can-we-help-you></app-can-we-help-you>
    </div>
  </section>
  <footer *ngIf="form?.controls?.email?.value">
    <div class="left">
        <button kendoButton themeColor="light" class="mr-12" fillMode="solid" iconClass="fa fa-arrow-left" (click)="openConfirmModel();">Back to Home</button>
        <button kendoButton themeColor="light" class="ml-12" fillMode="solid" iconClass="fa-solid fa-right-from-bracket" (click)="logout();">Log Out</button>
    </div>
    <div class="right">
        <button kendoButton class="mr-24" themeColor="primary" fillMode="link" (click)="clearForm();">Clear Page</button>
        <button kendoButton themeColor="primary" fillMode="solid" (click)="submitForm();">Submit</button>
    </div>
  </footer>
</form>
