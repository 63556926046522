<div [dialogRef]="dialog" (appClickOutside)="close()">
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>Upload Photos</span>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body">
    <div>
      <p>Which folder would you like to upload to?</p>
    </div>
    <div class="kendo-formfield" style="display: flex; flex-direction: column;">
      <div class="kendo-radio mb-16" *ngFor="let folder of data;">
        <input kendoRadioButton name="newFolderName" type="radio" [value]="folder" [id]="'newFolder_' + folder?.id"
          [(ngModel)]="selectedFolder" />
        <label class="k-radio-label" [for]="'newFolder_' + folder?.id">{{ folder?.name }}</label>
      </div>
      <div class="kendo-radio mb-16">
        <input kendoRadioButton name="newFolderName" type="radio" [value]="{id: null, name: null}" [id]="'newFolder_'"
          [(ngModel)]="selectedFolder" />
        <label class="k-radio-label" [for]="'newFolder_'">Other</label>
      </div>
    </div>
    <kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 5, cols: 20 }" [cols]="['repeat(2,minmax(0,1fr))']">
      <kendo-gridlayout-item>
        <kendo-formfield *ngIf="selectedFolder && !selectedFolder?.id">
          <kendo-label class="asterisk" [for]="folderName" text="Folder Name"></kendo-label>
          <kendo-textbox #folderName="ngModel" [(ngModel)]="newFolderName" name="folderName"
            placeholder="Enter folder name" fillMode="outline" [clearButton]="false" required
            appNoWhiteSpace></kendo-textbox>
          <kendo-formerror *ngIf="!folderName?.valid && (folderName?.dirty || folderName?.touched)">
            <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
            <span *ngIf="folderName?.errors?.required">Folder name cannot be left blank</span>
          </kendo-formerror>
        </kendo-formfield>
      </kendo-gridlayout-item>
    </kendo-gridlayout>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="light" class="mr-8" (click)="close()">
      Cancel
    </button>
    <button kendoButton [size]="'medium'" themeColor="primary" class="mr-16"
      [disabled]="!selectedFolder || (!selectedFolder?.id && newFolderName.length < 3)" (click)="selectFolder()">
      Upload Photos
    </button>
  </kendo-dialog-actions>
</div>
