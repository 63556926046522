import { IApiInvestigationRoleNames } from './../../modules/graphql/types/types';
import { map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { StandardNamePipe } from './../../pipes/name.pipe';
import { Component, OnInit, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from 'src/app/shared/services';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { IApiUser } from "../../modules/graphql/types/types";
import { unwrapConnection } from '../../rxjs.pipes';
import {
  staff
} from "src/app/shared/helpers/auth-config/staff.config";
@Component({
  selector: 'app-user-select',
  templateUrl: './user-select.component.html',
  styleUrls: ['./user-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserSelectComponent),
      multi: true
    }
  ]
})
export class UserSelectComponent implements OnInit, ControlValueAccessor {
  public authConfig = {
    staff
  };


  @Input() placeholder = "Search for a User";
  @Input() label = "";
  @Input() staticUsers: IApiUser[];
  @Input() investigationRole: IApiInvestigationRoleNames = null;
  @Input() disabled = false;
  @Input() includeInactive = false;
  @Input() userViewFilter = null;
  @Input() width: string = 'auto';
  @Input() userId: string = null;
  @Input() excludeLoginUserId = null;
  public users: IApiUser[] = [];

  @Output() selectionChanged = new EventEmitter<IApiUser | null>();

  public selected: IApiUser = null;

  @Input() public set user(val: IApiUser) {
    this.select(val);
  }

  constructor(
    private userService: UserService,
    private fullNamePipe: StandardNamePipe,
    private loader: LoaderService,
    private notifications: NotificationsService
  ) { }

  ngOnInit() {
    if (this.userId) {
      this.userService.getById(this.userId).pipe(
        this.notifications.snackbarErrorPipe(`Error loading user: ${this.userId}`)
      ).subscribe((user) => {
        this.users = [user];
        this.selected = user;
      });
    }
    if (this.staticUsers) this.users.push(...this.staticUsers);
  }

  public select(selected: IApiUser | null) {
    this.selected = selected;
    this.selectionChanged.emit(selected);
    this._propagateChange(this.selected);
  }

  public onSearchChange(search: string) {
    this.users = [];
    if (search?.length < 3) return;

    const filters = [{
      type: IApiUserFilterType.Search,
      value: search
    }];

    if (this.investigationRole) filters.push({
      type: IApiUserFilterType.Position,
      value: JSON.stringify(this.investigationRole.valueOf())
    });

    if (this.includeInactive) filters.push({
      type: IApiUserFilterType.IsInactive,
      value: 'true'
    });

    if(this.excludeLoginUserId) filters.push({
      type: IApiUserFilterType.NotUserId,
      value: JSON.stringify([this.excludeLoginUserId])
    }); 

    if (this.userViewFilter) {
      filters.push({
        type: this.userViewFilter,
        value: 'true'
      });
    }
    this.loader.show$(
      this.userService.get(filters).pipe(
        unwrapConnection(),
        map(results => {
          if (this.staticUsers) results.push(...this.staticUsers);
          return results;
        })
      )
    ).subscribe(results => this.users = results);
  }

  private _propagateChange = (_: any) => { };

  // Control Value accessor members
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.userId = value;
    }
  }

  public registerOnChange(fn: any): void {
    this._propagateChange = fn;
  }

  public registerOnTouched(fn: any): void { }
  public setDisabledState?(isDisabled: boolean): void { }

  public selectedDisplay(val: IApiUser) {
    return this.fullNamePipe.transform(val);
  }

  public closed() {
    if (typeof this.selected === "string") this.select(null);
  }

  public blur() {
    if (typeof this.selected === "string" && (this.selected as string).length < 3) this.select(null);
  }

}
