import { Component, Input } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiInvestigationMetricsFilter, IApiInvestigationMetricsOrderBy, IApiInvestigationMetricsState } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { StateService } from 'src/app/shared/services';
import { InvestigationMetricsStateDataSource, InvestigationMetricsStateService } from 'src/app/shared/services/reports';

@Component({
  selector: "app-investigation-metrics-state",
  templateUrl: "./investigation-metrics-state.component.html",
  styleUrls: ["./investigation-metrics-state.component.scss"],
})
export class InvestigationMetricsStateComponent {
  @Input() tabIndex: number;
  private _dataSource: InvestigationMetricsStateDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public data: IApiInvestigationMetricsState[] = [];
  public sort: SortDescriptor[] = [
    {
      field: IApiInvestigationMetricsOrderBy.LossState,
      dir: "asc",
    },
  ];

  constructor(
    private investigationMetricsStateService: InvestigationMetricsStateService,
    private loaderService: LoaderService,
    private stateService: StateService,
  ) {
    this.dataSource = new InvestigationMetricsStateDataSource(
      this.investigationMetricsStateService
    );
    this.loaderService.attachObservable(this.dataSource.loading$);
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = "CREATED_AT";
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  private load(): void {
    this.dataSource.load().then(() => {
      this.dataSource?.contents$.subscribe((res) => {
        this.data = res.map(item => {
          return {
            ...item,
            data: JSON.parse(item?.data)
          }
        });
      });
    });
  }

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode);
  }

  public applyFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    filters?.map(filter => this.dataSource.applyFilter(filter?.type, filter?.value));
    this.dataSource.pagingReset();
    this.load();
  }

  public clearFilter(filters: IApiInvestigationMetricsFilter[]) {
    this.dataSource?.lastFilters?.map(filter => this.dataSource.applyFilter(filter?.type, null));
    this.dataSource.pagingReset();
    this.load();
  }
}
