<div [dialogRef]="dialog" (appClickOutside)="close()" #pdf>
  <kendo-dialog-titlebar (close)="close()">
    <div class="modal-title">
      <span>View Evidence Photos</span>
      <div class="modal-subtitle">Investigation #{{ nefcoNumber }}</div>
    </div>
  </kendo-dialog-titlebar>
  <div class="model-body printable-only-content">
    <h4 class="text-center print-only">View Evidence Photos</h4>
    <h5 class="muted text-center print-only">Investigation #{{ nefcoNumber }}</h5>
    <ng-container *ngFor="let folder of folders">
      <p><span class="font-weight-bold">{{ folder.name }} </span><span class="muted"> Uploaded {{ folder.createdAt |
          customDate: 'MM/dd/yyyy' }}</span></p>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngFor="let photo of folder?.EvidencePhotos">
          <kendo-card width="100%">
            <kendo-card-body>
              <p class="mx-2">{{ photo.fileName }}</p>
              <div *ngIf="!photo?.isLoaded && photo?.s3Uri" class="text-center">
                <kendo-loader type="infinite-spinner" themeColor="primary" size="medium">
                </kendo-loader>
              </div>
              <img [src]="photo.s3Uri | s3" [alt]="photo.caption" (load)="openPrint(photo)" />
              <p class="muted mt-2">{{ photo.caption }} </p>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </ng-container>
    <h5 class="text-center my-5" *ngIf="!folders?.length">No Evidence Photos to Display</h5>
  </div>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="primary" (click)="close()">Ok</button>
  </kendo-dialog-actions>
</div>
