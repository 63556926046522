import { Component, Input } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IApiPhotoFolder } from 'src/app/shared/modules/graphql/types/types';

@Component({
  selector: 'app-investigation-photos-modal',
  templateUrl: './investigation-photos-modal.component.html',
  styleUrls: ['./investigation-photos-modal.component.scss']
})
export class InvestigationPhotosModalComponent extends DialogContentBase {

  selectedFolder: IApiPhotoFolder = null;
  newFolderName = '';
  @Input() public data: IApiPhotoFolder[];

  constructor(
    private dialogRef: DialogRef,
  ) {
    super(dialogRef);
  }

  public selectFolder() {
    this.dialogRef.close({
      ...this.selectedFolder,
      name: !this.selectedFolder.id ? this.newFolderName : this.selectedFolder.name,
    });
  }

  public close(result = false) {
    this.dialogRef.close(result);
  }

}
