import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { CanDeactivateAddFormDirty, CanDeactivateEditFormDirty, PermissionGuard, MultiPermissionGuard } from './route.guards';
import { RouterModule } from '@angular/router';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatSortModule } from '@angular/material/sort';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MomentModule } from 'ngx-moment';
import { MatMenuModule } from '@angular/material/menu';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RequestInterceptor } from './http.interceptor';
import { GraphQLModule } from './modules/graphql/graphql.module';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from "@angular/material/tooltip";
import { ContactsListComponent } from "../components/shared/contacts/contacts-list/contacts-list.component";
import { CertificationsListComponent } from "../components/shared/certifications/certifications-list/certifications-list.component";
import { CertificationsFilterComponent } from "../components/shared/certifications/certifications-filter/certifications-filter.component";
import {
  PermissionsResolver,
  InvestigationResolver,
  ContactResolver,
} from './route.resolvers';

import * as Services from "./services";

import { PhoneComponent, EmailComponent, DatepickerComponent } from '../components/shared/forms';
import { ExpenseItemsViewComponent } from '../components/shared/expense-items-view/expense-items-view.component';
import { IndExpenseFormKendoComponent } from '../components/shared/forms/ind-expense-form-kendo/ind-expense-form-kendo.component';
import { IndMileageFormKendoComponent } from '../components/shared/forms/ind-mileage-form-kendo/ind-mileage-form-kendo.component';
import { StaffListComponent } from '../components/shared/staff/staff-list/staff-list.component';
import { environment } from 'src/environments/environment';

import { AppAfterValueChangedDirective, DebouncedChangeDirective, DebouncedInputChangeDirective } from './directives/debounced-change.directive';
import { GoogleMapsModule } from './modules/google-maps/google-maps.module';
import { EnforcePermissionDirective } from './directives/enforce-permission.directive';
import { EventLinkDirective } from "./directives/event-link.directive";

import {
  StatesSelectComponent,
  StatesSelectFormKendoComponent,
  UserSelectComponent,
  TimepickerComponent,
  RiskTypesComponent,
  AddressComponent,
  AddressKendoComponent,
  RoleSelectComponent,
  ReportStatusIconComponent,
  AddressViewComponent,
  EmailPhoneViewComponent,
  InvestigationSelectKendoComponent,
  InvestigationMultiSelectKendoComponent,
  ContactMultiSelectKendoComponent,
  CausesMultiSelectKendoComponent,
  RiskTypeMultiSelectKendoComponent,
  InsuredMultiSelectKendoComponent,
  DocumentTypeMultiSelectKendoComponent
} from './components';

import { CalendarProfileSidebarComponent } from './components/calendar-profile-sidebar/calendar-profile-sidebar.component';
import { CalendarViewComponent } from './components/calendar-view/calendar-view.component';
import { StaffProfileComponent } from "./components/staff-profile/staff-profile.component";
import { SelectStaffComponent } from "./components/select-staff/select-staff.component";
import { ClipboardModule } from '@angular/cdk/clipboard';

import { E164PhoneValidator } from "./directives/phone-validator";
import { WhitespaceValidator } from "./directives/whitespace-validator";

import { InvestigationsTableComponent, InvestigationsFilterCardComponent } from "../components/shared/investigations-table-view";
import { NgxMaskModule } from "ngx-mask";
import { ServiceTypeListComponent } from './components/service-type-list/service-type-list.component';
import { AddressAutocompleteComponent } from './components/address-autocomplete/address-autocomplete.component';
import { CalendarDetailModalComponent } from './components/calendar-view/calendar-detail-modal/calendar-detail-modal.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { CommentLogTableComponent } from './components/comment-log-table/comment-log-table.component';
import { DirtyFormNotifyDirective } from "./directives/dirtyForm.directives";
import { CompanySelectComponent } from './components/company-select/company-select.component';
import { SkipClearButtonTabindexDirective } from './directives/skip-clear-button-tabindex-directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { DndDirective } from './directives/dnd.directive';
import { ScrollNearEndDirective } from './directives/scroll-near-end.directive';
import { MatchPasswordDirective } from './directives/matchPassword.directive';

import {
  DomSafePipe,
  PhoneFormatPipe,
  PhoneBranchFormatPipe,
  ActiveBranchPipe,
  NullableDatePipe,
  NullableTimePipe,
  CustomDatePipe,
  ContactCompanyPipe,
  BranchNamePipe,
  StandardNamePipe,
  StandardInsuredNamePipe,
  InvestigationStaffRolePipe,
  ShowMobileChangeStatusPipe,
  AddressPipe,
  AddressBranchPipe,
  NefcoNumberPipe,
  EvidenceNumberPipe,
  PrimaryAssociationPipe,
  PrimarySortPipe,
  AlphabeticalIndexPipe,
  PhotoInUseFilterPipe,
  DocumentSnapshotPipe,
  QuietPipe,
  EvidenceLocationPipe,
  EvidenceItemsPipe,
  EvidenceStatusPipe,
  EvidenceArrivalDatePipe,
  EvidenceTransferDatePipe,
  CertificationErrorPipe,
  TimeEntryNamePipe,
  MoreThanPipe,
  ArraySortByDatePipe,
  VisibleExpenseItemsPipe,
  VisibleTimeEntryItemsPipe,
  ArraySortPipe,
  S3Pipe,
  FormatFileSizePipe,
  DateWithTimezonePipe,
  TimeZonePipe,
  ReimbursementRatePipe
} from './pipes';
import { ContactSelectComponent } from './components/contact-select/contact-select.component';
import { ImageCropperModule } from 'ngx-image-cropper';

/* START Kendo Modules */
import { GridModule } from "@progress/kendo-angular-grid";
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { LabelModule } from "@progress/kendo-angular-label";
import { InputsModule } from "@progress/kendo-angular-inputs";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { DropDownsModule } from "@progress/kendo-angular-dropdowns";
import { IconModule, SVGIconModule } from '@progress/kendo-angular-icons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from "@progress/kendo-angular-dialog";
import { ListViewModule } from "@progress/kendo-angular-listview";
import { PagerModule } from "@progress/kendo-angular-pager";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { TooltipsModule } from "@progress/kendo-angular-tooltip";
import { NotificationModule } from "@progress/kendo-angular-notification";
import { SchedulerModule } from "@progress/kendo-angular-scheduler";
import { KendoAlertComponent } from '../kendo-alert/kendo-alert.component';
import { InvestigationsTableNewComponent } from '../components/shared/investigations-table-view/investigations-table-new/investigations-table-new.component';
import { UserSelectKendoComponent } from './components/user-select-kendo/user-select-kendo.component';
import { ContactSelectKendoComponent } from './components/contact-select-kendo/contact-select-kendo.component';
import { IndTimeFormKendoComponent } from '../components/shared/forms/ind-time-form-kendo/ind-time-form-kendo.component';
import { UserMultiSelectKendoComponent } from './components/user-multi-select-kendo/user-multi-select-kendo.component';
import { ExpenseReportDetailsComponent } from '../components/shared/bookkeeping/bookkeeping-expense-reports/expense-report-details/expense-report-details.component';
import { TimeExpHoursTableKendoComponent } from '../components/shared/time-and-exp/time-exp-hours-table-kendo/time-exp-hours-table-kendo.component';
import { TimeExpDateKendoFilterComponent } from '../components/shared/time-and-exp/time-exp-date-kendo-filter/time-exp-date-kendo-filter.component';
import { TimeExpExpensesTableKendoComponent } from '../components/shared/time-and-exp/time-exp-expenses-table-kendo/time-exp-expenses-table-kendo.component';
import { CompanySelectKendoComponent } from './components/company-select-kendo/company-select-kendo.component';
import { CompanySelectSingleKendoComponent } from './components/company-select-single-kendo/company-select-single-kendo.component';
import { InvestigationOnSceneActivityModalComponent } from './components/investigation-on-scene-activity-modal/investigation-on-scene-activity-modal.component';
import { StateMultiSelectKendoComponent } from './components/state-multi-select-kendo/state-multi-select-kendo.component';
import { ReportTableComponent } from '../components/report/report-table/report-table.component';
import { InvestigationsReportFilterComponent } from '../components/report/investigations-report-filter/investigations-report-filter.component';
import { EmailValidator } from './directives/email-validator';
import { StaffTypeMultiSelectKendoComponent } from './components/staff-type-multi-select-kendo/staff-type-multi-select-kendo.component';
import { DateRangeNextPrevFilterKendoComponent } from './components/date-range-next-prev-filter-kendo/date-range-next-prev-filter-kendo.component';
import { ReportStatusIconKendoComponent } from './components/report-status-icon-kendo/report-status-icon-kendo.component';
import { StateSelectKendoComponent } from './components/state-select-kendo/state-select-kendo.component';
import { EvidenceStorageLocationMultiSelectKendoComponent } from './components/evidence-storage-location-multi-select-kendo/evidence-storage-location-multi-select-kendo.component';
import { UenumberMultiSelectKendoComponent } from './components/uenumber-multi-select-kendo/uenumber-multi-select-kendo.component';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ScrollToTopComponent } from './components/scroll-to-top/scroll-to-top.component';
import { CalendarViewNavigationComponent } from './components/calendar-view/calendar-view-navigation/calendar-view-navigation.component';
import { FileSelectModalComponent } from './components/file-select-modal/file-select-modal.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NotesModalComponent } from './modules/notes/notes-modal/notes-modal.component';
import { NotesComponent } from './modules/notes/notes.component';
/* END Kendo Modules */
@NgModule({
  declarations: [
    PhoneComponent,
    EmailComponent,
    DatepickerComponent,
    AddressComponent,
    AddressKendoComponent,
    RoleSelectComponent,
    StatesSelectComponent,
    StatesSelectFormKendoComponent,
    ExpenseItemsViewComponent,
    TimepickerComponent,
    RiskTypesComponent,
    IndExpenseFormKendoComponent,
    IndMileageFormKendoComponent,
    StaffListComponent,
    UserSelectComponent,
    UserMultiSelectKendoComponent,
    DebouncedChangeDirective,
    DebouncedInputChangeDirective,
    AppAfterValueChangedDirective,
    EnforcePermissionDirective,
    EventLinkDirective,
    RoleSelectComponent,
    CalendarProfileSidebarComponent,
    CalendarViewComponent,
    SelectStaffComponent,
    StaffProfileComponent,
    E164PhoneValidator,
    WhitespaceValidator,
    EmailValidator,
    InvestigationsTableComponent,
    InvestigationsFilterCardComponent,
    ReportStatusIconComponent,
    AddressViewComponent,
    EmailPhoneViewComponent,
    ContactsListComponent,
    CertificationsListComponent,
    CertificationsFilterComponent,
    ServiceTypeListComponent,
    AddressAutocompleteComponent,
    CalendarDetailModalComponent,
    BreadcrumbsComponent,
    CommentLogTableComponent,
    DirtyFormNotifyDirective,
    CompanySelectComponent,
    ContactSelectComponent,
    KendoAlertComponent,
    InvestigationsTableNewComponent,
    UserSelectKendoComponent,
    ContactSelectKendoComponent,
    ContactMultiSelectKendoComponent,
    IndTimeFormKendoComponent,
    ExpenseReportDetailsComponent,
    TimeExpHoursTableKendoComponent,
    TimeExpDateKendoFilterComponent,
    TimeExpExpensesTableKendoComponent,
    CompanySelectKendoComponent,
    CompanySelectSingleKendoComponent,
    InvestigationSelectKendoComponent,
    InvestigationMultiSelectKendoComponent,
    InvestigationOnSceneActivityModalComponent,
    StateMultiSelectKendoComponent,
    CausesMultiSelectKendoComponent,
    RiskTypeMultiSelectKendoComponent,
    ReportTableComponent,
    InvestigationsReportFilterComponent,
    InsuredMultiSelectKendoComponent,
    StaffTypeMultiSelectKendoComponent,
    DateRangeNextPrevFilterKendoComponent,
    ReportStatusIconKendoComponent,
    StateSelectKendoComponent,
    EvidenceStorageLocationMultiSelectKendoComponent,
    UenumberMultiSelectKendoComponent,
    ScrollToTopComponent,
    CalendarViewNavigationComponent,
    FileSelectModalComponent,
    NotesComponent,
    NotesModalComponent,
    SkipClearButtonTabindexDirective,
    ClickOutsideDirective,
    DndDirective,
    ScrollNearEndDirective,
    MatchPasswordDirective,
    DocumentTypeMultiSelectKendoComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    PipesModule.forRoot(),
    GraphQLModule.forRoot(),
    GoogleMapsModule.forRoot(),
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    MomentModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatRadioModule,
    MatChipsModule,
    MatSelectInfiniteScrollModule,
    MatSidenavModule,
    MatSliderModule,
    MatTooltipModule,
    ClipboardModule,
    NgxMaskModule.forRoot(),
    GridModule,
    LayoutModule,
    ProgressBarModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    DropDownsModule,
    IconModule,
    SVGIconModule,
    DateInputsModule,
    DialogsModule,
    ListViewModule,
    PagerModule,
    IndicatorsModule,
    TooltipsModule,
    PopupModule,
    NotificationModule,
    SchedulerModule,
    ImageCropperModule,
    ScrollingModule
  ],
  exports: [
    RouterModule,
    GoogleMapsModule,
    MatListModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatSortModule,
    MomentModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonToggleModule,
    HttpClientModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatSnackBarModule,
    MatMenuModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatExpansionModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatRadioModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatSidenavModule,
    PhoneComponent,
    EmailComponent,
    DatepickerComponent,
    AddressComponent,
    AddressKendoComponent,
    RoleSelectComponent,
    StatesSelectComponent,
    StatesSelectFormKendoComponent,
    UserSelectComponent,
    UserMultiSelectKendoComponent,
    CompanySelectComponent,
    ExpenseItemsViewComponent,
    IndExpenseFormKendoComponent,
    IndMileageFormKendoComponent,
    TimepickerComponent,
    RiskTypesComponent,
    StaffListComponent,
    MatChipsModule,
    TitleCasePipe,
    DebouncedChangeDirective,
    DirtyFormNotifyDirective,
    DebouncedInputChangeDirective,
    AppAfterValueChangedDirective,
    EnforcePermissionDirective,
    EventLinkDirective,
    MatSliderModule,
    ClipboardModule,
    SelectStaffComponent,
    StaffProfileComponent,
    ClipboardModule,
    E164PhoneValidator,
    WhitespaceValidator,
    EmailValidator,
    InvestigationsTableComponent,
    InvestigationsFilterCardComponent,
    ReportStatusIconComponent,
    ReportStatusIconKendoComponent,
    AddressViewComponent,
    EmailPhoneViewComponent,
    ContactsListComponent,
    CertificationsListComponent,
    CertificationsFilterComponent,
    ServiceTypeListComponent,
    AddressAutocompleteComponent,
    BreadcrumbsComponent,
    CommentLogTableComponent,
    AddressPipe,
    AddressBranchPipe,
    ActiveBranchPipe,
    ContactCompanyPipe,
    DocumentSnapshotPipe,
    DomSafePipe,
    BranchNamePipe,
    StandardNamePipe,
    StandardInsuredNamePipe,
    InvestigationStaffRolePipe,
    ShowMobileChangeStatusPipe,
    NefcoNumberPipe,
    EvidenceNumberPipe,
    NullableDatePipe,
    NullableTimePipe,
    CustomDatePipe,
    DateWithTimezonePipe,
    TimeZonePipe,
    ReimbursementRatePipe,
    PrimaryAssociationPipe,
    PrimarySortPipe,
    AlphabeticalIndexPipe,
    PhoneFormatPipe,
    PhoneBranchFormatPipe,
    PhotoInUseFilterPipe,
    QuietPipe,
    EvidenceLocationPipe,
    EvidenceItemsPipe,
    EvidenceStatusPipe,
    EvidenceArrivalDatePipe,
    EvidenceTransferDatePipe,
    CertificationErrorPipe,
    ContactSelectComponent,
    TimeEntryNamePipe,
    MoreThanPipe,
    ArraySortByDatePipe,
    VisibleExpenseItemsPipe,
    VisibleTimeEntryItemsPipe,
    ArraySortPipe,
    S3Pipe,
    GridModule,
    LayoutModule,
    ProgressBarModule,
    LabelModule,
    InputsModule,
    ButtonsModule,
    DropDownsModule,
    IconModule,
    SVGIconModule,
    DateInputsModule,
    DialogsModule,
    ListViewModule,
    PagerModule,
    KendoAlertComponent,
    IndicatorsModule,
    TooltipsModule,
    PopupModule,
    SchedulerModule,
    NotificationModule,
    InvestigationsTableNewComponent,
    UserSelectKendoComponent,
    ContactSelectKendoComponent,
    ContactMultiSelectKendoComponent,
    IndTimeFormKendoComponent,
    ExpenseReportDetailsComponent,
    TimeExpHoursTableKendoComponent,
    TimeExpDateKendoFilterComponent,
    TimeExpExpensesTableKendoComponent,
    CompanySelectKendoComponent,
    CompanySelectSingleKendoComponent,
    InvestigationSelectKendoComponent,
    InvestigationMultiSelectKendoComponent,
    StateMultiSelectKendoComponent,
    CausesMultiSelectKendoComponent,
    RiskTypeMultiSelectKendoComponent,
    ReportTableComponent,
    InvestigationsReportFilterComponent,
    InsuredMultiSelectKendoComponent,
    StaffTypeMultiSelectKendoComponent,
    DateRangeNextPrevFilterKendoComponent,
    StateSelectKendoComponent,
    EvidenceStorageLocationMultiSelectKendoComponent,
    UenumberMultiSelectKendoComponent,
    ScrollToTopComponent,
    FileSelectModalComponent,
    NotesComponent,
    ImageCropperModule,
    ScrollingModule,
    FormatFileSizePipe,
    SkipClearButtonTabindexDirective,
    ClickOutsideDirective,
    DndDirective,
    ScrollNearEndDirective,
    MatchPasswordDirective,
    DocumentTypeMultiSelectKendoComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ...Object.keys(Services).map(s => Services[s]),
        PermissionsResolver,
        InvestigationResolver,
        CanDeactivateAddFormDirty,
        PermissionGuard,
        MultiPermissionGuard,
        CanDeactivateEditFormDirty,
        ContactResolver,
        TitleCasePipe,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RequestInterceptor,
          multi: true,
        },
      ]
    };
  }
}
