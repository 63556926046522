<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationServicesUpdate"
  #investigationServicesUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesDelete"
  #investigationServicesDelete="appEnforcePermission"></div>
<!-- Permissions -->
<section class="p-16">
  <!-- Reusable card for each service -->
  <kendo-card class="mb-16" [width]="''" *ngFor="let service of staffServices">
    <kendo-card-body>
      <!-- Card header options -->
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <div class="kendo-formfield service-checkbox">
            <input type="checkbox" kendoCheckBox (change)="serviceCheckboxClicked($event.target, service)"
              color="primary" [checked]="mainServiceCompleted(service)" *ngIf="investigationServicesUpdate?.enabled" />
            <h2 class="ml-12" [innerHTML]="service?.MainService?.Type?.name | titlecase"></h2>
          </div>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right">
          <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash fa-lg"
            (click)="deleteService(service.MainService)" *ngIf="investigationServicesDelete?.enabled"></button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout class="align-items-center" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
        [cols]="['repeat(3,minmax(0,1fr))']">
        <!-- Assigned Users Dropdown -->
        <kendo-gridlayout-item>
          <kendo-formfield>
            <kendo-label text="Assigned to"></kendo-label>
            <kendo-dropdownlist style="width: 250px;" [data]="selectedStaff" name="assignedTo" #type="ngModel"
              [ngModel]="service.User.id" [valuePrimitive]="true" fillMode="outline" textField="userName"
              (valueChange)="onAssignedToChange($event, service)" valueField="userId"
              [popupSettings]="{appendTo: 'component', animate: false}">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span [ngClass]="dataItem?.userId ? 'not-placeholder': 'placeholder'"> {{ dataItem?.userName }} </span>
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
        </kendo-gridlayout-item>
        <!-- Authorized By -->
        <kendo-gridlayout-item *ngIf="service?.MainService?.authorizedBy">
          <p class="kendo-caption">
            Authorized by:
          </p>
          <p class="kendo-card-body">
            {{service?.MainService?.authorizedBy || '-'}}
          </p>
        </kendo-gridlayout-item>
        <!-- Note -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            Notes / Instructions:
          </p>
          <p class="kendo-card-body">
            {{service?.MainService?.notes || '-'}}
          </p>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
       <!-- Sub Services-->
      <kendo-gridlayout
        *ngIf="((service?.SubServices?.length && service?.SubServices[0].Type?.name !== service?.MainService?.Type?.name && service?.SubServices?.length === 1) || (service?.SubServices?.length > 1))"
        [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
         <!-- Individual service items -->
        <kendo-gridlayout-item *ngFor="let subService of service.SubServices">
          <kendo-card [width]="''">
            <kendo-card-body>
              <kendo-gridlayout class="align-items-center" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
                <kendo-gridlayout-item class="mb-0">
                  <div class="kendo-formfield service-checkbox">
                    <input type="checkbox" kendoCheckBox (change)="checkboxSelect($event.target, subService.id)"
                      [checked]="subService.isComplete" *ngIf="investigationServicesUpdate?.enabled" />
                    <h2 class="ml-12 mb-0" [innerHTML]="subService?.Type?.name"></h2>
                  </div>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item class="text-right mb-0">
                  <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash fa-lg"
                    (click)="onDeleteSubServiceClick(subService)" *ngIf="investigationServicesDelete?.enabled"></button>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-card-body>
  </kendo-card>
</section>
