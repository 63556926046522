<div [dialogRef]="dialog" (appClickOutside)="dialog.close(false)">
  <kendo-dialog-titlebar (close)="dialog.close(false)">
    <div class="modal-title">
      <span>o365 Authentication Required</span>
    </div>
  </kendo-dialog-titlebar>
  <section>
    <p>
      Launching this report will open the document directly in o365. When prompted, please enter the public token shown
      below to confirm your identity within this system.
    </p>
    <h5 class="mb-0">
      <i class="fa-solid fa-lock"></i>
      Your public token:
    </h5>
    <code [innerHTML]="user.msGraphToken"></code>
    <button kendoButton [size]="'medium'" [fillMode]="'link'" [themeColor]="'primary'"
      [iconClass]="'fa-regular fa-copy'" [cdkCopyToClipboard]="user.msGraphToken" class="mb-16">
        Copy to Clipboard
    </button>
    <!-- Hidden iFrame to pass info into Office o365 addin for cookie -->
    <iframe [src]="cookieUrl | domSafe: 'resourceUrl'" width="1" height="1" class="hidden-iframe"></iframe>
  </section>
  <kendo-dialog-actions layout="end">
    <button kendoButton [size]="'medium'" themeColor="primary" (click)="launchReport()"
      [iconClass]="'fa-regular fa-circle-check'" class="mr-16">
      Open Report
    </button>
  </kendo-dialog-actions>
</div>