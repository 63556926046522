import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { BranchService } from 'src/app/shared/services';
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { BranchOrderBy, BranchFilter } from 'src/app/shared/modules/graphql/enums/branch.enums';
import { IGqlBranchOptions, IGqlBranchFilter } from 'src/app/shared/modules/graphql/interfaces/branch.interfaces';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IApiBranch, IApiBranchConnection, IApiBranchFilterType, IApiBranchOrderBy, IApiCompany, IApiPageInfo, IApiSortOrder } from "src/app/shared/modules/graphql/types/types";
import { BranchDataSource } from 'src/app/shared/services/branch/branch.datasource';
import {
  companiesBranchesView,
  companiesBranchesDelete
} from "src/app/shared/helpers/auth-config/companies.config";
import { filter } from 'rxjs/operators';
import { SortDescriptor } from '@progress/kendo-data-query';
@UntilDestroy()
@Component({
  selector: 'app-company-branch-list',
  templateUrl: './company-branch-list.component.html',
  styleUrls: ['./company-branch-list.component.scss']
})
export class CompanyBranchListComponent implements OnInit {
  public authConfig = {
    companiesBranchesView,
    companiesBranchesDelete
  };

  @Input() company: IApiCompany;

  private _dataSource: BranchDataSource;
  public get dataSource(): BranchDataSource {
    return this._dataSource;
  }
  public set dataSource(val: BranchDataSource) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  public branches: IApiBranch[] = [];
  constructor(
    private route: ActivatedRoute,
    private branchService: BranchService,
    private loaderService: LoaderService,
    private notifications: NotificationsService
  ) { }

  public columnsToDisplay: string[] = ["name", "address", "phone", "contacts", 'action'];

  ngOnInit() {
    this._dataSource = new BranchDataSource(this.branchService);

    this.loaderService.attachObservable(this.dataSource.loading$);
    this._dataSource.listPage.orderBy = IApiBranchOrderBy.Name;
    this._dataSource.listPage.sortOrder = SortOrder.ASC;
    this._dataSource.listPage.limit = -1;
    // Init Table
    // This filter should always be applied so we only get the branches for this company
    this._dataSource.applyFilter(IApiBranchFilterType.Company, this.company.id);
    this.dataSource?.contents$.subscribe((res) => {
      this.branches = res;
    });
    this._dataSource.load();
  }

  public load(): Promise<IApiBranchConnection> {
    return this._dataSource.load();
  }

  deleteBranch(branch) {
    try {
      this.notifications.
        kendoConfirm("Are you sure you want to delete this branch?", "Delete Branch")
        .pipe(
          filter(response => !!response),
          this.loaderService.showPipe(
            this.branchService.remove(branch?.id)
          ),
          this.notifications.snackbarErrorPipe("You cannot delete this branch as it is associated with contacts or investigations.")
        ).subscribe(() => {
          this.load();
          this.notifications.notify("Branch deleted successfully!");
        });
    } catch (error) {
      console.log('error', error)
    }
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

}

