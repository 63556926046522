import { mergeMap, take } from 'rxjs/operators';
import { filter, tap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { AuthService, InvestigationReportService } from 'src/app/shared/services';
import { Component, OnInit, Inject, ElementRef, Input } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LoaderService } from "src/app/shared/modules/loader/loader.service";
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { IApiDocument, IApiDocumentSnapshot, IApiInvestigation, IApiInvestigationDetailCause, IApiInvestigationReportStatus, IApiInvestigationRoleNames, IApiUser } from "src/app/shared/modules/graphql/types/types";
import {
  investigationDocumentsUpdate,
  investigationReportsSnapshotsDownload,
} from "src/app/shared/helpers/auth-config/investigations.config";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { PermissionActions, PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { InvestigationReportStatus } from 'src/app/shared/services/investigation/investigation-report/investigation-report.service';

@UntilDestroy()
@Component({
  selector: 'app-investigation-documents-sidebar',
  templateUrl: './investigation-documents-sidebar.component.html',
  styleUrls: ['./investigation-documents-sidebar.component.scss']
})
export class InvestigationDocumentsSidebarComponent extends DialogContentBase implements OnInit {

  public authConfig = {
    investigationDocumentsUpdate,
    investigationReportsSnapshotsDownload,
  };

  public adminConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.INVESTIGATION_DOCUMENTS,
    appliedPermissions: {
      All: [PermissionActions.UPDATE],
      AllAssigned: [],
      Assigned: [],
      Own: []
    },
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  public comment: string = null;
  public reviewComment: string = null;

  public get currentSnapshot(): IApiDocumentSnapshot {
    return this.report.History.slice(-1).pop();
  }

  public get history(): IApiDocumentSnapshot[] {
    return [...this.report.History].reverse();
  }

  private _isAdmin: boolean;

  private get isAdmin() {
    return this._isAdmin;
  }
  private set isAdmin(val) {
    this._isAdmin = val;
  }

  report: IApiDocument = null;
  investigation: IApiInvestigation = null;

  public causes = IApiInvestigationDetailCause;
  public InvestigationReportStatus = InvestigationReportStatus;
  public IApiInvestigationReportStatus = IApiInvestigationReportStatus;
  private user: IApiUser;
  @Input() public data: { report: IApiDocument, investigation: IApiInvestigation }

  constructor(
    public reports: InvestigationReportService,
    private loader: LoaderService,
    public dialogRef: DialogRef,
    private auth: AuthService,
    private notifications: NotificationsService,
  ) {
    super(dialogRef);
    // Find logged in user
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // Find permissions
    this.auth.hasCategoryPermission(this.authConfig.investigationDocumentsUpdate.category, this.authConfig.investigationDocumentsUpdate.appliedPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((perm) => this._isAdmin = perm);
  }

  ngOnInit(): void {
    this.report = this.data.report;
    this.investigation = this.data.investigation;
  }

  public get updateStatusPerm(): boolean {
    // If user admin
    if (this.isAdmin && this.currentSnapshot.status !== IApiInvestigationReportStatus.Final) {
      return true;
    }
    // Else, see if user has permissions to change report status
    // Finding permissions here because we don't 'assign' reports to users
    else {
      const foundUser = this.investigation.InvestigationStaff.filter(obj => obj?.User?.id === this.user?.id);

      switch (this.currentSnapshot.status) {
        // Only the person who created the document can advance it from draft
        case IApiInvestigationReportStatus.Draft:
          return this.user?.id === this.report?.CreatedBy?.id;
        // Editors
        case IApiInvestigationReportStatus.ReadyForEditor:
        case IApiInvestigationReportStatus.EditorReview:
          return foundUser.some((obj) => obj?.Role.title === IApiInvestigationRoleNames.Editor);

        // Tech Reviewers
        case IApiInvestigationReportStatus.TechReview:
        case IApiInvestigationReportStatus.TechReview_2:
          return foundUser.some((obj) => obj?.Role.title === IApiInvestigationRoleNames.TechReviewer);

        // Default to returning false if user isn't admin & isn't on investigation
        default:
          return false;
      }
    }
  }

  public advanceReport(caption: string, reject = false, sendToFinal = false) {
    if (this.currentSnapshot.status === IApiInvestigationReportStatus.ClientReview || sendToFinal === true) {
      return this.notifications.kendoConfirm(caption).pipe(
        filter(r => r),
        this.loader.showPipe(
          this.reports.advance({
            documentId: this.report.id,
            comment: this.reviewComment,
            reject,
            sendToFinal
          })
        )
      );
    } else {
      return this.loader.show$(this.reports.advance({
        documentId: this.report.id,
        comment: this.reviewComment,
        reject
      }));
    }

  }

  public approve() {
    this.advanceReport("Really approve and advance this report?").pipe(
      this.notifications.snackbarErrorPipe(),
    ).subscribe(() => this.dialogRef.close(true));
  }

  public moveToFinal() {
    this.advanceReport("Really approve and advance this report?", false, true).pipe(
      this.notifications.snackbarErrorPipe(),
    ).subscribe(() => this.dialogRef.close(true));
  }

  public sendBack() {
    this.advanceReport("Really send this back? This revert report state to previous.", true).pipe(
      this.notifications.snackbarErrorPipe("Error sending report back.")
    ).subscribe(() => this.dialogRef.close(true));
  }

  public addComment(elem) {
    this.loader.show$(
      this.reports.addComment(this.report.id, elem.value)
    ).subscribe(comment => {
      this.report.Comments.unshift(comment);
      elem.value = "";
    });
  }

  public close(action = false) {
    this.dialog.close(action);
  }

}
