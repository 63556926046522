<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationServicesCreate"
  #investigationServicesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationServicesCreateOwn"
  #investigationServicesCreateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsCreate"
  #investigationRequestsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-gridlayout class="p-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
  <!-- Service/Select toggle-->
  <kendo-gridlayout-item>
    <kendo-label text=""></kendo-label>
    <div>
      <kendo-buttongroup selection="single">
        <button kendoButton [toggleable]="true" iconClass="fa-solid fa-check-circle"
          [selected]="servicesRequestsToggle === 'services'" (click)="servicesRequestsToggleFn('services')"
          title="Services">Services</button>
        <button kendoButton [toggleable]="true" iconClass="fa-solid fa-reply"
          [selected]="servicesRequestsToggle === 'requests'" (click)="servicesRequestsToggleFn('requests')"
          title="Requests">Requests</button>
      </kendo-buttongroup>
    </div>
  </kendo-gridlayout-item>
  <kendo-gridlayout-item>
    <!-- AssignedTo select -->
    <ng-container *ngIf="servicesRequestsToggle === 'services'">
      <kendo-formfield>
        <kendo-label text="Assigned to"></kendo-label>
        <kendo-dropdownlist style="width: 250px;" [data]="selectedStaff" name="assignedTo" #type="ngModel"
          [(ngModel)]="assignedToUser" [defaultItem]="{userId: null, userName: 'All'}" [valuePrimitive]="true" fillMode="outline" textField="userName"
          valueField="userId"
          [popupSettings]="{appendTo: 'component', animate: false}">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span> {{ dataItem?.userName }} </span>
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </ng-container>
  </kendo-gridlayout-item>
  <!-- content buttons -->
  <kendo-gridlayout-item class="text-right">
    <kendo-label text=""></kendo-label>
    <div>
      <ng-container [ngSwitch]="servicesRequestsToggle">
        <ng-container *ngSwitchCase="'services'">
          <button kendoButton themeColor="primary" fillMode="solid"
            *ngIf="investigationServicesCreate?.enabled || investigationServicesCreateOwn?.enabled"
            iconClass="fa-solid fa-plus" (click)="openAddService()" title="Requests"> Add Service</button>
        </ng-container>
        <ng-container *ngSwitchCase="'requests'">
          <button kendoButton *ngIf="investigationRequestsCreate?.enabled" themeColor="primary" fillMode="solid"
            iconClass="fa-solid fa-plus" (click)="openAddRequest()" title="Requests"> New Request</button>
        </ng-container>
      </ng-container>
    </div>
  </kendo-gridlayout-item>
</kendo-gridlayout>
<!-- Content -->
<section [ngSwitch]="servicesRequestsToggle">
  <app-investigation-requests *ngSwitchCase="'requests'" [(requests)]="requests"
    (addCommentEvent)="updateRequest($event)" (deleteItemEvent)="deleteRequest($event)"></app-investigation-requests>
  <app-investigation-services *ngSwitchCase="'services'" (reload)="reloadInvestigation()"
    [assignedToUser]="assignedToUser" [investigation]="investigation"></app-investigation-services>
</section>
