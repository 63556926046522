<section>
  <app-report-sent-metric-filters 
    (applyFilter)="applyFilter($event)"
    (clearFilter)="applyFilter($event)"
    (exportCsv)="exportCsv()"
  ></app-report-sent-metric-filters>
  <hr class="m-0" />
  <kendo-grid
    class="repor-sent-metric-by-editor td-border-left"
    [data]="{
      data: data,
      total: (dataSource?.pageInfo$ | async)?.totalCount
    }"
    [pageSize]="pageOptions?.limit"
    [skip]="pageOptions?.offset"
    [pageable]="
      pageOptions
        ? {
            buttonCount: 6,
            type: 'numeric',
            pageSizes: [5, 10, 25, 100],
            previousNext: true,
            responsive: true,
            position: 'bottom'
          }
        : false
    "
    (pageChange)="pageChange($event)"
    [sortable]="true"
    [sort]="sort"
    (sortChange)="sortChange($event)"
    [navigatable]="false"
    [resizable]="true"
    [scrollable]="true"
  >
    <kendo-grid-column
      field="SEND_DATE"
      title="Date"
      [width]="10"
      [sortable]="false"
      [resizable]="false"
      [headerClass]="'no-sort'"
    >
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex"
      >
        <span class="font-weight-600">
          {{ dataItem?.sendDate | customDate : "MM/dd/yyyy" }}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      field="COUNT"
      title="Count"
      [width]="10"
      [sortable]="false"
      [resizable]="false"
      [headerClass]="'no-sort'"
    >
      <ng-template kendoGridCellTemplate let-dataItem>
        <span class="phone-number">
          {{ dataItem?.count }}
        </span>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="OfReports"
        title="# of Reports"
        [width]="25"
        [sortable]="false" 
        [headerClass]="'no-cursor'"
    >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-main">
                <p class="cell-item" *ngFor="let item of dataItem?.ReportEditor; let index = index">
                  {{ item?.count }}
                </p>
            </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        field="Editor"
        title="Editor"
        [width]="25"
        [sortable]="false" 
        [headerClass]="'no-cursor'"
    >
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <div class="cell-main">
                <p class="cell-item" *ngFor="let item of dataItem?.ReportEditor; let index = index">
                  {{ item?.Editor | fullName }}
                </p>
            </div>
        </ng-template>
    </kendo-grid-column>
  </kendo-grid>
</section>
