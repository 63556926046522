import { Component, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IApiCertification, IApiCertificationFilter, IApiCertificationOrderBy, IApiComment } from 'src/app/shared/modules/graphql/types/types';
import { AuthService, CertificationService, StateService } from 'src/app/shared/services';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { CertificationSidebarComponent } from '../certification-sidebar/certification-sidebar.component';
import { ICertificationSidebarData } from '../certification-sidebar/certification-sidebar.component';
import { CertificationDataSource } from 'src/app/shared/services/certification/certification.datasource';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter } from 'rxjs/operators';
import { CertificationsFilterComponent } from '../certifications-filter/certifications-filter.component';
import { staffViewAll } from 'src/app/shared/helpers/auth-config/staff.config';
import { manageCertificationsUpdate, manageCertificationsDelete, manageCertificationsCreate } from "src/app/shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { forkJoin } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _, { result } from 'lodash';
import { CertificationModalComponent } from '../certification-modal/certification-modal.component';

@UntilDestroy()
@Component({
  selector: 'app-certifications-investigator-list',
  templateUrl: './certifications-investigator-list.component.html',
  styleUrls: ['./certifications-investigator-list.component.scss']
})
export class CertificationsInvestigatorListComponent implements OnInit {

  public authConfig = {
    staffViewAll,
    manageCertificationsUpdate,
    manageCertificationsDelete,
    manageCertificationsCreate
  };

  // Datasource for table
  private _dataSource: CertificationDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  @ViewChild("certificationFilter") certificationFilter: CertificationsFilterComponent;
  public sort: SortDescriptor[] = [
    {
      field: IApiCertificationOrderBy.User,
      dir: "asc",
    },
  ];
  public data: IApiCertification[];
  public actionItem: { text: string }[] = [];
  constructor(
    private certificationService: CertificationService,
    private loader: LoaderService,
    private notification: NotificationsService,
    private stateService: StateService,
    private authService: AuthService,
    private dialogService: DialogService,
  ) { }

  public filtersChange({ type, value }: IApiCertificationFilter) {
    // We need to flip the type from CertificationFilter to UserFilter
    value ? this.dataSource.applyFilter(type, value) : this.dataSource.removeFilter(type); // If no value, remove it, primarily for include restrictions filter
    this.load();
  }

  public getLatestComment(comments: IApiComment[]) {
    if (comments.length) return comments[0]?.text;
    else return "No comments available";
  }

  public viewCert(cert: IApiCertification) {
    const data: ICertificationSidebarData = {
      certification: null,
      config: {
        singleInvestigator: { ...cert.User, Certifications: [cert] }
      }
    };

    const dialog: DialogRef = this.dialogService.open({
      content: CertificationSidebarComponent,
      width: "50%",
      height: "100vh",
      cssClass: 'right-position certification-sidebar',
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as CertificationSidebarComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public load(tabChange = false) {
    this.dataSource.pagingReset();
    this.dataSource.load();
    if (tabChange) {
      this.certificationFilter.load();
    }
  }

  public deleteStaffCertification(id: string) {
    this.notification.kendoConfirm("Really remove this certification?", "Are you sure?").pipe(
      filter(response => !!response),
      this.loader.showPipe(
        this.certificationService.remove(id)
      ),
      this.notification.snackbarErrorPipe("Error removing Certification; please try again"),
      this.notification.snackbarPipe("Certification removed successfully!")
    ).subscribe(() => {
      this.load();
    }, () => {
      this.load();
    });
  }

  public ngOnInit() {
    // List of all positions we want to be included in this list, pulled from InvestigationRoles table
    const positionFilter = ["ADMIN", "INVESTIGATOR", "TECH_REVIEWER", "REGIONAL_MANAGER", "HVAC_EXPERT", "AUTO_EXPERT", "ELECTRICAL_ENGINEER", "EDITOR", "ADMINISTRATIVE_STAFF", "SALES", "MARKETING", "BILLING", "FIRE_PROTECTION_ENGINEER", "SALES_PERSON"];
    this.dataSource = new CertificationDataSource(this.certificationService);
    this.dataSource.listPage.sortOrder = SortOrder.ASC;
    this.dataSource.listPage.orderBy = IApiCertificationOrderBy.User;
    // this.dataSource.applyFilter(IApiCertificationFilterType.UserPosition, JSON.stringify(positionFilter));
    this.loader.attachObservable(this.dataSource.loading$);

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageCertificationsUpdate.category, this.authConfig.manageCertificationsUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageCertificationsDelete.category, this.authConfig.manageCertificationsDelete.appliedPermissions)
    ]).subscribe(([up, del]) => {
      if (up) {
        this.actionItem.push({text: 'View'});
      }
      if (del) {
        this.actionItem.push({text: 'Delete'});
      }
    });

    this.load();

    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
    });
  }

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode)?.text || '-';
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiCertificationOrderBy.User;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  public itemSelected(action: { text: string }, dataItem: IApiCertification) {
    switch (action.text) {
      case "View":
        this.viewCert(dataItem);
        break;
      case "Delete":
        this.deleteStaffCertification(dataItem?.id);
        break;
      default:
        break;
    }
  }

  public addEdit(data: any) {
    const dialog: DialogRef = this.dialogService.open({
      content: CertificationModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as CertificationModalComponent;
    dialogInstance.data = data;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

}
