<section>
    <div class="page-header">
        <h1 class="mb-0">Investigation Report Timelog</h1>
    </div>
</section>
<kendo-card width="100%">
    <section class="content-wrapper">
        <app-investigation-metrics-filters
            (applyFilter)="applyFilter($event)"
            (clearFilter)="applyFilter($event)"
            [investigationFilter]="true"
            [editorFilter]="true"
            [persistFilters]="false"
        ></app-investigation-metrics-filters>
        <hr/>
        <div class="total-record">Total Records: {{(dataSource?.pageInfo$ | async)?.totalCount}}</div>
        <kendo-grid class="report-list odd-even-background td-p-0 td-border-left footer-border-none"
            [data]="{
                data: tableData,
                total: (dataSource?.pageInfo$ | async)?.totalCount
            }" 
            [pageSize]="pageOptions?.limit" 
            [skip]="pageOptions?.offset" 
            [pageable]="
            pageOptions
            ? {
                buttonCount: 6,
                type: 'numeric',
                pageSizes: [5, 10, 25, 100],
                previousNext: true,
                responsive: true,
                position: 'bottom'
                }
            : false
            "
            (pageChange)="pageChange($event)" 
            [sortable]="false"
            [navigatable]="false" 
            [resizable]="true" 
            [scrollable]="true">
            
            <kendo-grid-column 
                field="Editor" 
                class="id-column" 
                title="Editor" 
                [width]="30" 
                [sortable]="false" 
                [resizable]="false" 
                [headerClass]="'no-cursor'"
            >
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <div class="cell-item-first" *ngIf="rowIndex === 0">
                </div>
                    <div class="kendo-span">
                    {{ dataItem?.lastName ? dataItem?.lastName + " " : "" }}
                    {{ dataItem?.firstName || "" }}
                </div>
            </ng-template>
            </kendo-grid-column>

            <!-- ID -->
            <kendo-grid-column
                field="NEFCO_NUMBER"
                title="ID#"
                [width]="25"
                class="id-column"
                [sortable]="false" 
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item-first" *ngIf="rowIndex === 0">&nbsp;</span>
                        <p
                            class="cell-item"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <a
                                target="_blank"
                                [routerLink]="['/investigations/', item?.investigationId]"
                                tel=""
                                class="text-decoration cell-item p-0"
                            >
                            {{ item?.nefcoNumber || "Unassigned" }}
                            </a>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>


            <!-- Received Date -->
            <kendo-grid-column
                field="RECEIVED_DATE"
                title="Received Date"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                    <span class="cell-item-first" *ngIf="rowIndex === 0">
                        <span>&nbsp;</span>
                        <span class="phone-number">&nbsp;</span>
                    </span>
                    <p
                        class="cell-item"
                        *ngFor="
                        let item of dataItem?.data;
                        let index = index
                        "
                    >
                        <span>{{ item?.receivedDate | customDate : "MM/dd/yyyy" }}<br /></span>
                        <span class="phone-number">
                            {{ item?.receivedDate | customDate : "hh:mm a" }}
                        </span>
                    </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Investigator -->
            <kendo-grid-column
                field="INVESTIGATOR"
                title="Investigator"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item-first" *ngIf="rowIndex === 0">&nbsp;</span>
                        <p
                            class="cell-item"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span class="text-overflow">{{ item?.investigator || '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Tech Reviewer -->
            <kendo-grid-column
                field="TECH_REVIEWER"
                title="Tech Reviewer"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item-first" *ngIf="rowIndex === 0">&nbsp;</span>
                        <p
                            class="cell-item"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span class="text-overflow">{{ item?.techReviewer || '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Document Label -->
            <kendo-grid-column
                field="DOCUMENT_LABEL"
                title="Document Label"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
                class="text-right"
            >
                
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item header-text" *ngIf="rowIndex === 0">Average</span>
                        <p
                            class="cell-item"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span class="text-overflow">{{ item?.documentLabel || '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- On Scene to Reports Uploaded -->
            <kendo-grid-column
                field="REPORT_UPLOADED"
                title="On Scene to Reports Uploaded"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor white-space-normal'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item header-text" *ngIf="rowIndex === 0">{{reportTotal?.onSceneToReportsUploaded ? (reportTotal?.onSceneToReportsUploaded | number : '1.2-2') : '-'}}</span>
                        <p
                            class="cell-item"
                            style="text-align: end;"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span>{{ item?.onSceneToReportsUploaded ? (item?.onSceneToReportsUploaded | number : '1.2-2') : '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Report Submission to Approval -->
            <kendo-grid-column
                field="REPORT_SUBMISSION_APPROVAL"
                title="Report Submission to Approval"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item header-text" *ngIf="rowIndex === 0">-</span>
                        <p
                            class="cell-item"
                            style="text-align: end;"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span>{{ item?.reportSubmissionToApproval ? (item?.reportSubmissionToApproval | number : '1.2-2') : '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- Report Approval to Report Sent -->
            <kendo-grid-column
                field="REPORT_APPROVAL_SENT"
                title="Report Approval to Report Sent"
                [width]="20"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item header-text" *ngIf="rowIndex === 0">-</span>
                        <p
                            class="cell-item"
                            style="text-align: end;"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span>{{ item?.reportApprovalToReportSent ? (item?.reportApprovalToReportSent | number : '1.2-2') : '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>

            <!-- On Scene to Report Sent -->
            <kendo-grid-column
                field="SCENE_REPORT_SENT"
                title="On Scene to Report Sent"
                [width]="15"
                [sortable]="false"
                [headerClass]="'no-cursor'"
            >
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div class="cell-main">
                        <span class="cell-item header-text" *ngIf="rowIndex === 0">
                            {{reportTotal?.onSceneToReportSent ? (reportTotal?.onSceneToReportSent | number : '1.2-2') : '-'}}</span>
                        <p
                            class="cell-item"
                            style="text-align: end;"
                            *ngFor="
                            let item of dataItem?.data;
                            let index = index
                            "
                        >
                            <span>{{ item?.onSceneToReportSent ? (item?.onSceneToReportSent | number : '1.2-2') : '-'}}</span>
                        </p>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <ng-template kendoGridNoRecordsTemplate>
                <p class="mt-24 mb-24">There are no results to display.</p>
            </ng-template>
        </kendo-grid>
    </section>
</kendo-card>