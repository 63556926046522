import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiAddInvestigationHistoryInput, IApiInvestigationStaff, IApiServiceFilterType } from './../../../../shared/modules/graphql/types/types';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { filter, switchMap, take } from 'rxjs/operators';
import { MatDialog } from "@angular/material/dialog";
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { ServiceService, SubServiceService } from 'src/app/shared/services/service';
import { IApiUser, IApiService, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiInvestigation, IApiSubService } from 'src/app/shared/modules/graphql/types/types';
import { AuthService, InvestigationService } from 'src/app/shared/services';
import { forkJoin } from 'rxjs';

import { investigationServicesUpdate, investigationServicesDelete } from "src/app/shared/helpers/auth-config/investigations.config";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';

interface IStaffService {
  MainService: IApiService;
  SubServices: IApiSubService[];
  User: IApiUser;
}

@UntilDestroy()
@Component({
  selector: 'app-investigation-services',
  templateUrl: './investigation-services.component.html',
  styleUrls: ['./investigation-services.component.scss']
})
export class InvestigationServicesComponent implements OnInit {

  public authConfig = {
    investigationServicesUpdate,
    investigationServicesDelete
  };

  public staffServices: IStaffService[] = [];

  private _investigation: IApiInvestigation;
  @Input() public set investigation(val: IApiInvestigation) {
    this._investigation = val;
    this.generateServices();
  }
  public get investigation() {
    return this._investigation;
  }

  @Output() reload = new EventEmitter<boolean>();

  /**
   * The User selected in the AssignedToFilter Dropdown
   */
  private _assignedToUser: string;
  @Input() public set assignedToUser(val: string) {
    this._assignedToUser = val;
    this.generateServices();
  }
  public get assignedToUser() {
    return this._assignedToUser;
  }

  // Permissions
  private _isAdmin: boolean;

  private get isAdmin() {
    return this._isAdmin;
  }
  private set isAdmin(val) {
    this._isAdmin = val;
  }

  private user: IApiUser;
  public selectedStaff = [];

  constructor(
    private auth: AuthService,
    private serviceService: ServiceService,
    private subServiceService: SubServiceService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private investigationService: InvestigationService,
    private dialogService: DialogService,
    private notifications: NotificationsService) {

    // Find logged in user
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

    // Find permissions
    this.auth.hasCategoryPermission(this.authConfig.investigationServicesDelete.category, this.authConfig.investigationServicesDelete.appliedPermissions)
      .pipe(untilDestroyed(this))
      .subscribe((perm) => this._isAdmin = perm);

  }

  ngOnInit(): void {
    this.selectedStaff = this.staffSort(this.investigation?.InvestigationStaff);
  }

  /**
   * Handles sub-service checkboxes selection.
   */
  public checkboxSelect({ checked }, id: string) {

    this.loader.show$(
      this.subServiceService.update({
        id,
        isComplete: checked
      })
    ).pipe(
      this.notifications.snackbarErrorPipe("Error updating SubService; please try again.")
    ).subscribe((results) => {
      this.reload.emit(true);
      this.notifications.notify("SubService updated.");
    });

  }

  // Delete Service
  public deleteService(mainService) {
    const { id } = mainService;
    const usersEffected = this.investigation.Services.find((i) => i.id === id)?.AssignedUsers.length;
    this.notifications.kendoConfirm(`Are you sure you want to delete this service and its sub-services? This will remove the service for ${usersEffected} user${usersEffected > 1 ? "s" : ""}`, `Remove ${mainService?.Type?.name || ''} service?`).pipe(
      filter(result => !!result),
      switchMap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationHistoryKendoModalComponent,
          width: "40%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
        dialogInstance.data = {
          // shallow copy so updates don't mutate locally
          investigation: { ...this.investigation },
          selectedCategory: IApiInvestigationUpdateCategories.Services,
          selectedType: IApiInvestigationUpdateTypes.Delete,
          noteOnly: true
        };
        return dialog.result.pipe(
          filter((v) => !!v),
          // tap(v => (this.investigation as any).History = v),
          switchMap((v: IApiAddInvestigationHistoryInput) => this.investigationService.update({ id: this.investigation.id, History: { ...v, comment: `Service ${mainService?.Type?.name || ''} is deleted. Reason: ${v.comment?.trim() || ''}` } }))
        );
      }),
      switchMap(() => this.serviceService.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting Service"),
      this.notifications.snackbarPipe("Service deleted successfully.")
    ).subscribe((result) => { this.reload.emit(true); });
  }


  /**
   * Remove SubService Modal
   */
  public onDeleteSubServiceClick(subService) {
    const { id } = subService;
    this.notifications.kendoConfirm('Are you sure you want to delete this subservice?', `Remove ${subService?.Type?.name || ''} sub service?`).pipe(
      filter(result => !!result),
      switchMap(() => {
        const dialog: DialogRef = this.dialogService.open({
          content: InvestigationHistoryKendoModalComponent,
          width: "40%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
        dialogInstance.data = {
          // shallow copy so updates don't mutate locally
          investigation: { ...this.investigation },
          selectedCategory: IApiInvestigationUpdateCategories.Services,
          selectedType: IApiInvestigationUpdateTypes.Delete,
          noteOnly: true
        };

        return dialog.result.pipe(
          filter((v) => !!v),
          switchMap((v: IApiAddInvestigationHistoryInput) => this.investigationService.update({ id: this.investigation.id, History: { ...v, comment: `Sub Service ${subService?.Type?.name || ''} is deleted. Reason: ${v.comment?.trim() || ''}` } }))
        );
      }),
      switchMap(() => this.subServiceService.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting SubService"),
      this.notifications.snackbarPipe("SubService deleted successfully.")
    ).subscribe((result) => { this.reload.emit(true); });
  }

  public onAssignedToChange(value, service: IStaffService) {
    // change who is assigned to this main service
    const userIds = this.investigation.Services.find((s) => s.id === service.MainService.id).AssignedUsers.map((u) => u.id).filter((i) => i !== service.User.id);
    // update main services and subservices with selected user
    this.loader.show$(forkJoin([
      this.serviceService.update({ id: service.MainService.id, AssignedUserIds: [...userIds, value] }),
      ...service.SubServices.map((s) => this.subServiceService.update({ id: s.id, AssignedUserId: value }))
    ])
    ).subscribe(() => this.reload.emit(true));
  }

  public serviceCheckboxClicked({ checked }, serviceObj: IStaffService) {
    // set all subservices as complete
    this.loader.show$(forkJoin(
      serviceObj.SubServices.map((s) => this.subServiceService.update({ id: s.id, isComplete: checked }))
    )).pipe(
      this.notifications.snackbarErrorPipe("Error updating Service; please try again.")
    ).subscribe(() => {
      this.reload.emit(true);
      this.notifications.notify("Service updated.");
    });
  }

  private getServices(): Promise<IApiService[]> {
    const filters = [
      { type: IApiServiceFilterType.Investigation, value: this.investigation.id }
    ];
    if (this.assignedToUser) filters.push({ type: IApiServiceFilterType.AssignedUser, value: this.assignedToUser });

    return this.serviceService.get(filters).pipe(
      unwrapConnection()
    ).toPromise();
  }

  private async generateServices(): Promise<IStaffService[]> {
    if (!this.investigation?.id) return;

    const services = await this.getServices();

    // Group services together by user and Service with SubService children
    const staff: IStaffService[] = services.map((service) => {
      return {
        User: service?.AssignedUsers[0],
        MainService: service,
        SubServices: service?.SubServices
      };
    });

    this.staffServices = staff;
  }

  public mainServiceCompleted(service: IStaffService) {
    return service.SubServices.find((s) => !s.isComplete) ? false : true;
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

}
