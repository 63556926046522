<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationRequestsUpdate"
  #investigationRequestsUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationRequestsDelete"
  #investigationRequestsDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationDocumentsCreate"
  #investigationDocumentsCreate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="p-16">
  <!-- Reusable card for each service -->
  <kendo-card class="mb-16" [width]="''" *ngFor="let request of requests">
    <kendo-card-body>
      <!-- Card header options -->
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item>
          <h3 [innerHTML]="request?.RequestType?.name"></h3>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="text-right">
          <button kendoButton themeColor="secondary" fillMode="link" iconClass="fa-solid fa-pen fa-lg"
            *ngIf="investigationRequestsUpdate?.enabled" (click)="openUpdateRequest(request)">
          </button>
          <button kendoButton themeColor="error" class="ml-12" fillMode="link" iconClass="fa-solid fa-trash fa-lg"
            (click)="removeRequest(request.id, {id: request?.Investigation?.id})"
            *ngIf="investigationRequestsDelete?.enabled"></button>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <!-- Request Data -->
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(5,minmax(0,1fr))']">
        <!-- createdAt -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            Submitted:
          </p>
          <p class="kendo-caption-body" [innerHTML]="request?.createdAt | customDate: 'MM/dd/yyyy, h:mm a'">
          </p>
        </kendo-gridlayout-item>
        <!-- CreatedBy -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            Submitted by:
          </p>
          <p class="kendo-caption-body" [innerHTML]="request?.CreatedBy | fullName">
          </p>
        </kendo-gridlayout-item>
        <!-- AuthorizedBy -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            Authorized by:
          </p>
          <p class="kendo-caption-body" [innerHTML]="request?.authorizedBy">
          </p>
        </kendo-gridlayout-item>
        <!-- Description -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            Description:
          </p>
          <p class="kendo-caption-body" [innerHTML]="request?.notes">
          </p>
        </kendo-gridlayout-item>
        <!-- Status -->
        <kendo-gridlayout-item>
          <p class="kendo-caption">
            STATUS
          </p>
          <p class="kendo-caption-body" [innerHTML]="request?.Status">
          </p>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <!-- Request History -->
      <kendo-grid [data]="request?.StatusHistory" [pageable]="false" [scrollable]="'none'" class="mt-12">
        <!-- Updated (actually createdAt) -->
        <kendo-grid-column title="Updated" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p [innerHTML]="dataItem.createdAt | customDate: 'MM/dd/yyyy, h:mm a'"></p>
          </ng-template>
        </kendo-grid-column>
        <!-- UpdatedBy (actually CreatedBy) -->
        <kendo-grid-column title="Updated By" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p [innerHTML]="dataItem.CreatedBy | fullName"></p>
          </ng-template>
        </kendo-grid-column>
        <!-- Comment -->
        <kendo-grid-column title="Comment" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p [innerHTML]="dataItem?.comment || '-'"></p>
          </ng-template>
        </kendo-grid-column>
        <!-- Status -->
        <kendo-grid-column title="Status" [width]="50" [sortable]="false" [headerClass]="'nocursor'">
          <ng-template kendoGridCellTemplate let-dataItem>
            <p [innerHTML]="dataItem?.name || '-'"></p>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
      <!-- Add Comment Button-->
      <button kendoButton class="mt-12" themeColor="secondary" fillMode="link" iconClass="fa-solid fa-message fa-lg"
        (click)="openUpdateRequest(request)" *ngIf="investigationDocumentsCreate?.enabled"></button>
    </kendo-card-body>
  </kendo-card>
</section>
