import { AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { mergeMap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  selector: 'app-mobile-login',
  templateUrl: './mobile-login.component.html',
  styleUrls: ['./mobile-login.component.scss']
})
export class MobileLoginComponent implements OnInit {
  public loggedIn = false;
  private loginChecked = false;
  private redirected = false;
  private platform = Capacitor.getPlatform();
  @ViewChild("loginBtn", { static: false }) loginBtn: ElementRef;
  constructor(
    public loader: LoaderService,
    private ngZone: NgZone,
    private auth: AuthService,
    private router: Router
  ) {
    this.loader.displayText = 'Loading...';
    this.loader.displaySubText = 'This will just take a moment.';
  }

  ngOnInit(): void {
    console.log("LOGIN NG ON INIT");
    // Use Capacitor's App plugin to subscribe to the `appUrlOpen` event
    App.addListener("appUrlOpen", ({ url }) => {
      this.loader.showOther();
      console.log("app url open::", url);
      // Must run inside an NgZone for Angular to pick up the changes
      // https://capacitorjs.com/docs/guides/angular
      this.ngZone.run(async () => {
        if (url?.startsWith(`capacitor`) || url?.startsWith("com.nefco.net")) {
          console.log(url);
          // If the URL is an authentication callback URL..
          if (
            url.includes("state=") &&
            (url.includes("error=") || url.includes("code="))
          ) {
            // Call handleRedirectCallback and close the browser
            this.auth
              .handleRedirectCallback(url)
              .pipe(mergeMap(() => Browser.close()))
              .subscribe(() => {
                this.loader.hideOther();
                if (!this.redirected) {
                  this.redirected = true
                  this.router.navigate(['admin/investigation-list']);
                }
              }, error => {
                console.log("HANDLE REDIRECT ERROR", error);
              });
          } else {
            if (this.platform == 'ios') {
              await Browser.close();
              this.router.navigate(['login'])
            } else {
              await Browser.addListener(
                'browserFinished',
                async () => await Browser.close()
              )
              setTimeout(() => {
                this.router.navigate(['login'])
              }, 500)
            }
            this.loader.hideOther();
          }
        } else {
          this.loader.hideOther();
        }
      });
    });
    this.checkLogin();
  }

  login = () => {
    try {
      this.auth
        .loginWithRedirect({
          async openUrl(url: string) {
            console.log("login :::", url);
            try {
              await Browser.open({ url });
            } catch (e) {
              console.log("ERROR OPENING BROWSER", e)
            }
          },
        })
        .subscribe();
    } catch (e) {
      console.log("LOGIN ERROR ++", e);
    }
  }

  checkLogin() {
    this.auth.isAuthenticated$.subscribe((loggedIn) => {
      this.loggedIn = loggedIn;
      if (!loggedIn && !this.loginChecked) {
        this.login();
        this.loginChecked = true;
      } else if (loggedIn && !this.redirected) {
        this.redirected = true
        this.router.navigate(['admin/investigation-list']);
      }
    });
  }

}
