
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IGqlBaseOptions } from 'src/app/shared/modules/graphql/interfaces';
import { ListPage } from 'src/app/shared/helpers/list-page.class';
import { IApiDocumentSentFilter, IApiQueryReportSentExportConnectionArgs, IApiReportSentConnection } from 'src/app/shared/modules/graphql/types/types';
import { GqlReportSentMetricService } from 'src/app/shared/modules/graphql/services/reports/report-sent-metric/report-sent-metric.service';

@Injectable()
export class ReportSentMetricService {
  constructor(
    private gqlReportSentMetricService: GqlReportSentMetricService
  ) { }

  public get(filters?: IApiDocumentSentFilter[], options?: IGqlBaseOptions): Observable<IApiReportSentConnection> {
    return this.gqlReportSentMetricService.get(filters, options || new ListPage());
  }

  public getCsvReport(input: IApiQueryReportSentExportConnectionArgs): Observable<string> {
    return this.gqlReportSentMetricService.getCsvReport(input);
  }

}
