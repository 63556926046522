import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ExpensePurposeService } from '../../../../shared/services/expenses/expense-purpose/expense-purpose.service';
import { ExpenseCategoryService } from 'src/app/shared/services';
import { IApiAddExpenseCategoryInput, IApiExpenseCategory, IApiExpensePurpose, IApiUpdateExpenseCategoryInput } from "src/app/shared/modules/graphql/types/types";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';

@Component({
  templateUrl: './expense-category-modal.component.html',
  styleUrls: ['./expense-category-modal.component.scss']
})
export class ExpenseCategoryModalComponent extends DialogContentBase implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;

  public isSubmitted = false;
  public expensePurposes: IApiExpensePurpose[] = [];

  public model: IApiUpdateExpenseCategoryInput = {
    id: "",
    name: "",
    details: "",
    ExpensePurposeIds: []
  };
  public data: IApiExpenseCategory | null;

  constructor(
    private expenseCategoryService: ExpenseCategoryService,
    private expensePurposeService: ExpensePurposeService,
    private notifications: NotificationsService,
    private loaderService: LoaderService,
    public dialog: DialogRef
  ) {
    super(dialog);
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.loaderService.show$(this.expensePurposeService.get().pipe(
      unwrapConnection()
    )).subscribe((results) => {
      this.expensePurposes = results
      const {
        id = "",
        name = "",
        details = "",
        ExpensePurposes = []
      } = this.data || {};
  
      this.model = {
        id,
        name,
        details,
        ExpensePurposeIds: ExpensePurposes.map(({ id: _id }) => _id)
      };
    });
  }

 private save() {
    this.expenseCategoryService.update({
      ...this.model,
      id: this.data.id,
      name: this.model.name?.trim(),
      details: this.model?.details?.trim()
    }).pipe(
      this.notifications.snackbarErrorPipe("Error updating Expense Category"),
      this.notifications.snackbarPipe("Category updated.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  private add() {
    const createData = { ...this.model };
    delete createData.id;
    this.expenseCategoryService.add(createData as IApiAddExpenseCategoryInput).pipe(
      this.notifications.snackbarErrorPipe("Error adding Expense Category"),
      this.notifications.snackbarPipe("Category created.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
}
