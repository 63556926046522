<!-- Permissions -->

<!-- START MANAGE -->
<div [appEnforcePermission]="authConfig.manage" #manage="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseReportCategoriesList" #manageExpenseReportCategoriesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpenseItemsList" #manageExpenseItemsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageExpensePurposesList" #manageExpensePurposesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageTimeEntryTypesList" #manageTimeEntryTypesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCarriersList" #manageCarriersList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageCertificationsList" #manageCertificationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageDocumentTypes" #manageDocumentTypes="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageEvidenceLocationsList" #manageEvidenceLocationsList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageUserRolesList" #manageUserRolesList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.manageDocumentTemplatingView" #manageDocumentTemplatingView="appEnforcePermission"></div>
<!-- END MANAGE -->

<!-- START BOOKKEEPING -->
<div [appEnforcePermission]="authConfig.bookKeepingEvidenceBilling" #bookKeepingEvidenceBilling="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListHours" #bookKeepingTimeExpensesEntryListHours="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesEntryListExpensesMileage" #bookKeepingTimeExpensesEntryListExpensesMileage="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesHoursAccountSummary" #bookKeepingTimeExpensesHoursAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingTimeExpensesExpensesMileageAccountSummary" #bookKeepingTimeExpensesExpensesMileageAccountSummary="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingInvoiceList" #bookKeepingInvoiceList="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingPayroll" #bookKeepingPayroll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.bookKeepingExpenseReports" #bookKeepingExpenseReports="appEnforcePermission"></div>
<!-- END BOOKKEEPING -->

<!-- START INVESTIGATION -->
<div [appEnforcePermission]="authConfig.investigation" #investigation="appEnforcePermission"></div>
<!-- END INVESTIGATION -->

<!-- START REPORTS -->
<div [appEnforcePermission]="authConfig.companyInvestigationAndEvidenceReport" #companyInvestigationAndEvidenceReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.companyInvestigationReport" #companyInvestigationReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationTimelogPacing" #investigationTimelogPacing="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationReportTimelog" #investigationReportTimelog="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.quarterlyUtilizationReport" #quarterlyUtilizationReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.quarterlyHourReport" #quarterlyHourReport="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.reportSentMetric" #reportSentMetric="appEnforcePermission"></div>

<!-- START REPORT METRICS-->
<div [appEnforcePermission]="authConfig.investigationMetricBasicCharts" #investigationMetricBasicCharts="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricInvestigator" #investigationMetricInvestigator="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricState" #investigationMetricState="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricBillToCompany" #investigationMetricBillToCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricClientCompany" #investigationMetricClientCompany="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricTechReviewer" #investigationMetricTechReviewer="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricContact" #investigationMetricContact="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricRisktype" #investigationMetricRisktype="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.investigationMetricDetermination" #investigationMetricDetermination="appEnforcePermission"></div>
<!-- END REPORT METRICS-->
<!-- END REPORTS -->

<!-- START EVIDENCE-->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<!-- END EVIDENCE-->

<!-- START CONTACTS-->
<div [appEnforcePermission]="authConfig.companies" #companies="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.contacts" #contacts="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staff" #staff="appEnforcePermission"></div>
<!-- END CONTACTS-->

<!-- Permissions -->
<ul *ngIf="user && !portal">
  <li><a [routerLink]="['/']">DASHBOARD</a></li>
  <li *ngIf="investigation?.enabled
  || investigationMetricBasicCharts?.enabled
  || investigationMetricInvestigator?.enabled
  || investigationMetricState?.enabled
  || investigationMetricBillToCompany?.enabled
  || investigationMetricClientCompany?.enabled
  || investigationMetricTechReviewer?.enabled
  || investigationMetricContact?.enabled
  || investigationMetricRisktype?.enabled
  || investigationMetricDetermination?.enabled
  || companyInvestigationAndEvidenceReport?.enabled
  || companyInvestigationReport?.enabled
  || investigationReportTimelog?.enabled
  || investigationTimelogPacing?.enabled
  || quarterlyUtilizationReport?.enabled
  || quarterlyHourReport?.enabled
  || reportSentMetric?.enabled"
  class="menu" [class.open]="isMenuOpen.investigations" (click)="toggleMenu('investigations')">
    <a class="menuBtn" [routerLink]="">INVESTIGATIONS <i class="fa fa-caret-down fa-lg"></i></a>
    <ul class="menu-content">
      <li *ngIf="investigation?.enabled"><a [routerLink]="['/admin/investigations/list']">Investigation List</a></li>
      <li *ngIf="investigationMetricBasicCharts?.enabled
      || investigationMetricInvestigator?.enabled
      || investigationMetricState?.enabled
      || investigationMetricBillToCompany?.enabled
      || investigationMetricClientCompany?.enabled
      || investigationMetricTechReviewer?.enabled
      || investigationMetricContact?.enabled
      || investigationMetricRisktype?.enabled
      || investigationMetricDetermination?.enabled
      || companyInvestigationAndEvidenceReport?.enabled
      || companyInvestigationReport?.enabled
      || investigationReportTimelog?.enabled
      || investigationTimelogPacing?.enabled
      || quarterlyUtilizationReport?.enabled
      || quarterlyHourReport?.enabled
      || reportSentMetric?.enabled" class="sub-menu" >
        <a class="subMenuBtn" [routerLink]="">Metrics <i class="fa fa-caret-down fa-lg"></i></a>
        <ul class="sub-menu-content">
          <li *ngIf="companyInvestigationAndEvidenceReport?.enabled"><a routerLink="/report/evidence">Company & Evidence Report</a></li>
          <li *ngIf="companyInvestigationReport?.enabled"><a routerLink="/report/investigations">Company Investigation Report</a></li>
          <li *ngIf="investigationMetricBasicCharts?.enabled
          || investigationMetricInvestigator?.enabled
          || investigationMetricState?.enabled
          || investigationMetricBillToCompany?.enabled
          || investigationMetricClientCompany?.enabled
          || investigationMetricTechReviewer?.enabled
          || investigationMetricContact?.enabled
          || investigationMetricRisktype?.enabled
          || investigationMetricDetermination?.enabled">
            <a routerLink="/report/investigations-metrics">Investigation Metrics</a>
          </li>
          <li *ngIf="investigationReportTimelog?.enabled"><a routerLink="/report/investigations-report-timelog">Investigation Report Timelog</a></li>
          <li *ngIf="investigationTimelogPacing?.enabled"><a routerLink="/report/report-timelog">Report Timelog</a></li>
          <li *ngIf="quarterlyUtilizationReport?.enabled"><a routerLink="/report/quarterly-utilization-report">Quarterly Utilization Report</a></li>
          <li *ngIf="quarterlyHourReport?.enabled"><a routerLink="/report/quarterly-hour-report">Quarterly Hour Report</a></li>
          <li *ngIf="reportSentMetric?.enabled"><a routerLink="/report/report-sent-metric">Report Sent Metric</a></li>
        </ul>
      </li>
    </ul>
  </li>
  <li *ngIf="evidence?.enabled">
    <a [routerLink]="['/evidence/list']">EVIDENCE</a>
  </li>
  <li *ngIf="bookKeepingEvidenceBilling?.enabled
  || bookKeepingTimeExpensesEntryListHours?.enabled
  || bookKeepingTimeExpensesEntryListExpensesMileage?.enabled
  || bookKeepingTimeExpensesHoursAccountSummary?.enabled
  || bookKeepingTimeExpensesExpensesMileageAccountSummary?.enabled
  || bookKeepingInvoiceList?.enabled
  || bookKeepingPayroll?.enabled
  || bookKeepingExpenseReports?.enabled"
  class="menu" [class.open]="isMenuOpen.bookkeeping" (click)="toggleMenu('bookkeeping')">
    <a class="menuBtn" [routerLink]="">BOOKKEEPING <i class="fa fa-caret-down fa-lg"></i></a>
    <ul class="menu-content">
      <li *ngIf="bookKeepingEvidenceBilling?.enabled"><a [routerLink]="['/admin/bookkeeping/evidence/billing']">Evidence Billing</a></li>
      <li *ngIf="bookKeepingTimeExpensesEntryListHours?.enabled
      || bookKeepingTimeExpensesEntryListExpensesMileage?.enabled
      || bookKeepingTimeExpensesHoursAccountSummary?.enabled
      || bookKeepingTimeExpensesExpensesMileageAccountSummary?.enabled"><a [routerLink]="['/admin/bookkeeping/time']">Time &amp; Expenses</a></li>
      <li *ngIf="bookKeepingInvoiceList?.enabled"><a [routerLink]="['/admin/bookkeeping/invoice/list']">Invoice List</a></li>
      <li *ngIf="bookKeepingPayroll?.enabled"><a [routerLink]="['/admin/bookkeeping/payroll']">Payroll</a></li>
      <li *ngIf="bookKeepingExpenseReports?.enabled"><a [routerLink]="['/admin/bookkeeping/expenses']">Expense Reports</a></li>
    </ul>
  </li>
  <li *ngIf="companies?.enabled || contacts?.enabled || staff?.enabled"
  class="menu" [class.open]="isMenuOpen.contacts" (click)="toggleMenu('contacts')">
    <a class="menuBtn" [routerLink]="">CONTACTS <i class="fa fa-caret-down fa-lg"></i></a>
    <ul class="menu-content">
      <li *ngIf="companies?.enabled"><a routerLink="/admin/companies/list">Companies</a></li>
      <li *ngIf="contacts?.enabled"><a routerLink="/admin/contacts/list">Contacts</a></li>
      <li *ngIf="staff?.enabled"><a routerLink="/admin/staff/list">Staff</a></li>
    </ul>
  </li>
  <li *ngIf="manage?.enabled" class="menu" [class.open]="isMenuOpen.manage" (click)="toggleMenu('manage')">
    <a class="menuBtn" [routerLink]="">MANAGE <i class="fa fa-caret-down fa-lg"></i></a>
    <ul class="menu-content">
      <li *ngIf="manageExpenseReportCategoriesList?.enabled || manageExpenseItemsList?.enabled || manageExpensePurposesList?.enabled  || manageTimeEntryTypesList?.enabled"><a routerLink="/admin/settings">Settings</a></li>
      <li *ngIf="manageExpenseReportCategoriesList?.enabled || manageExpenseItemsList?.enabled || manageExpensePurposesList?.enabled  || manageTimeEntryTypesList?.enabled"><a routerLink="/admin/bookkeeping/management">Bookkeeping</a></li>
      <li *ngIf="manageCarriersList?.enabled"><a routerLink="/admin/carriers/list">Carriers</a></li>
      <li *ngIf="manageCertificationsList?.enabled"><a routerLink="/admin/certifications/manage">Certifications</a></li>
      <li *ngIf="manageDocumentTypes?.enabled"><a routerLink="/admin/documents/list">Document Types</a></li>
      <li *ngIf="manageEvidenceLocationsList?.enabled"><a routerLink="/admin/evidence/manage">Evidence Locations</a></li>
      <li *ngIf="manageUserRolesList?.enabled"><a routerLink="/admin/permissions/list">Permissions</a></li>
      <li *ngIf="manageDocumentTemplatingView?.enabled" [routerLinkActive]="['is-active']"><a routerLink="/admin/templating">Document Template</a></li>
    </ul>
  </li>
</ul>
