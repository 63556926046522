import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ExpensePurposeService } from 'src/app/shared/services/expenses/expense-purpose/expense-purpose.service';
import { ExpenseCategoryService } from 'src/app/shared/services';
import { IApiAddExpensePurposeInput, IApiExpenseCategory, IApiExpensePurpose, IApiUpdateExpensePurposeInput } from "src/app/shared/modules/graphql/types/types";
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';

@Component({
  templateUrl: './expense-purpose-modal.component.html',
  styleUrls: ['./expense-purpose-modal.component.scss']
})
export class ExpensePurposeModalComponent extends DialogContentBase implements OnInit {
  @ViewChild('form', { static: false }) form: NgForm;
  @Input() data: IApiExpensePurpose;
  public isLoading = false;
  public success = false;

  public expenseCategories: IApiExpenseCategory[];

  public model: IApiUpdateExpensePurposeInput = {
    id: "",
    name: "",
    details: "",
    ExpenseCategoryIds: []
  };

  constructor(
    private expenseCategoryService: ExpenseCategoryService,
    private expensePurposeService: ExpensePurposeService,
    private notifications: NotificationsService,
    public dialog: DialogRef,  
  ) {
    super(dialog);
  }

  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  close() {
    this.dialog.close();
  }

  ngOnInit() {
    this.expenseCategoryService.get().pipe(
      unwrapConnection()
    ).subscribe((results) => {
      this.expenseCategories = results;
      // this.model.ExpenseCategoryIds = results.filter(p => this.model.ExpenseCategoryIds.find(id => p.id === id)).map(({ id }) => id);
      const {
        id,
        name = '',
        active = true,
        details = '',
        ExpenseCategories = []
      } = this.data || {};
  
      this.model = {
        id,
        name,
        active,
        details,
        ExpenseCategoryIds: ExpenseCategories.map(({ id }) => id)
      };
    });
  }

  private save() {
    this.expensePurposeService.update({
      ...this.model,
      id: this.data.id,
      name: this.model.name?.trim(),
      details: this.model.details?.trim()
    }).pipe(
      this.notifications.snackbarErrorPipe("Error updating expense purpose."),
      this.notifications.snackbarPipe("Expense purpose updated.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  private add() {
    const createData = { ...this.model };
    delete createData.id;
    this.expensePurposeService.add(createData as IApiAddExpensePurposeInput).pipe(
      this.notifications.snackbarErrorPipe("Error adding expense purpose."),
      this.notifications.snackbarPipe("Expense purpose created.")
    ).subscribe((res) => {
      this.dialog.close(res);
    });
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }
}
