import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { ShippingCarrierService } from './../../../../shared/services/shipping-carrier/shipping-carrier.service';
import { IApiAddShippingCarrierInput, IApiShippingCarrier, IApiUpdateShippingCarrierInput } from './../../../../shared/modules/graphql/types/types';
import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-carriers-manage-modal',
  templateUrl: './carriers-manage-modal.component.html',
  styleUrls: ['./carriers-manage-modal.component.scss']
})
export class CarriersManageModalComponent extends DialogContentBase implements OnInit{
  public model: IApiAddShippingCarrierInput | IApiUpdateShippingCarrierInput;
  @Input() data: IApiShippingCarrier;
  @ViewChild('form', { static: false }) form: NgForm;
  constructor(
    private carriers: ShippingCarrierService,
    private notifications: NotificationsService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {
    const {
      name = ""
    } = this.data || {};

    this.model = {
      name
    };
  }
  onSubmit() {
    if (this.form?.invalid) {
      this.form.form.markAllAsTouched();
      return;
    };
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  public close(action = false) {
    return this.dialogRef.close(action);
  }

  public save() {
    this.carriers.update({
      id: this.data.id,
      name: this.model?.name?.trim()
    }).pipe(
      this.notifications.snackbarErrorPipe("Error updating Shipping Carrier")
    ).subscribe(() => this.close(true));
  }
  public add() {
    this.carriers.add(this.model as IApiAddShippingCarrierInput).pipe(
      this.notifications.snackbarErrorPipe("Error adding Shipping Carrier")
    ).subscribe(() => this.close(true));
  }
}
