<section>
  <app-investigation-metrics-filters
    (applyFilter)="applyFilter($event)"
    (clearFilter)="applyFilter($event)"
    [persistFilters]="false"
  ></app-investigation-metrics-filters>
  <app-investigation-metrics-table
    [tabIndex]="tabIndex"
    [dataSource]="dataSource"
    [data]="data"
    [sort]="sort"
    [pageOptions]="pageOptions"
    (pageChange)="pageChange($event)"
    (sortChange)="sortChange($event)"
  ></app-investigation-metrics-table>
</section>
