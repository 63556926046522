import { AuthService } from '../../../../shared/services/authorization/auth.service';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { NgForm } from '@angular/forms';
import { IApiAddExpenseInput, IApiAddTimeEntryInput, IApiDocumentType, IApiExpense, IApiExpenseItem, IApiInvestigation, IApiInvestigationFilterType, IApiInvestigationStaff, IApiTimeEntry, IApiUpdateExpenseInput, IApiUpdateTimeEntryInput, IApiUser, IApiUserFilterType } from "src/app/shared/modules/graphql/types/types";
import { DocumentService, ExpenseService, InvestigationService, TimeEntryService } from "src/app/shared/services";
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { IndExpenseFormKendoComponent } from '../../forms/ind-expense-form-kendo/ind-expense-form-kendo.component';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IndMileageFormKendoComponent } from '../../forms/ind-mileage-form-kendo/ind-mileage-form-kendo.component';
import { IndTimeFormKendoComponent } from '../../forms/ind-time-form-kendo/ind-time-form-kendo.component';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';

export interface IInvestigationTimeExpenseModalData {
  investigationId?: string;
  nefcoNumber?: string;
  expense?: IApiExpense;
  time?: IApiTimeEntry;
  staff?: IApiInvestigationStaff[] | IApiUser[];
  editQtyOnly?: boolean;
  adminView?: boolean;
  showStaffMember?: boolean;
  showStaffMemberAutoComplete?: boolean;
  actionType?: ExpenseActionType;
  investigationView?: boolean;
  showStaffMemberNew?: boolean;
  selectedExpenseType?: string;
}

export interface IApiInvestigationStaffMember extends IApiInvestigationStaff {
  userName?: string;
  userId?: string;
}

export enum FormExpenseType {
  MILEAGE = "mileage",
  EXPENSE = "expense",
  HOURS = "hours",
}

type S3DocType = {
  key: string,
  filename: string,
  isNew?: boolean
};
@Component({
  selector: 'app-investigation-time-and-exp-modal-kendo',
  templateUrl: './investigation-time-and-exp-modal-kendo.component.html',
  styleUrls: ['./investigation-time-and-exp-modal-kendo.component.scss'],
})
export class InvestigationTimeAndExpModalKendoComponent extends DialogContentBase implements OnInit {

  @ViewChild("form") form: NgForm;
  @ViewChild(IndMileageFormKendoComponent) milageComp: IndMileageFormKendoComponent;
  @ViewChild(IndExpenseFormKendoComponent) expenseComp: IndExpenseFormKendoComponent;
  @ViewChild(IndTimeFormKendoComponent) timeComp: IndTimeFormKendoComponent;

  public formExpenseType: typeof FormExpenseType = FormExpenseType;
  public actionType: typeof ExpenseActionType = ExpenseActionType;

  public columnsToDisplay = ["staff", "date", "type", "qty", "non_billable", "description"];

  public selectedExpenseType = null;

  public get isMileageForm() {
    // better way to check if this mileage?
    // if editing an active expense, detect if its mileage, otherwise check to see what option has been selected
    if (!this.data.expense.id) return this.selectedExpenseType === this.formExpenseType.MILEAGE;
    else return this.data.expense?.ExpenseItem?.name === "Mileage";
  }

  // User Info
  public staffMember = "";
  public user: IApiUser;
  public users: IApiUser[] = [];
  public investigationStaffList: IApiInvestigationStaffMember[];

  // Expenses
  private _expense: IApiUpdateExpenseInput;

  public set expense(val) {
    this._expense = val;
  }
  public get expense() {
    return this._expense;
  }

  // Hours
  private _time: IApiUpdateTimeEntryInput;

  public set time(val) {
    this._time = val;
  }
  public get time() {
    return this._time;
  }

  @Input() public data: IInvestigationTimeExpenseModalData;
  @Input() public documentTypes: IApiDocumentType[];
  @Input() investigations: IApiInvestigation[];
  @Input() expenseItems: IApiExpenseItem[];
  @Input() investigationView = false;
  @Input() excludeExpenseItems: boolean = true;


  s3Doc: S3DocType;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;

  constructor(
    public dialog: DialogRef,
    private expenseService: ExpenseService,
    private timeEntryService: TimeEntryService,
    private notificationService: NotificationsService,
    private loader: LoaderService,
    private auth: AuthService,
    private documentService: DocumentService,
    private investigationService: InvestigationService,
  ) {
    super(dialog);
  }

  ngOnInit() {
    if (this.investigations && !this.investigations.length) {
      this.getInvestigations();
    }
    if (((this.data.expense?.id || this.data.actionType === 'DUPLICATE') && this.data.expense?.ExpenseItem?.name === "Mileage") || this.data.actionType === ExpenseActionType.ADD_MILEAGE) {
      this.selectedExpenseType = this.formExpenseType.MILEAGE;
      this.data.time = {} as IApiTimeEntry;
    } else if (((this.data.expense?.id || this.data.actionType === 'DUPLICATE') && this.data.expense?.ExpenseItem?.name !== "Mileage") || this.data.actionType === ExpenseActionType.ADD_EXPENSE) {
      this.selectedExpenseType = this.formExpenseType.EXPENSE;
      this.data.time = {} as IApiTimeEntry;
    } else if (this.data.time?.id || this.data.actionType === ExpenseActionType.ADD_TIME) {
      this.selectedExpenseType = this.formExpenseType.HOURS;
      this.data.expense = {} as IApiExpense;
    }

    // Default to Admin View because most references use admin view
    if (this.data.adminView === undefined) this.data.adminView = true;

    // Setting Expense
    const {
      id,
      expenseDate,
      billableQuantity,
      nonBillableQuantity,
      outOfPocket,
      nefcoVehicle = 0,
      authorizedBy,
      authorizedDate,
      paidDate,
      description,
      Investigation,
      Document,
    } = this.data.expense;
    this._expense = {
      id,
      expenseDate: new Date(expenseDate),
      billableQuantity: Investigation?.id ? billableQuantity : null,
      nonBillableQuantity,
      outOfPocket,
      nefcoVehicle,
      authorizedBy,
      authorizedDate: authorizedDate ? new Date(authorizedDate) : null,
      paidDate,
      description,
      InvestigationId: Investigation?.id || null,
      DocumentId: this.data.expense?.Document?.id || null,
      UserId: this.data.expense?.User?.id || null,
      ExpensePurposeId: this.data.expense?.ExpensePurpose?.id || null,
      ExpenseItemId: this.data.expense?.ExpenseItem?.id || null,
      receipts: this.data.expense?.receipts?.map((v) => v.id) || [] // FYI, Receipts are now Documents
    };
    this.s3Doc = { key: (Document?.s3Uri || ''), filename: (Document?.title || ''), isNew: false };
    // Setting Hours
    const {
      id: idTime,
      workday,
      hours,
      nonBillableHours,
      description: descTime,
      paidDate: paidDateTime
    } = this.data.time;
    this._time = {
      id: idTime,
      workday: new Date(workday),
      hours,
      nonBillableHours,
      paidDate: paidDateTime,
      TypeId: this.data.time?.Type?.id || null,
      UserId: this.data.time?.User?.id || null,
      description: descTime,
      InvestigationId: this.data.investigationId ? this.data.investigationId : null
    };

    // if (this.data.time?.User?.id) this.expense.UserId = this.data.time?.User?.id;
    this.staffMember = this.staffMember || this.data.expense?.User?.id || this.data.time?.User?.id;
    if (this.data?.staff) {
      this.data.staff.map((obj) => {
        obj['itemFullName'] = `${obj?.firstName} ${obj?.lastName}`;
        this.users.push(obj);
      })
    };
    if (this.data?.showStaffMember || this.data?.showStaffMemberAutoComplete) this.auth.authenticatedUser.subscribe((u) => {
      u['itemFullName'] = `${u?.firstName} ${u?.lastName}`;
      this.user = u
    });

    if (this.data?.showStaffMemberAutoComplete) {
      if (this.users?.length > 0) {
        this.user = this.users[0];
      }
    }
  }

  public getInvestigations(selectedUserId = null) {
    let userId = this.data?.expense?.User?.id || this.data?.time?.User?.id;
    if (selectedUserId) {
      userId = selectedUserId;
    }
      this.loader.show$(this.investigationService.get([{ type: IApiInvestigationFilterType.User, value: userId }, { type: IApiInvestigationFilterType.ViewOnlyNefconumber, value: "true" }], { limit: -1 })).pipe(
        unwrapConnection()
      ).subscribe(results => {
        if (results && results?.length) {
          results.map((obj) => {
            obj['name'] = obj?.nefcoNumber ? obj.nefcoNumber : obj.id;
          })
        }
        this.investigations = results;
      });
  }

  public findUser = (id: string) => this.users.find((u) => u.id === id);

  public saveEntry() {
    this.selectedExpenseType === 'hours' ? this.sendTime() : this.sendExpense();
  }

  private sendTime() {
    if (!this.checkValid) {
      return;
    }
    const data = { ...this.time, hours: this.time?.hours || 0, nonBillableHours: this.time?.nonBillableHours || 0, UserId: this.time.UserId };
    if ((this.data?.adminView && this.data?.showStaffMemberNew) || this.data?.showStaffMemberAutoComplete) {
      data.UserId = this.staffMember;
    }
    const message = data.id ? 'updated' : 'added';
    if (!data.id) delete data.id;
    this.loader.show$(
      data.id ? this.timeEntryService.update(data) : this.timeEntryService.add({ ...data } as IApiAddTimeEntryInput)
    ).pipe(
      this.notificationService.snackbarPipe(`Time entry ${message}.`),
      this.notificationService.snackbarErrorPipe("Error adding time entry.")
    ).subscribe((v) => this.dialog.close(true));
  }

  public async sendExpense() {
    if (!this.checkValid) {
      return;
    }
    let DocumentId = this.expense.DocumentId;
    if (this.expense.DocumentId && this.s3Doc.key && this.s3Doc.isNew) {
      await this.documentService.update({
        id: this.expense.DocumentId,
        title: this.s3Doc.filename,
        caption: this.s3Doc.filename,
        uri: '',
        s3Uri: this.s3Doc.key
      }).pipe(
        this.notificationService.snackbarErrorPipe("Error uploading document; please try again")
      ).toPromise().then((r) => {
        DocumentId = r.id;
      });
    } else if (!this.expense.DocumentId && this.s3Doc.key && this.s3Doc.isNew) {
      await this.documentService.add({
        uri: '',
        s3Uri: this.s3Doc.key,
        title: this.s3Doc.filename,
        caption: this.s3Doc.filename,
        TypeId: this.documentTypes.filter(t => t.name === "Other")[0].id,
      }).pipe(
        this.notificationService.snackbarErrorPipe("Error uploading document; please try again")
      ).toPromise().then((r) => {
        DocumentId = r.id;
      });
    } else if (!this.s3Doc.key && this.s3Doc.isNew) {
      DocumentId = null;
    }

    const data = {
      ...this.expense,
      DocumentId,
      billableQuantity: this.expense.billableQuantity || 0,
      nonBillableQuantity: this.expense.nonBillableQuantity || 0,
    };
    // if ((this.data?.adminView && this.data?.showStaffMemberNew) || this.data?.showStaffMemberAutoComplete) {
    //   data.UserId = this.staffMember;
    // }
    const message = data.id ? 'updated' : 'added';
    if (!data.id) delete data.id;
    this.loader.show$(
      data.id ? this.expenseService.update(data) : this.expenseService.add({ ...data } as IApiAddExpenseInput)
    ).pipe(
      this.notificationService.snackbarPipe(`Expense entry ${message}.`),
      this.notificationService.snackbarErrorPipe("Error adding expense entry.")
    ).subscribe((v) => this.dialog.close(true));
  }

  // See if form & child form is valid
  public get checkValid() {
    this.form.control.markAllAsTouched();
    if (this.milageComp) {
      return (this.expense.UserId || this.data.actionType === ExpenseActionType.ADD_MILEAGE) && this.milageComp.isFormValid() && this.form?.form?.valid;
    }
    else if (this.expenseComp) {
      return (this.expense.UserId || this.data.actionType === ExpenseActionType.ADD_EXPENSE) && this.expenseComp.isFormValid() && this.form?.form?.valid;
    }
    else if (this.timeComp) {
      return (this.time.UserId || this.data.actionType === ExpenseActionType.ADD_TIME) && this.timeComp.isFormValid() && this.form?.form?.valid;
    }
    else {
      return true;
    }
  }

  public close() {
    this.dialog.close(null);
  }

  // update user
  public setUser(userId: string) {
    this.staffMember = userId;
    this.getInvestigations(userId);
  }

}
