import { InvestigationTimeAndExpQuickbooksModalComponent } from './../../investigations/investigation-time-and-exp-quickbooks-modal/investigation-time-and-exp-quickbooks-modal.component';
import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { InvoiceService } from "src/app/shared/services";
import { InvoiceDataSource } from "src/app/shared/services/invoice/invoice.datasource";
import { IApiInvoice, IApiInvoiceOrderBy, IApiUserFilterType } from "src/app/shared/modules/graphql/types/types";
import { IApiInvoiceFilterType } from 'src/app/shared/modules/graphql/types/types';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { bookKeepingInvoiceListDelete, bookKeepingInvoiceListUpdate } from 'src/app/shared/helpers/auth-config/bookkeeping.config';
import { NgForm } from '@angular/forms';
import { SortDescriptor } from '@progress/kendo-data-query';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';

@Component({
  selector: 'app-bookkeeping-invoice-list',
  templateUrl: './bookkeeping-invoice-list.component.html',
  styleUrls: ['./bookkeeping-invoice-list.component.scss']
})
export class BookkeepingInvoiceListComponent implements OnInit {

  public authConfig = {
    bookKeepingInvoiceListUpdate,
    bookKeepingInvoiceListDelete
  }
  @ViewChild('filterForm', { static: false }) filterForm: NgForm;
  public filtersActive = false;

  // Table Setup
  private _dataSource: InvoiceDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }
  public displayedColumns = ["invoice", "invId", "billTo", "client", "createdBy", "createdAt", "status", "qbErrors", "actions"];
  public pageSize: number = null;
  public totalResults = 0;

  // Filter options
  public searchValue: string = null;
  public invoiceNumber: number = null;
  public investigationId: string = null;
  public createdBy = null;
  public startDate = null;
  public endDate = null;

  // Select options - need to be fetched
  public invoices = null;
  public investigations = null;
  public orderByOptions = IApiInvoiceOrderBy;
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public sort: SortDescriptor[] = [
    {
      field: IApiInvoiceOrderBy.CreatedAt,
      dir: "desc",
    },
  ];

  constructor(
    private invoiceService: InvoiceService,
    private notifications: NotificationsService,
    private loader: LoaderService,
    private dialogService: DialogService,
  ) {
    this.dataSource = new InvoiceDataSource(this.invoiceService);
    this.dataSource.listPage.orderBy = IApiInvoiceOrderBy.CreatedAt;
    this.dataSource.listPage.sortOrder = SortOrder.DESC;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Table Interaction
  public clearFilters() {
    this.investigationId = null;
    this.invoiceNumber = null;
    this.createdBy = null;
    this.startDate = null;
    this.endDate = null;
    this.searchValue = null;
    this.dataSource.removeFilter(IApiInvoiceFilterType.Investigation);
    this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceNumber);
    this.dataSource.removeFilter(IApiInvoiceFilterType.CreatedBy);
    this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceDate);
    this.dataSource.removeFilter(IApiInvoiceFilterType.Search);
    this.load();
  }

  public filterTable() {
    if (this.filterForm?.invalid && (this.startDate || this.endDate)) {
      this.filterForm.form.markAllAsTouched();
      return;
    }
    // execute data fetch here with filter parameters
    this.dataSource.applyFilter(IApiInvoiceFilterType.Investigation, this.investigationId);
    this.dataSource.applyFilter(IApiInvoiceFilterType.InvoiceNumber, this.invoiceNumber?.toString());
    this.dataSource.applyFilter(IApiInvoiceFilterType.CreatedBy, this.createdBy);
    this.dataSource.applyFilter(IApiInvoiceFilterType.Search, this.searchValue);
    if (this.startDate && this.endDate) this.dataSource.applyFilter(IApiInvoiceFilterType.InvoiceDate, NefcoDateHelper.dateFilterString(this.startDate, this.endDate));
    else this.dataSource.removeFilter(IApiInvoiceFilterType.InvoiceDate);
    this.load();
  }


  ngOnInit() {
    this.loader.attachObservable(this.dataSource.loading$);
    this.load();
  }

  public load() {
    // TODO - apply filters
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  public resubmitInvoice(invoice: IApiInvoice) {
    this.notifications.kendoConfirm("Are you sure this invoice is ready for resubmission?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.resubmit(invoice.id)),
      this.notifications.snackbarErrorPipe("Error resubmitting invoice"),
      this.notifications.snackbarPipe("Invoice resubmitted!")
    ).subscribe(() => this.load());
  }

  public deleteInvoice(invoice: IApiInvoice) {
    this.notifications.kendoConfirm("Are you sure you want to delete this invoice?").pipe(
      filter(v => !!v),
      switchMap(() => this.invoiceService.remove(invoice.id)),
      this.notifications.snackbarErrorPipe("Error deleting invoice"),
      this.notifications.snackbarPipe("Invoice deleted.")
    ).subscribe(() => this.load());
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiInvoiceOrderBy.CreatedAt;
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  };

  public openQbDialog(invoice?: IApiInvoice, disableEdit = false) {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpQuickbooksModalComponent,
      width: "90%",
      autoFocusedElement: 'invoiceNumber',
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpQuickbooksModalComponent;
    dialogInstance.data = {
      invoice,
      disableEdit,
    };
    dialog.result.subscribe((res) => {
      if (res === true) {
        this.load();
      }
    });
  }
}
