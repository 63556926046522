import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { filter, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { InvestigationServicesSidebarComponent } from '../investigation-services-sidebar/investigation-services-sidebar.component';
import { InvestigationAddServiceModalComponent } from '../investigation-add-service-modal/investigation-add-service-modal.component';
import { InvestigationRequestModalComponent } from '../investigation-request-modal/investigation-request-modal.component';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { RequestService, ServiceService, InvestigationService, AuthService } from 'src/app/shared/services/';
import { IApiAddInvestigationHistoryInput, IApiAddRequestInput, IApiAddServiceInput, IApiInvestigation, IApiInvestigationStaff, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiRequest, IApiRequestFilterType, IApiService, IApiServiceFilterType, IApiUpdateInvestigationInput, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import {
  investigationServicesCreate,
  investigationServicesCreateOwn,
  investigationRequestsCreate
} from "src/app/shared/helpers/auth-config/investigations.config";
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import _ from 'lodash';

@UntilDestroy()
@Component({
  selector: 'app-investigation-services-header',
  templateUrl: './investigation-services-header.component.html',
  styleUrls: ['./investigation-services-header.component.scss']
})
export class InvestigationServicesHeaderComponent implements OnInit {

  public authConfig = {
    investigationServicesCreate,
    investigationServicesCreateOwn,
    investigationRequestsCreate,
  };

  /**
   * Assigned User Dropdown value
   */
  private _assignedToUser: string;
  public get assignedToUser() { return this._assignedToUser; }
  public set assignedToUser(val) {
    this._assignedToUser = val;
  }

  @Output() reload = new EventEmitter<boolean>();


  public selectedStaff: IApiInvestigationStaff[] = [];

  /**
   * Investigation
   */
  private _investigation: IApiInvestigation;
  public get investigation() { return this._investigation; }
  @Input() public set investigation(val: IApiInvestigation) {
    this._investigation = val;
  }
  /**
   * Requests Data
   */
  private _requests: IApiRequest[];
  public get requests() { return this._requests; }
  public set requests(value) { this._requests = value; }

  /**
   * Services Data
   */
  private _services: IApiService[];
  public get services() { return this._services; }
  public set services(value) { this._services = value; }

  /**
   * Toggle button
   */
  public servicesRequestsToggle = "services";

  private user: IApiUser;

  constructor(
    private auth: AuthService,
    private serviceService: ServiceService,
    private requestService: RequestService,
    private investigationService: InvestigationService,
    private dialog: MatDialog,
    private loader: LoaderService,
    private notifications: NotificationsService,
    private dialogService: DialogService
  ) {
    // Find logged in user
    this.auth.authenticatedUser.pipe(
      take(1)
    ).subscribe(user => this.user = user);

  }

  // TODO
  public openSidebar() {
    const dialogRef = this.dialog.open(InvestigationServicesSidebarComponent, {
      width: '50%',
      height: "100vh",
      position: { right: "0" },
      data: this._investigation
    });
  }

  // Component to add investigation history entry
  private addInvestigationHistory(selectedCategory: IApiInvestigationUpdateCategories, selectedType: IApiInvestigationUpdateTypes) {

    const investigationUpdate: IApiUpdateInvestigationInput = {
      id: this._investigation.id
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationHistoryKendoModalComponent,
      width: "40%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this._investigation },
      selectedCategory,
      selectedType,
      noteOnly: true
    };

    return dialog.result.pipe(
      filter((v) => !!v),
      tap((v: IApiAddInvestigationHistoryInput) => (investigationUpdate as IApiUpdateInvestigationInput).History = v),
      switchMap(() => this.investigationService.update(investigationUpdate as IApiUpdateInvestigationInput))
    );
  }

  // Opens modal to add an @IApiRequest
  // This also saves the returned request when a new request
  public openAddRequest() {
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationRequestModalComponent,
      width: "60%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationRequestModalComponent;
    dialogInstance.data = { Investigation: { ...this._investigation } } as any;
    dialog.result.pipe(
      filter((v: IApiAddRequestInput) => {
        return _.isEmpty(v) ? false : !!v;
      }), mergeMap((newRequest) => {
        return this.addInvestigationHistory(IApiInvestigationUpdateCategories.Requests, IApiInvestigationUpdateTypes.Create).pipe(
          switchMap(() => this.requestService.add(newRequest))
        );
      }),
      this.notifications.snackbarPipe("Request has been added"),
      this.notifications.snackbarErrorPipe("Error adding request; please try again"),
    ).subscribe((res) => {
      if (res) {
        this.loadRequests();
      }
    });
  }

  // Opens modal to add an @IApiService
  // This also saves the returned request when a new request
  public openAddService() {
    let serviceCreate: IApiAddServiceInput;
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationAddServiceModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationAddServiceModalComponent;
    dialogInstance.data = { Investigation: this._investigation };

    return dialog.result.pipe(
      filter((v: DialogCloseResult) => {
        return _.isEmpty(v) ? false : !!v;
      }),
      tap((r: any) => serviceCreate = r),
      switchMap(() => this.addInvestigationHistory(IApiInvestigationUpdateCategories.Services, IApiInvestigationUpdateTypes.Create)),
      switchMap((r) => this.serviceService.add(serviceCreate)),
      this.notifications.snackbarPipe("Service has been added"),
      this.notifications.snackbarErrorPipe("Error adding service; please try again"),
    ).subscribe(() => {
      this.reload.emit(true);
    });
  }

  /**
   * Loads @IApiService data
   */
  // private loadServices() {
  //   const filters = [{ type: IApiServiceFilterType.Investigation, value: this._investigation.id }];

  //   if (this.assignedToFilter) {
  //     filters.push({ type: IApiServiceFilterType.AssignedUser, value: this.assignedToFilter.id });
  //   }

  //   this.loader.show$(this.serviceService.list(
  //     filters, {}
  //   )).pipe(
  //     filter(r => !!r),
  //     this.notifications.snackbarErrorPipe("Error loading Services; please try again")
  //   ).subscribe((results) => {
  //     this._services = results;
  //   });

  // }

  /**
   * Loads @IApiRequest data
   */
  private loadRequests() {
    this.loader.show$(this.requestService.list([{ type: IApiRequestFilterType.Investigation, value: this.investigation.id }], {}))
      .pipe(
        filter(r => !!r),
        this.notifications.snackbarErrorPipe("Error loading Requests; please try again")
      ).subscribe((r) => {
        r.forEach((request) => {
          request.StatusHistory = request?.StatusHistory.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
        });
        this._requests = r;
      });
  }

  // Refresh the view when a service or request is deleted
  public deleteService(event) {
    this.reload.emit(true);
  }

  public deleteRequest(event) {
    this.reload.emit(true);
    this.loadRequests();
  }

  public updateRequest(event) {
    this.loadRequests();
  }

  public reloadInvestigation() {
    this.reload.emit(true);
  }

  ngOnInit() {
    this.loadRequests();
    this.selectedStaff = this.staffSort(this.investigation?.InvestigationStaff);
    if (this.investigation?.InvestigationStaff.find(staff => staff?.User?.id === this.user?.id)) {
      this.assignedToUser = this.user.id;
    }
  }

  public servicesRequestsToggleFn(selected) {
    this.servicesRequestsToggle = selected;
  }

  public staffSort(list: IApiInvestigationStaff[]) {
    if (!list || !list.length) return [];
    let list_ = [...list];
    list_.map(a => {
      a['userName'] = `${a?.User?.firstName} ${a?.User?.lastName}`;
      a['userId'] = a?.User?.id;
    });
    return list_.sort((a, b) => a.User?.lastName > b.User.lastName ? 1 : -1);
  }

}
