import type { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.nefco.net',
  appName: 'NEFCO',
  webDir: 'dist/nefco-extranet',
  ios: {
    backgroundColor: "#FFFFFF",
  },
  server: {
    hostname: 'localhost',  // Ensure this is set correctly
    androidScheme: 'http',
    iosScheme: 'capacitor'
  },
  plugins: {
    SplashScreen: {
      launchShowDuration: 3000,
      launchAutoHide: true,
      launchFadeOutDuration: 500,
      backgroundColor: "#ffffffff",
      androidSplashResourceName: "splash",
      androidScaleType: "FIT_XY",
      showSpinner: true,
      androidSpinnerStyle: "large",
      iosSpinnerStyle: "small",
      spinnerColor: "#999999",
      splashFullScreen: true,
      splashImmersive: true,
    },
  }
};

export default config;
