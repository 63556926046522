import { filter, switchMap } from 'rxjs/operators';
import { NotificationsService } from './../../../../shared/modules/notifications/notifications.service';
import { LoaderService } from './../../../../shared/modules/loader/loader.service';
import { EnforcePermissionDisplayModes, IEnforcePermissionConfig } from 'src/app/shared/directives/enforce-permission.directive';
import { Component, OnInit } from '@angular/core';
import { IApiDocumentType } from "src/app/shared/modules/graphql/types/types";
import { DocumentTypeService } from "src/app/shared/services";
import { DocumentTypeModalComponent } from "../document-type-modal/document-type-modal.component";
import { PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';
import { DocumentTypeDataSource } from "src/app/shared/services/document/document-type/document-type.datasource";
import { manageDocumentTypesCreate, manageDocumentTypesUpdate, manageDocumentTypesDelete } from "../../../../shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  templateUrl: './document-type-list.component.html',
  styleUrls: ['./document-type-list.component.scss']
})
export class DocumentTypeListComponent implements OnInit {

  public authConfig = {
    manageDocumentTypesCreate,
    manageDocumentTypesUpdate,
    manageDocumentTypesDelete
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public permissionConfig: IEnforcePermissionConfig = {
    category: PermissionCategories.MANAGE_USER_ROLE_PERMISSIONS,
    displayMode: EnforcePermissionDisplayModes.StandAlone
  };

  private _dataSource: DocumentTypeDataSource;

  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  // Column headers
  public columnsToDisplay = ['NAME', 'CATEGORY', 'defaultTitle', 'action'];

  public documentTypeList: IApiDocumentType[] = [];

  public sort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];

  constructor(
    private dialog: DialogService,
    private documentTypes: DocumentTypeService,
    private loader: LoaderService,
    private notifications: NotificationsService
  ) {
  }

  public edit(data?: IApiDocumentType) {
    const dialog: DialogRef = this.dialog.open({
      content: DocumentTypeModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as DocumentTypeModalComponent;
    dialogInstance.data = data;
    dialog.result.subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    });
  }

  public delete(id: string) {
    this.notifications.kendoConfirm(
      "Really delete this Document Type?",
      "Delete Document Type",
      "No",
      "Yes"
    ).pipe(
      filter(v => !!v),
      switchMap(() => this.documentTypes.remove(id)),
      this.notifications.snackbarErrorPipe("Error deleting Document Type")
    ).subscribe((res) => {
        this.notifications.notify('Document type has been deleted successfully.')
        this.load()
      });
  }

  public ngOnInit() {
    this._dataSource = new DocumentTypeDataSource(this.documentTypes);
    this.loader.attachObservable(this._dataSource.loading$);
    this.dataSource?.contents$.subscribe((res) => {
      this.documentTypeList = res;
    });
    // populate the data
    this.load();
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = 'NAME';
      this.dataSource.listPage.sortOrder = SortOrder.DESC;
    }
    this.load();
  }

  public itemSelected(event, dataItem) {
    switch (event?.text) {
      case 'Edit':
        this.edit(dataItem);
        break;
      case 'Delete':
        this.delete(dataItem?.id)
        break;
      default:
        break;
    }
  }

  private load() {
    this.dataSource.pagingReset();
    this.dataSource.load();
  }

}
