import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InvestigationReportComponent } from './investigation-report/investigation-report.component';
import { InvestigationMetricsComponent } from './investigation-metrics/investigation-metrics.component';
import { EvidenceReportModule } from './evidence-report/evidence-report.module';
import { InvestigationTimelogComponent } from './investigation-timelog/investigation-timelog.component';
import { QuarterlyUtilizationReportComponent } from './quarterly-utilization-report/quarterly-utilization-report.component';
import { QuarterlyHourReportComponent } from './quarterly-hour-report/quarterly-hour-report.component';
import { ReportSentMetricTabsComponent } from './report-sent-metric-report/report-sent-metric-tabs.component';
import { InvestigationReportTimelogComponent } from './investigation-report-timelog/investigation-report-timelog.component';
import { PermissionGuard } from 'src/app/shared/route.guards';
import { PermissionActions, PermissionCategories } from 'src/app/shared/modules/graphql/constants/permission.constants';

const routes: Routes = [
  { path: '',   redirectTo: 'evidence', pathMatch: 'full' },
  {
    path: "investigations-metrics",
    component: InvestigationMetricsComponent
  },
  {
    path: "investigations",
    component: InvestigationReportComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.COMPANY_INVESTIGATION_REPORT, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
  {
    path: "report-timelog",
    component: InvestigationTimelogComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.INVESTIGATION_TIMELOG_PACING, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
  {
    path: "quarterly-utilization-report",
    component: QuarterlyUtilizationReportComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.QUARTERLY_UTILIZATION_REPORT, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
  {
    path: "quarterly-hour-report",
    component: QuarterlyHourReportComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.QUARTERLY_HOUR_REPORT, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
  {
    path: "report-sent-metric",
    component: ReportSentMetricTabsComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.REPORT_SENT_METRIC, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
  {
    path: "evidence",
    loadChildren: () => EvidenceReportModule,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.COMPANY_INVESTIGATION_AND_EVIDENCE_REPORT, appliedPermissions: { All: [PermissionActions.LIST] }}
  },
  {
    path: "investigations-report-timelog",
    component: InvestigationReportTimelogComponent,
    canActivate: [PermissionGuard],
    data: { category: PermissionCategories.INVESTIGATION_REPORT_TIMELOG, appliedPermissions: { All: [PermissionActions.LIST] }},
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportModuleRouter { }
