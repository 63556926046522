import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IApiDocumentCategory, IApiDocumentCategoryOrderBy, IApiDocumentTemplate } from './../../../../shared/modules/graphql/types/types';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { IApiAddDocumentTypeInput, IApiDocumentType, IApiSubServiceType, IApiUpdateDocumentTypeInput } from "src/app/shared/modules/graphql/types/types";
import { DocumentCategoryService, DocumentTemplateService, DocumentTypeService, SubServiceTypeService } from "src/app/shared/services";
import { NgForm } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
  templateUrl: './document-type-modal.component.html',
  styleUrls: ['./document-type-modal.component.scss']
})
export class DocumentTypeModalComponent extends DialogContentBase implements OnInit {

  @ViewChild("form") form: NgForm;
  @Input() public data: IApiDocumentType

  public model: IApiAddDocumentTypeInput = {
    name: "",
    CategoryId: "",
    isCourtDocument: false,
    templateName: "",
    TemplateId: null,
    defaultTitle: "",
    notes: ""
  };

  public subServiceTypes: IApiSubServiceType[];
  public documentCategories: IApiDocumentCategory[];
  public documentTemplates: IApiDocumentTemplate[];
  public get mapToService() {
    return this.model.SubServiceTypeId;
  }

  constructor(
    private notifications: NotificationsService,
    private documentTypeService: DocumentTypeService,
    private _subServiceTypes: SubServiceTypeService,
    private _documentCategories: DocumentCategoryService,
    private _documentTemplates: DocumentTemplateService,
    public dialogRef: DialogRef
  ) {
    super(dialogRef);
  }

  ngOnInit(): void {

    if (this.data?.id) {
      const {
        name = "",
        Template,
        Category,
        defaultTitle,
        notes
      } = this.data || {};
      if (Template) this.model.TemplateId = Template.id;
      if (Category?.id) {
        this.model.CategoryId = Category.id;
      }
      this.model.name = name || '';
      this.model.defaultTitle = defaultTitle || '';
      this.model.notes = notes || '';
    }

    this._subServiceTypes.get().pipe(
      unwrapConnection()
    ).subscribe(results => this.subServiceTypes = results);

    this._documentCategories.get([], { orderBy: IApiDocumentCategoryOrderBy.Name, sortOrder: SortOrder.DESC, limit: -1 }).pipe(
      unwrapConnection()
    ).subscribe(results => this.documentCategories = results);

    this._documentTemplates.get().pipe(
      unwrapConnection()
    ).subscribe(results => this.documentTemplates = [...results, { id: "other", name: "Other" }]);
  }

  onSubmit() {
    this.model.name = this.model.name?.trim();
    const { id = null } = this.data || {};
    if (id) this.save();
    else this.add();
  }

  private save() {
    return this.documentTypeService.update({ id: this.data.id, ...this.model } as IApiUpdateDocumentTypeInput).pipe(
      this.notifications.snackbarErrorPipe("Error saving Document Type")
    ).subscribe(() => this.close(true));
  }

  private add() {
    const createData = { ...this.model };
    return this.documentTypeService.add(createData as IApiAddDocumentTypeInput).pipe(
      this.notifications.snackbarErrorPipe("Error adding Document Type")
    ).subscribe(() => this.close(true));
  }

  public updateDefaultTitle(value: string) {
    // sync default title with document type unless it's been touched
    const defaultTitleControl = this.form.form.controls.defaultTitle;
    if (defaultTitleControl.pristine) {
      this.model.defaultTitle = value || '';
    }
  }

  close(result = false) {
    this.dialog.close(result);
  }
}
