import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { Component, OnInit } from '@angular/core';
import { ExpenseItemDataSource } from 'src/app/shared/services/expenses';
import { ExpenseItemService } from 'src/app/shared/services/expenses/expense-item/expense-item.service';
import { filter, switchMap } from 'rxjs/operators';
import { ExpenseItemModalComponent } from '../expense-item-modal/expense-item-modal.component';
import { NotificationsService } from "src/app/shared/modules/notifications/notifications.service";
import { manageExpenseItemsCreate, manageExpenseItemsUpdate, manageExpenseItemsDelete, manageExpenseItemsDeactivat } from "../../../../shared/helpers/auth-config/manage.config";
import { IApiExpenseItem, IApiExpenseItemOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { SortDescriptor } from '@progress/kendo-data-query';
import { AuthService } from 'src/app/shared/services';
import { forkJoin } from 'rxjs';
import _ from 'lodash';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';

@Component({
  selector: 'app-bookkeeping-expense-items',
  templateUrl: './bookkeeping-expense-items.component.html',
  styleUrls: ['./bookkeeping-expense-items.component.scss']
})
export class BookkeepingExpenseItemsComponent implements OnInit {

  public authConfig = {
    manageExpenseItemsCreate,
    manageExpenseItemsUpdate,
    manageExpenseItemsDelete,
    manageExpenseItemsDeactivat
  }

  private _dataSource: ExpenseItemDataSource;
  public get dataSource(): ExpenseItemDataSource {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public sort: SortDescriptor[] = [
    {
      field: IApiExpenseItemOrderBy.Name,
      dir: "asc",
    },
  ];
  public data: IApiExpenseItem[];
  public actionItem: { text: string }[] = [];
  constructor(
    private loader: LoaderService,
    private notifications: NotificationsService,
    private expenseItemService: ExpenseItemService,
    private dialogService: DialogService,
    private authService: AuthService,
  ) { }

  public parseExpensePurposes(purposes) {
    return purposes.map((k) => k.name).join(", ");
  }

  public addExpenseItem() {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpenseItemModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpenseItemModalComponent;
    dialogInstance.data = null;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public editItem(item) {
    const dialog: DialogRef = this.dialogService.open({
      content: ExpenseItemModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as ExpenseItemModalComponent;
    dialogInstance.data = item;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public activateItem(item) {
    this.expenseItemService.activate(item.id).subscribe(() => this.load());
  }
  public deactivateItem(item) {
    this.expenseItemService.deactivate(item.id).subscribe(() => this.load());
  }

  public deleteItem(item) {
    this.notifications.kendoConfirm("Are you sure you want to delete this item?", "Confirm Delete").pipe(
      filter(v => !!v),
      switchMap(() => this.expenseItemService.remove(item.id)),
      this.notifications.snackbarErrorPipe("Error removing Expense Item."),
      this.notifications.snackbarPipe("Expense Item deleted")
    ).subscribe(() => this.load());
  }

  ngOnInit() {
    // create the dataSource
    this._dataSource = new ExpenseItemDataSource(this.expenseItemService);
    this.loader.attachObservable(this._dataSource.loading$);

    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageExpenseItemsUpdate.category, this.authConfig.manageExpenseItemsUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageExpenseItemsDeactivat.category, this.authConfig.manageExpenseItemsDeactivat.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageExpenseItemsDelete.category, this.authConfig.manageExpenseItemsDelete.appliedPermissions)
    ]).subscribe(([up, acDa, del]) => {
      if (up) {
        this.actionItem.push({text: 'Update'});
      }
      if (acDa) {
        this.actionItem.push({text: 'Activate'});
        this.actionItem.push({text: 'Deactivate'});
      }
      if (del) {
        this.actionItem.push({text: 'Delete'});
      }
    });

    this.load();

    this.dataSource?.contents$.subscribe((res) => {
      this.data = res;
    });
  }

  public load() {
    this.dataSource.pagingReset();
    this.dataSource.load([]);
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiExpenseItemOrderBy.Name;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  getFilteredActionItems(dataItem: IApiExpenseItem) {
    return this.actionItem.filter(item => dataItem.active && item.text !== 'Activate' || !dataItem.active && item.text !== 'Deactivate' );
  }

  public itemSelected(action: { text: string }, dataItem: IApiExpenseItem) {
    switch (action.text) {
      case "Update":
        this.editItem(dataItem);
        break;
      case "Delete":
        this.deleteItem(dataItem);
        break;
      case "Activate":
        this.activateItem(dataItem);
        break;
      case "Deactivate":
        this.deactivateItem(dataItem);
        break;
      default:
        break;
    }
  }
}
