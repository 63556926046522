import { MonoTypeOperatorFunction, pipe, throwError, Observable } from 'rxjs';
import { Injectable, TemplateRef } from '@angular/core';
import { environment } from "src/environments/environment";
import { tap, catchError, filter, map } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { KendoModelComponent } from './kendo-model/kendo-model.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { NotificationService as KendoNotificationService, NotificationRef, NotificationSettings, Type } from "@progress/kendo-angular-notification";
import { MobileKendoConfirmComponent } from 'src/app/components/mobile-app/mobile-kendo-confirm/mobile-kendo-confirm.component';
@Injectable()
export class NotificationsService {

  private _registeredForms: NgForm[] = [];

  public get dirtyForms(): boolean {
    // Check if any form controls are dirty. Sometimes the form remains marked as dirty even though all controls are pristine and valid
    return this._registeredForms.some(({ form }) => Object.values(form.controls).some((c) => c.dirty));
  }

  constructor(
    private router: Router,
    private dialogService: DialogService,
    private kendoNotificationService: KendoNotificationService,
  ) {
    this.router.events.pipe(
      filter((e) => e instanceof NavigationEnd)
    ).subscribe(() => {
      // this.dirtyForms = false;
      this._registeredForms = [];
    });
  }

  public registerForm(form: NgForm) {
    this._registeredForms.push(form);
  }

  notify(message: string | TemplateRef<any> | Function, action?: Type, config?: NotificationSettings): NotificationRef | null {
    const defaults: NotificationSettings = {
      content: message,
      hideAfter: environment.notifyTimeoutMs,
      animation: { type: 'fade', duration: 200},
      position: { horizontal: "left", vertical: "bottom" },
      type: {...{ icon: true, style: 'success' }, ...action}
    };
    return this.kendoNotificationService.show({...defaults, ...(config || {}) });
  }

  alert(message: string, title?: string, buttonCaption?: string, width: string = "40%", headerIcon: string = 'fa-solid fa-circle-exclamation fa-xl error'): Observable<boolean> {
    let defaultWidth = '40%';

    if (screen.width <= 768) {
      defaultWidth = '90%';
      if (width === '40%') {
        width = defaultWidth;
      }
    }
    return this.kendoConfirm(message, title || 'Heads up', null, buttonCaption || "Ok", width || defaultWidth, headerIcon);
  }

  kendoConfirm(
    message: string = 'Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.',
    title: string = "Are you sure?",
		leftBtn: string = 'No',
		rightBtn: string = 'Yes',
    width: string | number = 600,
		headerIcon: string = 'fa-solid fa-circle-exclamation fa-xl',
    subTitle?: string
  ) {

    let defaultWidth = width;

    const dialogRef: DialogRef = this.dialogService.open({
      content: KendoModelComponent,
      width: defaultWidth,
      maxWidth: defaultWidth,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const confirmInfo = dialogRef.content.instance as KendoModelComponent;

    confirmInfo.title = title;
    confirmInfo.leftBtn = leftBtn;
    confirmInfo.rightBtn = rightBtn;
    confirmInfo.bodyMessage = message;
    confirmInfo.headerIcon = headerIcon;
    confirmInfo.subTitle = subTitle;

    return dialogRef.result.pipe(map((res) => {
			const res_ = res === true ? true : false;
      return res_;
    }));
  }

  kendoMobileConfirm(
    message: string = 'Are you sure you want to leave this page? Any changes made to the investigation form will not be saved.',
    title: string = "Are you sure?",
		leftBtn: string = 'No',
		rightBtn: string = 'Yes',
    width: number | string = 600,
		headerIcon: boolean = true,
    subTitle?: string,
    cssClass?: String,
    isClosable?: boolean
  ) {

    let defaultWidth = width;
    const dialogRef: DialogRef = this.dialogService.open({
      content: MobileKendoConfirmComponent,
      width: defaultWidth,
      maxWidth: defaultWidth,
      cssClass: cssClass,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });
    const confirmInfo = dialogRef.content.instance as MobileKendoConfirmComponent;

    confirmInfo.title = title;
    confirmInfo.leftBtn = leftBtn;
    confirmInfo.rightBtn = rightBtn;
    confirmInfo.bodyMessage = message;
    confirmInfo.headerIcon = headerIcon;
    confirmInfo.subTitle = subTitle;
    confirmInfo.isClosable = isClosable;

    return dialogRef.result.pipe(map((res) => {
			const res_ = res === true ? true : false;
      return res_;
    }));
  }

  error(message: string, title = "Error", headerIcon = "fa-solid fa-circle-exclamation fa-xl error") {
    return this.alert(message, title, "Ok", null, headerIcon);
  }

  snackbarPipe(message: string | TemplateRef<any> | Function, action?: Type, options?: NotificationSettings): MonoTypeOperatorFunction<any> {
    return pipe(
      tap(() => this.notify(message, action, options))
    );
  }

  snackbarError(message: string | TemplateRef<any> | Function, action?: Type, options?: NotificationSettings): void {
    this.notify(message, action || { icon: true, style: "error" } , options);
  }

  snackbarErrorPipe(message?: string | TemplateRef<any> | Function, action?: Type, options?: NotificationSettings): MonoTypeOperatorFunction<any> {
    return pipe(
      catchError((err) => {
        console.log("Error:", err);
        this.notify(message || err?.message || err, action || { icon: true, style: "error" } , options);
        return throwError(null);
      }),
      // stop the subscription here
      filter(v => !!v)
    );
  }

  resetDirtyFormPipe(predicate: () => boolean): MonoTypeOperatorFunction<any> {
    return pipe(
      filter(predicate),
      tap(() => {
        // this.dirtyForms = false;
        this._registeredForms.forEach(({ form }) => form.markAsPristine());
      })
    );
  }
}
