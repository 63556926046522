import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { combineLatest, forkJoin, Subject } from 'rxjs';
import { investigationRequestsCreate, investigationScheduledDateView } from 'src/app/shared/helpers/auth-config/investigations.config';
import { IApiInvestigation, IApiInvestigationFilter, IApiInvestigationFilterType, IApiInvestigationOrderBy, IApiInvestigationRoleNames, IApiInvestigationStaff, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes, IApiSettingsFilterType, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, InsuredPartyService, InvestigationService, SettingsService } from 'src/app/shared/services';
import { InvestigationDataSource } from 'src/app/shared/services/investigation/investigation.datasource';
import { InvestigationOnSceneStatus } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { getRiskTypeIcon } from 'src/app/shared/helpers/reactive-form.class';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { NavigationExtras, Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import _ from 'lodash';
import { dashboardInvestigatorsOnSceneUpdate } from 'src/app/shared/helpers/auth-config/dashboard.config';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { MobileChangeOnSceneStatusComponent } from '../mobile-change-on-scene-status/mobile-change-on-scene-status.component';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import dayjs from 'dayjs';
import { SharedService } from 'src/app/common/shared.service';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';

@Component({
  selector: "app-mobile-investigation-list",
  templateUrl: "./mobile-investigation-list.component.html",
  styleUrls: ["./mobile-investigation-list.component.scss"],
})
export class MobileInvestigationListComponent implements OnInit, AfterViewInit {
  public authConfig = {
    investigationRequestsCreate,
    investigationScheduledDateView,
    dashboardInvestigatorsOnSceneUpdate
  };
  @ViewChild('searchBy', { static: false }) public searchBy: TextBoxComponent;
  public investigationOnSceneStatus = InvestigationOnSceneStatus;
  private _dataSource: InvestigationDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this.dataSource) return null;
    return this.dataSource.listPage;
  }

  public currentUser: IApiUser;
  public data: IApiInvestigation[] = [];
  private _filters: IApiInvestigationFilter[] = [];
  public filterTypes = IApiInvestigationFilterType;
  public textSearch = "";
  public viewByCreatedDate = true;
  public viewByScheduleDate = true;
  public sortModel = { createdAt: 'DESC', scheduledDate: 'DESC' };
  public actionItem = [];

  constructor(
    private authService: AuthService,
    public loaderService: LoaderService,
    private investigationService: InvestigationService,
    private router: Router,
    private dialogService: DialogService,
    private insuredPartyService: InsuredPartyService,
    private settingsService: SettingsService,
    private sharedService: SharedService,
  ) {
    this.loaderService.displayText = "Loading Results";
    this.loaderService.displaySubText = "This will just take a moment.";
    this.dataSource = new InvestigationDataSource(this.investigationService);
    this.loaderService.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.limit = 25;

    this.dataSource?.contents$.subscribe((res) => {
      if (res && res?.length) {
        res.map((obj) => {
          obj['assigned'] = this.orderInvestigationStaff(obj?.InvestigationStaff);
        });
      }
      this.data.push(...res);
      if (this.textSearch) {
        setTimeout(() => {
          this.searchBy.focus();
        }, 0);
      }
    });


    this.searchSubject.pipe(
      debounceTime(1000), // Delay 300ms
      distinctUntilChanged() // Emit only if the search term changes
    ).subscribe((searchTerm) => {
      this.dataSource.applyFilter(IApiInvestigationFilterType.AppSearch, searchTerm);
      this.data = [];
      this.load();
    });
  }

  ngOnInit(): void {
    this.dataSource.applyFilter(IApiInvestigationFilterType.AppList, "true");
    this.dataSource.applyFilter(IApiInvestigationFilterType.Closed, "false");
    this.dataSource.applyFilter(IApiInvestigationFilterType.Cancelled, "false");
    this.dataSource.applyFilter(IApiInvestigationFilterType.AppSort, JSON.stringify(this.sortModel));
    this.loaderService.show();
    combineLatest([
      this.authService.authenticatedUser, 
      this.settingsService.get([
        {
          type: IApiSettingsFilterType.Keys,
          value: JSON.stringify(["APP_RELEASED_VERSION", "APP_RELEASED_BUILD"]),
        },
      ]).pipe(unwrapConnection()),
      this.sharedService.getAppInfo$()
    ]).subscribe(([u, settings, appInfo]) => {
        this.currentUser = u;
        const version = settings.find(item => item.key === 'APP_RELEASED_VERSION');
        const build = settings.find(item => item.key === 'APP_RELEASED_BUILD');
        if (
          settings.length && appInfo &&
          (+version?.value > +appInfo.version || +build?.value > +appInfo.build)
        ) {
          // Force update
          this.loaderService.hide();
          this.router.navigate(['admin/force-update']);
        } else {
          // Navigate to the investigation list
          this.load();
          forkJoin([
            this.authService.hasCategoryPermission(this.authConfig.investigationRequestsCreate.category, this.authConfig.investigationRequestsCreate.appliedPermissions),
            this.authService.hasCategoryPermission(this.authConfig.investigationScheduledDateView.category, this.authConfig.investigationScheduledDateView.appliedPermissions),
            this.authService.hasCategoryPermission(this.authConfig.dashboardInvestigatorsOnSceneUpdate.category, this.authConfig.dashboardInvestigatorsOnSceneUpdate.appliedPermissions)
          ]).subscribe(([requestCreate, investigationScheduleDateView, dashboardInvestigatorsOnSceneUpdate]) => {
            if (requestCreate && _.findIndex(this.actionItem, { text: "Data Request" }) === -1) this.actionItem.push({ text: "Data Request" });
            if (_.findIndex(this.actionItem, { text: "Enter Time and Expense" }) === -1)
              this.actionItem.push({ text: "Enter Time and Expense" });
            if (_.findIndex(this.actionItem, { text: "View Investigation Log" }) === -1)
              this.actionItem.push({ text: "View Investigation Log" });
            if (
              investigationScheduleDateView &&
              _.findIndex(this.actionItem, { text: "Schedule On Scene Exam Date" }) === -1) {
              this.actionItem.push({ text: "Schedule On Scene Exam Date" })
            }
    
            if (dashboardInvestigatorsOnSceneUpdate && _.findIndex(this.actionItem, { text: "Update Scene Status" }) === -1) {
              this.actionItem.push({ text: "Update Scene Status" })
            }
          });
        }
    });
  }

  public ngAfterViewInit(): void {
    this.setSafeAreaView();
  }

  public setSafeAreaView() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === 'android') {
        /* search header */
        document.getElementById('mobile-search-header').style.top = `${(16 + 38 + 16 + 1)}px`;
        /* investigation list */
        document.getElementById('mobile-investigation-list').style.paddingTop = `${(16 + 38 + 16 + 1 + 16 + 56 + 16)}px`;
      } else if (Capacitor.getPlatform() === 'ios') {
        /* search header */
        document.getElementById('mobile-search-header').style.top = `${(insets.top + 38 + 16 + 1)}px`;
        /* investigation list */
        document.getElementById('mobile-investigation-list').style.paddingTop = `${(insets.top + 38 + 16 + 1 + 16 + 56 + 16)}px`;
      } else {
        /* search header */
        document.getElementById('mobile-search-header').style.top = `${(16 + 38 + 16 + 1)}px`;
        /* investigation list */
        document.getElementById('mobile-investigation-list').style.paddingTop = `${(16 + 38 + 16 + 1 + 16 + 56 + 16)}px`;
      }
    });
  }

  public async load(resetPage = true): Promise<void> {
    if (resetPage) {
      this.dataSource.pagingReset();
    }
    await this.dataSource.load();
  }

  public pageChange(pageNumber: number) {
    this.pageOptions?.paginate(pageNumber)
  }

  public onNearEndScroll() {
    const maxPage = Math.round(this.dataSource.listPage.totalCount / this.dataSource.listPage.limit);
    const nextPage = this.dataSource.listPage.currentPageIndex + 1;
    if (maxPage <= nextPage) return;
    this.pageOptions?.paginate({
      pageIndex: nextPage,
      previousPageIndex: this.dataSource.listPage.currentPageIndex,
      pageSize: this.dataSource.listPage.limit,
      length: 0
    }, false);
  }

  public orderInvestigationStaff(investigationStaff: IApiInvestigationStaff[]) {
    // Order investigation staff correctly
    // 1) Lead Investigator 2) Lead Tech Reviewer/Engineer 3) Remaining staff, alpha order
    if (investigationStaff && investigationStaff.length) {
      // First sort by created Date
      // This way we find the correct investigator / tech reviewer
      investigationStaff = investigationStaff.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      const staff = [];
      // Then find lead investigator / tech reviewer
      const leadInvestigator = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.Investigator);
      const leadTechOnly = investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer && obj.isPrimary === true)
        || investigationStaff.find((obj) => obj.Role.title === IApiInvestigationRoleNames.TechReviewer);

      if (leadInvestigator) staff.push(leadInvestigator);
      if (leadTechOnly) staff.push(leadTechOnly);
      return staff;
    } else {
      return [];
    }
  }

  public getIcon(RiskTypeId: string) {
    return getRiskTypeIcon(RiskTypeId);
  }

  actionSelected(action: string, investigation: IApiInvestigation) {
    const navigationExtras: NavigationExtras = {
      state: {
        data: {
          ...investigation
        }
      }
    };
    switch (action) {
      case "Data Request":
        this.router.navigate(['data-request'], navigationExtras)
        break;
      case "Enter Time and Expense":
        if (investigation?.InvestigationStaff?.find(staff => staff?.User?.id === this.currentUser.id)) {
          this.router.navigate(['time-and-expense'], navigationExtras)
        } else {
          this.router.navigate(['admin/feature-unavailable'], navigationExtras);
        }
        break;
      case "View Investigation Log":
        this.router.navigate(['investigations-log', investigation.id], navigationExtras)
        break;
      case "Schedule On Scene Exam Date": {
        if (investigation?.InvestigationStaff?.find(staff => staff?.User?.id === this.currentUser.id && staff?.Role?.title === IApiInvestigationRoleNames.Investigator)) {
          this.router.navigate(['schedule'], navigationExtras);
        } else {
          this.router.navigate(['admin/feature-unavailable'], navigationExtras);
        }
        break;
      }
      case "Update Scene Status":
        const dialog: DialogRef = this.dialogService.open({
          content: MobileChangeOnSceneStatusComponent,
          width: "100%",
          cssClass: "change-status-dialog",
          maxWidth: "100%",
          preventAction: (ev) => {
            return ev !== 'closed' as any;
          },
        });

        const dialogInstance = dialog.content.instance as MobileChangeOnSceneStatusComponent;
        dialogInstance.onScene = investigation?.NearOnScene;
        dialogInstance.investigation = investigation;
        dialog.result.pipe(
          filter((v) => !!v)
        ).subscribe((result: DialogCloseResult) => {
          if (result === true) {
            this.data = [];
            this.load(false);
          }
        });
        break;
      default:
        break;
    }
  }

  public addBodyClass(isOpen = true) {
    if (isOpen) {
      document.body.classList.add("kendo-dialog-open");
    } else {
      document.body.classList.remove('kendo-dialog-open');
    }
  }

  public filterValue(filter: IApiInvestigationFilterType) {
    // Need to parse true/false strings so they aren't misinterpreted by truthy/falsy
    const value = this._filters.find(({ type }) => type === filter)?.value;
    return (value === "true" || value === "false") ? JSON.parse(value) : value;
  }

  public setFilters(value: string | undefined, type: IApiInvestigationFilterType) {
    const hasValue = (val: any) => (val !== undefined) || (val !== null) || (val?.trim() !== ''); // We can have falsy values for some filters, so permit those but not undefined/null
    const filtersCopy = this._filters.filter(f => f.type !== type);
    this._filters = hasValue(value) ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
  }

  public applyFilters() {
    this.setFilters(
      this.viewByCreatedDate && this.viewByScheduleDate
        ? null
        : this.viewByCreatedDate
          ? "createdAt"
          : this.viewByScheduleDate
            ? "scheduledDate"
            : null,
      this.filterTypes.AppViewBy
    );

    this._filters.map((item) => {
      this.dataSource.applyFilter(item.type, item.value || null);
    });
    this.data = [];
    this.load(true);
  }

  public searchSubject: Subject<string> = new Subject<string>();
  public changedText(searchTerm: string) {
    this.searchSubject.next(searchTerm);
  }

  public clearFilter() {
    this.viewByCreatedDate = true;
    this.viewByScheduleDate = true;
    this._filters.map((item) => {
      this.dataSource.applyFilter(item.type, null);
    });
    this._filters = [];
    this.data = [];
    this.load(true);
  }

  public applySort() {
    this.dataSource.applyFilter(this.filterTypes.AppSort, JSON.stringify(this.sortModel));
    this.data = [];
    this.load(true);
  }

  public clearSort() {
    this.sortModel = { createdAt: 'DESC', scheduledDate: 'DESC' };
    this.dataSource.applyFilter(this.filterTypes.AppSort, JSON.stringify(this.sortModel));
    this.data = [];
    this.load(true);
  }

  public addInsuredContacted(investigation: IApiInvestigation) {
    const date = dayjs().tz(investigation.timezone, true).toISOString();
    const input = {
      InvestigationId: investigation.id,
      UserId: this.currentUser.id,
      date: date
    };
    this.insuredPartyService.addInsuredContact(input).subscribe();
  }

  public clientCallHistorySave(investigation: IApiInvestigation) {
    this.investigationService.update({
      id: investigation.id,
      History: {
        updateType: IApiInvestigationUpdateTypes.Update,
        updateCategory: IApiInvestigationUpdateCategories.Detail,
        comment: `Contacted client ${investigation.Client?.firstName} ${investigation.Client?.lastName}`,
        InvestigationId: investigation.id
      },
    }).subscribe();
  }

  public trackByIndex(index: number) {
    return index;
  }
}
