<!-- Permissions -->
<div [appEnforcePermission]="authConfig.staff" #staffList="appEnforcePermission"></div>
<!-- Permissions -->
<kendo-gridlayout [rows]="['auto']" [gap]="{ rows: 3, cols: 12 }" [cols]="['repeat(7,minmax(0,1fr))']">
    <kendo-gridlayout-item [colSpan]="2" [col]="0" [row]="0">
        <div class="kendo-formfield date-range-wrapper">
            <kendo-daterange #dateRangePicker style="gap: 0;">
                <kendo-label text="Sent Date (Start)" class="mr-16">
                    <kendo-dateinput #startDateRange calendarType="classic" class="mr-16 dateinput"
                        placeholder="Start Date" [fillMode]="'outline'" kendoDateRangeStartInput
                        [(ngModel)]="range.startDate" (ngModelChange)="updateDateFilters()"
                        (click)="focusDateInput(true)"></kendo-dateinput>
                </kendo-label>
                <kendo-label text="Sent Date (End)">
                    <kendo-dateinput #endDateRange calendarType="classic" class="mr-0 dateinput" placeholder="End Date"
                        [fillMode]="'outline'" kendoDateRangeEndInput [(ngModel)]="range.endDate"
                        (ngModelChange)="updateDateFilters()" (click)="focusDateInput(false)"></kendo-dateinput>
                </kendo-label>
                <kendo-daterange-popup #dateRangePickerPopup autoCorrectOn="blur"></kendo-daterange-popup>
            </kendo-daterange>
        </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
        <kendo-formfield class="kendo-formfield left-search-icon multi">
            <kendo-label [text]="'Editor'"></kendo-label>
            <div style="position: relative;">
                <i class="fa fa-search" aria-hidden="true"></i>
                <kendo-multiselect [checkboxes]="true" [autoClose]="false" [loading]="loading" [valuePrimitive]="true" [data]="users"
                    [(ngModel)]="selectedId" [rounded]="'full'" (valueChange)="setFilters($event, filterTypes.Editors)" valueField="id"
                    textField="itemFullName" [tagMapper]="tagMapper" [placeholder]="'Select editors'" [fillMode]="'outline'"
                    [kendoDropDownFilter]="{caseSensitive: false, operator: 'startsWith'}" [disabled]="!staffList?.enabled">
                <ng-template kendoMultiSelectItemTemplate let-dataItem>
                    <span style="padding-left: 5px;">{{ dataItem?.itemFullName }}</span>
                </ng-template>
                </kendo-multiselect>
            </div>
        </kendo-formfield>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
        <app-document-type-multi-select-kendo label="Report Type" placeholder="Select Report Type"
            [selectedId]="filterByIdValue(filterTypes.ReportType)"
            (selectionChanged)="setFilters($event, filterTypes.ReportType)"></app-document-type-multi-select-kendo>
    </kendo-gridlayout-item>
    

    <kendo-gridlayout-item [colSpan]="1">
        <div class="filter-actions">
            <div class="mr-16 applied-filter">
                <kendo-label text=""></kendo-label>
                <div>
                    <kendo-button kendoButton themeColor="primary" fillMode="solid" size="medium"
                        (click)="appliedFilters()">Apply Filters</kendo-button>
                </div>
            </div>
            <div class="mt-5 clear-filter">
                <kendo-label text=""></kendo-label>
                <div>
                    <kendo-button kendoButton themeColor="primary" fillMode="link" size="medium"
                        (click)="clearAll()">Clear All</kendo-button>
                </div>
            </div>
        </div>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
        <app-company-select-kendo label="Client (Company)" placeholder="Search Company"
            [selectedId]="filterByIdValue(filterTypes.ClientCompany)"
            (selectionChanged)="setFilters($event, filterTypes.ClientCompany)"></app-company-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
        <app-investigation-multi-select-kendo label="Investigation ID" placeholder="Search by nefco number"
            [selectedIds]="filterValue(filterTypes.Investigations)"
            (selectionChanged)="setFilters($event, filterTypes.Investigations)"></app-investigation-multi-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="2">
        <app-contact-multi-select-kendo placeholder="Search contact" label="Client (Contact)"
            [selectedId]="filterByIdValue(filterTypes.ClientContact)"
            (selectionChanged)="setFilters($event, filterTypes.ClientContact)">
        </app-contact-multi-select-kendo>
    </kendo-gridlayout-item>

    <kendo-gridlayout-item [colSpan]="7">
        <button kendoButton themeColor="light" fillMode="solid" iconClass="fa-solid fa-file-export" (click)="exportCSV()"
          class="mr-10">Export as CSV</button>
    </kendo-gridlayout-item>
</kendo-gridlayout>