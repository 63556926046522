import { Component, Input, Inject, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DialogCloseResult, DialogContentBase, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { EnforcePermissionDisplayModes } from 'src/app/shared/directives/enforce-permission.directive';
import {
  investigationStatusListView,
  investigationStatusUpdate,
  investigationCauseListView,
  investigationCauseUpdate,
  investigationCommentsView,
  investigationCommentsCreate,
  investigationInformationUpdate,
  investigationInformationListView
} from "src/app/shared/helpers/auth-config/investigations.config";
import { InvestigationStatusNames } from 'src/app/shared/helpers/helper';
import { InvestigationPriorities } from 'src/app/shared/modules/graphql/enums/investigation.enums';
import { GqlCommentService } from 'src/app/shared/modules/graphql/services';
import { IApiAddInvestigationHistoryInput, IApiCommentableType, IApiInvestigation, IApiInvestigationDetailCause, IApiInvestigationUpdateCategories, IApiInvestigationUpdateTypes } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { InvestigationDetailService, InvestigationService } from 'src/app/shared/services';
import { InvestigationHistoryModalComponent } from '../investigation-history-modal/investigation-history-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { InvestigationHistoryKendoModalComponent } from '../investigation-history-kendo-modal/investigation-history-kendo-modal.component';
import _ from 'lodash';

export interface IInvestigationDetailSidebarData {
  investigation: IApiInvestigation;
  from?: string;
}

@Component({
  selector: 'app-investigation-details-sidebar',
  templateUrl: './investigation-details-sidebar.component.html',
  styleUrls: ['./investigation-details-sidebar.component.scss']
})
export class InvestigationDetailsSidebarComponent extends DialogContentBase implements OnInit {

  public authConfig = {
    investigationStatusListView,
    investigationStatusUpdate,
    investigationCauseListView,
    investigationCauseUpdate,
    investigationCommentsView,
    investigationCommentsCreate,
    investigationInformationUpdate,
    investigationInformationListView
  };

  @Output() reload = new EventEmitter<boolean>();
  @ViewChild("investigationStatusUpdate") investigationStatusUpdatePermission;
  @ViewChild("form") form: NgForm;
  @ViewChild("priorityForm") priorityForm: NgForm;

  public comment: string = null;
  public statusComment: string = null;
  public reportHistoryColumns = ["date", "category", "type", "comment", "user"];
  public requestLogColumns = ["date", "type", "status", "comment", "user"];
  public commentLogColumns = ["date", "comment", "user"];
  public newStatus: string = null;

  // Selections
  public cause: string = null;
  public status: string = null;
  public priorityId: string = null;
  // Lists
  public causes = this.convertEnumIntoArray(IApiInvestigationDetailCause);
  public statuses = this.convertEnumIntoArray(InvestigationStatusNames);
  public statusName = InvestigationStatusNames;
  @Input() public data: IInvestigationDetailSidebarData;
  public showMoreChar = 85;
  public InvestigationPriorities = InvestigationPriorities;
  public investigation = null;
  public commentsData: GridDataResult;
  public oldStatus = '';

  public pageOptions = {
    pageSize: 10,
    skip: 0,
  }


  constructor(
    public dialog: DialogRef,
    private matDialog: MatDialog,
    private commentService: GqlCommentService,
    private loader: LoaderService,
    private investigationService: InvestigationService,
    private investigationDetailService: InvestigationDetailService,
    private notification: NotificationsService,
    private dialogService: DialogService
  ) {
    super(dialog);
  }

  ngOnInit() {
    if (this.data.from === 'INVESTIGATION_LIST') {
      this.getSideBarInvestigation();
    } else {
      this.data.investigation.History = this.data.investigation.History.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
      this.status = this.data.investigation?.status;
      this.oldStatus = this.status;
      this.cause = this.data.investigation?.Detail?.cause;
      this.loadCommentItems();
    }
  }

  public convertEnumIntoArray(enumObject): Object[] {
    return Object.keys(enumObject).map(key => ({ text: enumObject[key], value: key }));
  }

  public updateStatus() {
    if (this.form.invalid) {
      this.form.control.markAllAsTouched();
      return;
    }
    const invId = this.data.investigation.id;
    const history: IApiAddInvestigationHistoryInput = {
      comment: this.statusComment,
      InvestigationId: invId,
      updateCategory: IApiInvestigationUpdateCategories.Detail,
      updateType: IApiInvestigationUpdateTypes.Update,
    };
    // Returns a boolean if the cause was updated
    let historyAdd = { id: invId, History: history, Status: this.status };
    if (!this.investigationStatusUpdatePermission?.enabled) delete historyAdd.Status;
    this.loader.show$(
      (this.cause !== this.data.investigation?.Detail?.cause ? this.investigationDetailService.update({ id: this.data.investigation.Detail.id, InvestigationId: invId, cause: this.cause as IApiInvestigationDetailCause }) : of(true)).pipe(
        switchMap(() => this.investigationService.update(historyAdd)),
        switchMap(() => this.rehydrate()),
        this.notification.snackbarPipe("Investigation status updated!"),
        this.notification.snackbarErrorPipe("Unable to update investigation status, please try again")
      )).subscribe(() => {
        // Reset form but keep status state
        const statusTemp = this.status;
        this.form.resetForm();
        this.reload.emit(true);
        this.status = statusTemp;
        this.form.controls.status.setValue(this.status);
        this.form.controls.cause.setValue(this.cause);
      });
  }

  public savePriority() {
    if (!this.priorityForm.valid) {
      return;
    }

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationHistoryKendoModalComponent,
      width: "40%",
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationHistoryKendoModalComponent;
    dialogInstance.data = {
      // shallow copy so updates don't mutate locally
      investigation: { ...this.data.investigation },
      selectedCategory: IApiInvestigationUpdateCategories.Detail,
      selectedType: IApiInvestigationUpdateTypes.Update,
    };
    let historyAdd = { id: this.investigation.id, History: null, PriorityId: this.priorityForm.controls?.priorityId?.value };

    dialog.result.pipe(
      filter((v: DialogCloseResult) => {
        return _.isEmpty(v) ? false : !!v
      }),
      tap((v) => (historyAdd.History = v)),
      tap(() => this.priorityForm.form.markAsDirty()),
      switchMap(() =>
        this.loader.show$(
          this.investigationService.update(
            historyAdd
          )),
      ),
      this.notification.snackbarErrorPipe(),
      this.notification.snackbarPipe("Investigation Priority Updated!"),
      this.notification.resetDirtyFormPipe(() => true)
    ).subscribe((result: DialogCloseResult) => {
      if (this.form)
        this.form.resetForm();
      this.reload.emit(true);
      this.close(true);
    })
  }

  public resetPriorityForm() {
    this.priorityId = this.data.investigation?.Priority?.id;
    this.priorityForm?.controls?.priorityId.setValue(this.priorityId);
  }

  private rehydrate() {
    return this.investigationService.getById(this.data.investigation.id).pipe(
      tap((investigation) => {
        this.data.investigation = investigation;
        this.data.investigation.History = investigation.History.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1);
        this.status = this.data.investigation?.status;
        this.cause = this.data.investigation?.Detail?.cause;
        this.loadCommentItems()
      })
    );
  }

  public addComment() {
    // Add comment to investigation
    this.loader.show$(
      this.commentService.addComment({ commentableId: this.data.investigation.id, commentableType: IApiCommentableType.Investigation, text: this.comment }).pipe(
        switchMap(() => this.rehydrate())
      )
    ).subscribe(() => this.comment = null);

  }

  public getSideBarInvestigation() {
    this.loader.show$(this.investigationService.getByIdForSidebar(this.data.investigation.id)).subscribe(
      investigation => {
        this.investigation = investigation;
        this.data.investigation.Comments = investigation.Comments;
        this.loadCommentItems();
        this.resetPriorityForm();
      }
    );
  }

  public close(action = false) {
    if (this.status !== this.oldStatus) {
      action = true;
    }
    this.dialog.close(action);
  }

  public pageChange(event: PageChangeEvent): void {
    this.pageOptions.skip = event.skip;
    this.pageOptions.pageSize = event.take;
    this.loadCommentItems();
  }

  private loadCommentItems(): void {
    this.commentsData = {
      data: this.data?.investigation?.Comments?.slice(this.pageOptions.skip, this.pageOptions.skip + this.pageOptions.pageSize),
      total: this.data?.investigation?.Comments?.length,
    };
  }


}
