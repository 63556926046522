<!-- Permissions -->
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursListOwnAssign"
  #timeEntryInvestigationHoursListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageListOwnAssign"
  #expenseInvestigationMileageListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesListOwnAssign"
  #expenseInvestigationExpensesListOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursListAll"
  #timeEntryInvestigationHoursListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesListAll"
  #expenseInvestigationExpensesListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageListAll"
  #expenseInvestigationMileageListAll="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreateOwnAssign"
  #timeEntryInvestigationHoursCreateOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate"
  #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreateOwnAssign"
  #expenseInvestigationMileageCreateOwnAssign="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.timeEntryInvestigationHoursCreate"
  #timeEntryInvestigationHoursCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationMileageCreate"
  #expenseInvestigationMileageCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesCreate"
  #expenseInvestigationExpensesCreate="appEnforcePermission"></div>
<!-- Permissions -->
<!-- All Permissions That Are Not "ALL" -->
<div class="time-exp-header">
  <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(1,minmax(0,1fr))']"
    class="header-raw">
    <kendo-gridlayout-item class="mb-0 time-exp-title">
      <h3
        *ngIf="(timeEntryInvestigationHoursListOwnAssign?.enabled || timeEntryInvestigationHoursCreateOwnAssign?.enabled || timeEntryInvestigationHoursListAll?.enabled)"
        class="mb-0 ml-24">Time & Expense</h3>
      <p class="ml-24 mt-5">{{ investigation?.billingInstructions || '' }}</p>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
</div>
<ng-template #nonAdmin>
  <hr />
  <section class="template-form">
    <!-- Hours -->
    <section
      *ngIf="timeEntryInvestigationHoursListOwnAssign?.enabled || timeEntryInvestigationHoursCreateOwnAssign?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
        [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Hours</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item class="mb-0" *ngIf="timeEntryInvestigationHoursCreateOwnAssign?.enabled"
          style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 10px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Hours
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-time-form-kendo [investigationView]="true" (formSubmitted)="addTime($event)"
                [timeEntry]="timeEntry">
              </app-ind-time-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
            #allOnAssign="appEnforcePermission">
            <mat-form-field appearance="legacy">
              <mat-label>Staff</mat-label>
              <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                  {{user.User | fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-time-exp-hours-table-kendo *ngIf="timeEntryInvestigationHoursListOwnAssign?.enabled"
            [investigationId]="investigation?.id" [investigationView]="true" [refresh]="refreshHour"
            [employeeFilter]="employeeFilter" (changedFilter)="changedFilter($event)"
            [displayAllOption]="true"></app-time-exp-hours-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>

    <!-- Mileage -->
    <section
      *ngIf="expenseInvestigationMileageListOwnAssign?.enabled || expenseInvestigationMileageCreateOwnAssign?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
        [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Mileage</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngIf="expenseInvestigationMileageCreateOwnAssign?.enabled" class="mb-0"
          style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 10px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Mileage
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-mileage-form-kendo #timeExpenseForm [investigationView]="true" [mileage]="mileageForm"
                [investigations]="investigations" [userId]="staffMember" (formSubmitted)="addExpense($event, true)">
              </app-ind-mileage-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item class="mb-0">
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
            #allOnAssign="appEnforcePermission">
            <mat-form-field appearance="legacy">
              <mat-label>Staff</mat-label>
              <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                  {{user.User | fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-time-exp-expenses-table-kendo *ngIf="expenseInvestigationMileageListOwnAssign?.enabled"
            [expenseItems]="expenseItems" [investigationView]="true" [investigationId]="investigation?.id"
            [isMileage]="true" [userId]="''" [reloadExpense]="reloadExpense"
            [rangerAndWeekFilters]="rangerAndWeekFilters"></app-time-exp-expenses-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>

    <!-- Expenses -->
    <section *ngIf="expenseInvestigationExpensesListOwnAssign?.enabled || expenseInvestigationExpensesCreate?.enabled">
      <kendo-gridlayout class="panel-header" [rows]="['auto']" [gap]="{rows: 5, cols: 0}"
        [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item style="background:#FFFFFF;">
          <h2 class="ml-24">Expenses</h2>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 0}" [cols]="['repeat(2,minmax(0,1fr))']">
        <kendo-gridlayout-item *ngIf="expenseInvestigationExpensesCreate?.enabled"
          style="background: linear-gradient(0deg, rgba(1, 24, 98, 0.02), rgba(1, 24, 98, 0.02)), #FFFFFF; padding: 8px">
          <kendo-card class="kendocard">
            <kendo-card-header class="pagetitle">
              Add Expense
            </kendo-card-header>
            <kendo-card-body>
              <app-ind-expense-form-kendo #timeExpenseForm [investigationView]="true" [expense]="expenseEntry"
                [expenseItems]="expenseItems" [investigations]="investigations" [userId]="staffMember"
                (formSubmitted)="addExpense($event)">
              </app-ind-expense-form-kendo>
            </kendo-card-body>
          </kendo-card>
        </kendo-gridlayout-item>
        <kendo-gridlayout-item>
          <div class="equal staff" [appEnforcePermission]="authConfig.expenseInvestigationExpensesAllAssigned"
            #allOnAssign="appEnforcePermission">
            <mat-form-field appearance="legacy">
              <mat-label>Staff</mat-label>
              <mat-select [value]="staffMember" (selectionChange)="setUser($event)">
                <mat-option>All</mat-option>
                <mat-option *ngFor="let user of staffSort(investigation?.InvestigationStaff)" [value]="user.User.id">
                  {{user.User | fullName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <app-time-exp-expenses-table-kendo *ngIf="expenseInvestigationExpensesListOwnAssign?.enabled"
            [expenseItems]="expenseItems" [investigationView]="true" [investigationId]="investigation?.id"
            [isMileage]="false" [userId]="''" [reloadExpense]="reloadExpense"
            [rangerAndWeekFilters]="rangerAndWeekFilters"></app-time-exp-expenses-table-kendo>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </section>
  </section>
</ng-template>

<!-- Permision "ALL" Section -->
<!-- All only -->
<div [appEnforcePermission]="authConfig.expenseInvestigationExpensesAll" #adminPerm="appEnforcePermission">
  <section class="mat-typography p-4" *ngIf="adminPerm.enabled; else nonAdmin">

    <!-- Create Invoice Button -->
    <div class="my-3">
      <div class="dflex justify-content-end align-items-center">
        <span class="selectedrecord mr-16" *ngIf="totalselectedRaw > 0">
          {{totalselectedRaw}} {{totalselectedRaw > 1 ? 'entries' : 'entry'}} selected
        </span>
        <button kendoButton [size]="'medium'" themeColor="primary" fillMode="solid" iconClass="fas fa-plus" class="mr-8"
          (click)='openQbDialog()' [disabled]="totalselectedRaw === 0">
          Create Invoice
        </button>
      </div>
    </div>

    <!-- Outstanding Entries -->
    <!-- Header Row  -->
    <div class="">
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']"
        class="header-raw">
        <div class="dflex align-items-center">
          <h3 *ngIf="timeEntryInvestigationHoursListAll?.enabled" class="mb-0 ml-24">Outstanding Entries</h3>
        </div>
        <kendo-gridlayout-item class="filter-actions mb-8 mt-8">
          <div class="kendo-formfield width250 mr-24">
            <kendo-dropdownlist [data]="staffSort(investigation?.InvestigationStaff)" textField="userName"
              valueField="userId" fillMode="outline" [valuePrimitive]="true"
              [defaultItem]="{ userId: null, userName: 'Select staff' }" [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250
              }" (selectionChange)="setUser($event?.userId)">
              <ng-template kendoDropDownListValueTemplate let-dataItem>
                <span *ngIf="dataItem?.userName === 'Select staff'" class="placeholder">
                  {{ dataItem?.userName }}
                </span>
                <span *ngIf="dataItem?.userName !== 'Select staff'" class="not-placeholder">
                  {{ dataItem?.userName }}
                </span>
              </ng-template>
            </kendo-dropdownlist>
          </div>
          <!-- Show Date Entered Button -->
          <div class="d-flex align-items-center mr-24 width300">
            <input type="checkbox" #showAll kendoCheckBox [(ngModel)]="showDateEntered"
              [ngModelOptions]="{ standalone: true }" />
            <kendo-label class="k-checkbox-filter-label ml-12" [for]="showAll" text="Show Date Entered"></kendo-label>
          </div>
          <!-- Add Entry Button -->
          <div class="mr-24"
            *ngIf="timeEntryInvestigationHoursCreate?.enabled || expenseInvestigationMileageCreate?.enabled || expenseInvestigationExpensesCreate?.enabled">
            <kendo-dropdownbutton [data]="addEntryMenu" themeColor="light" fillMode="solid"
              buttonClass="grid-action-items" iconClass="fa-regular fa-plus" (itemClick)="itemSelected($event)"
              [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu mt-12'
              }">
              Add Entry
            </kendo-dropdownbutton>
          </div>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </div>

    <!-- Outstanding Entries Table -->
    <app-outstanding-admin *ngIf="timeEntryInvestigationHoursListAll?.enabled" [timeVisible]="timeVisible"
      [showDateEntered]="showDateEntered" [pageTitle]="'Hours'" [pageOptions]="timePageOptions"
      [clearSelection]="clearSelection" [(selectedTime)]="selectedTime" [type]="'time'"
      (sortChange)="sortChange($event)" (pageChange)="pageChange($event)" (removeEntry)="removeEntry($event)"
      (editEntry)="editTime($event)">
    </app-outstanding-admin>
    <app-outstanding-admin
      *ngIf="expenseInvestigationMileageListAll?.enabled || expenseInvestigationExpensesListAll?.enabled"
      [timeVisible]="expensesVisible" [showDateEntered]="showDateEntered" [clearSelection]="clearSelection"
      [(selectedExpenses)]="selectedExpenses" [pageTitle]="'Expenses & Mileage'" [pageOptions]="expensesPageOptions"
      [type]="'expenses-mileage'" (sortChange)="sortExpenceChange($event)" (pageChange)="pageExpenceChange($event)"
      (removeEntry)="removeEntry($event)" (editEntry)="editExpense($event)"></app-outstanding-admin>

    <!-- Evidence Section -->
    <div class="mt-24">
      <app-evidence-table [investigation]="investigation" [evidenceExpenses]="evidenceExpenses" [pageTitle]="'Evidence'"
        (addEvidenceExpense)="addEvidenceExpense()">
      </app-evidence-table>
    </div>

    <br>

    <!-- Outstanding Entries -->
    <!-- Header Row  -->
    <kendo-panelbar [expandMode]="2">
      <kendo-panelbar-item [expanded]="true">
        <ng-template kendoPanelBarItemTitle>
          <h3 class="mb-0">Invoiced Entries</h3>
        </ng-template>
        <ng-template kendoPanelBarContent>
          <section class="p-24">
            <h4>Invoices</h4>
            <kendo-grid [data]="{
                    data: (invoices?.contents$ | async) || [],
                    total: (invoices?.pageInfo$ | async)?.totalCount
                  }" [pageSize]="invoicesPageOptions?.limit" [skip]="invoicesPageOptions?.offset" [pageable]="{
                    buttonCount: 6,
                    type: 'numeric',
                    pageSizes: [5, 10, 25, 100],
                    previousNext: true,
                    responsive: true,
                    position: 'bottom'
                  }" (pageChange)="invoicesPageOptions.paginate($event, false)" [sortable]="false">
              <kendo-grid-column field="CREATED_AT" title="Date" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.createdAt | customDate : "MM/dd/yyyy" }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="DESCRIPTION" title="Description" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.details || '-' }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="AMOUNT" title="Amount" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ calculateAmount(dataItem) | currency }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="INVOICED_BY" title="Invoiced by" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.createdBy?.firstName }} {{ dataItem?.createdBy?.lastName }}
                </ng-template>
              </kendo-grid-column>

              <kendo-grid-column field="QB_ERRORS" title="Import Errors" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <kendo-panelbar class="error-panelbar" *ngIf="dataItem?.QbErrors?.length">
                    <kendo-panelbar-item *ngFor="let error of dataItem?.QbErrors" [expanded]="false">
                      <ng-template kendoPanelBarItemTitle>
                        <h3 class="mb-0">{{error?.statusCode}}</h3>
                      </ng-template>
                      <ng-template kendoPanelBarContent>
                        <p class="p-12" [innerHtml]="error.statusMessage"></p>
                      </ng-template>
                    </kendo-panelbar-item>
                  </kendo-panelbar>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="EXPORTED" title="Exported?" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <strong *ngIf="dataItem?.qbExported && !dataItem?.QbErrors.length" class="text-success">Complete</strong>
                  <strong
                    *ngIf="(!dataItem?.qbExported && !dataItem?.qbTicketId) || (!dataItem?.qbExported && dataItem?.qbTicketId && !dataItem?.QbErrors.length)"
                    class="text-warning">Pending</strong>
                  <strong *ngIf="dataItem?.qbTicketId && dataItem?.QbErrors.length" class="text-danger">Failed</strong>
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="INVOICE_NUMBER" title="Invoice #" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.invoiceNumber || '-' }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="ACTIONS" title="" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <ng-container *ngIf="!dataItem.qbExported">
                    <button kendoButton themeColor="primary" fillMode="link" size="medium"
                      iconClass="fa-solid fa-rotate-right" (click)="resubmitInvoice(dataItem)"></button>
                    <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-pen"
                      (click)="openQbDialog(dataItem)"></button>
                  </ng-container>
                  <button kendoButton themeColor="error" fillMode="link" iconClass="fa-solid fa-trash"
                    (click)="deleteInvoice(dataItem)"></button>
                  <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-list"
                    (click)="openQbDialog(dataItem, true)"></button>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
            <h4  class="mt-16">Invoiced Items</h4>
            <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
              [cols]="['repeat(6,minmax(0,1fr))']">
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-dropdownlist [data]="staffSort(investigation?.InvestigationStaff)" textField="userName"
                    valueField="userId" fillMode="outline" [valuePrimitive]="true"
                    [defaultItem]="{ userId: null, userName: 'All Staff' }"
                    [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
                    (selectionChange)="filterInvoice(IApiInvoiceItemFilterType.Staff, $event.userId)">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="dataItem?.userName === 'All Staff'" class="placeholder">
                        {{ dataItem?.userName }}
                      </span>
                      <span *ngIf="dataItem?.userName !== 'All Staff'" class="not-placeholder">
                        {{ dataItem?.userName }}
                      </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-dropdownlist [data]="timeExpenseTypeFilter" textField="name" valueField="id" fillMode="outline"
                    [valuePrimitive]="true" [defaultItem]="{ id: null, name: 'Type / Expense' }"
                    [popupSettings]="{ appendTo: 'component', animate: false, width: 250 }"
                    (selectionChange)="filterInvoice(IApiInvoiceItemFilterType.Type, $event.id)">
                    <ng-template kendoDropDownListValueTemplate let-dataItem>
                      <span *ngIf="dataItem?.name === 'Type / Expense'" class="placeholder">
                        {{ dataItem?.name }}
                      </span>
                      <span *ngIf="dataItem?.name !== 'Type / Expense'" class="not-placeholder">
                        {{ dataItem?.name }}
                      </span>
                    </ng-template>
                  </kendo-dropdownlist>
                </div>
              </kendo-gridlayout-item>
              <kendo-gridlayout-item>
                <div class="kendo-formfield">
                  <kendo-textbox name="search" [(ngModel)]="invoiceNumFilter"
                    (appAfterValueChanged)="filterInvoice(IApiInvoiceItemFilterType.Invoice, $event)" fillMode="outline"
                    placeholder="Invoice #" [clearButton]="true">
                  </kendo-textbox>
                </div>
              </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-grid [data]="{
                              data: (invoiceItems?.contents$ | async) || [],
                              total: (invoiceItems?.pageInfo$ | async)?.totalCount
                            }" [pageSize]="invoiceItemsPageOptions?.limit" [skip]="invoiceItemsPageOptions?.offset"
              [pageable]="{
                              buttonCount: 6,
                              type: 'numeric',
                              pageSizes: [5, 10, 25, 100],
                              previousNext: true,
                              responsive: true,
                              position: 'bottom'
                            }" (pageChange)="invoiceItemsPageOptions.paginate($event, false)" [sortable]="false">
              <kendo-grid-column field="WORK_DAY" title="Date" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem?.Expense ? (dataItem?.Expense?.expenseDate | customDate:
                  'MM/dd/yyyy' ) : (dataItem?.TimeEntry?.workday | customDate: 'MM/dd/yyyy')}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="STAFF" title="Staff" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{dataItem?.Expense ? (dataItem?.Expense?.User | fullName) : (dataItem?.TimeEntry?.User | fullName)}}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="TYPE" title="Type" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.ExpenseItem.name) :
                  (dataItem?.TimeEntry?.Type.name) }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="PURPOSE" title="Purpose" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.ExpensePurpose?.name) : "-" }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="BILLABLE" title="Billable" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.billableQuantity) : (dataItem?.TimeEntry?.hours) }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="NONBILLABLE" title="Non-Billable" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.nonBillableQuantity) : (dataItem?.TimeEntry?.nonBillableHours)
                  }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="DESCRIPTION" title="Description" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.description) :
                  (dataItem?.TimeEntry?.description) }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="PAID" title="Paid" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.paidDate | customDate: 'MM/dd/yyyy')
                  : (dataItem?.TimeEntry?.paidDate ? (dataItem?.TimeEntry?.paidDate | customDate: 'MM/dd/yyyy') : '') }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="APPROVAL" title="Approval" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Expense ? (dataItem?.Expense?.authorizedDate) : "" }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="INVOICE" title="Invoice #" [width]="12" [sortable]="false"
                [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  {{ dataItem?.Invoice?.invoiceNumber || '-' }}
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="ACTIONS" title="" [width]="12" [sortable]="false" [headerClass]="'nocursor'">
                <ng-template kendoGridCellTemplate let-dataItem>
                  <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-pen"
                    (click)="openDialog(dataItem?.Expense?.id ? dataItem.Expense : dataItem.TimeEntry, true)"></button>
                  <button kendoButton themeColor="primary" fillMode="link" size="medium" iconClass="fa-solid fa-list"
                    (click)="openQbDialog(dataItem?.Invoice, true)"></button>
                </ng-template>
              </kendo-grid-column>
            </kendo-grid>
          </section>
        </ng-template>
      </kendo-panelbar-item>
    </kendo-panelbar>
  </section>
</div>
