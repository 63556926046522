import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSelectChange } from "@angular/material/select";
import { ISelectEvent, IConfig } from "src/app/components/shared/expense-items-view/expense-items-view.component";
import { IApiDocumentType, IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseItemOrderBy, IApiGenerateReportXmlInput, IApiMarkAllExpensesAsPaid, IApiUser, IApiUserFilter, IApiUserFilterType } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { UserDataSource, UserService } from 'src/app/shared/services/user';
import moment from "moment";
import { DocumentTypeService, ExpenseItemService, ExpenseService } from 'src/app/shared/services';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { IInvestigationTimeExpenseModalData, InvestigationTimeAndExpModalKendoComponent } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { filter } from 'rxjs/operators';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { expenseInvestigationExpensesCreate, expenseInvestigationMileageCreate, expenseUnlinkedExpenseCreate, expenseUnlinkedMileageCreate } from 'src/app/shared/helpers/auth-config/time-expenses.config';
@Component({
  selector: 'app-bookkeeping-expense-reports',
  templateUrl: './bookkeeping-expense-reports.component.html',
  styleUrls: ['./bookkeeping-expense-reports.component.scss']
})
export class BookkeepingExpenseReportsComponent implements OnInit, AfterViewInit {

  public authConfig = {
    expenseUnlinkedExpenseCreate,
    expenseInvestigationExpensesCreate,
    expenseInvestigationMileageCreate,
    expenseUnlinkedMileageCreate,
  }

  @ViewChild("expenseUnlinkedExpenseCreate") expenseUnlinkedExpenseCreatePermission;
  @ViewChild("expenseInvestigationExpensesCreate") expenseInvestigationExpensesCreatePermission;
  @ViewChild("expenseInvestigationMileageCreate") expenseInvestigationMileageCreatePermission;
  @ViewChild("expenseUnlinkedMileageCreate") expenseUnlinkedMileageCreatePermission;

  @Input() public selectedTab: number;
  public documentTypes: IApiDocumentType[];

  private _expenseItems: IApiExpenseItem[] = null;

  public get expenseItems() {
    return this._expenseItems;
  }

  public set expenseItems(value: IApiExpenseItem[]) {
    this._expenseItems = value;
  }

  public reloadUserId: string = '';

  public adminConfig: IConfig = { admin: true };

  public userFilters: IApiUserFilter[] = [];
  public users: IApiUser[];
  public selectedUser = null;

  public expenseFilters?: IApiExpenseFilter[] = [];
  public reportTotal: string;

  // data source users
  private _dataSource: UserDataSource;

  public set dataSource(val) {
    this._dataSource = val;
  }

  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public get selectAllInter() {
    return Object.values(this.userSelection)?.some((v) => v === true) && this.selectAll === false ? true : false
  }

  public set selectAllInter(data) {
    this.selectAllInter = data;
  }

  public get selectAll() {
    return Object.values(this.userSelection)?.length && Object.values(this.userSelection)?.every((v) => v === true) ? true : false
  }

  public set selectAll(data) {
    this.selectAll = data;
  }

  public get selectedTotal(): number {
    return +(Object.values(this.selectedExpenseTotal)?.length ? Object.values(this.selectedExpenseTotal)?.reduce((a: number, b: number) => a + b, 0) : 0);
  }

  private _selectedMonth = NefcoDateHelper.dateFilterStringUTCFromLocal(moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  );
  public userExpenseSelection;
  public userSelection;
  public selectedExpenseTotal;

  public isMarkSelectedAsPaid: boolean = false;

  public currentDateRangeFilterType = 'ENTRY_DATE';
  public addEntryMenu = [];

  constructor(
    private userService: UserService,
    private loader: LoaderService,
    private expenseService: ExpenseService,
    private expenseItemService: ExpenseItemService,
    private documentTypeService: DocumentTypeService,
    private dialogService: DialogService,
    private notifications: NotificationsService
  ) {
    this.dataSource = new UserDataSource(this.userService);
    this.loader.attachObservable(this.dataSource.loading$);
    this.dataSource.listPage.limit = 5;

    // Expense Items
    this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.ASC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(
      unwrapConnection()
    ).subscribe(items => this.expenseItems = items);

    // Document Types
    this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(
      unwrapConnection(),
    ).subscribe((result) => {
      this.documentTypes = result;
    });

    this.dataSource.contents$.subscribe(res => {
      this.users = res;
      this.resetSelection();
      if (this.users.length) {
        this.users.forEach((user) => {
          const obj = {
            [user.id]: []
          }
          const userSelectObj = {
            [user.id]: false
          }
          const userExpenseSelectedTotal = {
            [user.id]: 0
          }
          this.selectedExpenseTotal = {
            ...this.selectedExpenseTotal,
            ...userExpenseSelectedTotal
          }
          this.userExpenseSelection = {
            ...this.userExpenseSelection,
            ...obj
          }
          this.userSelection = {
            ...this.userSelection,
            ...userSelectObj
          }
        })
      }
    })
  }


  ngOnInit(): void {
    this.getSelectedTabResults();
  }

  ngAfterViewInit() {
    if (this.expenseUnlinkedExpenseCreatePermission?.enabled || this.expenseInvestigationExpensesCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Expense'});
    }
    if (this.expenseInvestigationMileageCreatePermission?.enabled || this.expenseUnlinkedMileageCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Mileage'});
    }
  }

  getSelectedTabResults() {
    if (this.selectedTab === 0) {
      /* Out Of Pocket Tab Filter */
      this.setUserDateFilter(this._selectedMonth);
      // this.setUserFilters('true', IApiUserFilterType.ExpensePaidDateIsNull);
      this.setUserFilters('1', IApiUserFilterType.ExpenseMileageType);
      this.setUserFilters('1', IApiUserFilterType.ExpenseStatus);

      this.setExpenseFilters('1', IApiExpenseFilterType.Status);
      this.setExpenseFilters('1', IApiExpenseFilterType.PocketExpenseMileage);
      // this.setExpenseFilters('false', IApiExpenseFilterType.ExpensePaid)
      this.setExpenseDateFilters(this._selectedMonth)
    } else if (this.selectedTab === 1) {
      /* Nefco Card Tab Filter */
      this.setUserDateFilter(this._selectedMonth);
      // this.setUserFilters('true', IApiUserFilterType.ExpensePaidDateIsNull);
      this.setUserFilters('0', IApiUserFilterType.ExpenseMileageType);
      this.setUserFilters('1', IApiUserFilterType.ExpenseStatus);

      this.setExpenseFilters('1', IApiExpenseFilterType.Status);
      this.setExpenseFilters('0', IApiExpenseFilterType.PocketExpenseMileage);
      // this.setExpenseFilters('false', IApiExpenseFilterType.ExpensePaid)
      this.setExpenseDateFilters(this._selectedMonth)
    } else if (this.selectedTab === 2) {
      /* Vendo/Other Tab Filter */
      this.setUserDateFilter(this._selectedMonth);
      // this.setUserFilters('true', IApiUserFilterType.ExpensePaidDateIsNull);
      this.setUserFilters('2', IApiUserFilterType.ExpenseMileageType);
      this.setUserFilters('1', IApiUserFilterType.ExpenseStatus);

      this.setExpenseFilters('1', IApiExpenseFilterType.Status);
      this.setExpenseFilters('2', IApiExpenseFilterType.PocketExpenseMileage);
      // this.setExpenseFilters('false', IApiExpenseFilterType.ExpensePaid)
      this.setExpenseDateFilters(this._selectedMonth)
    }
    this.setUserFilters("true", IApiUserFilterType.ExpenseFilterOutForUser);
    this.setExpenseFilters("true", IApiExpenseFilterType.ExpenseFilterOutForUser);
    this.setUserFilters('true', IApiUserFilterType.ViewBookkeepingReport);

    this.getUsers();
  }

  public getUsers() {
    this.dataSource.load();
    this.getReportTotal();
  }

  setExpenseDateFilters(date) {
    if (this.currentDateRangeFilterType === 'ENTRY_DATE') {
      // this.setExpenseFilters(String(date.month() + 1), IApiExpenseFilterType.EntryMonth);
      // this.setExpenseFilters(String(date?.year()), IApiExpenseFilterType.EntryYear);
      this.setExpenseFilters(date, IApiExpenseFilterType.EntryDateRange);
      this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseDateRange);
      // this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseMonth);
      // this.setExpenseFilters(null, IApiExpenseFilterType.ExpenseYear);
    } else {
      // this.setExpenseFilters(String(date.month() + 1), IApiExpenseFilterType.ExpenseMonth);
      // this.setExpenseFilters(String(date?.year()), IApiExpenseFilterType.ExpenseYear);
      this.setExpenseFilters(date, IApiExpenseFilterType.ExpenseDateRange);
      this.setExpenseFilters(null, IApiExpenseFilterType.EntryDateRange);
      // this.setExpenseFilters(null, IApiExpenseFilterType.EntryMonth);
      // this.setExpenseFilters(null, IApiExpenseFilterType.EntryYear);
    }
  }

  setUserDateFilter(date) {
    if (this.currentDateRangeFilterType === 'ENTRY_DATE') {
      // this.setUserFilters(String(date.month() + 1), IApiUserFilterType.EntryMonth);
      // this.setUserFilters(String(date?.year()), IApiUserFilterType.EntryYear);
      this.setUserFilters(date, IApiUserFilterType.EntryDateRange);
      this.setUserFilters(null, IApiUserFilterType.ExpenseDateRange);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseMonth);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseYear);
    } else {
      // this.setUserFilters(String(date.month() + 1), IApiUserFilterType.ExpenseMonth);
      // this.setUserFilters(String(date?.year()), IApiUserFilterType.ExpenseYear);
      this.setUserFilters(date, IApiUserFilterType.ExpenseDateRange);
      this.setUserFilters(null, IApiUserFilterType.EntryDateRange);
      // this.setUserFilters(null, IApiUserFilterType.EntryMonth);
      // this.setUserFilters(null, IApiUserFilterType.EntryYear);
    }
  }

  getReportTotal() {

    let userFilters = JSON.parse(JSON.stringify(this.dataSource.lastFilters));
    // if users are selected then we need to use expense filter data to get total
    if (this.getUserFilter(IApiUserFilterType.UserIdIn)?.value) {
      // const expenseMonth = this.currentDateRangeFilterType === 'ENTRY_DATE' ? this.getExpenseFilter(IApiExpenseFilterType.EntryMonth)?.value : this.getExpenseFilter(IApiExpenseFilterType.ExpenseMonth)?.value;
      // const expenseYear = this.currentDateRangeFilterType === 'ENTRY_DATE' ? this.getExpenseFilter(IApiExpenseFilterType.EntryYear)?.value : this.getExpenseFilter(IApiExpenseFilterType.ExpenseYear)?.value;;
      // const expensePaid = this.getExpenseFilter(IApiExpenseFilterType.ExpensePaid)?.value;
      const expenseMileageType = this.getExpenseFilter(IApiExpenseFilterType.PocketExpenseMileage)?.value;
      const expenseStatus = this.getExpenseFilter(IApiExpenseFilterType.Status)?.value;

      userFilters = [
        ...this.dataSource.lastFilters,
        {
          type: this.currentDateRangeFilterType === 'ENTRY_DATE' ? IApiUserFilterType.EntryDateRange : IApiUserFilterType.ExpenseDateRange,
          value: this.currentDateRangeFilterType === 'ENTRY_DATE' ? this.getExpenseFilter(IApiExpenseFilterType.EntryDateRange)?.value : this.getExpenseFilter(IApiExpenseFilterType.ExpenseDateRange)?.value
        },
        // {
        //   type: "EXPENSE_PAID_DATE_IS_NULL",
        //   value: expensePaid === 'false' ? 'true' : 'false'
        // },
        {
          type: IApiUserFilterType.ExpenseMileageType,
          value: expenseMileageType
        },
        {
          type: IApiUserFilterType.ExpenseStatus,
          value: expenseStatus
        }
      ]
    }
    userFilters.push({
      type: IApiExpenseFilterType.ExpenseFilterOutForUser,
      value: "true"
    });

    this.expenseService.getExpenseReportTotalAll(userFilters).subscribe(res => {
      this.reportTotal = res || '0'
    })
  }


  private setUserFilters(value: string | undefined, type: IApiUserFilterType) {
    const filtersCopy = this.userFilters.filter(f => f.type !== type);
    this.userFilters = [...filtersCopy, {
      type: type,
      value: value
    }];
    this.userFilters.map(item => {
      this.dataSource.applyFilter(item.type, item.value);
    });
  }

  private getUserFilter(type: IApiUserFilterType) {
    return this.userFilters?.find((f) => f.type === type)
  }

  private getExpenseFilter(type: IApiExpenseFilterType) {
    return this.expenseFilters?.find((f) => f.type === type)
  }

  private setExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this.expenseFilters.filter(f => f.type !== type);
    this.expenseFilters = value ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
  }

  public itemSelected($event, user: IApiUser) {
    this.reloadUserId = '';
    let action: any = ExpenseActionType.ADD_EXPENSE;
    switch ($event?.text) {
      case 'Add Expense':
        action = ExpenseActionType.ADD_EXPENSE;
        break;
      case 'Add Mileage':
        action = ExpenseActionType.ADD_MILEAGE;
        break;
      default:
        action = '';
        break;
    }

    if (!action) {
      return
    }
    const expenseItem = {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: action === ExpenseActionType.ADD_EXPENSE ? 1 : 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      description: null,
      User: user,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: null,
      nefcoNumber: null,
      expense: expenseItem as IApiExpense,
      time: null,
      staff: null,
      adminView: false,
      showStaffMember: false,
      actionType: action
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = [];
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.reloadUserId = user.id;
        this.getReportTotal();
      }
    })
  }

  public selection(event: ISelectEvent) {
    // change items that are selected
  }

  public resetSelection() {
    this.userSelection = {};
    this.userExpenseSelection = {};
    this.selectedExpenseTotal = {};
    this.checkMarkSelectedAsPaid();
  }

  public markSelectedPaid() {
    let expenseSelectArray = [];
    Object.keys(this.userExpenseSelection).forEach(v => expenseSelectArray.push(...this.userExpenseSelection[v]));
    // mark currently selected items as paid
    if (expenseSelectArray?.length) {
      this.loader.show$(this.expenseService.markSelectedPaid({ expenseIds: expenseSelectArray }).pipe(
        this.notifications.snackbarPipe("Expenses are marked as paid"),
        this.notifications.snackbarErrorPipe("Error marking expenses as paid; please try again"),
      )).subscribe((res) => {
        this.resetSelection();
        this.dataSource.pagingReset();
        this.dataSource.load();
      });
    }
  }

  public markAllAsPaid() {
    const isEntryDate = this.currentDateRangeFilterType === 'ENTRY_DATE' ? true : false;
    //type 0 out of pocket, type 1 nefco card, type 2 vendor/other
    const userFilter = this.getUserFilter(IApiUserFilterType.UserIdIn);
    const params: IApiMarkAllExpensesAsPaid = {
      dateRange: isEntryDate ? String(this.getExpenseFilter(IApiExpenseFilterType.EntryDateRange)?.value) : String(this.getExpenseFilter(IApiExpenseFilterType.ExpenseDateRange)?.value),
      isEntryDate,
      type: this.selectedTab,
      userIds: userFilter?.value ? JSON.parse(userFilter?.value) : null
    }
    this.loader.show$(this.expenseService.markAllSelectedAsPaid(params).pipe(
      this.notifications.snackbarPipe("All Expenses are marked as paid"),
      this.notifications.snackbarErrorPipe("Error marking expenses as paid; please try again"),
    )).subscribe((res) => {
      this.resetSelection();
      this.dataSource.pagingReset();
      this.dataSource.load();
    });
  }

  public downloadXlsx() {
    // expense mileage type 0 = nefco card, 1 = out of pocket, 2 = vendor/other
    let expenseMileageType = 0;
    let tabName = 'ExpenseReport';
    switch (this.selectedTab) {
      case 0:
        expenseMileageType = 1;
        tabName = 'OutOfPocketExpenseReport';
        break;
      case 1:
        expenseMileageType = 0;
        tabName = "NefcoCardExpenseReport";
        break;
      case 2:
        expenseMileageType = 2;
        tabName = "VendorOtherReport";
        break;
      default:
        break;
    }
    const datePrefix = moment(JSON.parse(this._selectedMonth)?.startDate)?.format("MMMM-YYYY");
    tabName = tabName + '-' + datePrefix;
    const isEntryDate = this.currentDateRangeFilterType === 'ENTRY_DATE' ? true : false;

    const params: IApiGenerateReportXmlInput = {
      dateRange: isEntryDate ? String(this.getExpenseFilter(IApiExpenseFilterType.EntryDateRange)?.value) : String(this.getExpenseFilter(IApiExpenseFilterType.ExpenseDateRange)?.value),
      isEntryDate,
      expenseMileageType,
      status: 1,
    };
    const userFilter = this.getUserFilter(IApiUserFilterType.UserIdIn);
    if (userFilter?.value) {
      params.users = JSON.parse(userFilter?.value);
    }
    // download selected expense reports
    this.loader.show$(this.expenseService.generateReportXML(params).pipe(
      this.notifications.snackbarErrorPipe("Error downloading report; please try again"),
    )).subscribe((res) => {
      if (res)
        this.saveAsXlsxFile(res, tabName);
    });
  }

  public saveAsXlsxFile(data, tabName) {
    var mediaType = "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
    var a = document.createElement('a');
    a.href = mediaType + data;
    a.download = `${tabName}.xlsx`;
    a.textContent = 'Download file!';
    document.body.appendChild(a);
    a.click();
    a.remove();
  }

  public dateChange(date) {
    if(!date){
      return;
    }
    this.dataSource.pagingReset();
    this._selectedMonth = date;
    const userFilter = this.getUserFilter(IApiUserFilterType.UserIdIn)?.value;
    if (userFilter) {
      // if users are selected then remove user month/year and type filters to load user with empty data
      // this.setUserFilters(null, IApiUserFilterType.EntryMonth);
      // this.setUserFilters(null, IApiUserFilterType.EntryYear);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseMonth);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseYear);
      this.setUserFilters(null, IApiUserFilterType.EntryDateRange);
      this.setUserFilters(null, IApiUserFilterType.ExpenseDateRange);
      this.setUserFilters(null, IApiUserFilterType.ExpensePaidDateIsNull);
      this.setUserFilters(null, IApiUserFilterType.ExpenseMileageType);
      this.setUserFilters(null, IApiUserFilterType.ExpenseStatus);
      this.setExpenseDateFilters(date);
      this.getUsers();
    } else {
      this.setUserFilters(null, IApiUserFilterType.UserIdIn);
      this.getSelectedTabResults();
    }
  }

  public userChange(users) {
    this.dataSource.pagingReset();
    if (users.length) {
      /* RESET user filter to show if there is no expenses */
      this.setUserFilters(JSON.stringify(users), IApiUserFilterType.UserIdIn);
      // this.setUserFilters(null, IApiUserFilterType.EntryMonth);
      // this.setUserFilters(null, IApiUserFilterType.EntryYear);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseMonth);
      // this.setUserFilters(null, IApiUserFilterType.ExpenseYear);
      this.setUserFilters(null, IApiUserFilterType.EntryDateRange);
      this.setUserFilters(null, IApiUserFilterType.ExpenseDateRange);
      this.setUserFilters(null, IApiUserFilterType.ExpensePaidDateIsNull);
      this.setUserFilters(null, IApiUserFilterType.ExpenseMileageType);
      this.setUserFilters(null, IApiUserFilterType.ExpenseStatus);
      this.getUsers();
    } else {
      this.setUserFilters(null, IApiUserFilterType.UserIdIn);
      this.getSelectedTabResults();
      this.reloadUserId = null;
    }
  }

  public pageChange(event) {
    this.pageOptions?.paginate(event)
  }

  public updateExpenseUserSelection(event, userId) {
    this.userExpenseSelection[userId] = event[userId]?.values;
    this.userSelection[userId] = event[userId]?.metadata?.selectAll;
    this.checkMarkSelectedAsPaid();
  }

  public userSelectionChange(event, userId) {
    if (event.target.checked === false)
      this.userExpenseSelection[userId] = []
    this.checkMarkSelectedAsPaid();
  }

  public pageSelectAllChange(e) {
    if (e.target.checked) {
      Object.keys(this.userSelection).forEach(v => this.userSelection[v] = true);
    } else {
      Object.keys(this.userExpenseSelection).forEach(v => this.userExpenseSelection[v] = [])
      Object.keys(this.userSelection).forEach(v => this.userSelection[v] = false)
    }
    this.checkMarkSelectedAsPaid();
  }

  reloadTotalChange(event) {
    this.getReportTotal();
  }

  private checkMarkSelectedAsPaid() {
    let expenseSelectArray = [];
    Object.keys(this.userExpenseSelection).forEach(v => expenseSelectArray.push(...this.userExpenseSelection[v]));
    if (expenseSelectArray?.length) {
      this.isMarkSelectedAsPaid = true;
    } else {
      this.isMarkSelectedAsPaid = false;
    }
  }

  dateRangeTypeChange(type: string): void {
    this.currentDateRangeFilterType = type;
    this.getSelectedTabResults();
  }

  public addButtonPermisison() {
    if (this.selectedTab === 1) {
      if (this.expenseUnlinkedExpenseCreatePermission?.enabled || this.expenseInvestigationExpensesCreatePermission?.enabled) return true;
      else return false;
    } else {
      if (this.expenseUnlinkedExpenseCreatePermission?.enabled || this.expenseInvestigationExpensesCreatePermission?.enabled
        || this.expenseInvestigationMileageCreatePermission?.enabled || this.expenseUnlinkedMileageCreatePermission?.enabled
      ) return true;
      else return false;
    }
  }

}
