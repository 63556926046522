import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { CertificationSidebarComponent, ICertificationSidebarData } from "../certification-sidebar/certification-sidebar.component";
import { IApiCertification, IApiCertificationType, IApiCertificationTypeFilter, IApiCertificationTypeOrderBy } from 'src/app/shared/modules/graphql/types/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { CertificationTypeService } from 'src/app/shared/services/certification/certification-type/certification-type.service';
import { CertificationTypeDataSource } from 'src/app/shared/services/certification/certification-type/certification-type.datasource';
import { CertificationModalComponent } from "../certification-modal/certification-modal.component";
import { SelectionModel } from '@angular/cdk/collections';
import { CertificationErrorPipe } from 'src/app/shared/pipes';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, switchMap } from 'rxjs/operators';
import { manageCertificationsUpdate, manageCertificationsDelete, manageCertificationsCreate } from "src/app/shared/helpers/auth-config/manage.config";
import { SortDescriptor } from '@progress/kendo-data-query';
import { AuthService, StateService } from 'src/app/shared/services';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { forkJoin } from 'rxjs';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import _ from 'lodash';


@UntilDestroy()
@Component({
  selector: 'app-certifications-list',
  templateUrl: './certifications-list.component.html',
  styleUrls: ['./certifications-list.component.scss']
})
export class CertificationsListComponent implements OnInit {

  public authConfig = {
    manageCertificationsCreate,
    manageCertificationsUpdate,
    manageCertificationsDelete
  };

  @Input() selectableMode = false;
  @Input() certifications = [];
  @Output() done: EventEmitter<IApiCertificationType[]> = new EventEmitter();

  public selectionModel = new SelectionModel<any>(true, []);

  private _filters: IApiCertificationTypeFilter[] = [];
  public get filters() {
    return this._filters;
  }
  public set filters(val) {
    this._filters = val;
  }

  private _dataSource: CertificationTypeDataSource;
  public get dataSource() {
    return this._dataSource;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  public get columnsToDisplay(): string[] {
    if (this.selectableMode) return ["select", "TITLE", "STATE"];
    else return ["TITLE", "AGENCY", "STATE", "investigatorCount", "alerts", "more"];
  }

  public sort: SortDescriptor[] = [
    {
      field: IApiCertificationTypeOrderBy.Title,
      dir: "asc",
    },
  ];
  public data: IApiCertificationType[];
  public actionItem: { text: string }[] = [];
  constructor(
    private loader: LoaderService,
    private notification: NotificationsService,
    private certificatetionType: CertificationTypeService,
    private certificationTypeService: CertificationTypeService,
    private stateService: StateService,
    private authService: AuthService,
    private certificationError: CertificationErrorPipe,
    public dialogService: DialogService,
  ) {
  }

  public ngOnInit() {
    this._dataSource = new CertificationTypeDataSource(this.certificationTypeService);
    this.dataSource.listPage.sortOrder = SortOrder.ASC;
    this.dataSource.listPage.orderBy = IApiCertificationTypeOrderBy.Title;
    this.loader.attachObservable(this._dataSource.loading$);
    
    forkJoin([
      this.authService.hasCategoryPermission(this.authConfig.manageCertificationsUpdate.category, this.authConfig.manageCertificationsUpdate.appliedPermissions),
      this.authService.hasCategoryPermission(this.authConfig.manageCertificationsDelete.category, this.authConfig.manageCertificationsDelete.appliedPermissions)
    ]).subscribe(([up, del]) => {
      if (up) {
        this.actionItem.push({text: 'View'}, {text: 'Update'});
      }
      if (del) {
        this.actionItem.push({text: 'Delete'});
      }
    });

    this.load();

    this.dataSource.contents$.subscribe((res) => {
      this.data = res;
    });
    
    if (this.certifications.length) this.selectionModel.select(...this.certifications);
  }

  public view(cert: IApiCertification) {
    const data: ICertificationSidebarData = {
      certification: cert,
      config: {
        singleInvestigator: null
      }
    };

    const dialog: DialogRef = this.dialogService.open({
      content: CertificationSidebarComponent,
      width: "50%",
      height: "100vh",
      cssClass: 'right-position certification-sidebar',
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as CertificationSidebarComponent;
    dialogInstance.data = data;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });  
  }

  public addEdit(data: any) {
    const dialog: DialogRef = this.dialogService.open({
      content: CertificationModalComponent,
      width: "50%",
      preventAction: (ev) => {
        return ev !== ("closed" as any);
      },
    });

    const dialogInstance = dialog.content.instance as CertificationModalComponent;
    dialogInstance.data = data;
    dialog.result
    .pipe(
      filter((v: DialogCloseResult) => {
        return !_.isEmpty(v);
      })
    ).subscribe((res) => {
      if (res) {
        this.load()
      }
    });
  }

  public filterChange({ type, value }: IApiCertificationTypeFilter) {
    this.dataSource.applyFilter(type, value);
    this.dataSource.load();
  }

  public load() {
    this.dataSource.pagingReset();
    this.dataSource.load(this._filters);
  }

  public removeChip(sel: IApiCertificationType) {
    this.selectionModel.deselect(sel);
  }

  public save() {
    this.done.emit(this.selectionModel.selected);
  }

  public get isAllSelected(): boolean {
    return this.selectionModel.selected.length === this.data.length;
  }
  
  public onCheckAllChange(event: Event): void {
    if ((event.target as HTMLInputElement).checked) {
      this.selectAll();
    } else {
      this.deselectAll();
    }
  }

  public onCheckRowChange(dataItem: IApiCertificationType): void {
    this.selectionModel.toggle(dataItem);
  }

  public toggleSelectAll(): void {
    if (this.isAllSelected) {
      this.deselectAll();
    } else {
      this.selectAll();
    }
  }

  private selectAll(): void {
    this.selectionModel.select(...this.data);
  }

  private deselectAll(): void {
    this.selectionModel.clear();
  }

  public expiredCount(certifications: IApiCertification[] = []): number {
    return certifications.filter((e) => this.certificationError.transform(e))?.length || 0;
  }

  public expiresSoonCount(certifications: IApiCertification[] = []) {
    return certifications.filter((e) => this.certificationError.transform(e, 6, 0))?.length || 0;
  }

  public deleteCertificationType(id: string) {
    this.notification.kendoConfirm("Really remove this certification type?", "Are you sure?").pipe(
      filter(response => !!response),
      switchMap(() => 
        this.loader.show$(this.certificatetionType.remove(id))
      )
    ).pipe(
      this.notification.snackbarErrorPipe("Error removing certification type; please try again"),
      this.notification.snackbarPipe("Certification type removed successfully!")
    ).subscribe(() => {
      this.load();
    });
  }

  public getStateName(stateCode: string) {
    return this.stateService.allStates.find(i => i.value === stateCode)?.text || '-';
  }

  public sortChange = (e) => {
    this.sort = e;
    if (e && e?.[0]?.dir) {
      this.dataSource.listPage.orderBy = e?.[0]?.field;
      this.dataSource.listPage.sortOrder =
        e?.[0]?.dir === "asc" ? SortOrder.ASC : SortOrder.DESC;
    } else {
      this.dataSource.listPage.orderBy = IApiCertificationTypeOrderBy.Title;
      this.dataSource.listPage.sortOrder = SortOrder.ASC;
    }
    this.load();
  };

  public filterActionItem(dataItems: IApiCertificationType) {
    return this.actionItem.filter((item) => !(dataItems.Certifications.length === 0 && item.text === 'View'));
  }

  public itemSelected(action: { text: string }, dataItem: IApiCertification) {
    switch (action.text) {
      case "View":
        this.view(dataItem);
        break;
      case "Update":
        this.addEdit(dataItem);
        break;
      case "Delete":
        this.deleteCertificationType(dataItem?.id);
        break;
      default:
        break;
    }
  }
}
