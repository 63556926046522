<!-- Permissions -->
<div [appEnforcePermission]="authConfig.evidence" #evidence="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDownload" #evidenceDownload="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceView" #evidenceView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceCreate" #evidenceCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdate" #evidenceUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceUpdateOwn" #evidenceUpdateOwn="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceDelete" #evidenceDelete="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferCreate" #evidenceTransferCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceTransferUpdate" #evidenceTransferUpdate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceBilling" #evidenceBilling="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.evidenceBillingUpdate" #evidenceBillingUpdate="appEnforcePermission"></div>
<!-- Permissions -->
<section class="p-16" *ngIf="evidence?.enabled">
  <!-- ButtonHeader -->
  <h2 *ngIf="ueNumber">UE Number: {{ ueNumber }}</h2>
  <kendo-gridlayout class="mt-16" [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(2,minmax(0,1fr))']">
    <kendo-gridlayout-item>
      <!-- actionMenu -->
      <ng-container *ngIf="evidenceTransferCreate?.enabled
        || evidenceDownload?.enabled
        || evidenceDelete?.enabled
        || evidenceUpdate?.enabled
        || evidenceUpdateOwn?.enabled">
        <kendo-dropdownbutton [data]="evidenceActions" themeColor="light" fillMode="solid"
          buttonClass="grid-action-items-reverse"
          [iconClass]="!isOpenMoreActions ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"
          (open)="isOpenMoreActions = true" (close)="isOpenMoreActions = false" (itemClick)="itemSelected($event)"
          [disabled]="selectedEvidenceItems?.length <= 0" [popupSettings]="{
                appendTo: 'component',
                animate: false,
                width: 250,
                popupClass: 'grid-action-items-menu mt-12'
            }">
          Actions
          <ng-template kendoDropDownButtonItemTemplate let-dataItem>
            <span class="gray-item">{{ dataItem.text }}</span>
          </ng-template>
        </kendo-dropdownbutton>
      </ng-container>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(3,minmax(0,1fr))']">
        <!-- addEvidenceItem -->
        <kendo-gridlayout-item>
          <button kendoButton themeColor="light" fillMode="solid" *ngIf="evidenceCreate?.enabled"
            [disabled]="hasNewItem" iconClass="fa-solid fa-plus" (click)="addItem()">Add Item</button>
        </kendo-gridlayout-item>
        <!-- viewPhotos -->
        <kendo-gridlayout-item>
          <button kendoButton themeColor="light" fillMode="solid" [disabled]="hasNewItem" iconClass="fa-solid fa-camera"
            (click)="viewPhotos()">View Photos</button>
        </kendo-gridlayout-item>
        <!-- DownloadMenu -->
        <kendo-gridlayout-item class="text-right" *ngIf="evidenceDownload?.enabled">
          <kendo-dropdownbutton [data]="downloadActions" themeColor="primary" fillMode="solid"
            buttonClass="grid-action-items-reverse" [iconClass]="'fa-solid fa-chevron-down'"
            (itemClick)="downloadItemSelected($event)" [popupSettings]="{
                    appendTo: 'component',
                    animate: false,
                    width: 250,
                    popupClass: 'grid-action-items-menu mt-12'
                }">
            Download
            <ng-template kendoDropDownButtonItemTemplate let-dataItem>
              <span class="gray-item">{{ dataItem }}</span>
            </ng-template>
          </kendo-dropdownbutton>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <!-- EvidenceLoop -->
  <kendo-gridlayout class="align-items-center" *ngFor="let evidenceItem of evidenceItems; let i = index;" class="mt-16"
    [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(12,minmax(0,1fr))']">
    <!-- evidenceNumber -->
    <kendo-gridlayout-item>
      <kendo-label text=""></kendo-label>
      <div
        *ngIf="evidenceItem.id && ((isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || evidenceUpdate?.enabled)"
        class="kendo-formfield service-checkbox">
        <input type="checkbox" kendoCheckBox [(ngModel)]="evidenceItem.isSelected" />
        <kendo-label class="ml-12 mr-24 k-checkbox-filter-label"
          [text]="evidenceItem.evidenceNumber | evidenceNumber"></kendo-label>
      </div>
    </kendo-gridlayout-item>
    <!-- description -->
    <kendo-gridlayout-item [colSpan]="2">
      <kendo-formfield>
        <kendo-label class="asterisk" [for]="description" text="Description"></kendo-label>
        <kendo-textbox #description="ngModel" appNoWhiteSpace [(ngModel)]="evidenceItem.description" name="description"
          (ngModelChange)="updateValidation(i, $event, 'descriptionError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          placeholder="Enter description" fillMode="outline" [clearButton]="false" required></kendo-textbox>
        <kendo-formerror *ngIf="!description?.valid && (description?.dirty || description?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Description
            cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <!-- takenFrom -->
    <kendo-gridlayout-item [colSpan]="2">
      <kendo-formfield>
        <kendo-label class="asterisk" [for]="takenFrom" text="Taken From"></kendo-label>
        <kendo-textbox #takenFrom="ngModel" appNoWhiteSpace [(ngModel)]="evidenceItem.takenFrom" name="takenFrom"
          (ngModelChange)="updateValidation(i, $event, 'takenFromError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          placeholder="Enter taken from" fillMode="outline" [clearButton]="false" required></kendo-textbox>
        <kendo-formerror *ngIf="!takenFrom?.valid && (takenFrom?.dirty || takenFrom?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Taken From
            cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <!-- Status -->
    <kendo-gridlayout-item [colSpan]="2">
      <kendo-formfield>
        <kendo-label class="asterisk" text="Status"></kendo-label>
        <kendo-dropdownlist [data]="statusTypes" name="status" #status="ngModel" [(ngModel)]="evidenceItem.Status"
          [valuePrimitive]="true" fillMode="outline"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          (ngModelChange)="updateValidation(i, $event, 'statusError')"
          [popupSettings]="{appendTo: 'component', animate: false}" required>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="'not-placeholder'"> {{ dataItem | titlecase }} </span>
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            <span [ngClass]="'not-placeholder'"> {{ dataItem | titlecase }} </span>
          </ng-template>
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="!status?.valid && (status?.dirty || status?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Status
            cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <!-- evidenceStorageLocation -->
    <kendo-gridlayout-item [colSpan]="2">
      <kendo-formfield>
        <kendo-label class="asterisk" text="Location"></kendo-label>
        <kendo-dropdownlist [data]="evidenceStorageLocations" [(ngModel)]="evidenceItem.EvidenceStorageLocation.id"
          [name]="'storageLocationId' + i" #location="ngModel" [valuePrimitive]="true" fillMode="outline"
          [defaultItem]="{id: null, name: 'Select Location'}" textField="name" valueField="id"
          (ngModelChange)="updateValidation(i, $event, 'evidenceStorageLocationError')"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          [popupSettings]="{appendTo: 'component', animate: false}" required>
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.name }} </span>
          </ng-template>
        </kendo-dropdownlist>
        <kendo-formerror *ngIf="!location?.valid && (location?.dirty || location?.touched)">
          <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
          <span>Location
            cannot be left blank</span>
        </kendo-formerror>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [for]="section" text="Section"></kendo-label>
        <kendo-textbox #section="ngModel" appNoWhiteSpace [(ngModel)]="evidenceItem.section" name="section"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          placeholder="Enter section" fillMode="outline" [clearButton]="false"></kendo-textbox>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item>
      <kendo-formfield>
        <kendo-label [for]="row" text="Row"></kendo-label>
        <kendo-textbox #row="ngModel" appNoWhiteSpace [(ngModel)]="evidenceItem.row" name="row"
          [disabled]="!(evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled))"
          placeholder="Enter row" fillMode="outline" [clearButton]="false"></kendo-textbox>
      </kendo-formfield>
    </kendo-gridlayout-item>
    <kendo-gridlayout-item class="text-right">
      <kendo-label text=""></kendo-label>
      <div>
        <button kendoButton themeColor="primary" [hidden]="!evidenceItem?.CurrentTransfer"
          *ngIf="evidenceTransferUpdate?.enabled" fillMode="link" (click)="receiveItem(evidenceItem)"
          iconClass="fa-solid fa-circle-right fa-lg"></button>
        <button kendoButton themeColor="primary" [hidden]="!evidenceItem.id" *ngIf="evidenceView?.enabled"
          fillMode="link" (click)="openSidebar(evidenceItem)" iconClass="fa-solid fa-external-link-alt fa-lg"></button>
        <button kendoButton themeColor="primary" [hidden]="evidenceItem.id" fillMode="link"
          (click)="cancelItem(evidenceItem)" iconClass="fa-solid fa-xmark fa-lg"></button>
        <button kendoButton themeColor="primary" [disabled]="disableCheckMark" fillMode="link"
          (click)="saveItem(i, evidenceItem)"
          *ngIf="evidenceUpdate?.enabled || (isOwn(evidenceItem?.CreatedBy?.id) && evidenceUpdateOwn?.enabled) || (!evidenceItem?.id && evidenceCreate?.enabled)"
          iconClass="fa-solid fa-check fa-lg"></button>
      </div>
    </kendo-gridlayout-item>
  </kendo-gridlayout>
  <kendo-card width="" class="mt-4" *ngIf="evidenceBilling?.enabled">
    <kendo-card-body>
      <kendo-gridlayout *ngIf="evidenceBillingUpdate?.enabled" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
        [cols]="['repeat(1,minmax(0,1fr))']">
        <kendo-gridlayout-item class="text-right">
          <button kendoButton themeColor="primary" fillMode="link"
            *ngIf="evidenceBillingEditMode; else evidenceBillingEditButton" (click)="evidenceBillingEditMode = false"
            iconClass="fa-solid fa-circle-xmark fa-lg"></button>
          <ng-template #evidenceBillingEditButton>
            <button kendoButton themeColor="primary" fillMode="link" (click)="evidenceBillingEditMode = true"
              iconClass="fa-solid fa-pen fa-lg"></button>
          </ng-template>
        </kendo-gridlayout-item>
      </kendo-gridlayout>
      <form #evidenceBillingForm="ngForm">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 16}" [cols]="['repeat(12,minmax(0,1fr))']">
          <kendo-gridlayout-item [colSpan]="2">
            <h4>Evidence Billing</h4>
            <kendo-formfield>
              <kendo-label class="asterisk" [for]="palletCount" text="# of Pallets"></kendo-label>
              <kendo-textbox #palletCount="ngModel" [pattern]="'^[0-9]+$'"
                [(ngModel)]="investigation.EvidenceBilling.palletCount" name="palletCount"
                (ngModelChange)="onPalletChange(palletCount)" [disabled]="!evidenceBillingUpdate?.enabled"
                placeholder="Enter pallets" fillMode="outline" [clearButton]="false"></kendo-textbox>
            </kendo-formfield>
            <kendo-formerror
              *ngIf="!palletCount?.valid && (palletCount?.dirty || palletCount?.touched) && palletCount?.errors?.pattern">
              <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
              <span>Pallet count should be valid number.</span>
            </kendo-formerror>
          </kendo-gridlayout-item>
          <kendo-gridlayout-item [colSpan]="10">
            <ng-container *ngIf="evidenceBillingEditMode; else billingView">
              <p>Billing Override Information</p>
              <kendo-gridlayout class="mt-12" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['repeat(6,minmax(0,1fr))']">
                <kendo-gridlayout-item [colSpan]="12">
                  <kendo-formfield>
                    <div class="kendo-radio">
                      <input type="radio" name="bill" id="Billable" kendoRadioButton
                        [(ngModel)]="investigation.EvidenceBilling.billable" [value]="true" [checked]="true" />
                      <label class="k-radio-label mr-24" for="Billable">Billable</label>
                      <input type="radio" name="bill" id="Non-Billable" kendoRadioButton
                        [(ngModel)]="investigation.EvidenceBilling.billable" [value]="false" />
                      <label class="k-radio-label" for="Non-Billable">Non-Billable</label>
                    </div>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [colSpan]="2">
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Bill Rate"></kendo-label>
                    <div class="numeric-text-suffix">
                      <kendo-numerictextbox #billRate="ngModel" type="number"
                        [(ngModel)]="investigation.EvidenceBilling.rate" name="rate" fillMode="outline"
                        [autoCorrect]="false" required></kendo-numerictextbox>
                    </div>
                    <kendo-formerror *ngIf="!billRate?.valid && (billRate?.dirty || billRate?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span>Bill rate cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [colSpan]="2">
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Billing Schedule"></kendo-label>
                    <kendo-dropdownlist [data]="billingOptions" name="billingFrequency" #billingFrequency="ngModel"
                      [(ngModel)]="investigation.EvidenceBilling.BillingFrequency.id" [valuePrimitive]="true"
                      textField="name" valueField="id" fillMode="outline"
                      [popupSettings]="{appendTo: 'component', animate: false}" required>
                      <ng-template kendoDropDownListValueTemplate let-dataItem>
                        <span [ngClass]="dataItem?.id ? 'not-placeholder' : 'placeholder'"> {{ dataItem?.name }}
                        </span>
                      </ng-template>
                    </kendo-dropdownlist>
                    <kendo-formerror
                      *ngIf="!billingFrequency?.valid && (billingFrequency?.dirty || billingFrequency?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span>Billing frequency
                        cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!-- billing date -->
                <kendo-gridlayout-item [colSpan]="2">
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Billing Date"></kendo-label>
                    <kendo-datepicker [(ngModel)]="investigation.EvidenceBilling.startDate" name="billingDate"
                      calendarType="classic" [fillMode]="'outline'" placeholder="month/day/year" format="MM/dd/yyyy"
                      formatPlaceholder="formatPattern" #billingDate="ngModel" [required]="true"></kendo-datepicker>
                    <kendo-formerror *ngIf="!billingDate?.valid && (billingDate?.dirty || billingDate?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span
                        *ngIf="billingDate?.errors?.required || billingDate?.errors?.minError || billingDate?.errors?.maxError">
                        Billing date cannot be left blank
                      </span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <!-- currentExpensed -->
                <kendo-gridlayout-item [colSpan]="2">
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Current Bill"></kendo-label>
                    <kendo-datepicker [(ngModel)]="investigation.EvidenceBilling.currentExpensed" name="currentExpensed"
                      calendarType="classic" [fillMode]="'outline'" placeholder="month/day/year" format="MM/dd/yyyy"
                      formatPlaceholder="formatPattern" #currentExpensed="ngModel"
                      [required]="false"></kendo-datepicker>
                  </kendo-formfield>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [colSpan]="4">
                  <kendo-formfield>
                    <kendo-label class="asterisk" text="Notes"></kendo-label>
                    <kendo-textbox appNoWhiteSpace #notes="ngModel" type="number"
                      [(ngModel)]="investigation.EvidenceBilling.notes" name="notes" fillMode="outline"
                      [clearButton]="false" required></kendo-textbox>
                    <kendo-formerror *ngIf="!notes?.valid && (notes?.dirty || notes?.touched)">
                      <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      <span>Notes cannot be left blank</span>
                    </kendo-formerror>
                  </kendo-formfield>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </ng-container>
            <ng-template #billingView>
              <p>Billing Override Information</p>
              <kendo-gridlayout class="mt-12" [rows]="['auto']" [gap]="{rows: 5, cols: 16}"
                [cols]="['repeat(6,minmax(0,1fr))']">
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Bill Rate</p>
                  <p class="kendo-caption-body" [innerHTML]="investigation.EvidenceBilling?.rate"></p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Billing Schedule</p>
                  <p class="kendo-caption-body" [innerHTML]="investigation.EvidenceBilling?.BillingFrequency?.name">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Billing Date</p>
                  <p class="kendo-caption-body"
                    [innerHTML]="investigation.EvidenceBilling?.startDate ? (investigation.EvidenceBilling?.startDate | customDate: 'MM/dd/yyyy') : '' ">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Last Billed Date</p>
                  <p class="kendo-caption-body"
                    [innerHTML]="investigation.EvidenceBilling?.currentExpensed ? (investigation.EvidenceBilling?.currentExpensed | customDate: 'MM/dd/yyyy') : '' ">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Previous Billed Date</p>
                  <p class="kendo-caption-body"
                    [innerHTML]="investigation.EvidenceBilling?.lastExpensed ? (investigation.EvidenceBilling?.lastExpensed | customDate: 'MM/dd/yyyy') : '' ">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Next Bill</p>
                  <p class="kendo-caption-body" [innerHTML]="getNextBill()"></p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item>
                  <p class="kendo-caption">Billable</p>
                  <p class="kendo-caption-body" [innerHTML]="investigation?.EvidenceBilling?.billable ? 'Yes' : 'No'">
                  </p>
                </kendo-gridlayout-item>
                <kendo-gridlayout-item [colSpan]="2">
                  <p class="kendo-caption">Notes</p>
                  <p class="kendo-caption-body" [innerHTML]="investigation?.EvidenceBilling?.notes"></p>
                </kendo-gridlayout-item>
              </kendo-gridlayout>
            </ng-template>
          </kendo-gridlayout-item>
        </kendo-gridlayout>
      </form>
      <div class="text-right" *ngIf="evidenceBillingEditMode">
        <button kendoButton themeColor="primary" fillMode="solid"
          [disabled]="!evidenceBillingForm.valid || !evidenceBillingForm.form.dirty"
          (click)="saveEvidenceBilling()">Save</button>
      </div>
    </kendo-card-body>
  </kendo-card>
  <!-- /EvidenceLoop -->
</section>
