<!-- Permissions -->
<div [appEnforcePermission]="authConfig.investigationCreate" #investigationCreate="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationView" #staffContactInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffContactInformationOwnView" #staffContactInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationView" #staffUserInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffUserInformationOwnView" #staffUserInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationView" #staffEmergencyInformationView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.staffEmergencyInformationOwnView" #staffEmergencyInformationOwnView="appEnforcePermission"></div>
<div [appEnforcePermission]="authConfig.downloadMobileApplicationAll" #downloadMobileApplicationAll="appEnforcePermission"></div>
<!-- Permissions -->

<section class="d-flex-justify-content-between-align-center m-16">
  <a [routerLink]="['/']">
    <img src="assets/logo.png" alt="NEFCO" />
  </a>
  <div class="d-flex-justify-content-between-align-center">
    <kendo-dropdownbutton
        [data]="actions"
        themeColor="light"
        fillMode="solid"
        buttonClass="grid-action-items-reverse"
        [iconClass]="isActionsOpen ? 'fa-solid fa-chevron-down' : 'fa-solid fa-chevron-up'"
        (open)="isActionsOpen = true"
        (close)="isActionsOpen = false"
        (itemClick)="actionClickedFn($event)"
        [popupSettings]="{
            appendTo: 'component',
            animate: false,
            popupClass: 'grid-action-items-menu'
        }"
        >
        Welcome, {{ user | fullName }}
    </kendo-dropdownbutton>
    <div class="notifications ml-8">
      <span class="unread" *ngIf="notifications?.length"></span>
      <kendo-button class="text-decoration-none" #anchor (click)="toggle(!show)" kendoButton themeColor="base" fillMode="link" size="medium"
        iconClass="fa-solid fa-bell fa-xl">
      </kendo-button>
      <kendo-popup
        #popup
        [anchor]="anchor.element"
        [anchorAlign]="anchorAlign"
        [popupAlign]="popupAlign"
        *ngIf="show"
      >
        <div class="notifications-content">
          <kendo-gridlayout
            *ngIf="!loading"
            [rows]="['auto']"
            [cols]="['100%']"
          >
            <kendo-gridlayout-item class="mb-0" *ngIf="notifications?.length">
              <kendo-listview
                [data]="notifications"
                class="notifications-list"
                containerClass="k-d-flex k-flex-col k-flex-nowrap"
              >
                  <ng-template kendoListViewHeaderTemplate>
                    <div class="notification-header">
                      <span>Notifications ({{getCount}} unread)</span>
                      <!-- <i class="close-notification fa-solid fa-xmark fa-lg" (click)="toggle(!show)"></i> -->
                    </div>
                  </ng-template>
                  <ng-template
                    kendoListViewItemTemplate
                    let-dataItem="dataItem"
                    let-isLast="isLast"
                  >
                    <div class="content-details">
                      <div class="content">
                        <div>
                          <i [class]="getIcon(dataItem?.Type?.entity)"></i>
                          <a href="javascript:void(0)" (click)="viewPage(dataItem)" [innerHTML]="dataItem?.message"></a>
                        </div>
                        <!-- START UNREAD -->
                        <span class="unread" kendoTooltip
                        tooltipClass="tooltipwidthauto"
                        position="left"
                        title="Mark as read"
                        (click)="markNotificationsAsReadOrUnread($event, true, dataItem?.id)"
                        *ngIf="!dataItem?.viewed"><i class="fa-solid fa-circle fa-2xs read-unread"></i></span>
                        <!-- END UNREAD -->
                         <!-- START READ -->
                        <span class="read" kendoTooltip
                        tooltipClass="tooltipwidthauto"
                        position="left"
                        title="Mark as Unread"
                        (click)="markNotificationsAsReadOrUnread($event, false, dataItem?.id)"
                        *ngIf="dataItem?.viewed"><i class="fa-solid fa-check read-unread"></i></span>
                        <!-- END READ -->
                      </div>
                      <div class="sub-content">
                        <div class="name-page">{{dataItem?.Sender ? (dataItem?.Sender | fullName) : ''}}<span class="color-hide" *ngIf="dataItem?.Sender">&nbsp;|&nbsp;</span>{{dataItem?.Type?.entity | quiet}}</div>
                        <div class="time">{{dataItem?.createdAt | customDate : "MM/dd/yyyy"}} at {{dataItem?.createdAt | customDate : "h:mm a"}}</div>
                      </div>
                    </div>
                    <div class="content-info" *ngIf="isLast">
                      <span><i class="fa-solid fa-flag"></i></span>
                      <p>That’s all your most recent notifications. To view all your notifications, click on “View All” below.</p>
                    </div>
                  </ng-template>
                  <ng-template kendoListViewFooterTemplate>
                    <div class="notification-footer">
                      <button
                        kendoButton
                        class="mr-24"
                        themeColor="primary"
                        fillMode="link"
                        (click)="redirecToList()"
                      >
                      View All
                      </button>
                    </div>
                  </ng-template>
              </kendo-listview>
            </kendo-gridlayout-item>
            <kendo-gridlayout-item class="mb-0" *ngIf="!notifications?.length">
              <kendo-listview
                [data]="[{id : null}]"
                [height]="220"
                class="notifications-list no-notifications"
                containerClass="k-d-flex k-flex-col k-flex-nowrap"
              >
                  <ng-template kendoListViewHeaderTemplate>
                    <div class="notification-header">
                      <span>Notifications</span>
                      <!-- <i class="close-notification fa-solid fa-xmark fa-lg" (click)="toggle(!show)"></i> -->
                    </div>
                  </ng-template>
                  <ng-template
                    kendoListViewItemTemplate
                    let-dataItem="dataItem"
                  >
                    <div class="content-details">
                      <div class="no-notification">
                        <span>0 Unread Notifications</span>
                        <p class="mb-0">You’re all caught up! Looks like you don’t have any unread notifications.</p>
                      </div>
                    </div>
                  </ng-template>
                  <ng-template kendoListViewFooterTemplate>
                    <div class="notification-footer">
                      <button
                        kendoButton
                        class="mr-24"
                        themeColor="primary"
                        fillMode="link"
                        [routerLink]="['/notifications']"
                        (click)="redirecToList()"
                      >
                      View All
                      </button>
                    </div>
                  </ng-template>
              </kendo-listview>
            </kendo-gridlayout-item>
            </kendo-gridlayout>
            <kendo-gridlayout *ngIf="loading"
                [rows]="['auto']"
                [cols]="['100%']"
              >
                <kendo-gridlayout-item class="mb-0">
                  <div  class="k-loading-panel">
                    <div class="k-loading-panel-mask"></div>
                    <div class="k-loading-panel-wrapper">
                      <kendo-loader
                        type="infinite-spinner"
                        themeColor="primary"
                        size="large"
                      >
                      </kendo-loader>
                      <div class="k-loading-panel-text">Loading notifications</div>
                    </div>
                  </div>
                </kendo-gridlayout-item>
            </kendo-gridlayout>
        </div>
      </kendo-popup>
    </div>
    <kendo-button style="text-decoration: none;" kendoButton class="ml-8" themeColor="base" fillMode="link" size="medium"
      iconClass="fa-regular fa-clock fa-xl" [routerLink]="['/time/management']">
    </kendo-button>
    <div style="display: flex;">
      <kendo-button style="text-decoration: none;" kendoButton class="ml-8 mr-8" themeColor="base" fillMode="link" size="medium"
        iconClass="fa-solid fa-magnifying-glass fa-xl" (click)="showSearch = !showSearch">
      </kendo-button>
      <ng-container *ngIf="showSearch">
        <kendo-gridlayout [rows]="['auto']" [gap]="{rows: 5, cols: 8}" [cols]="['repeat(2,minmax(0,1fr))']">
          <kendo-gridlayout-item class="mb-0" style="width: 240px;">
            <kendo-formfield class="left-search-icon">
              <div style="position: relative;">
                <i class="fa fa-search" aria-hidden="true"></i>
                <kendo-textbox
                  name="nefcoNumSearch"
                  id="nefcoSearch" 
                  [(ngModel)]="nefcoNumberSearch"
                  (ngModelChange)="!nefcoNumberSearch ? goToInvestigations() : onChangeUpdateFiltes()"
                  (keydown.enter)="goToInvestigations()"
                  placeholder="Nefco # Search"
                  fillMode="outline"
                  [clearButton]="true">
                </kendo-textbox>
              </div>
            </kendo-formfield>
          </kendo-gridlayout-item>

          <kendo-gridlayout-item class="mb-0" style="width: 240px;">
            <kendo-formfield class="left-search-icon">
              <div style="position: relative;">
                <i class="fa fa-search" aria-hidden="true"></i>
                <kendo-textbox
                  name="claimPolicyNumSearch"
                  id="claimSearch" 
                  [(ngModel)]="policyClaimNumberSearch"
                  (ngModelChange)="!policyClaimNumberSearch ? goToInvestigations() : onChangeUpdateFiltes()"
                  (keydown.enter)="goToInvestigations()"
                  placeholder="Policy / Claim # Search"
                  fillMode="outline"
                  [clearButton]="true">
                </kendo-textbox>
              </div>
            </kendo-formfield>
          </kendo-gridlayout-item>
        </kendo-gridlayout>          
      </ng-container>
    </div>
    <kendo-button kendoButton class="ml-8" themeColor="base" fillMode="solid" size="medium"
      [routerLink]="['/admin/investigations/create']" style="width: 240px;">
      Create Investigation
    </kendo-button>
  </div>
</section>
