import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IApiDocumentType, IApiDocumentTypeFilterType, IApiDocumentTypeOrderBy, IApiRiskType } from '../../modules/graphql/types/types';
import { DocumentTypeService, RiskTypesService } from '../../services';
import _ from 'lodash';
import { SortOrder } from '../../modules/graphql/enums/generic.enums';
import { DocumentCategories } from '../../modules/graphql/constants/document.constants';
import { unwrapConnection } from '../../rxjs.pipes';

@Component({
  selector: 'app-document-type-multi-select-kendo',
  templateUrl: './document-type-multi-select-kendo.component.html',
  styleUrls: ['./document-type-multi-select-kendo.component.scss']
})
export class DocumentTypeMultiSelectKendoComponent implements OnInit, OnChanges {

  @Input() placeholder = "Select Document type";
  @Input() label = "Document Type";
  @Input() disabled = false;
  @Input() selectedId: Array<string> = [];
  @Input() documentCategories: DocumentCategories[] = [DocumentCategories.REPORT];
  @Output() selectionChanged = new EventEmitter<string[] | null>();

  public selectedValue = [];
  public riskTypes: IApiRiskType[] = [];
  public documentTypes: IApiDocumentType[] = [];
  constructor(
    private riskTypeService: RiskTypesService,
    private documentTypeService: DocumentTypeService,
  ) {}

  ngOnInit(): void {
    this.documentTypeService.get(
      [
        {
          type: IApiDocumentTypeFilterType.Categories,
          value: JSON.stringify(this.documentCategories),
        },
      ],
      {
        orderBy: IApiDocumentTypeOrderBy.Name,
        sortOrder: SortOrder.ASC,
        limit: -1,
      }
    ).pipe(unwrapConnection()).subscribe((types) => this.documentTypes = types);
    
    this.riskTypeService.riskTypes().subscribe((types) => this.riskTypes = types);
    if (!this.selectedId || this.selectedId?.length === 0) {
      this.selectedValue = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!_.isEqualWith(this.selectedValue, changes?.selectedId?.currentValue)) {
      this.selectedId = changes?.selectedId?.currentValue || [];
        if (this.selectedId?.length) {
          this.selectedValue = this.selectedId
        } else {
          this.selectedValue = [];
        }
    }
  }

  public searchSelect($event) {
    this.selectedId = $event;
    this.selectionChanged.emit(this.selectedId);
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 1 ? tags : [tags];
  }

}
