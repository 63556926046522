import { unwrapConnection } from 'src/app/shared/rxjs.pipes';
import { ExpenseItem } from './../../../../shared/modules/graphql/interfaces/expense.interfaces';
import { ExpenseItemService } from './../../../../shared/services/expenses/expense-item/expense-item.service';
import { ExpenseService } from './../../../../shared/services/expenses/expense.service';
import { ExpenseDataSource } from './../../../../shared/services/expenses/expense.datasource';
import { IApiDocumentType, IApiExpense, IApiExpenseFilterType, IApiExpenseItem, IApiExpenseItemFilter, IApiExpenseItemFilterType, IApiExpenseItemOrderBy, IApiTimeEntry, IApiTimeEntryFilterType, IApiTimeEntryOrderBy, IApiTimeEntryType, IApiTimeEntryTypeFilter, IApiTimeEntryTypeFilterType, IApiTimeEntryTypeOrderBy, IApiUserFilterType } from './../../../../shared/modules/graphql/types/types';
import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { DocumentTypeService, DownloadHelper, TimeEntryTypeService, AuthService } from "../../../../shared/services";
import { filter, tap } from 'rxjs/operators';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { InvestigationTimeAndExpModalKendoComponent } from '../../investigations';
import { forkJoin } from 'rxjs';
import { TimeEntryDataSource, TimeEntryService } from 'src/app/shared/services/time-entry';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortDescriptor } from '@progress/kendo-data-query';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { NgForm } from '@angular/forms';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { ExpenseActionType } from 'src/app/shared/modules/graphql/enums/expense.enums';
import { IInvestigationTimeExpenseModalData } from '../../investigations/investigation-time-and-exp-modal-kendo/investigation-time-and-exp-modal-kendo.component';
import { bookKeepingTimeExpensesEntryListExpensesMileage, bookKeepingTimeExpensesEntryListHours, bookKeepingTimeExpensesExpensesMileageAccountSummary, bookKeepingTimeExpensesHoursAccountSummary } from 'src/app/shared/helpers/auth-config/bookkeeping.config';
import { expenseInvestigationExpensesCreate, expenseInvestigationExpensesUpdate, expenseInvestigationExpensesUpdateOwn, expenseInvestigationMileageCreate, expenseInvestigationMileageUpdate, expenseInvestigationMileageUpdateOwn, expenseUnlinkedExpenseCreate, expenseUnlinkedExpenseUpdate, expenseUnlinkedExpenseUpdateOwn, expenseUnlinkedMileageCreate, expenseUnlinkedMileageUpdate, expenseUnlinkedMileageUpdateOwn, timeEntryInvestigationHoursCreate, timeEntryInvestigationHoursUpdate, timeEntryInvestigationHoursUpdateOwn, timeEntryUnlinkedHoursCreate, timeEntryUnlinkedHoursUpdate, timeEntryUnlinkedHoursUpdateOwn } from 'src/app/shared/helpers/auth-config/time-expenses.config';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SharedService } from 'src/app/common/shared.service';

export enum dataType {
  hours = "hours",
  expenses = "expenses"
}

export enum advanceFiltersType {
  Type = 'Type',
  Paid = 'Paid',
  Invoiced = 'Invoiced'
}
@UntilDestroy()
@Component({
  selector: 'app-bookkeeping-time-and-expenses',
  templateUrl: './bookkeeping-time-and-expenses.component.html',
  styleUrls: ['./bookkeeping-time-and-expenses.component.scss']
})
export class BookkeepingTimeAndExpensesComponent implements OnInit, AfterViewInit {

  public authConfig = {
    bookKeepingTimeExpensesEntryListHours,
    bookKeepingTimeExpensesEntryListExpensesMileage,
    bookKeepingTimeExpensesHoursAccountSummary,
    bookKeepingTimeExpensesExpensesMileageAccountSummary,
    expenseUnlinkedMileageUpdate,
    expenseUnlinkedMileageUpdateOwn,
    expenseInvestigationMileageUpdate,
    expenseInvestigationMileageUpdateOwn,
    expenseInvestigationExpensesUpdate,
    expenseInvestigationExpensesUpdateOwn,
    expenseUnlinkedExpenseUpdate,
    expenseUnlinkedExpenseUpdateOwn,
    timeEntryUnlinkedHoursUpdate,
    timeEntryUnlinkedHoursUpdateOwn,
    timeEntryInvestigationHoursUpdate,
    timeEntryInvestigationHoursUpdateOwn,
    timeEntryInvestigationHoursCreate,
    timeEntryUnlinkedHoursCreate,
    expenseUnlinkedMileageCreate,
    expenseInvestigationMileageCreate,
    expenseInvestigationExpensesCreate,
    expenseUnlinkedExpenseCreate
  }

  @ViewChild("form") form: NgForm;
  @ViewChild("filterForm") filterForm: NgForm;
  @ViewChild("bookKeepingTimeExpensesEntryListHours") bookKeepingTimeExpensesEntryListHoursPermission;
  @ViewChild("bookKeepingTimeExpensesEntryListExpensesMileage") bookKeepingTimeExpensesEntryListExpensesMileagePermission;
  @ViewChild("bookKeepingTimeExpensesHoursAccountSummary") bookKeepingTimeExpensesHoursAccountSummaryPermission;
  @ViewChild("bookKeepingTimeExpensesExpensesMileageAccountSummary") bookKeepingTimeExpensesExpensesMileageAccountSummaryPermission;
  @ViewChild('expenseUnlinkedMileageUpdate') expenseUnlinkedMileageUpdatePermission;
  @ViewChild('expenseUnlinkedMileageUpdateOwn') expenseUnlinkedMileageUpdateOwnPermission;
  @ViewChild('expenseInvestigationMileageUpdate') expenseInvestigationMileageUpdatePermission;
  @ViewChild('expenseInvestigationMileageUpdateOwn') expenseInvestigationMileageUpdateOwnPermission;
  @ViewChild('expenseInvestigationExpensesUpdate') expenseInvestigationExpensesUpdatePermission;
  @ViewChild('expenseInvestigationExpensesUpdateOwn') expenseInvestigationExpensesUpdateOwnPermission;
  @ViewChild('expenseUnlinkedExpenseUpdate') expenseUnlinkedExpenseUpdatePermission;
  @ViewChild('expenseUnlinkedExpenseUpdateOwn') expenseUnlinkedExpenseUpdateOwnPermission;
  @ViewChild('timeEntryUnlinkedHoursUpdate') timeEntryUnlinkedHoursUpdatePermission;
  @ViewChild('timeEntryUnlinkedHoursUpdateOwn') timeEntryUnlinkedHoursUpdateOwnPermission;
  @ViewChild('timeEntryInvestigationHoursUpdate') timeEntryInvestigationHoursUpdatePermission;
  @ViewChild('timeEntryInvestigationHoursUpdateOwn') timeEntryInvestigationHoursUpdateOwnPermission;
  @ViewChild('timeEntryInvestigationHoursCreate') timeEntryInvestigationHoursCreatePermission;
  @ViewChild('timeEntryUnlinkedHoursCreate') timeEntryUnlinkedHoursCreatePermission;
  @ViewChild('expenseUnlinkedMileageCreate') expenseUnlinkedMileageCreatePermission;
  @ViewChild('expenseInvestigationMileageCreate') expenseInvestigationMileageCreatePermission;
  @ViewChild('expenseInvestigationExpensesCreate') expenseInvestigationExpensesCreatePermission;
  @ViewChild('expenseUnlinkedExpenseCreate') expenseUnlinkedExpenseCreatePermission;

  public filtersActive = false;

  public searchValue: string = null;
  public selectedStaff = null;
  public selectedType = null;

  // Entry dates
  public entryStartDate = null;
  public entryEndDate = null;

  // Work dates
  public workStartDate = null;
  public workEndDate = null;

  // Paid Filters
  public selectedPaid = null;
  public paidEndDate = null;
  public paidStartDate = null;

  // Invoice filters
  public selectedInvoiced = null;
  public invoicedStartDate = null;
  public invoicedEndDate = null;

  public totalResults = null;
  public pageSize = 25;

  public summaryColumns = ["type", "billable", "nonBillable"];
  public entryColumns = ["STAFF", "investigation", "type", "billable", "nonBillable", "description", "EXPENSE_DATE", "paid", "INVOICE", "edit"];

  // Expense Data Source
  private _dataSource: ExpenseDataSource;
  public get dataSource() {
    return this._dataSource;
  }
  public set dataSource(val) {
    this._dataSource = val;
  }

  public get pageOptions() {
    if (!this._dataSource) return null;
    return this._dataSource.listPage;
  }

  // Time Data Source
  private _dataSourceTime: TimeEntryDataSource;
  public get dataSourceTime() {
    return this._dataSourceTime;
  }
  public set dataSourceTime(val) {
    this._dataSourceTime = val;
  }

  public get pageOptionsTime() {
    if (!this._dataSourceTime) return null;
    return this._dataSourceTime.listPage;
  }

  public typeListData: IApiExpenseItem[] | IApiTimeEntryType[] = [];
  public expenseItems: IApiExpenseItem[];
  public timeItems: IApiTimeEntryType[];
  public expenseItemSummary: ExpenseItem[];
  public timeEntryTypeSummary: IApiTimeEntryType[];
  public userViewFilter = IApiUserFilterType.ViewStaffUser;
  public expenseFilters: IApiExpenseItemFilter[] = [];
  public timeFilters: IApiTimeEntryTypeFilter[] = [];
  public currentActiveTab: string;
  public authenticatedUserId = null;
  public addEntryMenu = [];
  public colSpan = 0;
  constructor(
    private dialog: MatDialog,
    private expenseService: ExpenseService,
    private expenseItemService: ExpenseItemService,
    private timeEntryService: TimeEntryService,
    private timeEntryTypeService: TimeEntryTypeService,
    private loader: LoaderService,
    private downloadHelper: DownloadHelper,
    private notifications: NotificationsService,
    private dialogService: DialogService,
    private documentTypeService: DocumentTypeService,
    public auth: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sharedService: SharedService,
    private cdr: ChangeDetectorRef
  ) {
    this.loader.isMatLoader = false;
    this.dataSource = new ExpenseDataSource(this.expenseService);
    this.dataSourceTime = new TimeEntryDataSource(this.timeEntryService);
  }

  public isInvoiced(item: IApiExpense, permissionCheck = true): boolean {
    let invoiced = item.InvoiceItems?.find((inv) => inv?.invoiceableId === item?.id) ? true : false;
    if (!invoiced && permissionCheck) {
      if (this.displayDataType === dataType.hours) {
        if (item?.Investigation) {
          if (this.timeEntryInvestigationHoursUpdatePermission?.enabled || (this.timeEntryInvestigationHoursUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
          else return false;
        } else {
          if (this.timeEntryUnlinkedHoursUpdatePermission?.enabled || (this.timeEntryUnlinkedHoursUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
          else return false;
        }
      } else {
        if (item?.ExpenseItem?.name === 'Mileage') {
          if (item?.Investigation) {
            if (this.expenseInvestigationMileageUpdatePermission?.enabled || (this.expenseInvestigationMileageUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
            else return false;
          } else {
            if (this.expenseUnlinkedMileageUpdatePermission?.enabled || (this.expenseUnlinkedMileageUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
            else return false;
          }
        } else {
          if (item?.Investigation) {
            if (this.expenseInvestigationExpensesUpdatePermission?.enabled || (this.expenseInvestigationExpensesUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
            else return false;
          } else {
            if (this.expenseUnlinkedExpenseUpdatePermission?.enabled || (this.expenseUnlinkedExpenseUpdateOwnPermission?.enabled && item?.User?.id === this.authenticatedUserId)) return true;
            else return false;
          }
        }
      }
    } else {
      return !invoiced;
    }
  }

  public load() {
    if (this.displayDataType === dataType.hours) {
      this.timeEntryLoad();
    } else {
      this.expensesEntryLoad();
    }
  }

  // Entries, Expenses & Mileage
  public expensesEntryLoad() {
    this.dataSource.applyFilter(IApiExpenseFilterType.EvidenceBilling, 'false');
    this.dataSource.applyFilter(IApiExpenseFilterType.ViewBookkeepingView, 'true');
    if (this.searchValue?.trim()) {
      this.dataSource.applyFilter(IApiExpenseFilterType.Search, this.searchValue);
    } else {
      this.dataSource.removeFilter(IApiExpenseFilterType.Search);
    }
    if (this.selectedStaff) {
      this.dataSource.applyFilter(IApiExpenseFilterType.User, this.selectedStaff);
    } else {
      this.dataSource.removeFilter(IApiExpenseFilterType.User);
    }
    this.dataSource.applyFilter(IApiExpenseFilterType.ExpenseItem, this.selectedType ? this.selectedType.toString() : null);
    this.dataSource.applyFilter(IApiExpenseFilterType.IsInvoiced, this.selectedInvoiced === null ? null : this.selectedInvoiced.toString());
    this.dataSource.applyFilter(IApiExpenseFilterType.ExpensePaid, this.selectedPaid === null ? null : this.selectedPaid.toString());
    if (this.entryStartDate && this.entryEndDate) this.dataSource.applyFilter(IApiExpenseFilterType.CreatedatDate, NefcoDateHelper.dateFilterString(this.entryStartDate, this.entryEndDate));
    else this.dataSource.removeFilter(IApiExpenseFilterType.CreatedatDate);

    if (this.paidStartDate && this.paidEndDate) this.dataSource.applyFilter(IApiExpenseFilterType.ExpensePaidDateRange, NefcoDateHelper.dateFilterString(this.paidStartDate, this.paidEndDate));
    else this.dataSource.removeFilter(IApiExpenseFilterType.ExpensePaidDateRange);

    if (this.invoicedStartDate && this.invoicedEndDate) this.dataSource.applyFilter(IApiExpenseFilterType.InvoicedDate, NefcoDateHelper.dateFilterString(this.invoicedStartDate, this.invoicedEndDate));
    else this.dataSource.removeFilter(IApiExpenseFilterType.InvoicedDate);

    this.dataSource.pagingReset();
    this.dataSource.load();
  }

  // Entries, Time/Hours
  public timeEntryLoad() {
    this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.ViewListView, 'true');
    this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.ViewBookkeepingView, 'true');
    if (this.searchValue?.trim()) {
      this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.Search, this.searchValue);
    } else {
      this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Search);
    }
    if (this.selectedStaff) {
      this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.Employee, this.selectedStaff);
    } else {
      this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Employee);
    }

    this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.Type, this.selectedType ? this.selectedType.toString() : null);
    this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.Invoiced, this.selectedInvoiced === null ? null : this.selectedInvoiced.toString());
    this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.Paid, this.selectedPaid === null ? null : this.selectedPaid.toString());

    if (this.entryStartDate && this.entryEndDate) this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.CreatedatDate, NefcoDateHelper.dateFilterString(this.entryStartDate, this.entryEndDate));
    else this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.CreatedatDate);
    if (this.workEndDate && this.workStartDate) this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.DateRange, NefcoDateHelper.dateFilterString(this.workStartDate, this.workEndDate))
    else this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.DateRange);

    if (this.paidStartDate && this.paidEndDate) this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.PaidDate, NefcoDateHelper.dateFilterString(this.paidStartDate, this.paidEndDate));
    else this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.PaidDate);

    if (this.invoicedStartDate && this.invoicedEndDate) this.dataSourceTime.applyFilter(IApiTimeEntryFilterType.InvoicedDate, NefcoDateHelper.dateFilterString(this.invoicedStartDate, this.invoicedEndDate));
    else this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.InvoicedDate);

    this.dataSourceTime.pagingReset();
    this.dataSourceTime.load();
  }

  public loadSummary() {

    const expenseFilters = [
      { type: IApiExpenseItemFilterType.Summary, value: 'true' },
      { type: IApiExpenseItemFilterType.Id, value: this.selectedType },
      { type: IApiExpenseItemFilterType.ExpenseStaff, value: this.selectedStaff },
      { type: IApiExpenseItemFilterType.ExpensePaid, value: this.selectedPaid === null ? null : this.selectedPaid.toString() },
      { type: IApiExpenseItemFilterType.ExpenseInvoiced, value: this.selectedInvoiced === null ? null : this.selectedInvoiced.toString() },
    ].filter(({ value }) => value !== null);

    const timeFilters: any[] = [
      { type: IApiTimeEntryTypeFilterType.Summary, value: 'true' },
      { type: IApiTimeEntryTypeFilterType.Id, value: this.selectedType },
      { type: IApiTimeEntryTypeFilterType.EntryStaff, value: this.selectedStaff },
      { type: IApiTimeEntryTypeFilterType.Paid, value: this.selectedPaid === null ? null : this.selectedPaid.toString() },
      { type: IApiTimeEntryTypeFilterType.EntryInvoiced, value: this.selectedInvoiced === null ? null : this.selectedInvoiced.toString() },
    ].filter(({ value }) => value !== null);

    if (this.entryStartDate && this.entryEndDate) {
      const formattedDate = NefcoDateHelper.dateFilterString(this.entryStartDate, this.entryEndDate);
      expenseFilters.push({ type: IApiExpenseItemFilterType.CreatedatDate, value: formattedDate });
      timeFilters.push({ type: IApiTimeEntryTypeFilterType.CreatedatDate, value: formattedDate });
    }

    if (this.workEndDate && this.workStartDate) {
      const formattedDate = NefcoDateHelper.dateFilterString(this.workStartDate, this.workEndDate);
      timeFilters.push({ type: IApiTimeEntryTypeFilterType.EntryDate, value: formattedDate });
    }

    if (this.paidStartDate && this.paidEndDate) {
      const formattedDate = NefcoDateHelper.dateFilterString(this.paidStartDate, this.paidEndDate);
      expenseFilters.push({ type: IApiExpenseItemFilterType.ExpensePaidDate, value: formattedDate });
      timeFilters.push({ type: IApiTimeEntryTypeFilterType.PaidDate, value: formattedDate });
    }

    if (this.invoicedStartDate && this.invoicedEndDate) {
      const formattedDate = NefcoDateHelper.dateFilterString(this.invoicedStartDate, this.invoicedEndDate);
      expenseFilters.push({ type: IApiExpenseItemFilterType.ExpenseInvoicedDate, value: formattedDate });
      timeFilters.push({ type: IApiTimeEntryTypeFilterType.EntryInvoicedDate, value: formattedDate });
    }
    this.timeFilters = timeFilters;
    this.expenseFilters = expenseFilters;
    if (this.displayDataType === dataType.hours) {
      this.timeSummaryLoad(timeFilters);
    } else {
      this.expensesSummaryLoad(expenseFilters);
    }

  }

  // time summary load
  public timeSummaryLoad(timeFilters, sort = SortOrder.ASC, orderBy: string = IApiTimeEntryTypeOrderBy.Name) {
    this.loader.show$(
      this.timeEntryTypeService.get(timeFilters, { limit: -1, sortOrder: sort, orderBy: orderBy }).pipe(unwrapConnection())
    ).subscribe((timeEntryTypes) => {
      this.timeEntryTypeSummary = timeEntryTypes;
    });
  }

  // expenses summary load
  public expensesSummaryLoad(expenseFilters, sort = SortOrder.ASC, orderBy: string = IApiTimeEntryTypeOrderBy.Name) {
    this.loader.show$(
      this.expenseItemService.get(expenseFilters, { limit: -1, sortOrder: sort, orderBy: orderBy }).pipe(unwrapConnection()),
    ).subscribe((expenseItems) => {
      this.expenseItemSummary = expenseItems;
    });
  }

  // Only load the needed tab
  public loadAll() {
    this.form.control.markAllAsTouched();
    this.filterForm.control.markAllAsTouched();
    if ((!this.workStartDate && this.workEndDate) || (this.workStartDate && !this.workEndDate) || (!this.entryStartDate && this.entryEndDate) || (this.entryStartDate && !this.entryEndDate) || (this.invoicedStartDate && !this.invoicedEndDate) || (this.paidStartDate && !this.paidEndDate)) {
      return;
    }
    this.updateQueryParams();
    if (this.currentActiveTab === 'entryList') this.load();
    else this.loadSummary();
  }

  private updateQueryParams() {
      const filter = [{
        type: 'TAB',
        value: this.currentActiveTab === 'entryList' ? 'entrylist' : 'summary'
      }];

      if (this.displayDataType) {
        filter.push({
          type: 'SUB_TAB',
          value: this.displayDataType === dataType.hours ? dataType.hours : dataType.expenses
        });
      };

      if (this.searchValue?.trim()) {
        filter.push({
          type: 'SEARCH',
          value: this.searchValue?.trim()
        });
      };

      if (this.selectedStaff) {
        filter.push({
          type: 'USER_ID',
          value: this.selectedStaff
        });
      };

      if (this.entryStartDate && this.entryEndDate) {
        filter.push({
          type: 'ENTRY_START_DATE',
          value: this.entryStartDate
        }, {
          type: 'ENTRY_END_DATE',
          value: this.entryEndDate
        });
      }

      if (this.workStartDate && this.workEndDate) {
        filter.push({
          type: 'WORK_START_DATE',
          value: this.workStartDate
        }, {
          type: 'WORK_END_DATE',
          value: this.workEndDate
        });
      }

      if (this.selectedType) {
        filter.push({
          type: this.filterTypes.Type,
          value: this.selectedType
        });
      }

      if (this.selectedPaid) {
        filter.push({
          type: this.filterTypes.Paid,
          value: this.selectedPaid
        });
      }

      if (this.paidStartDate && this.paidEndDate) {
        filter.push({
          type: 'PAID_START_DATE',
          value: this.paidStartDate
        },{
          type: 'PAID_END_DATE',
          value: this.paidEndDate
        });
      }

      if (this.selectedInvoiced) {
        filter.push({
          type: this.filterTypes.Invoiced,
          value: this.selectedInvoiced
        });
      }

      if (this.invoicedStartDate && this.invoicedEndDate) {
        filter.push({
          type: 'INVOICE_START_DATE',
          value: this.invoicedStartDate
        },{
          type: 'INVOICE_END_DATE',
          value: this.invoicedEndDate
        });
      }

      this.sharedService.setLocalStorageByKey(`BOOKKEEPING_TIME_AND_EXPENSES`, filter);
      const queryParams = this.sharedService.getLocalStorageByKey(`BOOKKEEPING_TIME_AND_EXPENSES`)?.reduce((p, { type, value }) => {
        p[type] = value;
        return p;
      }, {});
      this.router.navigate([], { relativeTo: this.activatedRoute, queryParams });
  }

  public clearFilters() {
    this.sharedService.removeLocalStorageByKey(`BOOKKEEPING_TIME_AND_EXPENSES`);
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: {} });
    this.sortAccountSummarySort = [{
      field: 'NAME',
      dir: 'asc'
    }];
    this.searchValue = null;
    this.selectedStaff = null;
    this.selectedPaid = null;
    this.selectedType = null;
    this.selectedInvoiced = null;

    this.entryStartDate = null;
    this.entryEndDate = null;
    this.workStartDate = null;
    this.workEndDate = null;
    this.paidStartDate = null;
    this.paidEndDate = null;
    this.invoicedStartDate = null;
    this.invoicedEndDate = null;
    this.advancedFilters = [];

    // Expense Data Source
    this.dataSource.removeFilter(IApiExpenseFilterType.Search);
    this.dataSource.removeFilter(IApiExpenseFilterType.User);
    this.dataSource.removeFilter(IApiExpenseFilterType.ExpensePaid);
    this.dataSource.removeFilter(IApiExpenseFilterType.ExpenseType);
    this.dataSource.removeFilter(IApiExpenseFilterType.EntryDate);
    this.dataSource.removeFilter(IApiExpenseFilterType.ExpensePaidDateRange);
    this.dataSource.removeFilter(IApiExpenseFilterType.InvoicedDate);
    this.dataSource.removeFilter(IApiExpenseFilterType.CreatedatDate);

    // Time Data Source
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Search);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Employee);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Type);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.Invoiced);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.DateRange);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.InvoicedDate);
    this.dataSourceTime.removeFilter(IApiTimeEntryFilterType.CreatedatDate);

    this.loadAll();
  }

  public loadDropdowns() {
    this.loader.show$(
      forkJoin([
        this.expenseItemService.get([], { limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiExpenseItemOrderBy.Name }).pipe(unwrapConnection()),
        this.timeEntryTypeService.get([], { limit: -1, sortOrder: SortOrder.DESC, orderBy: IApiTimeEntryOrderBy.CreatedAt }).pipe(unwrapConnection()),
        this.documentTypeService.get([], { sortOrder: SortOrder.ASC, limit: -1 }).pipe(unwrapConnection())
      ])
    ).subscribe(([expenseItems, timeEntryTypes, documentTypes]) => {
      this.documentTypes = documentTypes;
      this.expenseItems = expenseItems.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      this.timeItems = timeEntryTypes.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      });

      if (this.displayDataType === dataType.hours) {
        this.typeListData = [...this.timeItems];
      } else {
        this.typeListData = [...this.expenseItems];
      }
    });
  }

  public editRecord(row: IApiExpense | IApiTimeEntry, e: MouseEvent) {
    e.stopPropagation();
    const data: IInvestigationTimeExpenseModalData = {
      expense: (row.__typename === 'Expense' ? { ...row as IApiExpense } : null),
      time: (row.__typename === 'TimeEntry' ? { ...row as IApiTimeEntry } : null),
      investigationId: row?.Investigation?.id,
      nefcoNumber: row?.Investigation?.nefcoNumber,
      staff: [row?.User],
      showStaffMemberAutoComplete: true,
    };
    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.excludeExpenseItems = false;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = [];
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) this.load();
    });
  }

  public itemSelected($event) {
    let action: any = ExpenseActionType.ADD_EXPENSE;
    switch ($event?.text) {
      case 'Add Hours':
        action = ExpenseActionType.ADD_TIME;
        break;
      case 'Add Expense':
        action = ExpenseActionType.ADD_EXPENSE;
        break;
      case 'Add Mileage':
        action = ExpenseActionType.ADD_MILEAGE;
        break;
      default:
        action = '';
        break;
    }

    if (!action) {
      return
    }
    const expenseItem = {
      id: null,
      nefcoVehicle: 0,
      expenseDate: new Date(),
      outOfPocket: action === ExpenseActionType.ADD_EXPENSE ? 1 : 0,
      billableQuantity: null,
      nonBillableQuantity: null,
      description: null,
      // User: user,
      ExpenseItem: { id: null, name: null },
      ExpensePurpose: { id: null, name: null }
    };
    const timeItem: IApiTimeEntry = {
      id: null,
      workday: new Date(),
      Type: { id: null, createdAt: new Date(), updatedAt: new Date() },
      hours: 0,
      description: '',
      User: { id: null, email: null },
      createdAt: new Date(),
      updatedAt: new Date()
    };

    const data: IInvestigationTimeExpenseModalData = {
      investigationId: null,
      nefcoNumber: null,
      expense: expenseItem as IApiExpense,
      time: timeItem,
      // staff: row?.Investigation?.InvestigationStaff,
      adminView: false,
      showStaffMember: false,
      showStaffMemberAutoComplete: true,
      actionType: action
    };

    const dialog: DialogRef = this.dialogService.open({
      content: InvestigationTimeAndExpModalKendoComponent,
      width: 651,
      maxWidth: 651,
      preventAction: (ev) => {
        return ev !== 'closed' as any;
      },
    });

    const dialogInstance = dialog.content.instance as InvestigationTimeAndExpModalKendoComponent;
    dialogInstance.data = data;
    dialogInstance.excludeExpenseItems = false;
    dialogInstance.documentTypes = this.documentTypes;
    dialogInstance.investigations = [];
    dialogInstance.expenseItems = this.expenseItems;
    dialog.result.pipe(
      filter((v) => !!v)
    ).subscribe((result: DialogCloseResult) => {
      if (result === true) {
        this.load();
      }
    })
  }

  async ngOnInit() {
    this.auth.authenticatedUser.subscribe((u) => this.authenticatedUserId = u.id);
    this.dataSourceTime.contents$.subscribe((content) => {
      this.timeData = content;
    });
    this.dataSource.contents$.subscribe((content) => {
      this.expensesData = content;
    });
    this.loader.attachObservable(this.dataSource.loading$);
    this.loader.attachObservable(this.dataSourceTime.loading$);

    this.currentActiveTab = 'summary';
    this.auth.hasCategoryPermission(this.authConfig.bookKeepingTimeExpensesEntryListHours.category, this.authConfig.bookKeepingTimeExpensesEntryListHours.appliedPermissions).subscribe(res => {
      if (res) {
        this.currentActiveTab = 'entryList';
        this.displayDataType = dataType.hours;
      }
    });

    this.auth.hasCategoryPermission(this.authConfig.bookKeepingTimeExpensesEntryListExpensesMileage.category, this.authConfig.bookKeepingTimeExpensesEntryListExpensesMileage.appliedPermissions).subscribe(res => {
      if (res) {
        this.currentActiveTab = 'entryList';
        if (!this.displayDataType) {
          this.displayDataType = dataType.expenses;
        }
      }
    });

    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).pipe(
      tap()
    ).subscribe(async (params) => {
      let urlActiveTab = null;
      let urlActiveSubTab = null;
      Object.keys(params).forEach((k: any) => {
        const value = params[k];
        switch (k) {
          case 'TAB':
            urlActiveTab = (value === 'entrylist') ? 'entryList' : value === 'summary' ? 'summary' : '';
            break;
          case 'SUB_TAB':
            urlActiveSubTab = value === 'hours' ? dataType.hours : dataType.expenses;
            break;
        };
      });

      if (urlActiveTab && urlActiveSubTab) {
        this.currentActiveTab = urlActiveTab;
        this.displayDataType = urlActiveSubTab;
      }

      const queryParams = this.sharedService.getLocalStorageByKey(`BOOKKEEPING_TIME_AND_EXPENSES`)?.reduce((p, { type, value }) => {
        p[type] = value;
        return p;
      }, {});

      await this.router.navigate([], { relativeTo: this.activatedRoute, queryParams});

      this.activatedRoute.queryParams.pipe(untilDestroyed(this)).pipe(
        tap()
      ).subscribe((params) => {
        this.advancedFilters = [];
        Object.keys(params).forEach((k: any) => {
          const value = params[k];
          switch (k) {
            case 'TAB':
              this.currentActiveTab = (value === 'entrylist') ? 'entryList' : value === 'summary' ? 'summary' : '';
              break;
            case 'SUB_TAB':
              this.displayDataType = value === 'hours' ? dataType.hours : dataType.expenses;
              break;
            case 'SEARCH':
              this.searchValue = value;
              break;
            case 'USER_ID':
              this.selectedStaff = value;
              break;
            case 'ENTRY_START_DATE':
              this.entryStartDate = new Date(value);
              break;
            case 'ENTRY_END_DATE':
              this.entryEndDate = new Date(value);
              break;
            case 'WORK_START_DATE':
              this.workStartDate = new Date(value);
              break;
            case 'WORK_END_DATE':
              this.workEndDate = new Date(value);
              break;
            case 'Type':
              this.advancedFilters.push(this.filterTypes.Type);
              this.selectedType = value;
              break;
            case 'Paid':
              this.advancedFilters.push(this.filterTypes.Paid);
              this.selectedPaid = value;
              break;
            case 'PAID_START_DATE':
              this.paidStartDate = new Date(value);
              break;
            case 'PAID_END_DATE':
              this.paidEndDate = new Date(value);
              break;
            case 'Invoiced':
              this.advancedFilters.push(this.filterTypes.Invoiced);
              this.selectedInvoiced = value;
              break;
            case 'INVOICE_START_DATE':
              this.invoicedStartDate = new Date(value);
              break;
            case 'INVOICE_END_DATE':
              this.invoicedEndDate = new Date(value);
              break;
          };
        });
        this.setColSpan();
        if (this.currentActiveTab === 'entryList') {
          this.load();
        } else {
          this.loadSummary();
        }
      });
    })


  }

  ngAfterViewInit() {
    if (this.timeEntryInvestigationHoursCreatePermission?.enabled || this.timeEntryUnlinkedHoursCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Hours'});
    }
    if (this.expenseUnlinkedMileageCreatePermission?.enabled || this.expenseInvestigationMileageCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Mileage'});
    }
    if (this.expenseInvestigationExpensesCreatePermission?.enabled || this.expenseUnlinkedExpenseCreatePermission?.enabled) {
      this.addEntryMenu.push({text: 'Add Expense'});
    }
    this.loadDropdowns();
    this.cdr.detectChanges();
  }

  public sort: SortDescriptor[] = [{
    field: '',
    dir: 'desc'
  }];

  public sortChange = (e: SortDescriptor[]) => {
    this.sort = e;
    if (this.displayDataType === dataType.hours) {
      if (e && e?.[0]?.dir) {
        this.dataSourceTime.listPage.orderBy = e?.[0]?.field;
        this.dataSourceTime.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
      } else {
        this.dataSourceTime.listPage.orderBy = 'CREATED_AT';
        this.dataSourceTime.listPage.sortOrder = SortOrder.DESC;
      }
      this.dataSourceTime.pagingReset();
      this.dataSourceTime.load();
    } else {
      if (e && e?.[0]?.dir) {
        this.dataSource.listPage.orderBy = e?.[0]?.field;
        this.dataSource.listPage.sortOrder = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
      } else {
        this.dataSource.listPage.orderBy = 'CREATED_AT';
        this.dataSource.listPage.sortOrder = SortOrder.DESC;
      }
      this.dataSource.pagingReset();
      this.dataSource.load();
    }
  }

  public sortAccountSummarySort: SortDescriptor[] = [{
    field: 'NAME',
    dir: 'asc'
  }];


  public sortChangeAccountSummary = (e: SortDescriptor[]) => {
    this.sortAccountSummarySort = e;
    if (this.displayDataType === dataType.hours) {
      let sort = SortOrder.ASC;
      let orderBy = 'NAME';
      if (e && e?.[0]?.dir) {
        orderBy = e?.[0]?.field;
        sort = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
      } else {
        orderBy = 'NAME';
        sort = SortOrder.ASC;
      }
      this.timeSummaryLoad(this.timeFilters, sort, orderBy);
    } else {
      let sort = SortOrder.ASC;
      let orderBy = 'NAME';
      if (e && e?.[0]?.dir) {
        orderBy = e?.[0]?.field;
        sort = e?.[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
      } else {
        orderBy = 'NAME';
        sort = SortOrder.ASC;
      }
      this.expensesSummaryLoad(this.expenseFilters, sort, orderBy);
    }
  }

  public timeData: Array<IApiTimeEntry> = [];
  public expensesData: Array<IApiExpense> = [];
  public displayDataType: dataType;
  public dataTypesOpts = dataType;
  public filterColumns = [`repeat(${8},minmax(0,1fr))`];
  public filterTypes = advanceFiltersType;
  public availableFilters: Array<string> = Object.keys(advanceFiltersType);
  public advancedFilters: Array<string> = [];
  public today: Date = new Date();
  public submitted = false;
  public dropDownOption = [{ id: 'true', title: 'Yes' }, { id: 'false', title: 'No' }];
  private documentTypes: IApiDocumentType[] = [];

  public pageChange(event) {
    this.setColSpan();
    switch (this.displayDataType) {
      case dataType.hours:
        this.pageOptionsTime?.paginate(event);
        break;
      case dataType.expenses:
        this.pageOptions?.paginate(event);
        break;
    }
  }

  private setColSpan() {
    if (this.currentActiveTab === 'entryList') {
      if (this.displayDataType === dataType.hours ) {
        this.colSpan = 0;
      } else {
        this.colSpan = 3;
      }
    } else {
      if (this.displayDataType === dataType.hours ) {
        this.colSpan = 2;
      } else {
        this.colSpan = 4;
      }
    };
  }

  public onTabSelect(e: SelectEvent): void {
    this.currentActiveTab = e?.title === 'Account Summary' ? 'summary' : 'entryList';
    if (this.currentActiveTab === 'entryList') {
      this.displayDataType = (this.bookKeepingTimeExpensesEntryListHoursPermission?.enabled) ? dataType.hours : dataType.expenses;
    } else {
      this.displayDataType = (this.bookKeepingTimeExpensesHoursAccountSummaryPermission?.enabled) ? dataType.hours : dataType.expenses;
    }
    this.setColSpan();
    if (this.currentActiveTab === 'entryList') {
      this.load();
    } else {
      this.loadSummary();
    }
  }

  public viewChange(e: dataType): void {
    if (e === this.dataTypesOpts.expenses) {
      this.typeListData = [...this.expenseItems];
    } else {
      this.typeListData = [...this.timeItems];
    }
    this.selectedType = null;
    this.setColSpan();
    if (this.currentActiveTab === 'entryList') {
      this.load();
    } else {
      this.loadSummary();
    }
  }

  // handles changes on the staff
  public setSelectedStaff(event: string): void {
    this.selectedStaff = event;
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 2 ? tags : [tags];
  }
  public advancedFilterValueChange(e: string[] = []) {
    // this.submitted = false;
    if (e?.length === 0) {
      this.clearAdvanceFilter();
    }
  }

  public removeFilterCard(filter: advanceFiltersType) {
    this.advancedFilters = this.advancedFilters.filter((e) => e !== filter);
    if (filter === advanceFiltersType.Type) {
      this.selectedType = null;
    } else if (filter === advanceFiltersType.Paid) {
      this.selectedPaid = null;
      this.paidStartDate = null;
      this.paidEndDate = null;
    } else if (filter === advanceFiltersType.Invoiced) {
      this.selectedInvoiced = null;
      this.invoicedStartDate = null;
      this.invoicedEndDate = null;
    }
  }

  public clearAdvanceFilter() {
    this.selectedPaid = null;
    this.selectedType = null;
    this.selectedInvoiced = null;

    this.paidStartDate = null;
    this.paidEndDate = null;
    this.invoicedStartDate = null;
    this.invoicedEndDate = null;
  }

  public exportAsCSV() {
    let sort: SortOrder = SortOrder.DESC;
    let orderBy: string = IApiTimeEntryTypeOrderBy.Name;
    if (this.sortAccountSummarySort?.length > 0) {
      sort = this.sortAccountSummarySort[0]?.dir === 'asc' ? SortOrder.ASC : SortOrder.DESC;
      orderBy = this.sortAccountSummarySort[0].field
    }

    let endPoint = this.timeEntryTypeService.accountSummaryHoursReport(
      this.timeFilters,
      { limit: -1, sortOrder: sort, orderBy: orderBy }
    );
    if (this.displayDataType === dataType.expenses) {
      endPoint = this.expenseItemService.accountSummaryExpenseReport(
        this.expenseFilters,
        { limit: -1, sortOrder: sort, orderBy: orderBy }
      );
    }

    this.loader.show$(endPoint.pipe(
      this.notifications.snackbarErrorPipe("Error downloading report; please try again"),
    )).subscribe(res => {
      if (res) {
        this.downloadHelper.download(res, `account-summary-${this.displayDataType}-report`);
      }
    });
  }

  public getListPermission(type) {
    if (this.currentActiveTab === 'entryList') {
      if (type === 'hours') {
        if (this.bookKeepingTimeExpensesEntryListHoursPermission?.enabled) return true;
        else return false;
      } else {
        if (this.bookKeepingTimeExpensesEntryListExpensesMileagePermission?.enabled) return true;
        else return false;
      }
    } else {
      if (type === 'hours') {
        if (this.bookKeepingTimeExpensesHoursAccountSummaryPermission?.enabled) return true;
        else return false;
      } else {
        if (this.bookKeepingTimeExpensesExpensesMileageAccountSummaryPermission?.enabled) return true;
        else return false;
      }
    }
  }

}
