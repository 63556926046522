import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { SafeArea } from 'capacitor-plugin-safe-area';
import { NefcoDateHelper } from 'src/app/shared/helpers/nefco-date.class';
import { SortOrder } from 'src/app/shared/modules/graphql/enums/generic.enums';
import { IApiExpense, IApiExpenseFilter, IApiExpenseFilterType, IApiExpenseMileageTotal, IApiExpenseOrderBy, IApiExpenseReportTotal, IApiTimeEntry, IApiTimeEntryFilterType, IApiTimeEntryOrderBy, IApiTimeEntryTotal, IApiUser } from 'src/app/shared/modules/graphql/types/types';
import { LoaderService } from 'src/app/shared/modules/loader/loader.service';
import { AuthService, TimeEntryService } from 'src/app/shared/services';
import { ExpenseDataSource, ExpenseService } from 'src/app/shared/services/expenses';
import { TimeEntryDataSource } from 'src/app/shared/services/time-entry';
import { NotificationsService } from 'src/app/shared/modules/notifications/notifications.service';
import { filter, switchMap } from 'rxjs/operators';
import moment from 'moment';

export enum TABS {
  "MY_HOURS" = "MY_HOURS",
  "MY_EXPENSES" = "MY_EXPENSES",
  "OUT_OF_POCKET" = "OUT_OF_POCKET"
};

@Component({
  selector: 'app-mobile-time-and-expense-tabs',
  templateUrl: './mobile-time-and-expense-tabs.component.html',
  styleUrls: ['./mobile-time-and-expense-tabs.component.scss']
})
export class MobileTimeAndExpenseTabsComponent implements OnInit, AfterViewInit {

  public selectedTab = TABS.MY_HOURS as string;
  public TABS = TABS;
  public expenseTypeSelected = null;

  /* TIME ENTRY */
  private _dataSourceTimeEntry: TimeEntryDataSource;
  public set dataSourceTimeEntry(val) {
    this._dataSourceTimeEntry = val;
  }
  public get dataSourceTimeEntry(): TimeEntryDataSource {
    return this._dataSourceTimeEntry;
  }

  public get pageOptionsTimeEntry() {
    if (!this._dataSourceTimeEntry) return null;
    return this._dataSourceTimeEntry.listPage;
  }
  private dateFilterWeek: string = (moment().isoWeek() - 1).toString();
  private dateFilterYear: string = (moment().year()).toString();
  private dateRangeFilterTimeEntry: string;
  private dateRangeFilterExpenseMileage: string;
  public timeEntriesCount: IApiTimeEntryTotal;
  public timeEntries: IApiTimeEntry[] = [];
  /* TIME ENTRY */

  /* EXPENSE & MILEAGE ENTRY */
  private _dataSourceExpenseMileage: ExpenseDataSource;
  public set dataSourceExpenseMileage(val) {
    this._dataSourceExpenseMileage = val;
  }
  public get dataSourceExpenseMileage() {
    return this._dataSourceExpenseMileage;
  }

  public get pageOptionsExpenseMilage() {
    if (!this._dataSourceExpenseMileage) return null;
    return this._dataSourceExpenseMileage.listPage;
  }
  private _expenseFilter: IApiExpenseFilter[] = [];
  private _selectedMonthExpenseMileage = NefcoDateHelper.dateFilterStringUTCFromLocal(moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  );
  public expenses: IApiExpense[] = [];
  public expenseMileageCount: IApiExpenseMileageTotal;
  /* EXPENSE & MILEAGE ENTRY */

  /* Out Of Expense ENTRY */
  private _dataSourceOutOfExpense: ExpenseDataSource;
  public set dataSourceOutOfExpense(val) {
    this._dataSourceOutOfExpense = val;
  }
  public get dataSourceOutOfExpense() {
    return this._dataSourceOutOfExpense;
  }

  public get pageOptionsOutOfExpense() {
    if (!this._dataSourceOutOfExpense) return null;
    return this._dataSourceOutOfExpense.listPage;
  }
  private _outOfExpenseFilter: IApiExpenseFilter[] = [];
  private _selectedMonthOutOfExpenses = NefcoDateHelper.dateFilterStringUTCFromLocal(moment().startOf("month").toDate(),
    moment().endOf("month").toDate(),
  );
  public outOfExpenses: IApiExpense[] = [];
  public outOfExpenseCount: IApiExpenseReportTotal;
  /* Out Of Expense ENTRY */
  
  private user: IApiUser = null;

  constructor(
    private authService: AuthService,
    private loaderService: LoaderService,
    private timeEntryService: TimeEntryService,
    private expenseService: ExpenseService,
    private notificationsService: NotificationsService,
  ) { 
    this.authService.authenticatedUser.subscribe((r) => this.user = r);
  }

  ngOnInit(): void {
    this._dataSourceTimeEntry = new TimeEntryDataSource(this.timeEntryService);
    this.loaderService.attachObservable(this._dataSourceTimeEntry.loading$);
    this._dataSourceTimeEntry.listPage.orderBy = IApiTimeEntryOrderBy.Workday;
    this._dataSourceTimeEntry.listPage.sortOrder = SortOrder.ASC;
    this._dataSourceTimeEntry.listPage.limit = 25;
    this.getTimeEntry();
    this.loadTimeEntry();


    this._dataSourceExpenseMileage = new ExpenseDataSource(this.expenseService);
    this.loaderService.attachObservable(this._dataSourceExpenseMileage.loading$);
    this._dataSourceExpenseMileage.listPage.orderBy = IApiExpenseOrderBy.ExpenseDate;
    this._dataSourceExpenseMileage.listPage.sortOrder = SortOrder.DESC;
    this._dataSourceExpenseMileage.listPage.limit = 25;

    this._dataSourceOutOfExpense = new ExpenseDataSource(this.expenseService);
    this.loaderService.attachObservable(this._dataSourceOutOfExpense.loading$);
    this._dataSourceOutOfExpense.listPage.sortOrder = SortOrder.DESC;
    this._dataSourceOutOfExpense.listPage.orderBy = IApiExpenseOrderBy.CreatedAt;
    this._dataSourceOutOfExpense.listPage.limit = 25;

    this.dataSourceTimeEntry?.contents$.subscribe((res) => {
      this.timeEntries.push(...res);
    });

    this.dataSourceExpenseMileage?.contents$.subscribe((res) => {
      this.expenses.push(...res);
    });

    this.dataSourceOutOfExpense?.contents$.subscribe((res) => {
      this.outOfExpenses.push(...res);
    });
  }

  public ngAfterViewInit(): void {
    this.setSafeAreaView();
  }

  public setSafeAreaView() {
    SafeArea.getSafeAreaInsets().then(({ insets }) => {
      if (Capacitor.getPlatform() === 'android') {
        /* time-and-expense */
        const element = document.getElementById('time-and-expense');
        element.style.top = `${(16 + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      } else if (Capacitor.getPlatform() === 'ios') {
        /* time-and-expense */
        const element = document.getElementById('time-and-expense')
        element.style.top = `${(insets.top + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      } else {
        /* search header */
        const element = document.getElementById('time-and-expense')
        element.style.top = `${(16 + 38 + 16 + 1)}px`;
        element.style.position = `relative`;
      }
    });
  }

  public changeTab(event: SelectEvent = { title: this.selectedTab } as any, scroll = true) {
    if (scroll) window?.scrollTo(0, 0);
    this.selectedTab = event?.title || this.selectedTab;
    switch (this.selectedTab) {
      case TABS.MY_HOURS:
        this.getTimeEntry();
        this.loadTimeEntry(true);
        break;
      case TABS.MY_EXPENSES:
        this.getExpenseMilageEntry();
        this.loadExpenseMilageEntry(true);
        break;
      case TABS.OUT_OF_POCKET:
        this.getOutOfExpenses();
        this.loadOutOfExpenses(true);
        break;
      default:
        break;
    }
  }

  private getTimeEntry() {
    this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.Week, this.dateFilterWeek);
    this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.Year, this.dateFilterYear)
    this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.Employee, "");
  }

  private getTimeEntryTotal() {
    this.timeEntryService
      .getTimeEntryTotal(
        this.dataSourceTimeEntry.lastFilters,
        this.pageOptionsTimeEntry.getPageOptions()
      )
      .subscribe((r) => this.timeEntriesCount = r);
  }

  public loadTimeEntry(reset = false) {
    if (reset) {
      this.timeEntries = [];
      this.dataSourceTimeEntry.pagingReset();
    }
    this.dataSourceTimeEntry.load().then(() => {
      this.getTimeEntryTotal();
    });
  }
  
  public onNearEndScrollTimeEntry() {
    const maxPage = Math.round(this.dataSourceTimeEntry.listPage.totalCount / this.dataSourceTimeEntry.listPage.limit);
    const nextPage = this.dataSourceTimeEntry.listPage.currentPageIndex + 1;
    if (maxPage <= nextPage) return;
    this.pageOptionsTimeEntry?.paginate({
      pageIndex: nextPage,
      previousPageIndex: this.dataSourceTimeEntry.listPage.currentPageIndex,
      pageSize: this.dataSourceTimeEntry.listPage.limit,
      length: 0
    }, false);
  }

  private applyFilters() {
    this.dataSourceTimeEntry.removeFilter(IApiTimeEntryFilterType.PayPeriod);
    if (this.dateRangeFilterTimeEntry) {
      this.dataSourceTimeEntry.removeFilter(IApiTimeEntryFilterType.Week);
      this.dataSourceTimeEntry.removeFilter(IApiTimeEntryFilterType.Year);
      this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.DateRange, this.dateRangeFilterTimeEntry);
    } else {
      this.dataSourceTimeEntry.removeFilter(IApiTimeEntryFilterType.DateRange);
      this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.Year, this.dateFilterYear)
      this.dataSourceTimeEntry.applyFilter(IApiTimeEntryFilterType.Week, this.dateFilterWeek);
    }
    this.loadTimeEntry(true);
  }


  public dateRangeChangeTimeEntry(event) {
    this.dateRangeFilterTimeEntry = event;
    this.applyFilters();
  }

  public dateChangeWeek(event) {
    this.dateRangeFilterTimeEntry = null;
    this.dateFilterWeek = (event.isoWeek() - 1).toString();
    this.dateFilterYear = (event.year()).toString();
    this.applyFilters();
  }

  private getExpenseMilageEntry() {
    // this._dataSourceExpenseMileage = new ExpenseDataSource(this.expenseService);
    // this.loaderService.attachObservable(this._dataSourceExpenseMileage.loading$);
    // this._dataSourceExpenseMileage.listPage.orderBy = IApiExpenseOrderBy.ExpenseDate;
    // this._dataSourceExpenseMileage.listPage.sortOrder = SortOrder.DESC;
    // this._dataSourceExpenseMileage.listPage.limit = 1;
    this.setExpenseFilters(this.user?.id, IApiExpenseFilterType.User);
    this.setExpenseFilters(this._selectedMonthExpenseMileage, IApiExpenseFilterType.EntryDateRange);
    this.setExpenseFilters("true", IApiExpenseFilterType.ExpenseFilterOutForUser);
    this.setExpenseFilters("true", IApiExpenseFilterType.ExcludeNefcoVehicle);
  }

  private getExpenseMileageTotal() {
    this.expenseService
      .getExpenseMileageTotal(
        this.dataSourceExpenseMileage.lastFilters,
        this.pageOptionsExpenseMilage.getPageOptions()
      )
      .subscribe((response) => (this.expenseMileageCount = response));
  }

  private loadExpenseMilageEntry(reset = false) {
    if (reset) {
      this.expenses = [];
      this.dataSourceExpenseMileage.pagingReset();
    }
    this.dataSourceExpenseMileage.load().then(() => {
      this.getExpenseMileageTotal();
    });
  }

  public onNearEndScrollExpensesMileage() {
    const maxPage = Math.round(this.dataSourceExpenseMileage.listPage.totalCount / this.dataSourceExpenseMileage.listPage.limit);
    const nextPage = this.dataSourceExpenseMileage.listPage.currentPageIndex + 1;
    if (maxPage <= nextPage) return;
    this.pageOptionsExpenseMilage?.paginate({
      pageIndex: nextPage,
      previousPageIndex: this.dataSourceExpenseMileage.listPage.currentPageIndex,
      pageSize: this.dataSourceExpenseMileage.listPage.limit,
      length: 0
    }, false);
  }

  private setExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this._expenseFilter.filter(f => f.type !== type);
    this._expenseFilter = [...filtersCopy, {
      type: type,
      value: value
    }]
    this._expenseFilter.map(item => {
      this.dataSourceExpenseMileage.applyFilter(item.type, item.value);
    })
  }

  private applyFiltersExpenseMileage() {
    this.setExpenseFilters(this.dateRangeFilterExpenseMileage || this._selectedMonthExpenseMileage, IApiExpenseFilterType.EntryDateRange)
    this.loadExpenseMilageEntry(true);
  }

  public dateChangeMonth(event) {
    this.dateRangeFilterExpenseMileage = '';
    this._selectedMonthExpenseMileage = event;
    this.applyFiltersExpenseMileage();
  }

  public dateRangeChangeExpenseMileage(event) {
    this.dateRangeFilterExpenseMileage = event;
    this.applyFiltersExpenseMileage();
  }

  private getOutOfExpenses() {
    // this._dataSourceOutOfExpense = new ExpenseDataSource(this.expenseService);
    // this.loaderService.attachObservable(this._dataSourceOutOfExpense.loading$);
    // this._dataSourceOutOfExpense.listPage.sortOrder = SortOrder.DESC;
    // this._dataSourceOutOfExpense.listPage.orderBy = IApiExpenseOrderBy.CreatedAt;
    // this._dataSourceOutOfExpense.listPage.limit = 1;
    this.setOutOfExpenseFilters(this.user?.id, IApiExpenseFilterType.User);
    this.setOutOfExpenseFilters(this._selectedMonthOutOfExpenses || null, IApiExpenseFilterType.EntryDateRange);
    this.setOutOfExpenseFilters("true", IApiExpenseFilterType.ExpenseFilterOutForUser);
    this.setOutOfExpenseFilters('1', IApiExpenseFilterType.PocketExpenseMileage);
  }

  private getOutOfExpensesTotal() {
    this.expenseService.getExpenseReportTotal(this._outOfExpenseFilter, this.pageOptionsOutOfExpense.getPageOptions())
    .subscribe(response => this.outOfExpenseCount = response);
  }

  private loadOutOfExpenses(reset = false) {
    if (reset) {
      this.outOfExpenses = [];
      this.dataSourceOutOfExpense.pagingReset();
    }
    this.dataSourceOutOfExpense.load().then(() => {
      this.getOutOfExpensesTotal();
    });
  }

  public onNearEndScrollOutOfExpenses() {
    const maxPage = Math.round(this.dataSourceOutOfExpense.listPage.totalCount / this.dataSourceOutOfExpense.listPage.limit);
    const nextPage = this.dataSourceOutOfExpense.listPage.currentPageIndex + 1;
    if (maxPage <= nextPage) return;
    this.pageOptionsOutOfExpense?.paginate({
      pageIndex: nextPage,
      previousPageIndex: this.dataSourceOutOfExpense.listPage.currentPageIndex,
      pageSize: this.dataSourceOutOfExpense.listPage.limit,
      length: 0
    }, false);
  }

  private setOutOfExpenseFilters(value: string | undefined, type: IApiExpenseFilterType) {
    const filtersCopy = this._outOfExpenseFilter.filter(f => f.type !== type);
    this._outOfExpenseFilter = value ? [...filtersCopy, {
      type: type,
      value: value
    }] : filtersCopy;
    this._outOfExpenseFilter.map(item => {
      this.dataSourceOutOfExpense.applyFilter(item.type, item.value);
    });
  }

  public dateChangeOutOfExpensesMonth(event) {
    this._selectedMonthOutOfExpenses = event;
    this.setOutOfExpenseFilters(this._selectedMonthOutOfExpenses || null, IApiExpenseFilterType.EntryDateRange);
    this.loadOutOfExpenses(true);
  }

  public addExpenses(addExpenseType: string) {
    this.expenseTypeSelected = addExpenseType;
  }

  public resetExpenseTypeSelected() {
    this.expenseTypeSelected = null;
    window.scrollTo(0,0);
  }

  public deleteTimeEntry(item: IApiTimeEntry) {
    this.notificationsService
      .kendoConfirm(
        "Are you sure you want to delete this hours entry?",
        "Delete Hours Entry?",
        "No, Don’t Delete",
        "Yes, Delete",
        360
      )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.timeEntryService.remove(item.id)),
        this.notificationsService.snackbarErrorPipe("Error removing hours entry."),
        this.notificationsService.snackbarPipe("Hours entry deleted")
      )
      .subscribe(() => {
        this.changeTab({title: this.selectedTab } as any, false);
      });
  }

  public deleteExpenses(item: IApiExpense) {
    this.notificationsService
      .kendoConfirm(
        "Are you sure you want to delete this expense entry?",
        "Delete Expense Entry?",
        "No, Don’t Delete",
        "Yes, Delete",
        360
      )
      .pipe(
        filter((v) => !!v),
        switchMap(() => this.expenseService.remove(item.id)),
        this.notificationsService.snackbarErrorPipe("Error removing expense entry."),
        this.notificationsService.snackbarPipe("Expense entry deleted")
      )
      .subscribe(() => {
        this.changeTab({title: this.selectedTab } as any, false);
      });
  }

  public trackByIndex(index: number) {
    return index;
  }

}
